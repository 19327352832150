import React, { useEffect, useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterTypeDetails } from 'web/graphql/queries';
import { useSubscribedQuery } from 'web/hooks';
import { SbClientSdk } from 'web/react-redux/components/sb-client-sdk';
import { withApolloClient } from 'web/react-redux/hocs';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getInfoTrackUrl } from 'web/services/infotrack-urls';
import { sendMetric } from 'web/services/metrics';

export const INFOTRACK_MODAL_ID = 'INFOTRACK_MODAL_ID';

const hooks = ({ matterId, onClickLink }) => ({
  useMatterTypeDetails: () => {
    const matterTypeQueryResult = useSubscribedQuery(MatterTypeDetails, {
      skip: !matterId,
      variables: {
        matterId,
        matterFilter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
        },
      },
    });

    if (matterTypeQueryResult.error) {
      throw matterTypeQueryResult.error;
    }

    const { data } = matterTypeQueryResult;
    const matterTypeId = data?.matter.matterType.matterTypeId;

    return { matterId, onClickLink, matterTypeId };
  },
});

const Container = (props) => {
  const [url, setUrl] = useState<string>();

  const { onClickLink, matterId, matterTypeId, documentId } = props;

  useEffect(() => {
    sendMetric('OpenInfoTrack');

    if (matterTypeId) {
      getInfoTrackUrl(matterId, matterTypeId, documentId).then((v) => {
        setUrl(v);
      });
    }
  }, [matterTypeId]);

  return url ? <SbClientSdk sdkAppUrl={url} onClickLink={onClickLink} matterId={matterId} /> : null;
};

export const InfoTrackIframeContainer = withApolloClient(composeHooks(hooks)(withReduxProvider(Container)));
