import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SlidingToggle, DateRangePicker, Button } from '@sb-itops/react';
import { Table, Column, utils } from '@sb-itops/react/table';
import { Checkbox } from '@sb-itops/react/checkbox';
import { BillingSupportAccountingTransactionsDetail } from './transaction-detail/BillingSupportAccountingTransactionsDetail';
import Styles from './BillingSupportAccountingTransactions.module.scss';

const { unixLocalisedRenderer, yyyymmddLocalisedRenderer, checkboxCellWrapperRenderer } = utils;

export const BillingSupportAccountingTransactions = React.memo(
  ({
    transactions,
    showFailedOnly,
    fromDate,
    toDate,
    filterById,
    format,
    region,
    sortBy,
    sortDirection,
    selectAllChecked,
    onSort,
    onResend,
    batchResend,
    dataLoading,
    isResending,
    hasResentSingle,
    detailedTransaction,
    selectedCorrelationIds,
    onSelectChange,
    onSelectAllChange,
    fetchFullData,
    setFilterById,
    setShowDetail,
    setHasResentSingle,
    toggleShowFailed,
    onStartDateSelect,
    onEndDateSelect,
  }) => (
    <div className={Styles.billingSupportAccountingTransactions}>
      {!detailedTransaction && (
        <>
          <h2>Resend Integration Transactions</h2>
          <p>
            Can be used to debug and send integration transactions for Xero, MYOB, and QuickBooks, for firms that are
            using one of these integrations.
          </p>
          <p className={Styles.noticeMe}>Click Refresh to fetch transactions (It only fetches data from start date).</p>
          <p>
            The start date is default to 60 days ago. When you click “Refresh,” the system will fetch data starting
            based on the start date. Previously, the system fetched all data with a single refresh, regardless of the
            start date, but this caused timeouts for some firms with large datasets. Now, to improve performance, the
            data fetched depends on the start date. If you need to adjust the start date (e.g., to retrieve data from an
            earlier period), you must set the new start date and click “Refresh” again to fetch the updated data. After
            the initial refresh, you can adjust other filters (such as date range or filtering by ID) without needing to
            press “Refresh” again, unless you resend a message. After resending message(s), it’s important to reset the
            date range to the current day and click “Refresh” again to confirm the results. The “Last Updated” timestamp
            should be updated, and the message status should either show success (no longer marked in red) or indicate a
            failure again.
          </p>
          <div className={classnames('row', Styles.filterItemsRow)}>
            <div className="col-lg-4 form-group">
              <input
                type="text"
                name="filter-by-id"
                className="form-control"
                onChange={(e) => {
                  setFilterById(e.target.value);
                }}
                placeholder="Filter By ID"
                value={filterById}
              />
            </div>
            <div className="col-lg-4">
              <DateRangePicker
                startDate={fromDate}
                endDate={toDate}
                disableSelect
                format={format}
                region={region}
                onStartSelect={onStartDateSelect}
                onEndSelect={onEndDateSelect}
              />
            </div>
            <div className={classnames('col-lg-2', 'form-group', Styles.toggleContainer)}>
              <label className={Styles.toggleLabel} id={Styles['show-failed-only']}>
                Show only failed
              </label>
              <SlidingToggle scope="show-failed-only" onChange={toggleShowFailed} selected={showFailedOnly} />
            </div>
          </div>
          <Table
            list={transactions}
            rowClassName={({ rowData }) => (rowData?.isFailed ? Styles.failedTableRow : '')}
            onRowClick={({ rowData }) => {
              setHasResentSingle(false);
              setShowDetail(rowData);
            }}
            sortBy={sortBy}
            sortDirection={sortDirection}
            sort={onSort}
            dataLoading={dataLoading}
          >
            <Column
              key="UNNEEDED"
              dataKey="UNNEEDED"
              flexGrow={0.5}
              headerRenderer={checkboxCellWrapperRenderer(
                headerCheckboxCellRenderer({ onSelectAllChange, selectAllChecked }),
              )}
              cellRenderer={checkboxCellWrapperRenderer(
                checkboxCellRenderer({ onSelectChange, selectedCorrelationIds }),
              )}
            />
            <Column dataKey="integrationName" key="integrationName" label="Integration" flexGrow={1} />
            <Column dataKey="correlationId" key="correlationId" label="Correlation ID" flexGrow={3} />
            <Column dataKey="operationType" key="operationType" label="Entity Type" flexGrow={1} />
            <Column
              dataKey="transactionDate"
              key="transactionDate"
              label="Transaction Date"
              flexGrow={1}
              cellRenderer={yyyymmddLocalisedRenderer}
            />
            <Column
              dataKey="lastUpdated"
              key="lastUpdated"
              label="Last Updated"
              flexGrow={1}
              cellRenderer={({ cellData }) => unixLocalisedRenderer({ cellData, format: 'YYYY-MM-DD hh:mm:ss' })}
            />
          </Table>
          <div className={classnames('row', Styles.buttonContainer)}>
            <Button
              className={Styles.button}
              locked={isResending}
              disabled={isResending || !Object.keys(selectedCorrelationIds).length}
              onClick={batchResend}
            >
              Resend Selected
            </Button>
            <Button
              className={Styles.button}
              type="secondary"
              onClick={fetchFullData}
              locked={dataLoading}
              disabled={dataLoading}
            >
              Refresh
            </Button>
          </div>
        </>
      )}

      {!!detailedTransaction && (
        <BillingSupportAccountingTransactionsDetail
          hasResentSingle={hasResentSingle}
          transaction={detailedTransaction}
          onCloseDetail={() => setShowDetail(undefined)}
          isResending={isResending}
          onResend={onResend}
        />
      )}
    </div>
  ),
);

function headerCheckboxCellRenderer({ onSelectAllChange, selectAllChecked }) {
  return () => (
    <Checkbox
      checked={selectAllChecked}
      disabled={false}
      onChange={() => {
        onSelectAllChange(selectAllChecked);
      }}
    />
  );
}

function checkboxCellRenderer({ onSelectChange, selectedCorrelationIds }) {
  return ({ rowData }) => (
    <Checkbox
      checked={rowData.uniqueCorrelationIdentifier in selectedCorrelationIds}
      disabled={!rowData.isFailed}
      onChange={() => {
        onSelectChange(rowData);
      }}
    />
  );
}

BillingSupportAccountingTransactions.displayName = 'BillingSupportAccountingTransactions';

BillingSupportAccountingTransactions.propTypes = {
  transactions: PropTypes.array.isRequired,
  showFailedOnly: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  selectAllChecked: PropTypes.bool.isRequired,
  isResending: PropTypes.bool.isRequired,
  hasResentSingle: PropTypes.bool.isRequired,
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
  detailedTransaction: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([undefined])]),
  format: PropTypes.string,
  filterById: PropTypes.string,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  selectedCorrelationIds: PropTypes.object.isRequired,
  fetchFullData: PropTypes.func.isRequired,
  setFilterById: PropTypes.func.isRequired,
  setShowDetail: PropTypes.func.isRequired,
  setHasResentSingle: PropTypes.func.isRequired,
  toggleShowFailed: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  batchResend: PropTypes.func.isRequired,
  onStartDateSelect: PropTypes.func.isRequired,
  onEndDateSelect: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onSelectAllChange: PropTypes.func.isRequired,
};

BillingSupportAccountingTransactions.defaultProps = {
  fromDate: new Date(),
  toDate: new Date(),
  format: 'DD/MM/YYYY',
  detailedTransaction: undefined,
  filterById: '',
};
