'use strict';

const autoNumberingPrefixes = require('./auto-numbering-prefixes');
const { getBankAccountsByType } = require('./get-bank-accounts-by-type');
const { getBankAccountsByLocation } = require('./get-bank-accounts-by-location');
const { isStatutoryDepositMatter } = require('./is-statutory-deposit-matter');
const { isValidBankAccountId } = require('./is-valid-bank-account-id');
const { isValidBankAccountType } = require('./is-valid-bank-account-type');
const { getBankAccountName } = require('./get-bank-account-name');
const { shouldShowOriginalTrust } = require('./should-show-original-trust');
const { isBankAccountClosed } = require('./is-bank-account-closed');
const { isBankAccountDetailsEmpty } = require('./is-bank-account-details-empty');
const { getAvailableTrustAccountsForMatter } = require('./get-available-trust-accounts-for-matter');
const { getBankAccountsEntityByType } = require('./get-bank-accounts-entity-by-type');
const { localiseBankAccountTypeDisplay } = require('./localise-bank-account-type-display');
const { deriveInvoiceOverpaymentAccountId } = require('./derive-invoice-overpayment-account-id');

function getBankAccountDisplay(bankAccountId) {
  const accountType = bankAccountId.split('/')[1];

  switch (accountType) {
    case 'Trust':
      return 'Trust';
    case 'Operating':
      return 'Operating Retainer';
    default: {
      return bankAccountId;
    }
  }
}

function formatBankAccountDisplay(bankAccountLabel) {
  switch (bankAccountLabel) {
    case 'Operating':
      return 'Operating Retainer';
    default: {
      return bankAccountLabel;
    }
  }
}

module.exports = {
  getBankAccountDisplay,
  formatBankAccountDisplay,
  autoNumberingPrefixes,
  getBankAccountsByType,
  getBankAccountsEntityByType,
  isStatutoryDepositMatter,
  isValidBankAccountId,
  shouldShowOriginalTrust,
  isValidBankAccountType,
  getBankAccountName,
  getBankAccountsByLocation,
  isBankAccountClosed,
  getAvailableTrustAccountsForMatter,
  localiseBankAccountTypeDisplay,
  isBankAccountDetailsEmpty,
  deriveInvoiceOverpaymentAccountId,
};
