import PropTypes from 'prop-types';

import { featureActive } from '@sb-itops/feature';
import { useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { ALL_STATES } from '@sb-itops/region';
import { getSettings as getBankAccountSettings } from '@sb-billing/redux/bank-account-settings';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { bankAccountState } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import {
  showRetainerOnInvoiceOptionLabels,
  showRetainerOnInvoiceOptions as getShowRetainerOnInvoiceOptions,
} from '@sb-billing/business-logic/evergreen-retainer';

import { filterTrustAccountsByLocation } from 'web/redux/selectors/filter-trust-accounts';

import { MatterTrustSettings } from './MatterTrustSettings';

const hooks = ({
  selectedInvoiceTemplate,
  formInitialised,
  formFields,
  formDisabled,
  formSubmitting,
  submitFailed,
  onFieldValuesUpdated,
}) => ({
  useFormFields: () => {
    const {
      minimumTrustRetainerActive,
      minimumTrustRetainerAmount,
      showRetainerOption,
      trustRetainerReplenishAmount,
      defaultTrustAccountId,
      locationId,
      splitBillingEnabled,
    } = formFields;

    const { t } = useTranslation();

    let defaultTrustAccountOptions = [];
    let defaultTrustAccountLabel = '';

    const locationIdValue = locationId?.value;
    const locationDefaultTrustAccountId = (getBankAccountSettings()?.defaultTrustAccounts || []).find(
      (account) => account.location === locationIdValue,
    )?.defaultTrustAccountId;

    const allStatesDefaultTrustAccountId = (getBankAccountSettings()?.defaultTrustAccounts || []).find(
      (account) => account.location === ALL_STATES.value,
    )?.defaultTrustAccountId;

    const trustAccount =
      (locationDefaultTrustAccountId && getBankAccountById(locationDefaultTrustAccountId)) ||
      (allStatesDefaultTrustAccountId && getBankAccountById(allStatesDefaultTrustAccountId));
    const defaultTrustAccounts = locationIdValue ? filterTrustAccountsByLocation(locationIdValue) : [];
    defaultTrustAccountOptions = defaultTrustAccounts.map((a) => ({
      label: getBankAccountName(a, t),
      value: a.id,
    }));

    defaultTrustAccountLabel = trustAccount ? getBankAccountName(trustAccount, t) : 'None set';

    // Handle special case when selected trust account is closed
    //
    // We can't select closed account as it is not in the selectbox, but you can
    // set trust account for matter and then close the trust account afterwards.
    //
    // When user tries to edit matter with trust account which is closed, we show validation error.
    // For the error to make sense, we have to temporary insert the closed account to selectbox.
    // Once user selects different account or clear the field, the closed account dissapears from selectbox.
    const selectedTrustAccount = defaultTrustAccountId?.value && getBankAccountById(defaultTrustAccountId?.value);
    if (selectedTrustAccount?.state === bankAccountState.CLOSED) {
      // this will show error during validation but we need to add this option to the options
      if (!defaultTrustAccountOptions.find((a) => a.value === selectedTrustAccount.id)) {
        defaultTrustAccountOptions.push({
          label: getBankAccountName(selectedTrustAccount, t),
          value: selectedTrustAccount.id,
        });
      }
    }

    let showRetainerOnInvoiceOptions = [];
    if (featureActive('BB-6908')) {
      const showRetainerOnInvoiceTemplateSetting =
        selectedInvoiceTemplate?.settings?.invoiceAdditionalOptions?.showRetainer;

      showRetainerOnInvoiceOptions = getShowRetainerOnInvoiceOptions.map((option) => {
        if (option.label === showRetainerOnInvoiceOptionLabels.USE_TEMPLATE_SETTING) {
          return {
            ...option,
            label: `${option.label} (currently ${showRetainerOnInvoiceTemplateSetting ? 'Enabled' : 'Disabled'})`,
          };
        }
        return option;
      });
    }

    return {
      defaultTrustAccountOptions,
      defaultTrustAccountLabel,
      showRetainerOnInvoiceOptions,
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields
      minimumTrustRetainerActive,
      minimumTrustRetainerAmount,
      trustRetainerReplenishAmount,
      defaultTrustAccountId,
      showRetainerOption,
      splitBillingEnabled,
    };
  },
  useFormActions: () => {
    const onMinimumTrustRetainerActiveUpdated = (newState) => {
      onFieldValuesUpdated({ minimumTrustRetainerActive: newState });
    };
    const onMinimumTrustRetainerAmountUpdated = (newAmount) => {
      onFieldValuesUpdated({ minimumTrustRetainerAmount: newAmount });
    };
    const onTrustRetainerReplenishAmountUpdated = (newAmount) => {
      onFieldValuesUpdated({ trustRetainerReplenishAmount: newAmount });
    };
    const onDefaultTrustAccountUpdated = (trustAccountId) => {
      onFieldValuesUpdated({ defaultTrustAccountId: trustAccountId });
    };
    const onShowRetainerOptionUpdated = (showRetainerOption) => {
      onFieldValuesUpdated({ showRetainerOption });
    };

    return {
      onMinimumTrustRetainerActiveUpdated,
      onMinimumTrustRetainerAmountUpdated,
      onTrustRetainerReplenishAmountUpdated,
      onDefaultTrustAccountUpdated,
      onShowRetainerOptionUpdated,
    };
  },
});

export const MatterTrustSettingsContainer = composeHooks(hooks)(MatterTrustSettings);

MatterTrustSettingsContainer.displayName = 'MatterTrustSettingsContainer';

MatterTrustSettingsContainer.propTypes = {
  matterId: PropTypes.string,
  selectedInvoiceTemplate: PropTypes.object,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired,
};

MatterTrustSettingsContainer.defaultProps = {
  matterId: undefined,
  selectedInvoiceTemplate: undefined,
};
