import { gql } from '@apollo/client';

const query = gql`
  query PaymentSourcesOperatingCreditData(
    $includeContactBalances: Boolean!
    $includeMatterBalances: Boolean!
    $operatingOrCreditBankAccountsFilter: BankAccountFilter
    $bankAccountBalanceFilter: BankAccountBalanceFilter
  ) {
    # for operating credit
    bankAccounts(filter: $operatingOrCreditBankAccountsFilter) {
      id
      accountType
      accountName
      displayName
      bankAccountBalances(filter: $bankAccountBalanceFilter) {
        id
        balance
        contactBalances @include(if: $includeContactBalances) {
          contact {
            displayName
            displayNameFull
          }
          contactId
          matterId
          balance
          availableBalance
        }
        matterBalances @include(if: $includeMatterBalances) {
          matterId
          adjustment
          balance
          availableBalance
          protectedBalance
        }
      }
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const PaymentSourcesOperatingCreditData = {
  query,
  notificationIds,
};
