import { featureActive } from '@sb-itops/feature';
import {
  getMap,
  getList,
  getById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/invoice-reminders';
import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

angular
  .module('@sb-billing/services')
  .service('sbAccountRemindersService', function(
    sbLoggerService,
    sbGenericCacheService,
    sbGenericEndpointService,
    sbUuidService,
    sbSimpleContactMbService,
    sbMessageDisplayService,
    sbEndpointType,
    sbFirmManagementMbService
  ) {

    const that = this;
    const ACCOUNT_REMINDERS_ENDPOINT = 'billing/invoice-reminders';
    const log = sbLoggerService.getLogger('sbAccountRemindersService');

    sbGenericCacheService.setupCache({
      name: 'sbAccountRemindersService',
      sync: {
        endpoint: { type: sbEndpointType.SYNC_SINCE, stub: ACCOUNT_REMINDERS_ENDPOINT },
        poll: 60 ,
        subscriptions: 'notifier.InvoicingNotifications.InvoiceReminderUpdated'
      },
      updateRedux,
      clearRedux,
    });

    that.getAll = getList;
    that.getById = getById;
    that.sendReminders = sendReminders;
    that.getLastReminders = getLastReminders;

    function getLastReminders() {
      const cachedReminders = getMap();
      const lastReminders = {}; // Keyed by {matterid}-{contactid}

      for (let reminderId in cachedReminders) {
        const cachedReminder = cachedReminders[reminderId];
        const timestampDate = moment(cachedReminder.timestamp).toDate();

        const matterContactId = `${cachedReminder.matterId || ''}-${cachedReminder.contactId || ''}`;
        if (!lastReminders[matterContactId] || lastReminders[matterContactId].timestampDate < timestampDate) {
          lastReminders[matterContactId] = Object.assign(cachedReminder, {timestampDate});
        }
      }

      return lastReminders;
    }

    // TODO make this bulk email stuff into a generic service
    async function sendReminders(reminders) {
      try {
        const staffDetails = await sbFirmManagementMbService.getStaffEmailDetails();

        const {valid: validReminders, invalid: invalidReminders} = await createEmailRequests({staffDetails, reminders});

        if (invalidReminders.length) {
          const uniqueBadRequests = _.unique(invalidReminders, (badRequest) => badRequest.customer.id);
          const warningMessage = uniqueBadRequests.reduce((msg, badRequest) => {
            if (msg.length) {
              msg += ', ';
            }

            return `${msg}${badRequest.customer.salutation}`;
          }, '');

          sbMessageDisplayService.warn(
            sbMessageDisplayService
            .builder()
            .text(`Warning: Cannot send ${invalidReminders.length} invoice reminder email(s) due to missing contact email addresses for: ${warningMessage}`)
            .timeout(5000)
          );

        }

        if (validReminders.length === 0) {
          return;
        }

        try {
          const message = {
            reminders: validReminders,
            eInvoiceEnabled: featureActive('BB-5725'),
            emailReminderModalEnabled: featureActive('BB-11448'),
          };
          await dispatchCommand({
            type: 'Integration.SendInvoiceReminders',
            message,
          });
          sbMessageDisplayService.success(`Processing ${validReminders.length} invoice reminder email(s)`);
        } catch (reqErr) {
          log.error('Failed to send invoice reminder', reqErr);
          if (
            reqErr.payload &&
            reqErr.payload.body &&
            reqErr.payload.body.message === emailMessages.notAllowedToSendEmailsServer
          ) {
            sbMessageDisplayService.error(emailMessages.notAllowedToSendEmailsDisplay);
          } else {
            sbMessageDisplayService.error(
              `Failed to send ${validReminders.length} invoice reminder email(s), please try again later`,
            );
          }
        }
      } catch (err) {
        log.error(err);
      }
    }

    async function createEmailRequests({staffDetails, reminders}) {
      const requests = {
        valid: [],
        invalid: []
      };

      const remindersCustomersInfo = await getRemindersCustomersInfo(reminders);
      const emailReminderModalEnabled = featureActive('BB-11448');
      remindersCustomersInfo.forEach(({customers, reminder}) => {
        // When feature on, user can manually enter email address in the modal for contact without email
        const hasManuallyEnteredToAddress = emailReminderModalEnabled && reminder.emailRequest.template.toAddress;
        if (customers.every(item => !item.email) && !hasManuallyEnteredToAddress) {
          requests.invalid.push({customer: customers[0]});
        } else {
          requests.valid.push(createEmailRequest({staffDetails, customer: (customers.length > 1 ? { id: reminder.debtorId } : customers[0]), reminder}));
        }
      });

      return requests;
    }

    async function getRemindersCustomersInfo(reminders) {
      return Promise.all(
        reminders.map(async (reminder) => {
          const customers = await sbSimpleContactMbService.getPeopleP(reminder.debtorId);

          return {
            customers,
            reminder
          };
        })
      );
    }

    function createEmailRequest({customer, reminder: {matterId, invoices, emailRequest}}) {
      return {
        id: sbUuidService.get(),
        contactId: customer.id,
        matterId,
        invoiceIds: invoices.map((invoice) => invoice.invoiceId),
        emailRequest,
      };
    }
  });
