import { gql } from '@apollo/client';

const query = gql`
  query InvoiceOverpaymentAccountData(
    $matterId: ID!
    $matterTypesFilter: MatterTypeFilter
    $bankAccountFilter: BankAccountFilter
    $matterTrustBankAccountsFilter: MatterBankAccountFilter
  ) {
    bankAccounts(filter: $bankAccountFilter) {
      id
      displayName
      state
      location
      accountType
    }
    matter(id: $matterId) {
      id
      billingConfiguration {
        id
        defaultTrustAccountId
      }
    }
    matterTrustBankAccounts(matterId: $matterId, filter: $matterTrustBankAccountsFilter) {
      id
      displayName
      accountName
      accountType
      state
    }
    matterTypeList(filter: $matterTypesFilter) {
      results {
        matterTypeId
        location
      }
    }
  }
`;

const notificationIds = [
  'AccountsNotifications.BankAccountUpdated',
  'BillingMattersNotifications.BillingConfigurationUpdated',
  'WebQueryAccountsNotifications.BankAccountUpdated',
  'BrowseMattersNotifications.MatterUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceOverpaymentAccountData = {
  query,
  notificationIds,
};
