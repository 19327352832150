import React from 'react';
import { Button, Modal, Spinner, buttonTypes } from '@sb-itops/react';
import smokeballIcon from 'web/assets/smokeball-icon.svg';
import wordLogo from 'web/assets/word-logo.svg';
import connectArrows from 'web/assets/connect-arrows.svg';
import externalLinkIcon from 'web/assets/external-link.svg';
import Styles from './Modal.module.scss';

export interface Contact {
  id: string;
  name: string;
  role: string;
}

export interface ICreateLetterModalProps {
  authorizationState: 'loading' | 'unauthorized' | 'authorized';
  selectableContactsLoading: boolean;
  selectableContacts: Contact[];
  selectedContact: Contact | undefined;
  setSelectedContact: (contact: Contact) => void;
  showCreateInDesktop: boolean;
  onCreateLetterInDesktop: () => void;
  isCreatingLetterInDesktop: boolean;
  onCreateLetterInWeb: () => void;
  isCreatingLetterInWeb: boolean;
  letterUrl: string | undefined;
  onClose: () => void;
}

export const CreateLetterModal = ({
  authorizationState,
  selectableContactsLoading,
  selectableContacts,
  selectedContact,
  setSelectedContact,
  showCreateInDesktop,
  onCreateLetterInDesktop,
  isCreatingLetterInDesktop,
  onCreateLetterInWeb,
  isCreatingLetterInWeb,
  letterUrl,
  onClose,
}: ICreateLetterModalProps) => {
  let modalBody: JSX.Element;
  if (authorizationState === 'unauthorized') {
    modalBody = (
      <>
        <div className={Styles.connectIcons}>
          <img src={wordLogo} alt="Word" width={60} />
          <img src={connectArrows} alt="Connect" width={50} />
          <img src={smokeballIcon} alt="Smokeball" width={60} />
        </div>
        <div>
          <p>To open Word documents from Smokeball we require some additional permissions.</p>
          <p>This can be done by following the steps below:</p>
          <ol>
            <li>Open a Word document in your browser.</li>
            <li>
              <a
                href="https://support.smokeball.com/hc/en-au/articles/27531660842647"
                target="_blank"
                rel="noopener noreferrer"
              >
                Install the Smokeball Word Add-in.
              </a>
            </li>
            <li>Log in to Smokeball.</li>
            <li>Grant access to your OneDrive, where we will place your documents.</li>
            <li>
              Once the Add-in has been successfully installed, please close this window and select &quot;Create
              Letter&quot; to continue.
            </li>
          </ol>
        </div>
      </>
    );
  } else if (selectableContactsLoading || authorizationState === 'loading') {
    modalBody = (
      <div className={Styles.center}>
        <Spinner small />
      </div>
    );
  } else {
    modalBody = (
      <div className={Styles.container}>
        {selectableContacts.map((contact) => (
          <label key={contact.id + contact.role} className={Styles.row}>
            <input
              type="radio"
              className={Styles.radioInput}
              value={contact.id}
              checked={contact.id === selectedContact?.id}
              disabled={isCreatingLetterInWeb || letterUrl !== undefined}
              onChange={(e) => {
                if (e.target.value) {
                  setSelectedContact(contact);
                }
              }}
            />
            <div className={Styles.name}>{contact.name}</div>
            <div className={Styles.role}>{contact.role}</div>
          </label>
        ))}
        {letterUrl && (
          <a href={letterUrl} target="_blank" rel="noreferrer">
            <img src={externalLinkIcon} alt="External link" className={Styles.externalLinkIcon} />
            {selectedContact?.name ? <>Open letter to {selectedContact?.name}</> : <>Open letter</>}
          </a>
        )}
      </div>
    );
  }

  const createButtonsAreDisabled =
    selectedContact === null ||
    isCreatingLetterInDesktop ||
    isCreatingLetterInWeb ||
    letterUrl !== undefined ||
    selectableContactsLoading ||
    authorizationState === 'unauthorized';

  return (
    <Modal
      className={`${Styles.modal} ${Styles.createLetterModal}`}
      title="Create Letter"
      body={modalBody}
      footer={
        <div className={Styles.createButtonContainer}>
          {showCreateInDesktop && (
            <Button
              className={Styles.createButton}
              type={buttonTypes.secondary}
              size="full-width"
              disabled={createButtonsAreDisabled}
              locked={isCreatingLetterInDesktop}
              onClick={onCreateLetterInDesktop}
            >
              Create in Desktop
            </Button>
          )}
          <Button
            className={Styles.createButton}
            type={buttonTypes.secondary}
            size="full-width"
            disabled={createButtonsAreDisabled}
            locked={isCreatingLetterInWeb}
            onClick={onCreateLetterInWeb}
          >
            Create in Web
          </Button>
        </div>
      }
      onModalClose={onClose}
    />
  );
};
