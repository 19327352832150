import * as React from 'react';
import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { useInvoiceCorrespondenceDetailsData, useInvoiceTableData, useInvoiceTableSelections } from 'web/hooks';
import {
  INVOICE_COMMUNICATE_MODAL_ID,
  INVOICE_EMAIL_MODAL_ID,
  MARK_AS_SENT_MODAL_ID as MARK_AS_SENT_NEW_MODAL_ID,
} from 'web/components';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { getLogger } from '@sb-itops/fe-logger';
import { featureActive } from '@sb-itops/feature';
import { operationTypes } from '@sb-billing/business-logic/correspondence-history';
import { MARK_AS_SENT_MODAL_ID } from 'web/react-redux';

import { BillingAdvancedSearchInvoicesRoute } from './BillingAdvancedSearchInvoicesRoute';

const log = getLogger('BillingAdvancedSearchInvoicesRoute.container');

const FETCH_LIMIT = 50;
const SCOPE = 'BillingAdvancedSearchInvoicesRoute';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_SORTING = `${SCOPE}/sorting`;

function removeHashAndSplitIntoSearchTerms({ searchText }) {
  let searchTextWithoutHash = searchText;

  // 1. Remove optional "#" if used
  if (searchTextWithoutHash.startsWith('#')) {
    searchTextWithoutHash = searchTextWithoutHash.slice(1);
  }

  // 2. Return invoice numbers in array format
  // The searchTerms can be comprised of:
  //  * A single search term (e.g. "100") or
  //  * Multiple search terms (e.g. "100 7")
  const digitsOnlyRegex = /^\d+$/;
  const searchTerms = searchTextWithoutHash.split(' ').filter((part) => digitsOnlyRegex.test(part));

  return searchTerms;
}

const hooks = () => ({
  useScope: () => ({
    scope: SCOPE,
  }),
  useTableData: ({ searchText, onGetInvoiceCountData }) => {
    const showDebtor = true;
    const showMatter = true;

    const validSearchTerms = removeHashAndSplitIntoSearchTerms({ searchText });

    const {
      invoiceCount,
      invoiceDataLoading,
      invoices,
      invoicesCurrentPage,
      invoicesSortBy,
      invoicesSortDirection,
      invoiceStatusCounts,
      invoicesTotalNumberOfPages,
      isPaginationHidden,
      invoicesOnPageChange,
      invoicesOnSort,
    } = useInvoiceTableData({
      scopePagination: SCOPE_PAGINATION,
      scopeSorting: SCOPE_SORTING,
      fetchLimit: FETCH_LIMIT,
      showDebtor,
      searchTerms: validSearchTerms,
      // Skip query if there are no valid search terms
      //  * E.g. User deletes their search term, no number(s) present
      skipQuery: !validSearchTerms.length,
    });

    React.useEffect(() => {
      onGetInvoiceCountData({ isLoading: invoiceDataLoading, invoiceCount });
    }, [onGetInvoiceCountData, invoiceCount, invoiceDataLoading]);

    return {
      invoiceCount,
      invoiceDataLoading,
      invoiceListReduxState: undefined, // No filter panel for this page
      invoices,
      invoicesCurrentPage,
      invoicesSortBy,
      invoicesSortDirection,
      invoiceStatuses: ['DRAFT', 'FINAL', 'PAID', 'VOID', 'OVERDUE'],
      invoiceStatusCounts,
      invoicesTotalNumberOfPages,
      isPaginationHidden,
      showDebtor,
      showMatter,
      // Callbacks
      invoicesOnPageChange,
      invoicesOnSort,
    };
  },
  useCorrespondenceHistory: () => {
    const {
      latestCorrespondencePerContact,
      latestCorrespondenceLoading,
      onFetchLatestCorrespondenceDetailsForInvoice,
    } = useInvoiceCorrespondenceDetailsData();

    return {
      latestCorrespondencePerContact,
      latestCorrespondenceLoading,
      onFetchLatestCorrespondenceDetailsForInvoice,
    };
  },
  useInvoiceSelection: () => {
    const {
      selectedInvoicesByAction,
      selectedInvoices,
      onDeselectDeletedDraftInvoices,
      onFinaliseDraftInvoicesSuccess,
      onToggleInvoices,
    } = useInvoiceTableSelections({ scope: SCOPE });

    return {
      selectedInvoicesByAction,
      selectedInvoices,
      onDeselectDeletedDraftInvoices,
      onFinaliseDraftInvoicesSuccess,
      onToggleInvoices,
    };
  },
  useSendInvoiceEmails: ({ sbInvoiceSendService }) => {
    // Invoice emails can be sent via:
    //  1. Email button (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenInvoiceEmailModal({ invoiceIds, contactId: contactIdFromResendLink }) {
      setModalDialogVisible({
        modalId: INVOICE_EMAIL_MODAL_ID,
        props: {
          // If an email is being resent from the Sent column icon we pass that
          //  * This is because it can contain other contacts too (from invoices with multiple contacts)
          debtorId: contactIdFromResendLink,
          invoiceIds,
          scope: `${SCOPE}/invoice-email-modal`,
          onPreview: ({ invoiceEmailRequest }) =>
            sbInvoiceSendService.createInvoiceEmailPreviewP({
              invoiceEmailRequest,
            }),
          onSendEmails: async ({ invoiceEmailRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
            } catch (err) {
              log.error('Failed to send invoice emails', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceEmailModal,
    };
  },
  useSendInvoiceCommunicates: ({ sbInvoiceSendService }) => {
    // Invoice Communicates are currently only sent via:
    //  1. Invoice row menu (single invoice)
    const isInvoiceViaCommunicateFeatureEnabled = featureActive('BB-9097');

    function onOpenInvoiceCommunicateModal({ invoiceIds, contactId: contactIdFromResendLink }) {
      if (!isInvoiceViaCommunicateFeatureEnabled) {
        return;
      }

      setModalDialogVisible({
        modalId: INVOICE_COMMUNICATE_MODAL_ID,
        props: {
          // If a communicate is being resent from the Sent column icon we pass that
          //  * This is because it can contain other contacts too (from invoices with multiple contacts)
          debtorId: contactIdFromResendLink,
          invoiceIds,
          scope: `${SCOPE}/invoice-communicate-modal`,
          onPreview: ({ invoiceCommunicateRequest }) =>
            sbInvoiceSendService.createInvoiceCommunicatePreviewP({
              invoiceCommunicateRequest,
            }),
          onSend: async ({ invoiceCommunicateRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
            } catch (err) {
              log.error('Failed to send invoice communicate requests', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceCommunicateModal,
    };
  },
  useMarkAsSent: () => {
    // Invoices can be "marked as sent" via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenMarkInvoiceAsSentModal({ invoiceIds }) {
      setModalDialogVisible({
        modalId: featureActive('BB-14422') ? MARK_AS_SENT_NEW_MODAL_ID : MARK_AS_SENT_MODAL_ID,
        props: {
          selectedItems: invoiceIds,
          scope: `${SCOPE}/mark-as-sent-modal`,
          operationType: operationTypes.INVOICE,
        },
      });
    }

    return {
      onOpenMarkInvoiceAsSentModal,
    };
  },
});

const dependentHooks = () => ({});

export const BillingAdvancedSearchInvoicesRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(BillingAdvancedSearchInvoicesRoute))),
);

BillingAdvancedSearchInvoicesRouteContainer.displayName = 'BillingAdvancedSearchInvoicesRouteContainer';

BillingAdvancedSearchInvoicesRouteContainer.propTypes = {
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbSaveInvoiceCommand: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbInvoiceSendService: PropTypes.object.isRequired, // Required to send invoice emails/communicates
  searchText: PropTypes.string.isRequired, // Used to filter the invoice list by invoice number
  // Callbacks
  onGetInvoiceCountData: PropTypes.func.isRequired, // Used to reflect the invoice count in an Angular component (and its relevant loading state)
  onClickLink: PropTypes.func.isRequired,
};

BillingAdvancedSearchInvoicesRouteContainer.defaultProps = {};
