import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  useInvoiceCorrespondenceDetailsData,
  useInvoiceTableData,
  useInvoiceTableSelections,
  useSubscribedQuery,
} from 'web/hooks';
import { BillingBulkActions, MatterInvoiceListData } from 'web/graphql/queries';
import {
  INVOICE_COMMUNICATE_MODAL_ID,
  INVOICE_EMAIL_MODAL_ID,
  MARK_AS_SENT_MODAL_ID as MARK_AS_SENT_NEW_MODAL_ID,
} from 'web/components';
import { MARK_AS_SENT_MODAL_ID } from 'web/react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as matterInvoicesReduxState from 'web/redux/route/home-billing-view-matter-bills';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';
import { operationTypes } from '@sb-billing/business-logic/correspondence-history';
import { getLogger } from '@sb-itops/fe-logger';
import { isEditableMatter } from '@sb-matter-management/business-logic/matters/services';
import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import { hasBillingAccess } from 'web/services/user-session-management';

import { BillingMatterInvoicesRoute } from './BillingMatterInvoicesRoute';

const log = getLogger('BillingMatterInvoicesRoute.container');

const FETCH_LIMIT = 50;

/**
 * The Matter Invoices page has some functionlity where the matter's assigned or default debtor is required
 * E.g. InvoiceListActionBar > "Add Credit"
 *
 * @param {object} params
 * @param {string[]} params.billingConfigurationDebtorIds
 * @param {object[]} params.clients
 */
function getAssignedOrDefaultMatterDebtorId({ billingConfigurationDebtorIds = [], clients = [] }) {
  // If a specified debtor was assigned, we use them
  if (billingConfigurationDebtorIds.length) {
    return billingConfigurationDebtorIds[0];
  }

  // Otherwise, we default to the first contact
  return clients[0]?.id;
}

const hooks = () => ({
  useScope: ({ matterId }) => ({
    scope: `BillingMatterInvoicesRoute/${matterId}`,
  }),
  useBillingAccess: () => ({
    userHasBillingAccess: hasBillingAccess(),
  }),
});

const dependentHooks = () => ({
  useMatterData: ({ matterId, userHasBillingAccess }) => {
    const { data, loading, error } = useSubscribedQuery(MatterInvoiceListData, {
      variables: {
        id: matterId,
      },
      skip: !userHasBillingAccess,
    });

    if (error) {
      throw new Error(error);
    }

    return {
      assignedOrDefaultMatterDebtorId: loading
        ? undefined
        : getAssignedOrDefaultMatterDebtorId({
            billingConfigurationDebtorIds: data?.matter?.billingConfiguration?.debtorIds || [],
            clients: data?.matter?.clients || [],
          }),
      isEditableMatter: loading ? false : isEditableMatter(data?.matter),
      isLoadingMatterData: loading,
    };
  },
  useBillingBulkActionsData: ({ matterId, userHasBillingAccess }) => {
    const { data } = useSubscribedQuery(BillingBulkActions, {
      variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES, bulkEntityIds: [matterId] },
      skip: !userHasBillingAccess,
    });

    const isBulkCreateInvoicesInProgress = data?.billingBulkActionList?.totalCount > 0;
    return {
      isBulkCreateInvoicesInProgress,
    };
  },
  useCorrespondenceHistory: ({ userHasBillingAccess }) => {
    const latestCorrespondenceHistory = useInvoiceCorrespondenceDetailsData({ skipQuery: !userHasBillingAccess });

    return latestCorrespondenceHistory;
  },
  useTableData: ({ scope, userHasBillingAccess }) => {
    const SCOPE_PAGINATION = `${scope}/pagination`;
    const SCOPE_SORTING = `${scope}/sorting`;

    const showDebtor = true;
    const showMatter = false;
    const filters = useSelector(matterInvoicesReduxState.selectors.getFilters);

    const tableData = useInvoiceTableData({
      scopePagination: SCOPE_PAGINATION,
      scopeSorting: SCOPE_SORTING,
      fetchLimit: FETCH_LIMIT,
      showDebtor,
      filters,
      skipQuery: !userHasBillingAccess,
    });

    return {
      ...tableData,
      invoiceListReduxState: matterInvoicesReduxState,
      invoiceStatuses: filters.invoiceStatuses,
      showDebtor,
      showMatter,
    };
  },
  useInvoiceSelection: ({ scope }) => {
    const invoiceTableSelectionDataAndLogic = useInvoiceTableSelections({ scope });

    return invoiceTableSelectionDataAndLogic;
  },
  useSendInvoiceEmails: ({ sbInvoiceSendService, scope }) => {
    // Invoice emails can be sent via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenInvoiceEmailModal({ invoiceIds, contactId: contactIdFromResendLink }) {
      setModalDialogVisible({
        modalId: INVOICE_EMAIL_MODAL_ID,
        props: {
          // If an email is being resent from the Sent column icon we pass that
          //  * This is because it can contain other contacts too (from invoices with multiple contacts)
          debtorId: contactIdFromResendLink,
          invoiceIds,
          scope: `${scope}/invoice-email-modal`,
          onPreview: ({ invoiceEmailRequest }) =>
            sbInvoiceSendService.createInvoiceEmailPreviewP({
              invoiceEmailRequest,
            }),
          onSendEmails: async ({ invoiceEmailRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
            } catch (err) {
              log.error('Failed to send invoice emails', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceEmailModal,
    };
  },
  useSendInvoiceCommunicates: ({ sbInvoiceSendService, scope }) => {
    // Invoice Communicates are currently only sent via:
    //  1. Invoice row menu (single invoice)
    const isInvoiceViaCommunicateFeatureEnabled = featureActive('BB-9097');

    function onOpenInvoiceCommunicateModal({ invoiceIds, contactId: contactIdFromResendLink }) {
      if (!isInvoiceViaCommunicateFeatureEnabled) {
        return;
      }

      setModalDialogVisible({
        modalId: INVOICE_COMMUNICATE_MODAL_ID,
        props: {
          // If a communicate is being resent from the Sent column icon we pass that
          //  * This is because it can contain other contacts too (from invoices with multiple contacts)
          debtorId: contactIdFromResendLink,
          invoiceIds,
          scope: `${scope}/invoice-communicate-modal`,
          onPreview: ({ invoiceCommunicateRequest }) =>
            sbInvoiceSendService.createInvoiceCommunicatePreviewP({
              invoiceCommunicateRequest,
            }),
          onSend: async ({ invoiceCommunicateRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
            } catch (err) {
              log.error('Failed to send invoice communicate requests', err);
            }
          },
        },
      });
    }

    return {
      onOpenInvoiceCommunicateModal,
    };
  },
  useMarkAsSent: ({ scope }) => {
    // Invoices can be "marked as sent" via:
    //  1. Bulk Action (multiple invoices)
    //  2. Invoice row menu (single invoice)

    function onOpenMarkInvoiceAsSentModal({ invoiceIds }) {
      setModalDialogVisible({
        modalId: featureActive('BB-14422') ? MARK_AS_SENT_NEW_MODAL_ID : MARK_AS_SENT_MODAL_ID,
        props: {
          selectedItems: invoiceIds,
          scope: `${scope}/mark-as-sent-modal`,
          operationType: operationTypes.INVOICE,
        },
      });
    }

    return {
      onOpenMarkInvoiceAsSentModal,
    };
  },
});

export const BillingMatterInvoicesRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(BillingMatterInvoicesRoute))),
);

BillingMatterInvoicesRouteContainer.displayName = 'BillingMatterInvoicesRouteContainer';

BillingMatterInvoicesRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  isCombineInPdfInProgress: PropTypes.bool.isRequired,
  isDownloadLedesInProgress: PropTypes.bool.isRequired,
  sbSaveInvoiceCommand: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbAsyncOperationsService: PropTypes.object.isRequired, // Required by actions in the InvoiceListActionBar
  sbInvoiceSendService: PropTypes.object.isRequired, // Required to send invoice emails/communicates
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
};

BillingMatterInvoicesRouteContainer.defaultProps = {};
