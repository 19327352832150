import PropTypes from 'prop-types';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { useTranslation } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { getLogger } from '@sb-itops/fe-logger';
import { capitalize, capitalizeAllWords } from '@sb-itops/nodash';
import { sort } from '@sb-itops/sort';
import * as messageDisplay from '@sb-itops/message-display';

import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useSort } from '@sb-itops/redux/sort/use-sort';

import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { roundCents } from '@sb-billing/bankers-rounding';

import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import {
  InitOperatingBankAccount,
  PrintOperatingChequeModalData,
  OperatingChequeAvailableNumbers,
} from 'web/graphql/queries';
import PrintOperatingChequeModal from './PrintOperatingChequeModal';

const logger = getLogger('web/react-redux/PrintOperatingChequeModalContainer');
export const PRINT_OPERATING_CHEQUE_MODAL_ID = 'print-operating-cheque-modal';
const SCOPE = 'print-operating-cheque-modal';
const REDUX_DATE_FORMAT = 'YYYYMMDD';

function transformUnprintedOperatingCheques(unprintedOperatingCheques) {
  const operatingChequesForPrintChequesForm = unprintedOperatingCheques.map((cheque) => {
    const payTo = cheque.payTo;

    // list of unique matters based on each matter referenced by the expenses
    const mattersMap = cheque.expenses.reduce((map, expense) => {
      if (expense && expense.matter && !map[expense.matter.id]) {
        // eslint-disable-next-line no-param-reassign
        map[expense.matter.id] = expense.matter;
      }

      return map;
    }, {});

    // sum of all expenses
    const amount = cheque.expenses.reduce((total, expense) => {
      if (expense) {
        const expenseAmount = roundCents((expense.price * expense.quantity) / 100);
        return total + (expense.amountIncludesTax ? expenseAmount : expenseAmount + expense.tax);
      }

      return total;
    }, 0);

    return {
      chequeId: cheque.id,
      effectiveDate: cheque.chequeDate,
      matters: Object.values(mattersMap),
      payee: payTo && payTo.displayNameFirstLast,
      reference: cheque.reference,
      timestamp: cheque.lastUpdated,
      chequeMemo: cheque.chequeMemo,
      amount,
    };
  });
  return operatingChequesForPrintChequesForm;
}

function allPreselectedOperatingChequesAvailable(preselectedOperatingChequeIds, unprintedOperatingChequesMap) {
  const allSelected = preselectedOperatingChequeIds.every((chequeId) => !!unprintedOperatingChequesMap[chequeId]);
  return allSelected;
}

function buildPrintOperatingChequesMessage({
  formData,
  nextChequeNumber,
  unprintedOperatingChequesMap,
  bankAccountId,
}) {
  logger.info('printOperatingChequeFormData:', formData);

  const selectedChequeIds = formData.selectedChequeIds;
  const overrideChequeMemos = formData.overrideChequeMemos;
  const assignedChequeReference = formData.assignedChequeReference;

  const selectedCheques = Object.keys(selectedChequeIds).reduce((acc, chequeId) => {
    if (selectedChequeIds[chequeId] === true) {
      acc.push({
        chequeId,
        chequeMemo:
          (overrideChequeMemos && overrideChequeMemos[chequeId]) || unprintedOperatingChequesMap[chequeId].chequeMemo,
        reference: assignedChequeReference && assignedChequeReference[chequeId],
      });
    }
    return acc;
  }, []);

  let firstChequeNumber;
  let chequeNumberPadding;
  if (formData.firstChequeNumber) {
    firstChequeNumber = formData.firstChequeNumber;
    chequeNumberPadding = firstChequeNumber.length;
  } else {
    firstChequeNumber = nextChequeNumber;
    chequeNumberPadding = firstChequeNumber.length;
  }

  // sorting by assigned reference number is important as .net will use the order of cheque sent
  // to assign an actual reference number to each cheque. This should match what's shown to the
  // user on the UI. In single user scenario, which should be the case for the printing of cheques
  // as there's only one cheque book, this will result in an exact match between the previewed
  // reference and the allocated reference for each cheque.
  const operatingCheques = sort(selectedCheques, ['reference'], ['ASC']).map((cheque) => ({
    chequeId: cheque.chequeId,
    chequeMemo: cheque.chequeMemo,
  }));

  return {
    bankAccountId,
    chequeDateOverride: formData.overrideChequeDate,
    startingNumber: firstChequeNumber,
    padding: chequeNumberPadding,
    cheques: operatingCheques,
    allowChequeNumberDuplication: hasFacet(facets.allowDuplicateCheque) && formData.allowDuplicateChequeNumbers,
  };
}

const isChequeNumberAvailable = ({ chequeNumber, availableChequeNumbers }) => {
  const availableNumberSet = new Set(availableChequeNumbers.map(Number));
  return availableNumberSet.has(chequeNumber);
};

const hooks = (props) => ({
  usePrintOperatingChequeModalData: () => {
    const { t } = useTranslation();
    const { chequeIds } = props;
    const { data, loading, error } = useSubscribedQuery(PrintOperatingChequeModalData, {
      variables: {},
    });
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);

    if (error) {
      throw new Error(error);
    }

    const unprintedOperatingCheques = data?.unprintedOperatingCheques || [];

    const { sortDirection, setSortDirection } = useSort({
      scope: SCOPE,
      initialSortBy: 'effectiveDate',
      initialSortDirection: 'asc',
    });

    const { formInitialised, onInitialiseForm, onUpdateFieldValues, onUpdateFields, onClearForm, onResetForm } =
      useForm({
        scope: SCOPE,
      });

    const numberOfUnprintedCheques = data?.unprintedOperatingCheques?.length || 0;
    const transformedUnprintedCheques = transformUnprintedOperatingCheques(unprintedOperatingCheques);
    const unprintedOperatingChequesMap = unprintedOperatingCheques.reduce((acc, cheque) => {
      acc[cheque.id] = cheque;
      return acc;
    }, {});

    const preselectedChequeIds = chequeIds;

    const accountName = capitalizeAllWords(t('operatingAccount'));
    const showLoadingIndicator =
      preselectedChequeIds &&
      preselectedChequeIds.length > 0 &&
      !allPreselectedOperatingChequesAvailable(preselectedChequeIds, unprintedOperatingChequesMap);

    const preselectedChequeIdsMap =
      chequeIds &&
      chequeIds.reduce((acc, chequeId) => {
        acc[chequeId] = true;
        return acc;
      }, {});

    // Ensure all of the pre-selected cheques appear in the list before
    // enabling the submit button. If none are pre-selected, default to true.
    const fetchedAllPreselectedCheques =
      chequeIds?.every((chequeId) => !!unprintedOperatingChequesMap[chequeId]) || true;

    return {
      modalId: PRINT_OPERATING_CHEQUE_MODAL_ID,
      modalDialogTitle: `Print ${capitalize(t('cheques'))}`,
      formInitialised,
      unprintedCheques: transformedUnprintedCheques,
      fetchedAllPreselectedCheques,
      sortDirection,
      accountName,
      showLoadingIndicator,
      showDuplicateChequeNumberToggle: hasFacet(facets.allowDuplicateCheque),
      showChequeMemo: hasFacet(facets.chequeMemo),
      // for dependent hooks
      numberOfUnprintedCheques,
      unprintedOperatingChequesMap,
      operatingAccountId: operatingBankAccountData?.bankAccounts[0]?.id,
      isLoading: loading,
      // functions
      onLoad: () => {
        onInitialiseForm({
          firstChequeNumber: undefined,
          overrideChequeDate: undefined,
          overrideChequeMemos: undefined,
          selectedChequeIds: preselectedChequeIdsMap,
          assignedChequeReference: undefined,
          allowDuplicateChequeNumbers: false,
        });
        return () => {
          onClearForm();
        };
      },
      onSortDirectionChanged: ({ sortDirection: sortDirectionValue }) => {
        setSortDirection(sortDirectionValue);
      },
      onFieldValueChanged: (field, value) => {
        onUpdateFieldValues(field, value);
      },
      onFieldValuesChanged: (updatedFieldValues) => {
        onUpdateFields(updatedFieldValues);
      },
      onCloseModal: () => {
        setModalDialogHidden({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID });
        onResetForm();
      },
    };
  },
});

const dependentHooks = (props) => ({
  useOperatingChequeAvailableNumbers: ({
    fetchedAllPreselectedCheques,
    numberOfUnprintedCheques,
    unprintedOperatingChequesMap,
    operatingAccountId,
  }) => {
    const { sbAsyncOperationsService } = props;

    const { formInitialised, formValues, onSubmitForm, onResetForm } = useForm({ scope: SCOPE });

    const { t } = useTranslation();

    // chequeNumberFrom needs to be a string containing only numbers
    const chequeNumberFrom = /^[0-9]+$/.test(formValues.firstChequeNumber) ? formValues.firstChequeNumber : undefined;
    const {
      data,
      loading: loadingAvailableNumbers,
      error,
    } = useSubscribedQuery(OperatingChequeAvailableNumbers, {
      variables: {
        filter: {
          chequeNumberFrom,
          quantity: numberOfUnprintedCheques,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const {
      lastChequeNumber = '',
      nextChequeNumber = '',
      availableChequeNumbers = [],
    } = data?.operatingChequeAvailableNumbers || {};

    let firstChequeNumber = nextChequeNumber;

    // set form defaults
    let overrideChequeDate;
    let chequeNumberPadding = firstChequeNumber.length;
    let selectedChequeIds = {};
    let overrideChequeMemos = {};
    let allowDuplicateChequeNumbers = false;

    const errors = {};

    // if form initialised override form defaults with user entered value
    if (formInitialised) {
      if (!fetchedAllPreselectedCheques) {
        errors.missingPreselectedCheques = true;
      }

      overrideChequeDate = formValues.overrideChequeDate
        ? moment(formValues.overrideChequeDate, REDUX_DATE_FORMAT).toDate()
        : overrideChequeDate;
      firstChequeNumber = formValues.firstChequeNumber !== undefined ? formValues.firstChequeNumber : firstChequeNumber;
      chequeNumberPadding = formValues.firstChequeNumber ? formValues.firstChequeNumber.length : chequeNumberPadding;
      selectedChequeIds = formValues.selectedChequeIds || selectedChequeIds;
      overrideChequeMemos = formValues.overrideChequeMemos || overrideChequeMemos;
      allowDuplicateChequeNumbers = hasFacet(facets.allowDuplicateCheque) && formValues.allowDuplicateChequeNumbers;

      // start form validation

      if (formValues.firstChequeNumber !== undefined) {
        // validate first cheque number only when it's overridden by user
        if (!formValues.firstChequeNumber) {
          errors.firstChequeNumber = true;
          errors.firstChequeNumberIsRequired = true;
        } else if (!/^[0-9]+$/.test(formValues.firstChequeNumber)) {
          errors.firstChequeNumber = true;
          errors.firstChequeNumberMustBeNumeric = true;
        } else if (
          (!allowDuplicateChequeNumbers || !hasFacet(facets.allowDuplicateCheque)) &&
          !loadingAvailableNumbers &&
          !isChequeNumberAvailable({
            chequeNumber: +(formValues.reference || formValues.firstChequeNumber),
            availableChequeNumbers,
          })
        ) {
          errors.firstChequeNumber = true;
          errors.firstChequeNumberIsAlreadyInUse = true;
        }
      }

      const numberOfSelectedCheques = formValues.selectedChequeIds
        ? Object.values(formValues.selectedChequeIds).filter((selected) => selected === true).length
        : 0;
      if (numberOfSelectedCheques === 0) {
        errors.selectedChequeIds = true;
      }
    }

    const disableSubmit = Object.keys(errors).length > 0;

    return {
      availableChequeNumbers,
      overrideChequeDate,
      lastChequeNumberFound: lastChequeNumber,
      firstChequeNumber,
      chequeNumberPadding,
      selectedChequeIds,
      overrideChequeMemos,
      errors,
      disableSubmit,
      allowDuplicateChequeNumbers,
      onSubmit: async () => {
        try {
          // flag selected operating cheques as printed and assign cheque reference
          let operatingChequesPrinted;
          await onSubmitForm({
            submitFnP: async (formData) => {
              const printOperatingChequesMessage = buildPrintOperatingChequesMessage({
                formData,
                nextChequeNumber,
                unprintedOperatingChequesMap,
                bankAccountId: operatingAccountId,
              });
              logger.info('printOperatingChequesMessage:', printOperatingChequesMessage);
              await dispatchCommand({
                type: 'Billing.Accounts.Messages.Commands.PrintOperatingCheques',
                message: printOperatingChequesMessage,
              });
              operatingChequesPrinted = printOperatingChequesMessage.cheques;
            },
          });

          // trigger cheque pdf regeneration & download link with progress bar
          const printedChequeIds = operatingChequesPrinted.map((operatingCheque) => operatingCheque.chequeId);
          sbAsyncOperationsService.startOperatingChequeCreation(printedChequeIds);
          setModalDialogHidden({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID });

          onResetForm();
        } catch (err) {
          logger.error('Error:', err);
          messageDisplay.error(`Failed to print ${t('operatingCheque')}(s).`);
        }
      },
    };
  },
});

export const PrintOperatingChequeModalContainer = withApolloClient(
  composeHooks(hooks)(composeHooks(dependentHooks)(withOnLoad(PrintOperatingChequeModal))),
);

PrintOperatingChequeModalContainer.displayName = 'PrintOperatingChequeModalContainer';

PrintOperatingChequeModalContainer.propTypes = {
  chequeIds: PropTypes.array,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

PrintOperatingChequeModalContainer.defaultProps = {};
