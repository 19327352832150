import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getTemplateByIdOrFirmDefault } from '@sb-billing/redux/invoice-settings-template';
import { getEInvoiceSettings } from '@sb-billing/redux/einvoice-settings/selectors';
import { eInvoiceEnabledOptions, eInvoiceEnabledOptionValues } from '@sb-billing/business-logic/einvoice';
import { payButtonEnabledOptionValues } from '@sb-billing/business-logic/matters/invoice-settings';
import { canIncludePaymentLinks } from '@sb-billing/redux/payment-provider-settings/selectors';
import { getAccountId } from 'web/services/user-session-management';
import { shouldShowFieldError } from 'web/react-redux/components/add-edit-matter/include-error-classname-if-invalid';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getDebtorDefaultNameAndAddress } from '@sb-customer-management/redux/contacts';
import { encodeFooter, titleLine1StringToEnum, titleLine2StringToEnum } from './transforms';
import { MatterInvoiceSettings } from './MatterInvoiceSettings';

const getEInvoiceEnabledOptions = () => {
  if (!hasFacet(facets.eInvoiceUserDefinedSwitch)) {
    return eInvoiceEnabledOptions;
  }

  const eInvoiceSettings = getEInvoiceSettings(getAccountId());
  const eInvoiceEnabledOptionsShowingFirmSetting = eInvoiceEnabledOptions.map((option) => {
    if (option.value === eInvoiceEnabledOptionValues.USE_FIRM_DEFAULT) {
      return {
        value: option.value,
        label: `${option.label} (currently ${eInvoiceSettings?.eInvoiceDisabled ? 'Disabled' : 'Enabled'})`,
      };
    }
    return option;
  });
  return eInvoiceEnabledOptionsShowingFirmSetting;
};

const mapStateToProps = (
  state,
  { formInitialised, formFields, formFieldValues, formDisabled, formSubmitting, submitFailed },
) => {
  const {
    invoiceTemplateId,
    invoiceTitleLine1CustomText,
    invoiceTitleLine1Option,
    invoiceTitleLine1Overridden,
    invoiceTitleLine2CustomText,
    invoiceTitleLine2Option,
    invoiceTitleLine2Overridden,
    addressesOverridden,
    selectedDebtorId,
    invoiceFooter,
    invoiceFooterOverridden,
    eInvoiceEnabledOption,
    payButtonEnabledOption,
    surchargeOverridden,
    surchargeType,
    surchargePercentage,
    surchargeAmount,
    surchargeApplyTo,
    surchargeLabel,
  } = formFields;

  const { overriddenDebtorAddresses } = formFieldValues;

  const templateInvoiceSettings = getTemplateByIdOrFirmDefault(invoiceTemplateId.value)?.settings;

  const isSurchargeValid =
    !shouldShowFieldError(surchargeAmount, submitFailed) && !shouldShowFieldError(surchargePercentage, submitFailed);

  const showPaymentsSection = canIncludePaymentLinks();

  return {
    // form state
    formInitialised,
    formDisabled,
    formSubmitting,
    isSurchargeValid,
    // form fields
    invoiceTemplateId,
    invoiceTitleLine1CustomText,
    invoiceTitleLine1Option,
    invoiceTitleLine1Overridden,
    invoiceTitleLine2CustomText,
    invoiceTitleLine2Option,
    invoiceTitleLine2Overridden,
    addressesOverridden,
    overriddenDebtorAddresses,
    selectedDebtorId,
    invoiceFooter,
    invoiceFooterOverridden,
    eInvoiceEnabledOption,
    payButtonEnabledOption,
    surchargeOverridden,
    surchargeType,
    surchargePercentage,
    surchargeAmount,
    surchargeApplyTo,
    surchargeLabel,
    // other
    templateInvoiceSettings,
    eInvoiceEnabledOptions: getEInvoiceEnabledOptions(),
    showPaymentsSection,
    eInvoiceSelectEnabled: hasFacet(facets.eInvoiceUserDefinedSwitch),
  };
};

const mapDispatchToProps = (dispatch, { formFieldValues, onFieldValuesUpdated, onFieldValueSet }) => ({
  onFooterUpdated: (newFooter, delta, source) => {
    // Improvement Note: once the old edit matter page is retired, this special
    // handling can move into to InvoiceTemplateSettings component. This was put
    // here to limit impact of the change and allow a full rollback if necessary
    if (source !== 'user') {
      // Ignore any changes made by react-quill itself instead of user input
      // this is necessary when invoice template for matter is changed, where
      // the onFooterUpdated is called unexpectedly.
      return;
    }

    const encodedFooter = encodeFooter(newFooter);
    onFieldValuesUpdated({ invoiceFooter: encodedFooter, invoiceFooterOverridden: true });
  },

  onResetToTemplateDefault: (templateId) => {
    const { settings } = getTemplateByIdOrFirmDefault(templateId.value);
    onFieldValuesUpdated({
      invoiceTemplateId: templateId.value,
      invoiceFooter: settings.footer,
      invoiceFooterOverridden: false,
      invoiceTitleLine1CustomText: settings.titleLine1CustomText,
      invoiceTitleLine1Option: titleLine1StringToEnum(settings.titleLine1Option),
      invoiceTitleLine1Overridden: false,
      invoiceTitleLine2CustomText: settings.titleLine2CustomText,
      invoiceTitleLine2Option: titleLine2StringToEnum(settings.titleLine2Option),
      invoiceTitleLine2Overridden: false,
    });
  },

  onSubtitleOptionsUpdated: (newSubtitleOption) => {
    const value = titleLine2StringToEnum(newSubtitleOption);
    onFieldValuesUpdated({ invoiceTitleLine2Option: value, invoiceTitleLine2Overridden: true });
  },

  onSubtitleTextUpdated: (newSubtitleText) => {
    onFieldValuesUpdated({ invoiceTitleLine2CustomText: newSubtitleText, invoiceTitleLine2Overridden: true });
  },

  onTemplateUpdated: (newTemplateId) => {
    const { settings } = getTemplateByIdOrFirmDefault(newTemplateId.value);
    onFieldValuesUpdated({
      invoiceTemplateId: newTemplateId.value,
      invoiceFooter: settings.footer,
      invoiceFooterOverridden: false,
      invoiceTitleLine1CustomText: settings.titleLine1CustomText,
      invoiceTitleLine1Option: titleLine1StringToEnum(settings.titleLine1Option),
      invoiceTitleLine1Overridden: false,
      invoiceTitleLine2CustomText: settings.titleLine2CustomText,
      invoiceTitleLine2Option: titleLine2StringToEnum(settings.titleLine2Option),
      invoiceTitleLine2Overridden: false,
    });
  },

  onTitleOptionsUpdated: (newTitleOption) => {
    const value = titleLine1StringToEnum(newTitleOption);
    onFieldValuesUpdated({ invoiceTitleLine1Option: value, invoiceTitleLine1Overridden: true });
  },

  onTitleTextUpdated: (newTitleText) => {
    onFieldValuesUpdated({ invoiceTitleLine1CustomText: newTitleText, invoiceTitleLine1Overridden: true });
  },

  onEInvoiceEnabledOptionUpdated: (newEInvoiceEnabledOption) => {
    onFieldValuesUpdated({ eInvoiceEnabledOption: newEInvoiceEnabledOption.value });
  },

  onPayButtonEnabledOptionChange: (isPayButtonEnabledChecked) => {
    onFieldValuesUpdated({
      payButtonEnabledOption: isPayButtonEnabledChecked
        ? payButtonEnabledOptionValues.ENABLED
        : payButtonEnabledOptionValues.DISABLED,
    });
  },

  onAddressesOverriddenUpdated: (addressesOverridden) => {
    onFieldValuesUpdated({ addressesOverridden });
  },

  onDebtorAddressesUpdated: (newAddress) => {
    const { overriddenDebtorAddresses, selectedDebtorId } = formFieldValues;
    const updatedDebtorAddresses = overriddenDebtorAddresses?.map((debtor) => {
      if (debtor.debtorId === selectedDebtorId) {
        return {
          ...debtor,
          addressLines: newAddress,
        };
      }
      return debtor;
    });
    onFieldValueSet({
      field: 'overriddenDebtorAddresses',
      value: updatedDebtorAddresses,
    });
  },

  onChangeSelectedDebtorTab: (selectedDebtorId) => {
    onFieldValuesUpdated({ selectedDebtorId });
  },

  onResetToDebtorDefaultAddress: async () => {
    const { overriddenDebtorAddresses, selectedDebtorId } = formFieldValues;

    // Fetch default details for the selected debtor
    const defaultDebtorAddress = await getDebtorDefaultNameAndAddress({
      id: selectedDebtorId,
    });
    const updatedDebtorAddresses = overriddenDebtorAddresses?.map((debtor) =>
      debtor.debtorId === selectedDebtorId ? defaultDebtorAddress : debtor,
    );
    onFieldValueSet({
      field: 'overriddenDebtorAddresses',
      value: updatedDebtorAddresses,
    });
  },

  onSurchargeOverriddenUpdated: (surchargeOverridden) => {
    onFieldValuesUpdated({ surchargeOverridden });
  },

  onSurchargeTypeUpdated: (newSurchargeType) => {
    onFieldValuesUpdated({ surchargeType: newSurchargeType });
  },

  onSurchargePercentageUpdated: (newSurchargePercentage) => {
    onFieldValuesUpdated({ surchargePercentage: newSurchargePercentage });
  },

  onSurchargeAmountUpdated: (newSurchargeAmount) => {
    onFieldValuesUpdated({ surchargeAmount: newSurchargeAmount });
  },

  onSurchargeApplyToUpdated: (newSurchargeApplyTo) => {
    onFieldValuesUpdated({ surchargeApplyTo: newSurchargeApplyTo });
  },

  onSurchargeLabelUpdated: (newSurchargeLabel) => {
    onFieldValuesUpdated({ surchargeLabel: newSurchargeLabel });
  },
});

export const MatterInvoiceSettingsContainer = withReduxStore(
  connect(mapStateToProps, mapDispatchToProps)(MatterInvoiceSettings),
);

MatterInvoiceSettingsContainer.displayName = 'MatterInvoiceSettingsContainer';

MatterInvoiceSettingsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
};

MatterInvoiceSettingsContainer.defaultProps = {
  matterId: undefined,
};
