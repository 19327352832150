import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { getDepositSources } from '@sb-billing/business-logic/payment-deposit-sources/services';
import { paymentSource as depositSourcesEnum } from '@sb-billing/business-logic/payment-deposit-sources/entities/constants';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import { todayAsInteger, dateToInteger } from '@sb-itops/date';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { getLogger } from '@sb-itops/fe-logger';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { CONTACT_CREATE_EDIT_MODAL_ID } from 'web/react-redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { findContactsWithMissingStreetOrPOAddress } from '@sb-customer-management/business-logic/contacts/services';
import { createContactAddressChecker } from '@sb-customer-management/contact-address-checker';
import { getRegion } from '@sb-itops/region';
import { depositFundsFormSchema } from './DepositFundsForm.yup';
import { DepositFundsModal } from './DepositFundsModal';
import { marshalData } from './marshal-data';

const log = getLogger('DepositFundsModal.forms.container');

const hooks = () => ({
  useDepositFundsForm: ({
    scope,
    onModalClose,
    onClickLink,
    bankAccountOptions,
    bankAccountOptionsLoading,
    onFetchControlledMoneyAccounts,
    checkIsStatutoryDepositMatter,
    onFetchBankRecLatestCompleted,
    bankReconciliationLatestCompletedByBankAccount,
    bankReconciliationSetup,
    bankReconciliationLoading,
    onFetchMatterTrustBankAccountIds,
    matterTrustBankAccountIds,
    matterTrustBankAccountIdsLoading,
    onFetchMatterDetails,
    matterDetailsLoading,
    matterDetails,

    matterTrustBalancesMap,
    matterTrustBalancesLoading,
    onFetchMatterTrustBalances,
    // defaults
    matterId: defaultMatterId,
    bankAccountId: defaultBankAccountId,
    defaultMatterLoading,
    defaultContactLoading,
    defaultMatter,
    defaultContact,
  }) => {
    const { t } = useTranslation();
    const [processClicked, setProcessClicked] = useState(false);
    const [processReceiptClicked, setProcessReceiptClicked] = useState(false);
    const [showAddReceivedFromContactForm, setShowAddReceivedFromContactForm] = useState(false);
    const [showAddDrawerContactForm, setShowAddDrawerContactForm] = useState(false);

    const depositFundsForm = useForm({
      scope,
      schema: depositFundsFormSchema,
    });

    const {
      formFields,
      formInitialised,
      formSubmitting,
      formValues,
      submitFailed,
      onClearForm,
      onInitialiseForm,
      onValidateForm,
      onSubmitFormWithValidation,
    } = depositFundsForm;

    const depositSourceOptions = getDepositSources({ t })?.paymentDepositSource || [];
    // Removed rows are set to undefined since we can't remove their key from forms so we need to filter them out
    const matterRows = Object.values(formValues?.rows || {}).filter((row) => !!row?.matterId);

    const matterClientAddressRequiredForTrustDeposit = hasFacet(facets.matterClientAddressRequiredForTrustDeposit);
    const clientsMissingAddresses = matterClientsMissingAddresses({
      matterDetails,
      required: matterClientAddressRequiredForTrustDeposit,
    });

    // On load hook
    useEffect(
      () => {
        if (defaultBankAccountId) {
          onFetchBankRecLatestCompleted({ bankAccountId: defaultBankAccountId });
        }
        if (defaultMatterId) {
          onFetchMatterTrustBankAccountIds({ matterId: defaultMatterId });
          onFetchMatterTrustBalances({ matterId: defaultMatterId, effectiveDate: todayAsInteger() });
          onFetchMatterDetails({ matterId: defaultMatterId });
          onFetchControlledMoneyAccounts({ matterId: defaultMatterId });
        }

        // Form Cleanup
        return () => onClearForm();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    /**
     * Form initialisation and callbacks
     */
    const validateForm = () => {
      const validateCtx = {
        t,
        requiresReasonField: hasFacet(facets.reasonField),
        checkIsStatutoryDepositMatter,
        bankReconciliationLatestCompleted: bankReconciliationLatestCompletedByBankAccount,
        bankReconciliationSetup,
        bankAccountOptions,
        matterTrustBankAccountIds,
        matterTrustBalancesMap,

        clientsMissingAddresses,
        matterDetails,
        matterClientRequiredForTrustDeposit: hasFacet(facets.matterClientRequiredForTrustDeposit),
        matterDescriptionRequiredForTrustDeposit: hasFacet(facets.matterDescriptionRequired),
        matterClientAddressRequiredForTrustDeposit,
        matterClientOrDescriptionRequired:
          hasFacet(facets.matterClientRequiredForTrustDeposit) || hasFacet(facets.matterDescriptionRequired),
      };

      onValidateForm(validateCtx);
    };

    const onUpdateFieldValues = (fieldValues) => {
      depositFundsForm.onUpdateFields(fieldValues);
      validateForm();
    };

    const getDefaultFormValues = () => {
      const defaultBankAccount = bankAccountOptions.find((ba) => ba.value === defaultBankAccountId);
      const matterDisplay = defaultMatter?.id
        ? getMatterDisplay(defaultMatter, defaultMatter?.matterType?.name)
        : undefined;

      return {
        matter: { id: defaultMatter?.id, displayName: matterDisplay },
        contact: getDefaultPayor({ contact: defaultContact, bankAccount: defaultBankAccount }),
        bankAccountId: defaultBankAccount?.value,
        effectiveDate: todayAsInteger(),
        bank: undefined,
        bankName: undefined,
        bankBranchNumber: undefined,
        drawer: { id: undefined, displayName: undefined },
        comment: undefined,
        amount: undefined,
        reference: undefined,
        reason: undefined,
        depositSourceId: (depositSourceOptions.length && depositSourceOptions[0]?.value) || undefined,
        rows: undefined,
      };
    };

    const isReadyToInitialiseForm =
      !formInitialised && !defaultContactLoading && !defaultMatterLoading && !bankAccountOptionsLoading;

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultFormValues();
      onInitialiseForm(defaultValues);
      validateForm();
    }

    /**
     *  Matter row
     */
    const entryMatterRowRef = useRef(); // We need ref to scroll to bottom when we add a matter

    const onAddMatterRow = () => {
      if (isAddRowDisabled({ bankAccount: selectedBankAccount, formFields, t })?.disabled) {
        return;
      }

      const matterRow = {
        matterId: formValues?.matter?.id,
        matterDisplay: formValues?.matter?.displayName,
        bankAccountId: formValues?.bankAccountId,
        amount: formValues?.amount,
        balance: matterTrustBalancesMap?.[formValues?.bankAccountId]?.availableBalance || 0,
      };

      // Since we can't remove existing key, when row is removed, we set key to undefined.
      // This means, if such a key is added again, it would be added to the original position, not the end.
      // Therefore, we append index to the key to make it always unique
      const index = Object.values(formValues?.rows || {}).length;

      const fieldsToUpdate = {
        amount: undefined,
        matter: { id: undefined, displayName: undefined },
        rows: {
          [`${matterRow.matterId}-${index}`]: matterRow,
        },
      };

      onUpdateFieldValues(fieldsToUpdate);
      // We always want to scroll to the botom of modal when we add a matter
      // We can't scroll directly as the new row is not rendered yet so we schedule it for next event loop
      setTimeout(() => {
        entryMatterRowRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    };

    const onRemoveMatterRow = ({ rowId }) => {
      const matterRow = {
        matterId: undefined,
        matterDisplay: undefined,
        bankAccountId: undefined,
        amount: undefined,
        balance: undefined,
      };

      if (matterRows.length === 1) {
        // We are removing the only row so we need to refetch CMA bank accounts
        onFetchControlledMoneyAccounts({ matterId: formValues?.matter?.id });
      }

      // We can't really remove a key from a forms field. We could set to undefined by onFieldValueSet but that automatically
      // triggers validation without context object. Therefore, we just update the object fields to undefined.
      onUpdateFieldValues({
        rows: {
          [rowId]: matterRow,
        },
      });
    };

    // Amount field for locked row can still be updated
    const onUpdateLockedRowAmount = ({ rowId, amount }) => {
      onUpdateFieldValues({
        rows: {
          [rowId]: { amount },
        },
      });
    };

    // Total amount is sum of all rows plus amount in "entry" row
    const totalAmount = matterRows.reduce((sum, row) => sum + (row?.amount || 0), 0) + (formValues?.amount || 0);

    const selectedBankAccount = bankAccountOptions.find((ba) => ba.value === formValues?.bankAccountId)?.data;
    const readOnlyReference = isReferenceReadonly({ bankAccount: selectedBankAccount });

    // Default options for typeaheads
    const defaultReceivedFromContactOptions = getContactTypeaheadDefaultOptions({ contact: formValues?.contact });
    const defaultMatterSummaries = getMatterTypeaheadDefaultOption({ matter: defaultMatter });

    const disableBankAccount = matterRows.length > 0; // We can't change the bank accounts once we "add" a matter
    const { disabled: disableAddRow, tooltip: addRowTooltip } = isAddRowDisabled({
      bankAccount: selectedBankAccount,
      formFields,
      t,
    });
    const allowReceipt =
      isCMABankAccount({ bankAccount: selectedBankAccount }) ||
      isTrustBankAccount({ bankAccount: selectedBankAccount });

    useEffect(() => {
      if (
        matterTrustBankAccountIdsLoading === true ||
        matterTrustBalancesLoading === true ||
        matterDetailsLoading === true ||
        bankReconciliationLoading === true
      ) {
        return;
      }
      // trigger validation only after we have data
      validateForm();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      matterTrustBankAccountIds,
      matterTrustBankAccountIdsLoading,
      matterTrustBalancesMap,
      matterTrustBalancesLoading,
      matterDetails,
      matterDetailsLoading,
      bankReconciliationLatestCompletedByBankAccount,
      bankReconciliationLoading,
    ]);

    const validationDataLoading =
      matterTrustBankAccountIdsLoading ||
      bankReconciliationLoading ||
      matterTrustBalancesLoading ||
      matterDetailsLoading;

    const onSelectMatter = (matter) => {
      const fieldsToUpdate = { matter: { id: matter?.value, displayName: matter?.label } };
      if (!disableBankAccount) {
        onFetchControlledMoneyAccounts({ matterId: matter?.value });
        if (selectedBankAccount?.accountType === bankAccountTypeEnum.CONTROLLEDMONEY) {
          // if CMA is selected, we reset bank account as CMA is per matter
          fieldsToUpdate.bankAccountId = undefined;
        }
      }
      onFetchMatterTrustBankAccountIds({ matterId: matter?.value });
      onFetchMatterTrustBalances({ matterId: matter?.value, effectiveDate: formValues?.effectiveDate });
      onFetchMatterDetails({ matterId: matter?.value });
      onUpdateFieldValues(fieldsToUpdate);
    };

    const onSelectBankAccount = (ba) => {
      const newBankAccountId = ba?.value;

      if (ba?.data?.accountType === bankAccountTypeEnum.TRUST) {
        onFetchBankRecLatestCompleted({ bankAccountId: newBankAccountId });
      }

      const newReadOnlyReference = isReferenceReadonly({
        bankAccountId: ba.data,
      });

      const fieldsToUpdate = {
        bankAccountId: newBankAccountId,
        reference: newReadOnlyReference ? '' : formValues?.reference,
      };

      onUpdateFieldValues(fieldsToUpdate);
    };

    const onProcess = async ({ withReceipt = false }) => {
      try {
        validateForm();

        if (withReceipt) {
          setProcessReceiptClicked(true);
        } else {
          setProcessClicked(true);
        }

        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            const data = marshalData({ formData, t, bankAccount: selectedBankAccount });

            await dispatchCommand({
              type: 'Billing.Accounts.Messages.Commands.DepositFunds',
              message: data,
            });

            if (
              withReceipt &&
              (isCMABankAccount({ bankAccount: selectedBankAccount }) ||
                isTrustBankAccount({ bankAccount: selectedBankAccount }))
            ) {
              const params = {};
              if (data.isBulk) {
                params.bulkDepositId = data.bulkDepositId;
              } else {
                params.transactionId = data.transactions[0].transactionId;
              }

              onClickLink({ type: 'receipt', id: params });
            } else {
              displaySuccessToUser('Funds deposited successfully');
            }

            setProcessClicked(false);
            setProcessReceiptClicked(false);

            onModalClose();
          },
        });
      } catch (error) {
        log.error('Failed to deposit funds', error);
        displayErrorToUser('Failed to deposit funds');
        setProcessClicked(false);
        setProcessReceiptClicked(false);
      }
    };

    return {
      totalAmount,
      isModalLoading: !formInitialised,
      validationDataLoading,
      submitIsDisabled:
        formSubmitting || validationDataLoading || showAddReceivedFromContactForm || showAddDrawerContactForm,
      processIsLocked: formSubmitting && processClicked,
      processReceiptIsLocked: formSubmitting && processReceiptClicked,
      // form
      formData: formValues,
      formErrors: formFields, // Contains data related to form errors
      submitFailed,
      formSubmitting,
      depositSourceOptions,
      bankAccountOptions,
      bankAccountOptionsLoading,
      clientsMissingAddresses, // We need these to correctly display missing address errors
      // ui
      disableAddRow,
      disableBankAccount,
      readOnlyReference,
      isChequeSource: formValues?.depositSourceId === depositSourcesEnum.CHECK,
      supportsElectronicPayment: hasFacet(facets.electronicPayment),
      useBankNameForDepositFunds: hasFacet(facets.bankNameForDepositFunds),
      entryMatterRowRef,
      addRowTooltip,
      allowReceipt,
      // received from and drawer typeahead
      showAddReceivedFromContactForm,
      setShowAddReceivedFromContactForm,
      showAddDrawerContactForm,
      setShowAddDrawerContactForm,
      // callbacks
      onUpdateFieldValues,
      onAddMatterRow,
      onRemoveMatterRow,
      onUpdateLockedRowAmount,
      onSelectMatter,
      onSelectBankAccount,
      onSelectReceivedFrom: (receivedFrom) => {
        onUpdateFieldValues({ contact: { id: receivedFrom?.value, displayName: receivedFrom?.label } });
      },
      onSelectDrawer: (drawer) => {
        onUpdateFieldValues({ drawer: { id: drawer?.value, displayName: drawer?.label } });
      },
      onSelectEffectiveDate: (newDate) => {
        const dateInt = newDate ? dateToInteger(newDate) : undefined;

        onFetchMatterTrustBalances({ matterId: formValues?.matter?.id, effectiveDate: dateInt });
        onUpdateFieldValues({ effectiveDate: dateInt });
      },
      onOpenEditContactModal: (clickedContactId) => {
        setModalDialogVisible({
          modalId: CONTACT_CREATE_EDIT_MODAL_ID,
          props: { contactId: clickedContactId, onContactEdited: validateForm },
        });
      },
      onProcess,
      // typeaheads
      defaultReceivedFromContactOptions,
      defaultMatterSummaries,
    };
  },
});

const matterClientsMissingAddresses = ({ matterDetails, required }) => {
  if (!required || !matterDetails) {
    return [];
  }
  const addressChecker = createContactAddressChecker(getRegion());

  const contacts = findContactsWithMissingStreetOrPOAddress({
    contacts: matterDetails?.clients || [],
    spreadGroupsOfPeople: true,
    contactAddressChecker: addressChecker,
  });

  return contacts;
};

const isAddRowDisabled = ({ bankAccount, formFields, t }) => {
  if (!bankAccount) {
    return { disabled: true, tooltip: 'Bank Account, Matter and Amount are required before adding row' };
  }

  // If CMA is selected, we can't add multiple matters
  if (isCMABankAccount({ bankAccount })) {
    return {
      disabled: true,
      tooltip: `Cannot add rows for ${t(
        'controlledMoneyAccount',
      )?.toLowerCase()} deposits. Change account to enable additional rows`,
    };
  }

  const canAddRow = formFields?.matter?.id?.value && formFields?.matter?.id?.isValid && formFields?.amount?.isValid;
  // We don't allow adding new row until entry row is valid with a matter selected
  if (!canAddRow) {
    return { disabled: true, tooltip: 'Matter and Amount are required before adding row' };
  }

  return { disabled: false };
};

const getContactTypeaheadDefaultOptions = ({ contact }) => {
  if (!contact?.id) {
    return [];
  }

  return [{ data: contact, label: contact.displayName, value: contact.id }];
};

const getMatterTypeaheadDefaultOption = ({ matter }) => {
  if (!matter) {
    return [];
  }

  const typeahead = [
    matter.matterNumber,
    matter.clientDisplay,
    matter.otherSideDisplay,
    matter.matterType?.name,
    matter.attorneyResponsible?.name,
    matter.attorneyResponsible?.initials,
    matter.description,
  ];
  const defaultMatterSummaries = [
    {
      ...matter,
      display: getMatterDisplay(matter, matter.matterType?.name),
      matterClientNames: matter.clientNames,
      matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
      matterStartedISO: matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '',
      typeahead: typeahead.filter((m) => m).join(' '),
    },
  ];
  return defaultMatterSummaries;
};

const isReferenceReadonly = ({ bankAccount }) => {
  const autoGeneratedReference = hasFacet(facets.autoGeneratedReference);

  if (!bankAccount) {
    return autoGeneratedReference;
  }

  return autoGeneratedReference && (isCMABankAccount({ bankAccount }) || isTrustBankAccount({ bankAccount }));
};

const getDefaultPayor = ({ contact, bankAccount }) => {
  if (contact?.id) {
    return { id: contact?.id, displayName: contact?.displayNameFull || contact?.displayName };
  }

  // for CMA account, use first beneficiary, unless other contact specified
  if (bankAccount?.id && isCMABankAccount({ bankAccount })) {
    const firstBeneficiary = bankAccount?.beneficiaries?.[0];
    return {
      id: firstBeneficiary?.id,
      displayName: firstBeneficiary?.displayNameFull || firstBeneficiary?.displayName,
    };
  }

  return { id: undefined, displayName: undefined };
};

const isCMABankAccount = ({ bankAccount }) => bankAccount?.accountType === bankAccountTypeEnum.CONTROLLEDMONEY;
const isTrustBankAccount = ({ bankAccount }) => bankAccount?.accountType === bankAccountTypeEnum.TRUST;

export const DepositFundsModalFormsContainer = composeHooks(hooks)(DepositFundsModal);

DepositFundsModalFormsContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,

  bankAccountOptions: PropTypes.array.isRequired,
  bankAccountOptionsLoading: PropTypes.bool.isRequired,
  checkIsStatutoryDepositMatter: PropTypes.func.isRequired,

  onFetchControlledMoneyAccounts: PropTypes.func.isRequired,
  onFetchMatterTrustBankAccountIds: PropTypes.func.isRequired,
  matterTrustBankAccountIds: PropTypes.array,
  matterTrustBankAccountIdsLoading: PropTypes.bool.isRequired,

  onFetchMatterDetails: PropTypes.func.isRequired,
  matterDetailsLoading: PropTypes.bool.isRequired,
  matterDetails: PropTypes.object,

  onFetchBankRecLatestCompleted: PropTypes.func.isRequired,
  bankReconciliationLatestCompletedByBankAccount: PropTypes.object,
  bankReconciliationSetup: PropTypes.object,
  bankReconciliationLoading: PropTypes.bool.isRequired,

  matterTrustBalancesMap: PropTypes.object,
  matterTrustBalancesLoading: PropTypes.bool.isRequired,
  onFetchMatterTrustBalances: PropTypes.func.isRequired,

  // defaults
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  defaultMatterLoading: PropTypes.bool.isRequired,
  defaultContactLoading: PropTypes.bool.isRequired,
  defaultMatter: PropTypes.object,
  defaultContact: PropTypes.object,
};
DepositFundsModalFormsContainer.defaultProps = {
  matterId: undefined,
  bankAccountId: undefined,
  defaultMatter: undefined,
  defaultContact: undefined,
  defaultBankAccount: undefined,
  matterTrustBankAccountIds: undefined,
  matterDetails: undefined,
  bankReconciliationLatestCompletedByBankAccount: undefined,
  bankReconciliationSetup: undefined,
  matterTrustBalancesMap: undefined,
};

DepositFundsModalFormsContainer.displayName = 'DepositFundsModalFormsContainer';
