import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@sb-itops/react';
import Styles from './MarkAsSentModal.module.scss';
import { MarkAsSentModalBody } from './MarkAsSentModalBody';

export const MARK_AS_SENT_MODAL_ID = 'mark-as-sent-new-modal';

export const MarkAsSentModal = ({
  isModalLoading,
  showModal,
  onModalClose,

  // form
  formData,
  formErrors,
  externalSentViaOptions,
  isSendViaNotMatchMatterPreference,
  isSubmitDisabled,

  // callbacks
  onFieldValueUpdated,
  onMark,
}) => (
  <Modal
    className={Styles.markAsSentModal}
    isVisible={showModal}
    title="Mark As Sent"
    isLoading={isModalLoading}
    body={
      <MarkAsSentModalBody
        // form
        formData={formData}
        formErrors={formErrors}
        externalSentViaOptions={externalSentViaOptions}
        isSendViaNotMatchMatterPreference={isSendViaNotMatchMatterPreference}
        // callbacks
        onFieldValueUpdated={onFieldValueUpdated}
      />
    }
    footer={
      <div>
        <Button onClick={onMark} disabled={isSubmitDisabled}>
          Mark as Sent
        </Button>
      </div>
    }
    onModalClose={onModalClose}
  />
);

MarkAsSentModal.propTypes = {
  isModalLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  // form
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  externalSentViaOptions: PropTypes.array.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isSendViaNotMatchMatterPreference: PropTypes.bool.isRequired,
  // callbacks
  onFieldValueUpdated: PropTypes.func.isRequired,
  onMark: PropTypes.func.isRequired,
};
MarkAsSentModal.defaultProps = {};

MarkAsSentModal.displayName = 'MarkAsSentModal';
