'use strict';

const { ALL_STATES } = require('@sb-itops/region');

const { bankAccountState } = require('../entities/constants');

/**
 * This function will return the relevant bank account id to use for invoice overpayments
 *  * Invoices can be overpaid via a direct source (e.g. Cash, Bank transfer, etc.)
 *  * The overpaid amount will be transferred to either:
 *    1. Trust account (AU & UK)
 *    2. Operating account (US)
 *
 * There are some rules involved in determining the appropriate bank account
 *  * The original logic has been copied over from: monorepo/billing/endpoints/payment/util.js
 *
 * NB: This function is LOD compatible
 *
 * @param {object} params
 * @param {boolean} params.isOverpaymentGoingToTrustAccount facets.overpaymentMustGoToTrust
 * @param {boolean} params.isTrustAccountPerStateFacetEnabled facets.trustAccountPerState
 * @param {string} params.legacyTrustAccountId
 * @param {object} params.bankAccountSettings entity with default trust accounts for states
 * @param {object} params.matterBillingConfiguration
 * @param {object} params.matterLocation e.g. NSW
 * @param {Array<object>} params.matterTrustAccounts
 * @param {Array<object>} params.firmTrustAccounts
 * @param {string} params.operatingAccountId
 * @returns {string}
 */
function deriveInvoiceOverpaymentAccountId({
  bankAccountSettings,
  firmTrustAccounts,
  isOverpaymentGoingToTrustAccount,
  isTrustAccountPerStateFacetEnabled,
  legacyTrustAccountId,
  matterBillingConfiguration,
  matterLocation,
  matterTrustAccounts,
  operatingAccountId,
} = {}) {
  // Required args
  if (typeof isOverpaymentGoingToTrustAccount !== 'boolean' || !operatingAccountId || !legacyTrustAccountId) {
    throw new Error(
      `Please provide all required args. Provided args: ${JSON.stringify({
        isOverpaymentGoingToTrustAccount: !!isOverpaymentGoingToTrustAccount,
        legacyTrustAccountId: !!legacyTrustAccountId,
        operatingAccountId: !!operatingAccountId,
      })}`,
    );
  }

  /**
   * [1] Overpayment going to OPERATING account
   */

  if (!isOverpaymentGoingToTrustAccount) {
    return operatingAccountId;
  }

  /**
   * [2] Overpayment going to TRUST account
   */

  // Required args for trust accounts
  if (
    !bankAccountSettings ||
    typeof isTrustAccountPerStateFacetEnabled !== 'boolean' ||
    (isTrustAccountPerStateFacetEnabled && !matterLocation) ||
    !matterTrustAccounts ||
    !firmTrustAccounts
  ) {
    throw new Error(
      `Please provide all required args for trust accounts. Provided args: ${JSON.stringify({
        bankAccountSettings: !!bankAccountSettings,
        matterLocation: !!matterLocation,
        matterTrustAccounts: !!matterTrustAccounts,
        firmTrustAccounts: !!firmTrustAccounts,
      })}`,
    );
  }

  // [2a] The matter's default trust account (if active)
  const matterDefaultTrustAccountId = matterBillingConfiguration?.defaultTrustAccountId;
  const matterDefaultTrustAccount = matterDefaultTrustAccountId
    ? firmTrustAccounts.find((ta) => ta.id === matterDefaultTrustAccountId)
    : undefined;

  if (matterDefaultTrustAccount?.state === bankAccountState.OPEN) {
    return matterDefaultTrustAccount.id;
  }

  // [2b] The matter's location (e.g. state) default trust account (if active)
  const { defaultTrustAccounts } = bankAccountSettings;

  if (defaultTrustAccounts?.length) {
    let defaultTrustAccountForLocation;

    // For state based regions (e.g. AU)
    if (isTrustAccountPerStateFacetEnabled) {
      defaultTrustAccountForLocation = defaultTrustAccounts.find((ta) => ta.location === matterLocation);
    }

    // If no state based match is found or if it is a stateless region (e.g. GB), we attempt to assign the ALL_STATES default

    if (!defaultTrustAccountForLocation) {
      defaultTrustAccountForLocation = defaultTrustAccounts.find((ta) => ta.location === ALL_STATES.value);
    }

    if (defaultTrustAccountForLocation) {
      // We need to check if the trust account is "open"
      //  * Unfortunately, the "state" property isn't available in bankAccountSettings.defaultTrustAccounts
      //  * So for now, find the corresponding trust account from the firmTrustAccounts (which does)
      defaultTrustAccountForLocation = firmTrustAccounts.find(
        (ta) => ta.id === defaultTrustAccountForLocation.defaultTrustAccountId,
      );

      const isDefaultTrustAccountForLocationOpen = defaultTrustAccountForLocation?.state === bankAccountState.OPEN;

      if (isDefaultTrustAccountForLocationOpen) {
        return defaultTrustAccountForLocation.id;
      }
    }
  }

  // [2c] The first trust account on the matter (if active)
  if (matterTrustAccounts.length) {
    const firstOpenTrustAccountInMatter = matterTrustAccounts.find((ta) => ta.state === bankAccountState.OPEN);

    if (firstOpenTrustAccountInMatter) {
      return firstOpenTrustAccountInMatter.id;
    }
  }

  // [2d] The firm's legacy trust account (even if inactive)
  return legacyTrustAccountId;
}

module.exports = {
  deriveInvoiceOverpaymentAccountId,
};
