import { gql } from '@apollo/client';

import { matterSummaryFragment } from '../fragments/matterSummaryFragment.graphql';

const query = gql`
  query ExpenseModalData($id: ID!) {
    expense(id: $id) {
      id
      activityCode # expenseActivityId || utbmsActivityCode
      amountExcTax
      amountIncludesTax
      attachmentFile {
        fileName
        location
      }
      description # Subject field
      costType
      expenseDate
      expenseEarnerStaff {
        id
      }
      finalized
      isAnticipated
      isBillable
      isInvoicedExternally
      isTaxOverridden
      isOutputTaxOverridden
      matter {
        ...matterSummaryFragment
      }
      notes # Description field
      price
      quantity
      tax
      outputTax
      utbmsTaskCode
      waived # Write off field
      expensePaymentDetails {
        id
        supplier {
          id
          displayName
          displayNameFull
        }
        supplierReference
        paymentDue
        isPayable
        isPaid
        paymentMethod
        paymentAccountName
        paymentReference
      }
      operatingCheque {
        id
        isManual
        chequeNumber # Reference
        chequeMemo
        payTo {
          id
          displayName
          displayNameFull
        }
      }
      invoice {
        id
        invoiceNumber
      }
    }
  }

  ${matterSummaryFragment}
`;

const notificationIds = [
  'ExpensesNotifications.ExpenseUpdated',
  'ExpensesNotifications.ExpensePaymentDetailsUpdated',
  'InvoicingNotifications.InvoiceUpdated',
  'AccountsNotifications.OperatingChequeUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ExpenseModalData = {
  query,
  notificationIds,
};
