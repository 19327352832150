import { gql } from '@apollo/client';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

// NOTE: The reason we do not request the matter ID here is because this query
// uses `useQuery` meaning it remains in the cache after the user leaves the
// route. Having the `id` field here would cache the matter for the lifespan
// of the application, accumulating data from other queries and potentially
// causing cache conflicts
const query = gql`
  query MatterAnyCheckData($matterListFilter: MatterListFilter, $offset: Int, $limit: Int) {
    matterList(filter: $matterListFilter, offset: $offset, limit: $limit) {
      results {
        status
      }
    }
  }
`;

const notificationIds = ['IGNORED'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const MatterAnyCheckData = {
  query,
  notificationIds,
};
