/**
 * A map of cache versions. The key represents the cache version and is compared to the version in the browser
 * dict store. The value is an array of cache names.
 * 
 * Used when an EXISTING cache will need to be purged and reloaded due to changes in the cache structure or otherwise.
 */
/** @type {CacheUpgrades} */
export const cacheUpgrades = {
  1: ['sbTransactionService'],
  2: ['sbTransactionService'],
  3: ['sbTransactionService'],
  // WI-2686
  4: ['sbInvoiceTotalsService', 'sbMatterTotalsService', 'sbInvoicingService'], 
  // WI-3723 (sbSimpleContactMbService), BB-580 (sbTrustChequeService, sbTransactionService)
  5: ['sbSimpleContactMbService', 'sbTrustChequeService', 'sbTransactionService'],
  6: [
    'sbBankAccountService',
    'sbBankAccountBalancesService',
    'sbBankReconciliationService',
    'sbDepositSlipService',
    'sbFeeConfigurationService',
    'sbInvoiceSettingsService',
    'sbInvoicingService',
    'sbTrustChequePrintSettingsService',
    'sbTrustReceiptSettingsService',
    'sbSimpleContactMbService',
  ],
  7: [
    'sbBankAccountBalancesService',
    'sbTrustChequePrintSettingsService',
    'sbTrustReceiptSettingsService',
    'sbSimpleContactMbService',
  ],
  8: [
    'sbSimpleContactMbService',
  ],
  9: [
    'sbExpenseService',
  ],
  10: [
    'sbSimpleContactMbService',
  ],
  11: [
    'sbFirmManagementMbService',
  ],
  12: [
    'sbRelateContactToMattersMbService',
  ],
  13: [
    'sbRelateContactToMattersMbService',
  ],
  14: [
    'sbRelateContactToMattersMbService',
  ],
  15: [
    'sbRelateContactToMattersMbService',
  ],
  16: [
    'sbRelateContactToMattersMbService',
  ],
  17: [
    'sbDepositSlipService',
    'sbTransactionService',
  ],
  18: [
    'sbCorrespondenceHistoryService',
  ],
  19: [
    'sbCorrespondenceHistoryService',
  ],
  20: [
    'sbContactsMbService',
  ],
};
