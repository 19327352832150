import { useState } from 'react';
import { featureActive } from '@sb-itops/feature';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useCreateLetter } from 'web/hooks';
import { useMatterParties } from 'web/hooks/use-matter-parties';
import { sendMetric } from 'web/services/metrics';
import { CreateLetterModal, Contact, ICreateLetterModalProps } from './CreateLetterModal';

export interface ICreateLetterModalContainerProps {
  matterId: string;
  selectedEntityId?: string;
  body?: string;
  isFileManagerInstalled: boolean;
  onClose: () => void;
}

const hooks = ({
  matterId,
  selectedEntityId,
  body,
  isFileManagerInstalled,
  onClose,
}: ICreateLetterModalContainerProps) => ({
  useModalData: (): ICreateLetterModalProps => {
    const { selectableContacts, loading: selectableContactsLoading } = useMatterParties(matterId);

    const {
      authorizationLoading,
      isUnauthorized,
      isCreatingLetterInDesktop,
      createLetterInDesktop,
      isCreatingLetterInWeb,
      createLetterInWeb,
    } = useCreateLetter();

    const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined);
    const [letterUrl, setLetterUrl] = useState<string | undefined>();

    if (selectedContact === undefined && selectableContacts && selectableContacts.length > 0) {
      const firstContact = selectableContacts[0];
      const initialContact = selectedEntityId
        ? selectableContacts?.find((contact) => contact.id === selectedEntityId) ?? firstContact
        : firstContact;

      setSelectedContact(initialContact);
    }

    const onCreateLetterInDesktop = async () => {
      sendMetric('CreateLetterInDesktopSent');
      await createLetterInDesktop(matterId, selectedContact!.id, selectedContact!.name, body);

      onClose();
    };

    const onCreateLetterInWeb = async () => {
      sendMetric('CreateLetterInWebSent');
      const createLetterUrl = await createLetterInWeb(matterId, selectedContact!.id, selectedContact!.name, body);

      if (createLetterUrl) {
        window.open(createLetterUrl, '_blank');
        setLetterUrl(createLetterUrl);
      }
    };

    let authorizationState: 'loading' | 'unauthorized' | 'authorized';
    if (authorizationLoading) {
      authorizationState = 'loading';
    } else if (isUnauthorized) {
      authorizationState = 'unauthorized';
    } else {
      authorizationState = 'authorized';
    }

    const showCreateInDesktop = featureActive('NV-5529') && isFileManagerInstalled;

    return {
      authorizationState,
      selectableContactsLoading,
      selectableContacts,
      selectedContact,
      setSelectedContact,
      showCreateInDesktop,
      onCreateLetterInDesktop,
      isCreatingLetterInDesktop,
      onCreateLetterInWeb,
      isCreatingLetterInWeb,
      letterUrl,
      onClose,
    };
  },
});

export const CreateLetterModalContainer = composeHooks(hooks)(CreateLetterModal);
