import React from 'react';
import PropTypes from 'prop-types';
import { getMfaSetupUrl } from '@sb-itops/environment-config';
import { getRegion, regionType } from '@sb-itops/region';
import { owningCompanyBrandNames } from '@sb-itops/business-logic/white-label';
import { LoginXeroTwoFactorNotice } from './LoginXeroTwoFactorNotice';

// We hardcode the help link here as this component is shown when user is not authenticated so we can't use LD
export const helpLinkByRegion = {
  [regionType.US]: 'https://support.smokeball.com/hc/en-us/articles/22524679683479',
  [regionType.AU]: 'https://support.smokeball.com/hc/en-au/articles/22524679683479',
  [regionType.GB]: 'https://support.smokeball.com/hc/en-gb/articles/22524679683479',
};

export const LoginXeroTwoFactorNoticeContainer = (props) => {
  const onEnableTwoFactorClick = () => {
    const brandName = owningCompanyBrandNames[props.branding] || owningCompanyBrandNames.Smokeball;
    const mfaSetupUrl = getMfaSetupUrl();
    const mfaSetupUrlWithBrandName = `${mfaSetupUrl}${brandName}`;
    window.open(mfaSetupUrlWithBrandName, '_blank', 'noopener,noreferrer');
    props?.onEnableTwoFactorClick();
  };
  const helpGuideUrl = helpLinkByRegion[getRegion()];

  return <LoginXeroTwoFactorNotice onEnableTwoFactorClick={onEnableTwoFactorClick} helpGuideUrl={helpGuideUrl} />;
};

LoginXeroTwoFactorNoticeContainer.displayName = 'LoginXeroTwoFactorNoticeContainer';

LoginXeroTwoFactorNoticeContainer.propTypes = {
  branding: PropTypes.string.isRequired,
  onEnableTwoFactorClick: PropTypes.func.isRequired,
};
