import { gql } from '@apollo/client';

const query = gql`
  query AddInvoicePaymentModalData(
    $id: ID!
    $isMatterClientRequiredForTrustDeposit: Boolean!
    $isMatterDescriptionRequiredForTrustDeposit: Boolean!
  ) {
    invoice(id: $id) {
      id
      invoiceNumber
      matter {
        id
        description @include(if: $isMatterDescriptionRequiredForTrustDeposit)
        clients @include(if: $isMatterClientRequiredForTrustDeposit) {
          id
          displayNameFirstLast
          type
          residentialAddress {
            city
            zipCode
            detailedStreetAddress {
              streetName
            }
            pOBoxAddress {
              pOBoxType
              pOBoxNumber
              city
              state
              zipCode
            }
          }
          businessAddress {
            city
            zipCode
            detailedStreetAddress {
              streetName
            }
            pOBoxAddress {
              pOBoxType
              pOBoxNumber
              city
              state
              zipCode
            }
          }
        }
      }
      debtors {
        id
        contact {
          id
          displayName
          displayNameFull
        }
      }
      totals {
        id
        total
        unpaid
        paid
        unpaidExcInterest
        interest
      }
    }
  }
`;

const notificationIds = [
  'InvoicingNotifications.InvoiceUpdated',
  'BillingMattersNotifications.BillingInvoiceTotalsUpdated',
];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const AddInvoicePaymentModalData = {
  query,
  notificationIds,
};
