import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { FirmSchema } from '@sb-firm-management/validation';
import { firmManagement } from '@sb-firm-management/redux';
import * as messageDisplay from '@sb-itops/message-display';
import { getRegion, states } from '@sb-itops/region';
import { getSchemeValue } from '@sb-itops/region-schemes';
import { isSameAddress } from '@sb-customer-management/business-logic/contacts/services';
import { mapAddressFormFields } from '@sb-customer-management/business-logic/contacts/services/address-search';
import { sendMetric } from 'web/services/metrics';
import { useSubscribedQuery } from 'web/hooks';
import { FirmLogo } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { getImageFormatP } from 'web/ng-components/invoice-settings/invoice-settings-helpers';
import { getLogger } from '@sb-itops/fe-logger';
import { featureActive } from '@sb-itops/feature';
import FirmDetails from './FirmDetails';

const { getFirmDetails, editFirm } = firmManagement;
const REGION = getRegion();
const scope = 'firm-details';

const log = getLogger('FirmDetails.container');

export const FirmDetailsContainer = (props) => {
  const { selectors: formSelectors, actions: formActions, operations: formOperations } = useScopedFeature(forms, scope);

  const firmLogoResult = useSubscribedQuery(FirmLogo, {
    skip: !featureActive('NUCWEB-748'),
  });

  const { formInitialised, fields: formFields, submitFailed, formSubmitting } = useSelector(formSelectors.getFormState);

  const {
    name,
    abn,
    acn,
    mailingAddress,
    businessAddress,
    phoneAreaCode,
    phoneNumber,
    faxAreaCode,
    faxNumber,
    streetIsMailingAddress,
  } = formFields;

  const dispatch = useDispatch();

  useEffect(() => {
    const details = getFirmDetails() || {};
    const mergedMailingAddress = details.mailingAddress || details.poBoxAddress || {};
    const mergedStreetAddress = details.businessAddressDetailed || details.businessAddress || {};
    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          name: details.firmName || '',
          acn: details.acn || '',
          abn: details.abn || '',
          phoneAreaCode: details.phone?.areaCode || '',
          faxAreaCode: details.fax?.areaCode || '',
          phoneNumber: (REGION === 'US' ? details.phone?.localNumber : details.phone) || '',
          faxNumber: (REGION === 'US' ? details.fax?.localNumber : details.fax) || '',
          streetIsMailingAddress:
            REGION === 'US' &&
            isSameAddress({ mailingAddress: mergedMailingAddress, businessAddress: mergedStreetAddress }),
          mailingAddress: {
            addressLine1: '',
            addressLine2: '',
            city: mergedMailingAddress.suburbTown || '',
            county: '',
            locality: '',
            country: '',
            state: states[REGION][0].value,
            zipCode: mergedMailingAddress.postcode || '',
            careOf: '',
            boxNumber: '',
            boxType: '',
            ...mergedMailingAddress,
          },
          businessAddress: {
            buildingLevel: '',
            unitNumber: '',
            unitType: '',
            streetNumber: '',
            streetName: '',
            streetType: '',
            addressLine1: '',
            addressLine2: '',
            city: mergedStreetAddress.suburbTown || '',
            state: states[REGION][0].value,
            zipCode: mergedStreetAddress.postcode || '',
            county: '',
            locality: '',
            country: '',
            ...mergedStreetAddress,
          },
        },
      }),
    );
    const onUnload = () => dispatch(formActions.clearForm());
    return onUnload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldValueUpdated = (fieldValues) => {
    dispatch(formActions.updateFieldValues({ fieldValues }));
  };
  const validateForm = () => {
    dispatch(formOperations.validateSchema({ schema: FirmSchema }));
  };

  const onAddressAutocompleteSelected = (addressType, addressInfo) => {
    const addressFields = mapAddressFormFields(addressInfo, getSchemeValue('addressScheme'));
    dispatch(
      formActions.updateFieldValues({
        fieldValues: {
          [addressType]: addressFields,
        },
      }),
    );
  };

  const addressScheme = getSchemeValue('addressScheme');
  const phoneNumberScheme = getSchemeValue('phoneNumberScheme');

  const [firmLogo, setFirmLogo] = useState('');

  useEffect(() => {
    const fileBase64Encoded = firmLogoResult?.data?.firm?.logoBase64;
    if (fileBase64Encoded) {
      const decodedFile = new File([new Blob([Buffer.from(fileBase64Encoded, 'base64')])], 'logo');
      // eslint-disable-next-line promise/catch-or-return
      getImageFormatP(decodedFile).then((fileFormat) => {
        // eslint-disable-next-line promise/always-return
        if (fileFormat) {
          const renamedFile = new File([decodedFile], `logo${fileFormat}`);
          setFirmLogo(URL.createObjectURL(renamedFile));
        } else {
          log.error('Firm logo format not supported');
        }
      });
    }
  }, [firmLogoResult?.data?.firm?.logoBase64]);

  return (
    <FirmDetails
      {...props}
      {...{
        firmLogo,
        setFirmLogo,
        addressScheme,
        phoneNumberScheme,
        onAddressAutocompleteSelected,
        onSave: async (event) => {
          event.preventDefault();
          await dispatch(formOperations.validateSchema({ schema: FirmSchema }));
          try {
            await dispatch(
              formOperations.submitFormWithValidationP({
                submitFnP: async (formData) => {
                  const data = formData;
                  if (formData.streetIsMailingAddress) {
                    data.mailingAddress = formData.businessAddress;
                  }
                  sendMetric('EditFirm');
                  await editFirm(data);
                  messageDisplay.success('The firm details have successfully been saved');
                },
              }),
            );
          } catch (err) {
            messageDisplay.error('Failed to save firm details');
          }
        },
        onFieldValueUpdated,
        name,
        abn,
        acn,
        mailingAddress,
        businessAddress,
        validateForm,
        formDisabled: formSubmitting,
        submitFailed,
        phoneAreaCode,
        phoneNumber,
        faxAreaCode,
        faxNumber,
        formInitialised,
        streetIsMailingAddress,
      }}
    />
  );
};

FirmDetailsContainer.displayName = 'FirmDetailsContainer';

FirmDetailsContainer.propTypes = {};

FirmDetailsContainer.defaultProps = {};

export default withApolloClient(withReduxProvider(FirmDetailsContainer));
