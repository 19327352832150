import { store } from '@sb-itops/redux';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { featureActive, featureData } from '@sb-itops/feature';
import * as messageDisplay from '@sb-itops/message-display';
import { getContactStatusLabelById } from '@sb-customer-management/redux/contacts-summary';
import { getActiveProvider } from '@sb-billing/redux/payment-provider-settings/selectors';
import { providers, providerNames } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { selectors as supportDebugSelectors, actions as supportDebugActions } from 'web/redux/route/billing-support-debug';
import { getIntakeConfig } from 'web/services/intake-features';
import { 
  getAuthToken,
  getUserId,
  getAccountId,
  getProductTier,
  hasBillingAccess,
  getIsFirmOwner,
} from 'web/services/user-session-management';
import { capitalizeAllWords } from '@sb-itops/nodash';
import { fetchPostP } from '@sb-itops/redux/fetch';
import {
  manage as manageProvider,
} from '@sb-billing/business-logic/payment-provider/services/client-api';
import { getLogger } from '@sb-itops/fe-logger';
import { sendMetric } from 'web/services/metrics';
import { isNewTheme } from 'web/services/theme';
import { getFirmDetails } from '@sb-firm-management/redux/firm-management';

const log = getLogger('sbTopLevelNavController');

angular.module('sb.billing.webapp').controller('sbTopLevelNavController', function ($scope, $state, hotkeys, focusService, sbUserTimerService,
  sbFirmManagementMbService, sbUserSettingsService, sbUuidService, sbLinkService, $window) {
  'use strict';
  var that = this;
  that.settingsDropdownOpen = false;
  that.activeTimer = sbUserTimerService.getActiveTimer();
  that.hasBillingAccess = hasBillingAccess;
  that.canManageSubscription = () => getIsFirmOwner() && ['SMK001', 'SMK004'].includes(getProductTier());
  that.isGrowProsper = () => isNewTheme() && ['SMK002', 'SMK003'].includes(getProductTier());
  that.isEmbeddedApp = () => window && !!window.todesktop;
  that.numberOfUserTimers = (sbUserTimerService.getAllTimers() || []).length;
  // that.sbData = {};
  that.dataChangeFunction = dataChangeFunction;
  that.goToAdvancedSearch = goToAdvancedSearch;
  that.clearSearch = clearSearch;
  that.onClickLink = sbLinkService.onClickLink;
  that.showHelp = showHelp;
  that.showDebug = supportDebugSelectors.getShowDebug(store.getState());
  that.toggleShowDebug = toggleShowDebug;
  that.showStatusPill = showStatusPill;
  that.showSeeAllResults = showSeeAllResults;
  that.getFirmTitle = getFirmTitle;
  that.isNewTheme = isNewTheme();

  that.contactsChangeFunction = contactsChangeFunction;
  that.mattersChangeFunction = mattersChangeFunction;
  that.leadsChangeFunction = leadsChangeFunction;
  that.invoiceChangeFunction = invoiceChangeFunction;

  that.getContactStatusLabelById = getContactStatusLabelById;

  const staff = sbFirmManagementMbService.getLoggedInStaffMember();
  that.loggedInUser = staff ? staff.name : '';

  that.showIntakeFileMenuItem = () => getIntakeConfig().showIntakeFileMenuItem;

  // FeeWise specific
  that.showFeeWiseMenuItem = () => getActiveProvider() === providers.FEE_WISE;
  that.feeWiseMenuItemLabel = capitalizeAllWords(providerNames.FEE_WISE);
  that.onFeeWiseMenuItemClick = openFeeWiseDashboard;
  that.feeWiseMenuItemLoading = false;

  // This exist only so I can check 2 features at the same time in the template
  that.hasTwoFactorPreferences = () => featureActive("BB-13182");

  $scope.$on('smokeball-data-update-sbUserTimerService', function () {
    that.activeTimer = sbUserTimerService.getActiveTimer();
    that.numberOfUserTimers = (sbUserTimerService.getAllTimers() || []).length;
  });

  hotkeys.bindTo($scope)
    .add({
      combo: ['ctrl+alt+s'],
      description: 'Support Tab',
      callback: function() {
        $state.go('home.billing.support.services');
      }
    })
    .add({
      combo: ['ctrl+alt+1'],
      description: 'Support - Accounts',
      callback: function() {
        $state.go('home.billing.support.account');
      }
    })
    .add({
      combo: ['ctrl+alt+u'],
      description: 'Copy User Id',
      callback: function() {
        const userId = getUserId();
        navigator.clipboard.writeText(userId).then(() => {
          messageDisplay.success(`User id copied to clipboard.`);
        }, (error) => {
          messageDisplay.error("Could not copy User Id to clipboard, error:", error);
        })
      }
    })
    .add({
      combo: ['ctrl+alt+a'],
      description: 'Copy Account Id',
      callback: function() {
        const accountId = getAccountId();
        navigator.clipboard.writeText(accountId).then(() => {
          messageDisplay.success(`Account id copied to clipboard.`);
        }, (error) => {
          messageDisplay.error("Could not copy Account Id to clipboard, error:", error);
        })
      }
    })
    .add({
      combo: ['ctrl+alt+t'],
      description: 'Copy Auth Token',
      callback: function() {
        const authToken = getAuthToken();
        navigator.clipboard.writeText(authToken).then(() => {
          messageDisplay.success(`Auth token copied to clipboard.`);
        }, (error) => {
          messageDisplay.error("Could not copy auth token to clipboard, error:", error);
        })
      }
    })
    .add({
      combo: ['ctrl+alt+2'],
      description: 'Support - Feature Switches',
      callback: function() {
        $state.go('home.billing.support.switches');
      }
    })
    .add({
      combo: ['ctrl+alt+3'],
      description: 'Support - Caches',
      callback: function() {
        $state.go('home.billing.support.services');
      }
    })
    .add({
      combo: ['ctrl+alt+4'],
      description: 'Support - Debug',
      callback: function() {
        $state.go('home.billing.support.debug');
      }
    })
    .add({
      combo: ['ctrl+alt+b', 'ctrl+alt+d'],
      description: 'Toggle Debug',
      callback: function() {
        toggleShowDebug();
      }
    })
    .add({
      combo: ['ctrl+alt+5'],
      description: 'Support - Cancel Bank Rec',
      callback: function() {
        $state.go('home.billing.support.bank-recons');
      }
    })
    .add({
      combo: ['ctrl+alt+6'],
      description: 'Support - Cancel Trust Statement',
      callback: function() {
        $state.go('home.billing.support.trust-statement');
      }
    })
    .add({
      combo: ['ctrl+alt+7'],
      description: 'Support - Storage',
      callback: function() {
        $state.go('home.billing.support.storage');
      }
    })
    .add({
      combo: ['ctrl+alt+8'],
      description: 'Support - Storage Load Test',
      callback: function() {
        $state.go('home.billing.support.storage-load-test');
      }
    })
    .add({
      combo: ['ctrl+alt+9'],
      description: 'Support - Reset Modals',
      callback: function() {
        $state.go('home.billing.support.reset-modals');
      }
    })
    .add({
      combo: ['ctrl+alt+0'],
      description: 'Support - Endpoint Errors',
      callback: function() {
        $state.go('home.billing.support.rest-errors');
      }
    })
    .add({
      combo: ['ctrl+alt+-'],
      description: 'Transactions',
      callback: function() {
        $state.go('home.billing.support.accounting-transactions');
      }
    });

  function setTab() {
    that.navHighlights = {};
    if ($state.current.data && $state.current.data.navHighlights) {
      _.each($state.current.data.navHighlights, function (tabName) {
        that.navHighlights[tabName] = true;
      });
    }
  }

  $scope.$on('$stateChangeSuccess', () => {
    setTab();
    clearSearch();
  });

  function clearSearch() {
    that.searchData = {
      searchFor: '',
      searchInvoiceOnly: false,
      nbResults: 0,
      showDropdown: false,
      results: {}
    };

    // Commented out to enable QuickData functionality
    // Due to the auto focus on a textbox, this suppresses all keyboard shortcuts. For QuickData we need this.
    // In general this auto focus isn't that useful because most of us never notice or use it. But it's not
    // known whether our end users use it.
    // if (skipRefocus !== true) {
    //   focusService.focusOn('search');
    // }
  }

  that.clearSearch();

  function updateSizeAndDisplay(unprocessedResults) {
    const MAX_NB_SEARCH_RESULTS_TO_DISPLAY = 10;
    const unprocessedResultKeys = Object.keys(unprocessedResults);

    that.searchData.nbResults = 0;
    that.searchData.results = _.transform(unprocessedResults, (emptyArrays, n, key) => {
      emptyArrays[key] = [];
    });

    let nbUnprocessedResults = _.reduce(unprocessedResultKeys, (count, key) => {
      return count + (unprocessedResults[key] ? unprocessedResults[key].length : 0);
    }, 0);

    if (nbUnprocessedResults === 0) {
      that.searchData.results = {};
      that.searchData.nbResults = 0;
      that.searchData.showDropdown = false;
      return;
    }

    let iterationCount = 0;

    while (that.searchData.nbResults < MAX_NB_SEARCH_RESULTS_TO_DISPLAY && nbUnprocessedResults > 0) {
      const keyIndex = iterationCount % unprocessedResultKeys.length;
      const key = unprocessedResultKeys[keyIndex];

      if (unprocessedResults[key] && unprocessedResults[key].length) {
        that.searchData.results[key].push(unprocessedResults[key].pop());
        ++that.searchData.nbResults;
        --nbUnprocessedResults;
      }

      ++iterationCount;
    }

    that.searchData.showDropdown = that.searchData.nbResults !== 0;
  }

  that.goToContact = function (id) {
    if ($state.current.name === 'home.billing.contacts') {
      $scope.$broadcast('goToContact', id);
    } else {
      $state.go('home.billing.contacts', {sel: id, cr: 'all'});
    }
  };

  // needed because we can't reset a state when searchOnReload is false
  that.gotoOrReset = function (to) {
    if ($state.current.name !== to) {
      // not at state so let's go
      $state.go(to);
    } else {
      $scope.$broadcast('resetFilters');
      store.dispatch({ type: 'RESET_FILTERS' });
    }
  };

  $scope.focus = function (index) {
    if ($scope.tabs && $scope.tabs[index]) {
      $state.go($scope.tabs[index].state, $scope.tabs[index].stateParams);
    }
  };

  that.hideTimerPopup = function () {
    that.showTimerPopup = false;
  };
  function getFirmTitle() {
    const firmDetails = getFirmDetails();
    if (firmDetails && firmDetails.firmName) {
      return firmDetails.firmName;
    }
    return '';
  }

  function dataChangeFunction(key, doc) {
    if (key === 'searchResults') {
      updateSizeAndDisplay(doc);
      sendMetric('NavQuicksearch');
    }
  }

  function contactsChangeFunction (key, doc) {
    that.contacts = doc;
  }

  function mattersChangeFunction (key, doc) {
    that.matters = doc;
  }

  function leadsChangeFunction (key, doc) {
    that.leads = doc;
  }

  function invoiceChangeFunction (key, doc) {
    that.invoices = doc;
  }

  function showHelp() {
    if (featureActive('BB-12890')) {
      window.Brainfish.HelpWidget.open('brainfish-trigger-button');
      return;
    }

    const tier = getProductTier();
    const helpLink = (featureActive('BB-8892') && featureData('BB-8892')[tier || 'SMK001']) || getSupportHubUrl();
    $window.open(helpLink, '_blank');
  }

  function goToAdvancedSearch($event) {
    if ($event && $event.which !== 13 /*Enter key*/) {
      return;
    }

    /*const searchTerm = that.searchData.searchFor;
    that.clearSearch(true);*/
    $state.go('home.billing.advanced-search.matters', {searchId: sbUuidService.get(), searchTerm: that.searchData.searchFor});
  }
  
  function toggleShowDebug() {
    store.dispatch(supportDebugActions.toggleShowDebug());
    that.showDebug = supportDebugSelectors.getShowDebug(store.getState());
    that.showDebug ? messageDisplay.success('Debug toggled ON') : messageDisplay.info('Debug toggled OFF');
  }

  function showStatusPill() {
    return featureActive('BB-7911') && featureActive('BB-7912') && featureActive('BB-8525');
  }

  function showSeeAllResults() {
    const results = that.searchData.results;
    if (results && typeof results === 'object') {
      const hasResult = Object.values(results).some(result => Array.isArray(result) && result.length > 0)
      return hasResult;
    }
    return false;
  }

  async function openFeeWiseDashboard($event) {
    $event.stopPropagation();
    try {
      that.feeWiseMenuItemLoading = true;
      const response = await manageProvider({
        fetchPostP,
        providerType: providers.FEE_WISE,
        providerSpecificFields: { actionType: 'GET_MAGIC_LINK' },
      });
      const feeWiseDashboardUrl = response.url;
      window.open(feeWiseDashboardUrl, '_blank', 'noopener');
    } catch (err) {
      log.error(`Failed to get management URL for ${providerNames.FEE_WISE} account`, err);
      if (err.payload && err.payload.status === 404) {
        messageDisplay.error(`User account for ${providerNames.FEE_WISE} not found. Please contact support.`);
      } else {
        messageDisplay.error(`Failed to get management URL for ${providerNames.FEE_WISE} account`);
      }
    } finally {
      $scope.$applyAsync(() => {
        that.feeWiseMenuItemLoading = false;
        that.settingsDropdownOpen = false;
      });
    }
  }
});
