import {
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/transaction-receipts';

angular.module('@sb-billing/services').service('sbTransactionReceiptService', function (sbGenericEndpointService, sbGenericCacheService, sbEndpointType) {
  'use strict';

  const transactionReceiptEndpoint = 'billing/transaction-receipts';

  sbGenericCacheService.setupCache({
    name: 'sbTransactionReceiptService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: transactionReceiptEndpoint },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.ReceiptUpdated'
    },
    updateRedux,
    clearRedux,
  });
});
