import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query InvoiceStatementData($invoiceStatementId: ID!) {
    invoiceStatement(id: $invoiceStatementId) {
      id
      number
      status
      issuedDate
      merchantPaymentReference
      lastUpdated
      debtor {
        id
        displayName
        displayNameFull
        displayNameFirstLast
      }
      invoices {
        id
        invoiceNumber
        totals {
          interest
          total
          totalExcInterest
          tax
          waived
          paidByCredit
          paid
          unpaid
        }
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceStatementUpdated', 'InvoiceStatementPDFSyncNotification'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const InvoiceStatementData = {
  query,
  notificationIds,
};
