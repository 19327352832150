import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DatePicker, FormLabel, StatelessSelect } from '@sb-itops/react';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import Styles from './MarkAsSentModal.module.scss';

export const MarkAsSentModalBody = ({
  // form
  externalSentViaOptions,
  formData,
  formErrors,
  isSendViaNotMatchMatterPreference,

  // callbacks
  onFieldValueUpdated,
}) => {
  const { dateSent, sentVia, internalNote } = formData;
  const isErrorField = (field) => !!field?.isInvalid;

  return (
    <div className={Styles.markAsSentForm}>
      <fieldset>
        <div className="row">
          <div className="col-sm-6 form-group">
            <FormLabel label="Date Sent" />
            <DatePicker
              onSelect={(newDate) => onFieldValueUpdated({ dateSent: newDate ? dateToInteger(newDate) : undefined })}
              format="DD/MM/YYYY"
              value={dateSent && integerToDate(dateSent)}
              hasError={isErrorField(formErrors?.dateSent)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 form-group">
            <FormLabel label="Sent Via" />
            <StatelessSelect
              options={externalSentViaOptions}
              className={sentVia?.isInvalid && sentVia?.isDirty ? Styles.hasError : ''}
              selectedOption={sentVia}
              onChange={(option) => {
                onFieldValueUpdated({ sentVia: option?.value });
              }}
              isClearable={false}
              placeholder="Select..."
            />
            {isSendViaNotMatchMatterPreference && (
              <div className={Styles.sentViaWarning}>
                <i className="icon-information" color="lightgrey" />
                <span className={Styles.sentViaWarningText}>
                  Send method selected is different to the matter preferences
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-sm-12">
            <FormLabel label="Internal Notes" optional />
            <textarea
              className={classnames('form-control', !!isErrorField(formErrors?.internalNote) && Styles.hasError)}
              rows="5"
              value={internalNote || ''}
              onChange={(e) => {
                onFieldValueUpdated({ internalNote: e.target.value });
              }}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

MarkAsSentModalBody.propTypes = {
  externalSentViaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  formData: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  isSendViaNotMatchMatterPreference: PropTypes.bool.isRequired,
  onFieldValueUpdated: PropTypes.func,
};
MarkAsSentModalBody.defaultProps = {
  onFieldValueUpdated: () => {},
};

MarkAsSentModalBody.displayName = 'MarkAsSentModalBody';
