import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CheckboxLabelInline, forms2PropTypes, FormLabel, StatelessSelect } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { BasisPointInput } from '@sb-billing/react/basis-point-input';
import { splitBillingMethods, splitBillingMethodLabels } from '@sb-billing/business-logic/split-billing';

import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { ContactMultiSelect } from '../../contact-multi-select';

import Styles from './MatterContacts.module.scss';

// setting this to an unlikely high limit as clients and other sides has no limits technically
const NO_MAXIMUM = 1000000;
const SPLIT_BILLING_CONFIRMATION_MODAL_ID = 'SPLIT_BILLING_CONFIRMATION_MODAL_ID';

const MatterContacts = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields values
  clientCustomerIds,
  debtorIds,
  splitBillingEnabled,
  splitBillingMethod,
  splitBillingDebtors,
  splitBillingRemainderDebtorId,
  otherSideRole,
  otherSideCustomerIds,
  // form fields
  clientCustomerIdsIsRequired,
  splitBillingDebtorRatiosMustAddUpto100,
  splitBillingRemainderDebtorIdIsRequired,
  // other
  clientRoleLabel,
  otherSideRoleLabel,
  isRetainerOnInvoiceEnabled,
  // callbacks
  onClientsChanged,
  onDebtorsChanged,
  onSplitBillingEnabledChanged,
  onSplitBillingMethodChanged,
  onSplitBillingDebtorRatioChanged,
  onSplitBillingRemainderDebtorChanged,
  onOtherSidesChanged,
  onClickLink,
  showSplitBillingConfirmationModal,
}) => {
  if (!formInitialised) {
    return null;
  }

  const nonEmptyDebtors = debtorIds.filter((debtorId) => debtorId);
  const debtors = (nonEmptyDebtors.length > 0 ? nonEmptyDebtors : clientCustomerIds).filter((clientId) => clientId);

  const { contactOptions, contactOptionsLookup } = getContactTypeAheadSummaries().reduce(
    (acc, { display, id, typeahead, isDeleted }) => {
      const contactOption = {
        value: id,
        label: display,
        searchText: typeahead,
      };

      if (!isDeleted) {
        acc.contactOptions.push(contactOption);
      }

      acc.contactOptionsLookup[id] = contactOption;
      return acc;
    },
    { contactOptions: [], contactOptionsLookup: {} },
  );

  // so that debtors are always listed in the same order as the debtors list
  const debtorOptions = debtors.map((debtorId) => contactOptionsLookup[debtorId]);
  const debtorRatiosLookup = splitBillingDebtors.reduce((acc, { debtorId, debtorRatio }) => {
    acc[debtorId] = debtorRatio;
    return acc;
  }, {});

  const onSplitBillingEnabledCheckboxChange = (newValue) => {
    if (newValue && isRetainerOnInvoiceEnabled) {
      showSplitBillingConfirmationModal(true);
    } else {
      onSplitBillingEnabledChanged(newValue);
    }
  };

  // This variable is for hiding inline action link (VIEW | EDIT) space
  // so field width consistent with other sections when first load Add Matter page
  const showInlineActions = clientCustomerIds.length > 0 || debtorIds.length > 0 || otherSideCustomerIds.length > 0;

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label={`Client ${clientRoleLabel && clientRoleLabel !== 'Client' ? `(${clientRoleLabel})` : ``}`}
            field={clientCustomerIdsIsRequired}
            submitFailed={submitFailed}
          />
          <div>
            <ContactMultiSelect
              selectedContactIds={clientCustomerIds}
              contactOptions={contactOptions}
              onContactsChanged={onClientsChanged}
              max={NO_MAXIMUM} // there's technically no maximum of clients allowed, so just setting this to an unlikely high number
              disabled={formDisabled}
              isRequired={submitFailed && clientCustomerIdsIsRequired && clientCustomerIdsIsRequired.isInvalid}
              addContactFormType="modal"
              onClickLink={onClickLink}
              showInlineActions={showInlineActions}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label="Debtors"
            explainerText="If left blank, the first client will be used as the debtor on this matter"
            submitFailed={submitFailed}
            optional
          />
          <div>
            <ContactMultiSelect
              selectedContactIds={debtorIds}
              contactOptions={contactOptions}
              onContactsChanged={onDebtorsChanged}
              max={3}
              isRequired={false}
              disabled={formDisabled}
              addContactFormType="modal"
              onClickLink={onClickLink}
              showInlineActions={showInlineActions}
            />
          </div>
        </div>
      </div>

      {featureActive('BB-9790') && (
        <>
          <div className="row">
            <div className="col-lg-12 form-group">
              <div className="">
                <CheckboxLabelInline
                  checked={debtors.length > 1 ? splitBillingEnabled : false}
                  onChange={onSplitBillingEnabledCheckboxChange}
                  disabled={debtors.length < 2}
                  title={
                    debtors.length < 2
                      ? 'Split billing is only available when there is more than one debtor'
                      : undefined
                  }
                >
                  Invoice debtors individually (split billing)
                </CheckboxLabelInline>
              </div>

              <ConfirmationModalDialog
                onConfirm={() => {
                  onSplitBillingEnabledChanged(true);
                  showSplitBillingConfirmationModal(false);
                }}
                modalId={SPLIT_BILLING_CONFIRMATION_MODAL_ID}
                title="Enable Split Billing"
                body="Enabling split billing will disable the adding of evergreen retainer requests to your invoices. Are you sure you want to continue?"
                primaryButtonText="Enable"
              />
            </div>
          </div>

          {debtors.length > 1 && splitBillingEnabled && (
            <div className={Styles.splitBillingSettings}>
              <div className="row form-group">
                <div className="col-lg-12">
                  <div className={Styles.splitBillingMethod}>
                    <div className={Styles.paddingLeft} />
                    <div className={Styles.radioButtons}>
                      <label className={Styles.splitEvenly}>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <input
                              type="radio"
                              className={Styles.radio}
                              checked={splitBillingMethod === splitBillingMethods.SPLIT_EVENLY}
                              value={false}
                              onChange={() => onSplitBillingMethodChanged(splitBillingMethods.SPLIT_EVENLY)}
                            />
                          </span>
                          <span className={classnames('form-control', Styles.radioLabel)}>
                            {splitBillingMethodLabels.SPLIT_EVENLY}
                          </span>
                        </div>
                      </label>
                      <label className={Styles.splitByPercentage}>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <input
                              type="radio"
                              className={Styles.radio}
                              checked={splitBillingMethod === splitBillingMethods.USE_RATIO}
                              value={false}
                              onChange={() => onSplitBillingMethodChanged(splitBillingMethods.USE_RATIO)}
                            />
                          </span>
                          <span className={classnames('form-control', Styles.radioLabel)}>
                            {splitBillingMethodLabels.USE_RATIO}
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className={Styles.paddingRight} />
                  </div>
                </div>
              </div>
              {splitBillingMethod === splitBillingMethods.USE_RATIO &&
                debtors.map((debtorId) => (
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <div className={Styles.splitBillingDebtor}>
                        <div className={Styles.paddingLeft} />
                        <div className={Styles.debtorName}>{contactOptionsLookup[debtorId]?.label}</div>
                        <BasisPointInput
                          className={Styles.debtorPercentage}
                          basisPoints={debtorRatiosLookup[debtorId]}
                          isPerAnnum={false}
                          hasError={
                            submitFailed &&
                            splitBillingDebtorRatiosMustAddUpto100 &&
                            splitBillingDebtorRatiosMustAddUpto100.isInvalid
                          }
                          onChange={(newBasisPoints) => onSplitBillingDebtorRatioChanged(debtorId, newBasisPoints)}
                        />
                        <div className={Styles.paddingRight} />
                      </div>
                    </div>
                  </div>
                ))}
              <div className="row">
                <div className="col-lg-12 form-group">
                  <div className={Styles.splitBillingRemainderDebtor}>
                    <div className={Styles.paddingLeft} />
                    <div className={Styles.formField}>
                      <FormLabel
                        label="Assign Remainer To"
                        explainerText="The selected debtor will be billed the remainder when the amount owing can not be split evenly"
                        submitFailed={submitFailed}
                        optional
                      />
                      <div>
                        <StatelessSelect
                          className={Styles.remainderDebtorSelect}
                          options={debtorOptions}
                          selectedOption={splitBillingRemainderDebtorId}
                          hasError={
                            submitFailed &&
                            splitBillingRemainderDebtorIdIsRequired &&
                            splitBillingRemainderDebtorIdIsRequired.isInvalid
                          }
                          onChange={(newRemainderDebtor) =>
                            onSplitBillingRemainderDebtorChanged(newRemainderDebtor.value)
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.paddingRight} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {otherSideRole && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel label={otherSideRoleLabel || 'Other Side'} submitFailed={submitFailed} optional />
            <div>
              <ContactMultiSelect
                selectedContactIds={otherSideCustomerIds}
                contactOptions={contactOptions}
                onContactsChanged={onOtherSidesChanged}
                max={NO_MAXIMUM} // there's technically no maximum of other sides allowed, so just setting this to an unlikely high number
                disabled={formDisabled}
                isRequired={false}
                addContactFormType="modal"
                onClickLink={onClickLink}
                showInlineActions={showInlineActions}
              />
            </div>
          </div>
        </div>
      )}
    </fieldset>
  );
};

MatterContacts.displayName = 'MatterContacts';

const { Forms2Field } = forms2PropTypes;

MatterContacts.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  clientCustomerIds: PropTypes.arrayOf(PropTypes.string),
  debtorIds: PropTypes.arrayOf(PropTypes.string),
  splitBillingEnabled: PropTypes.bool,
  splitBillingMethod: PropTypes.oneOf(Object.values(splitBillingMethods)),
  splitBillingDebtors: PropTypes.arrayOf(
    PropTypes.shape({
      debtorId: PropTypes.string,
      debtorRatio: PropTypes.number,
    }),
  ),
  splitBillingRemainderDebtorId: PropTypes.string,
  otherSideRole: PropTypes.string,
  otherSideCustomerIds: PropTypes.arrayOf(PropTypes.string),
  // form fields
  clientCustomerIdsIsRequired: Forms2Field,
  splitBillingDebtorRatiosMustAddUpto100: Forms2Field,
  splitBillingRemainderDebtorIdIsRequired: Forms2Field,
  // other
  clientRoleLabel: PropTypes.string,
  otherSideRoleLabel: PropTypes.string,
  isRetainerOnInvoiceEnabled: PropTypes.bool,
  // callbacks
  onClientsChanged: PropTypes.func.isRequired,
  onDebtorsChanged: PropTypes.func.isRequired,
  onSplitBillingEnabledChanged: PropTypes.func.isRequired,
  onSplitBillingMethodChanged: PropTypes.func.isRequired,
  onSplitBillingDebtorRatioChanged: PropTypes.func.isRequired,
  onSplitBillingRemainderDebtorChanged: PropTypes.func.isRequired,
  onOtherSidesChanged: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  showSplitBillingConfirmationModal: PropTypes.func.isRequired,
};

MatterContacts.defaultProps = {
  // form state
  formDisabled: false,
  formSubmitting: false,
  submitFailed: false,
  // form fields values
  clientCustomerIds: [],
  debtorIds: [],
  splitBillingEnabled: false,
  splitBillingMethod: splitBillingMethods.SPLIT_EVENLY,
  splitBillingDebtors: [],
  splitBillingRemainderDebtorId: undefined,
  otherSideRole: undefined,
  otherSideCustomerIds: [],
  // form fields
  clientCustomerIdsIsRequired: undefined,
  splitBillingDebtorRatiosMustAddUpto100: undefined,
  splitBillingRemainderDebtorIdIsRequired: undefined,
  // other
  clientRoleLabel: undefined,
  otherSideRoleLabel: undefined,
  isRetainerOnInvoiceEnabled: false,
};

export default MatterContacts;
