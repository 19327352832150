import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { sortByProperty } from '@sb-itops/nodash';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { getById as getReportConfigById, getList as getReportConfig } from '@sb-billing/redux/report-config';
import * as billingReports from 'web/redux/route/home-billing-reports/feature';
import { filterReportConfigs } from './filter-billing-reports';
import { BillingReporting } from './BillingReporting';

const { getReportState, getShowFilters } = billingReports.selectors;
const { setReportState, toggleShowFilters } = billingReports.actions;
const path = billingReports.defaultPath;

// If no report has been selected by the user, either by direct selection or querystring parameter,
// we need to select a default. The default report config is the alphabetically first internal
// report config. If there are no internal reports, we just use the alphabetically first report config.
const getDefaultReportConfigId = (reportConfigs) => {
  const sortedReportConfigs = sortByProperty(reportConfigs, 'name');
  const firstInternalReportConfig = sortedReportConfigs.find((reportConfig) => reportConfig.category === 'internal');

  const defaultReportConfig = firstInternalReportConfig || sortedReportConfigs[0];
  return defaultReportConfig && defaultReportConfig.reportId;
};

const mapStateToProps = (state, { stateOverride, sbReportingService, sbAsyncOperationsService }) => {
  const authToken = authSelectors.getAuthToken(state);
  const showFilters = getShowFilters(state[path]);
  const allReportConfigs = getReportConfig();
  const reportConfigs = filterReportConfigs(allReportConfigs);
  const selectedReportConfigId = stateOverride.reportId || getDefaultReportConfigId(reportConfigs);

  const selectedReportConfig = getReportConfigById(selectedReportConfigId);
  const reportState = getReportState(state[path], { reportId: selectedReportConfig && selectedReportConfig.reportId });

  // BB-14715: This block of code is for when contacts/matter ledger redirects to reports
  // To automatically set filters based on URL params
  if (selectedReportConfigId === 'account-statement') {
    if (reportState && stateOverride.contactId) {
      reportState.filters.contact = { id: stateOverride.contactId };
      reportState.filters.showInvoicesFor = 'contact';
      reportState.filters.errors = {
        matters: false,
        contacts: false,
      };
    }
    if (reportState && stateOverride.matterId) {
      reportState.filters.matter = { id: stateOverride.matterId };
      reportState.filters.showInvoicesFor = 'matter';
      reportState.filters.errors = {
        matters: false,
        contacts: false,
      };
    }
  }

  return {
    showFilters,
    reportConfigs,
    selectedReportConfig,
    reportState,
    authToken,
    // Dodgy hack, these should be removed when reporting logic is moved to business logic.
    sbReportingService,
    sbAsyncOperationsService,
  };
};

const mapDispatchToProps = (dispatch, { onStateOverrideChange }) => ({
  toggleShowFilters: () => dispatch(toggleShowFilters()),
  onSelectedReportConfigChange: ({ id }) => {
    onStateOverrideChange({ reportId: id });
  },

  setReportState,
});

const BillingReportingContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(BillingReporting));

BillingReportingContainer.displayName = 'BillingReportingContainer';

BillingReportingContainer.propTypes = {
  stateOverride: PropTypes.object,
};

BillingReportingContainer.defaultProps = {
  stateOverride: {},
};

export { BillingReportingContainer };
