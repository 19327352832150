import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select } from '@sb-itops/react';

import Styles from './ContactTypeahead.module.scss';

const defaultNoOptionsMessageFn = ({ inputValue }) =>
  inputValue?.length ? 'No options' : 'Start typing to get a list of contacts...';

/**
 * LOD compatible ContactTypeahead
 */
export const ContactTypeahead = ({
  className,
  contactOptions,
  contactOptionsHasMore,
  defaultContactId,
  defaultContactOptions,
  disabled,
  forceValue,
  hasError,
  isAddActive,
  isClearable,
  isLoading,
  maxMenuHeight,
  menuPlacement,
  optionalLabelHeight,
  placeholder,
  showAdd,
  actionList,
  // Callbacks
  noOptionsMessageFn,
  onAdd,
  onContactSelected,
  onFetchContactOptions,
  onFetchMoreContactOptions,
}) => {
  const defaultOption = React.useMemo(
    () => defaultContactOptions.find((option) => option.value === defaultContactId),
    [defaultContactId, defaultContactOptions],
  );

  const fullActionList =
    contactOptionsHasMore === true
      ? [
          ...(actionList || []),
          {
            displayComponent: (
              <span>
                <i className="fa fa-plus" /> &emsp;Show more results
              </span>
            ),
            callback: () => {
              if (isLoading) {
                return;
              }

              onFetchMoreContactOptions();
            },
          },
        ]
      : actionList || [];

  const addStyles = `${Styles.add} ${disabled ? Styles.disabled : ''} ${!disabled && hasError ? Styles.error : ''}`;
  const icon = `${Styles.icon} fa ${isAddActive ? 'fa-minus' : 'fa-plus'} fa-fw`;

  return (
    <div className={Styles.contactTypeaheadContainer}>
      <Select
        actionList={fullActionList}
        className={classnames(showAdd && Styles.showAddButton, className)}
        defaultValue={defaultOption}
        forceValue={forceValue}
        disabled={disabled}
        filterOption={() => true} // Show all values, we use isSearchable to send requests to the back-end and need to show all the options provided
        hasError={hasError}
        isClearable={isClearable}
        isLoading={isLoading}
        isSearchable
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        noOptionsMessage={typeof noOptionsMessageFn === 'function' ? noOptionsMessageFn : defaultNoOptionsMessageFn}
        optionalLabelHeight={optionalLabelHeight}
        options={contactOptions}
        placeholder={placeholder}
        virtualizeList
        // Callbacks
        onInputChange={onFetchContactOptions}
        onLoadMore={onFetchMoreContactOptions}
        onValueChange={onContactSelected}
      />
      {showAdd && (
        <div className={addStyles} onClick={!disabled ? () => onAdd() : () => {}}>
          <i className={icon} />
        </div>
      )}
    </div>
  );
};

ContactTypeahead.displayName = 'ContactTypeahead';

const contactOption = PropTypes.shape({
  data: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

ContactTypeahead.propTypes = {
  actionList: PropTypes.array,
  className: PropTypes.string,
  contactOptions: PropTypes.arrayOf(contactOption),
  contactOptionsHasMore: PropTypes.bool,
  defaultContactId: PropTypes.string,
  defaultContactOptions: PropTypes.arrayOf(contactOption),
  disabled: PropTypes.bool,
  forceValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  hasError: PropTypes.bool,
  isAddActive: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  optionalLabelHeight: PropTypes.number,
  placeholder: PropTypes.string,
  showAdd: PropTypes.bool,
  // Callbacks
  noOptionsMessageFn: PropTypes.func,
  onAdd: PropTypes.func,
  onContactSelected: PropTypes.func.isRequired,
  onFetchContactOptions: PropTypes.func.isRequired,
  onFetchMoreContactOptions: PropTypes.func.isRequired,
};

ContactTypeahead.defaultProps = {
  actionList: [],
  className: undefined,
  contactOptions: [],
  contactOptionsHasMore: false,
  defaultContactId: undefined,
  defaultContactOptions: [],
  disabled: false,
  forceValue: undefined,
  hasError: false,
  isAddActive: undefined,
  isClearable: true,
  isLoading: false,
  maxMenuHeight: undefined,
  menuPlacement: 'auto',
  optionalLabelHeight: 70,
  placeholder: 'Select a contact...',
  showAdd: undefined,
  noOptionsMessageFn: undefined,
  onAdd: () => {},
};
