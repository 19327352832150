import PropTypes from 'prop-types';

import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { getRoleOptions } from '@sb-matter-listing/redux/matter-type-configurations';
import { showRetainerOnInvoiceOptionValues } from '@sb-billing/business-logic/evergreen-retainer';
import { getMatterDebtorIdsForAddressOverride } from '@sb-billing/business-logic/invoice-settings';
import { getDebtorDefaultNameAndAddress } from '@sb-customer-management/redux/contacts';

import MatterContacts from './MatterContacts';

const SPLIT_BILLING_CONFIRMATION_MODAL_ID = 'SPLIT_BILLING_CONFIRMATION_MODAL_ID';

const getUpdatedDebtorAddressesWhenContactOrDebtorChanges = async ({
  clientCustomerIds,
  debtorIds,
  currentDebtorAddresses,
}) => {
  const newDebtorIdsForAddressOverride = getMatterDebtorIdsForAddressOverride({ debtorIds, clientCustomerIds });
  // If the debtor detail is already present just return it, otherwise fetch the new debtor
  const updatedDebtorAddresses = Promise.all(
    newDebtorIdsForAddressOverride.map(
      async (id) =>
        currentDebtorAddresses.find((debtor) => debtor.debtorId === id) ||
        getDebtorDefaultNameAndAddress({
          id,
        }),
    ),
  );

  return updatedDebtorAddresses;
};

const hooks = ({
  selectedInvoiceTemplate,
  formInitialised,
  formFields,
  formFieldValues,
  formDisabled,
  formSubmitting,
  submitFailed,
  onFieldValueSet,
  onFieldValuesUpdated,
}) => ({
  useFormFields: () => {
    const {
      originalMatterTypeId,
      clientRole,
      clientCustomerIds,
      debtorIds,
      otherSideRole,
      otherSideCustomerIds,
      splitBillingEnabled,
      splitBillingMethod,
      splitBillingDebtors,
      splitBillingRemainderDebtorId,
      showRetainerOption,
    } = formFieldValues;

    const {
      clientCustomerIdsIsRequired,
      splitBillingDebtorRatiosMustAddUpto100,
      splitBillingRemainderDebtorId: splitBillingRemainderDebtorIdIsRequired,
    } = formFields;

    const rolesMap = originalMatterTypeId ? getRoleOptions(originalMatterTypeId, true) : {};
    const clientRoleLabel = rolesMap[clientRole];
    const otherSideRoleLabel = rolesMap[otherSideRole];

    const isRetainerOnInvoiceEnabled =
      showRetainerOption === showRetainerOnInvoiceOptionValues.ENABLED ||
      (showRetainerOption === showRetainerOnInvoiceOptionValues.USE_TEMPLATE_SETTING &&
        selectedInvoiceTemplate?.settings?.invoiceAdditionalOptions?.showRetainer);

    return {
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields values
      clientCustomerIds,
      debtorIds,
      splitBillingEnabled,
      splitBillingMethod,
      splitBillingDebtors,
      splitBillingRemainderDebtorId,
      otherSideRole,
      otherSideCustomerIds,
      // form fields
      clientCustomerIdsIsRequired,
      splitBillingDebtorRatiosMustAddUpto100,
      splitBillingRemainderDebtorIdIsRequired,
      // other
      clientRoleLabel,
      otherSideRoleLabel,
      isRetainerOnInvoiceEnabled,
    };
  },
  useFormActions: () => {
    const onClientsChanged = async (newSelectedContactIds) => {
      onFieldValueSet({
        field: 'clientCustomerIds',
        value: newSelectedContactIds,
      });
      const { debtorIds, overriddenDebtorAddresses, selectedDebtorId } = formFieldValues;
      const filteredDebtorIds = debtorIds.filter((debtorId) => debtorId);
      // Only update overriddenDebtorAddresses when there is no debtor (we use first client as debtor)
      if (filteredDebtorIds.length === 0) {
        const updatedDebtorAddresses = await getUpdatedDebtorAddressesWhenContactOrDebtorChanges({
          clientCustomerIds: newSelectedContactIds,
          debtorIds,
          currentDebtorAddresses: overriddenDebtorAddresses,
        });
        onFieldValueSet({
          field: 'overriddenDebtorAddresses',
          value: updatedDebtorAddresses,
        });
        // We also need to update selectedDebtorId if the selected debtor is not exist anymore
        const isDebtorIdExists = updatedDebtorAddresses.some((debtor) => debtor.debtorId === selectedDebtorId);
        if (!isDebtorIdExists) {
          onFieldValuesUpdated({
            selectedDebtorId: updatedDebtorAddresses.length > 0 ? updatedDebtorAddresses[0].debtorId : '',
          });
        }
      }
    };

    const onDebtorsChanged = async (newSelectedContactIds) => {
      onFieldValueSet({
        field: 'debtorIds',
        value: newSelectedContactIds,
      });

      // When debtor changes, we update debtorAddress
      const { clientCustomerIds, overriddenDebtorAddresses, selectedDebtorId } = formFieldValues;
      const updatedDebtorAddresses = await getUpdatedDebtorAddressesWhenContactOrDebtorChanges({
        clientCustomerIds,
        debtorIds: newSelectedContactIds,
        currentDebtorAddresses: overriddenDebtorAddresses,
      });
      onFieldValueSet({
        field: 'overriddenDebtorAddresses',
        value: updatedDebtorAddresses,
      });
      // We also need to update selectedDebtorId if the selected debtor is not exist anymore
      const isDebtorIdExists = updatedDebtorAddresses.some((debtor) => debtor.debtorId === selectedDebtorId);
      if (!isDebtorIdExists) {
        onFieldValuesUpdated({
          selectedDebtorId: updatedDebtorAddresses.length > 0 ? updatedDebtorAddresses[0].debtorId : '',
        });
      }
    };

    const onOtherSidesChanged = (newSelectedContactIds) => {
      // onFieldValuesUpdated doesn't work in all scenarios, this is a redux/form2 api
      // bug related to dealing with arrays, I don't have time to chase down now
      onFieldValueSet({
        field: 'otherSideCustomerIds',
        value: newSelectedContactIds,
      });
    };

    const onSplitBillingEnabledChanged = (newValue) => {
      onFieldValueSet({
        field: 'splitBillingEnabled',
        value: newValue,
      });

      // when split billing is enabled, disable retainer on invoice
      if (newValue) {
        onFieldValueSet({
          field: 'showRetainerOption',
          value: showRetainerOnInvoiceOptionValues.DISABLED,
        });
      }
    };

    const onSplitBillingMethodChanged = (newValue) => {
      onFieldValueSet({
        field: 'splitBillingMethod',
        value: newValue,
      });
    };

    const onSplitBillingDebtorRatioChanged = (debtorId, newBasisPoints) => {
      let debtorRatioAlreadySet = false;
      const newSplitBillingDebtors = formFieldValues.splitBillingDebtors.map((debtor) => {
        if (debtor.debtorId === debtorId) {
          debtorRatioAlreadySet = true;
          return {
            ...debtor,
            debtorRatio: newBasisPoints,
          };
        }
        return debtor;
      });

      if (!debtorRatioAlreadySet) {
        newSplitBillingDebtors.push({
          debtorId,
          debtorRatio: newBasisPoints,
        });
      }

      onFieldValueSet({
        field: 'splitBillingDebtors',
        value: newSplitBillingDebtors,
      });
    };

    const onSplitBillingRemainderDebtorChanged = (debtorId) => {
      onFieldValueSet({
        field: 'splitBillingRemainderDebtorId',
        value: debtorId,
      });
    };

    const showSplitBillingConfirmationModal = (value) => {
      if (value) {
        setModalDialogVisible({ modalId: SPLIT_BILLING_CONFIRMATION_MODAL_ID });
      } else {
        setModalDialogHidden({ modalId: SPLIT_BILLING_CONFIRMATION_MODAL_ID });
      }
    };

    return {
      onClientsChanged,
      onDebtorsChanged,
      onSplitBillingEnabledChanged,
      onSplitBillingMethodChanged,
      onSplitBillingDebtorRatioChanged,
      onSplitBillingRemainderDebtorChanged,
      onOtherSidesChanged,
      showSplitBillingConfirmationModal,
    };
  },
});

export const MatterContactsContainer = composeHooks(hooks)(MatterContacts);

MatterContactsContainer.displayName = 'MatterContactsContainer';

MatterContactsContainer.propTypes = {
  matterId: PropTypes.string,
  selectedInvoiceTemplate: PropTypes.object,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onFieldValueSet: PropTypes.func.isRequired, // generic method for set a field
  onClickLink: PropTypes.func.isRequired,
};

MatterContactsContainer.defaultProps = {
  matterId: undefined,
};
