import composeHooks from '@sb-itops/react-hooks-compose';
import { InitialCalendarSyncStatus, MicrosoftIdentity } from 'web/graphql/queries';
import * as messageDisplay from '@sb-itops/message-display';
import { useSubscribedQuery } from 'web/hooks';
import { useEffect, useState } from 'react';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { featureActive } from '@sb-itops/feature';
import { IScheduleTabProps, ScheduleTab } from './ScheduleTab';

const CALENDAR_SCOPE = 'calendar';

// eslint-disable-next-line no-empty-pattern
const hooks = ({}: IScheduleTabProps) => ({
  useSyncData: () => {
    const [isSyncing, setIsSyncing] = useState(false);

    const initialCalendarSyncStatusResults = useSubscribedQuery(InitialCalendarSyncStatus, {});

    useEffect(() => {
      const newIsSyncing = initialCalendarSyncStatusResults?.data?.initialCalendarSyncStatus?.status === 1;
      if (!newIsSyncing && isSyncing) {
        messageDisplay.success('Sync complete.');
      }
      setIsSyncing(newIsSyncing);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCalendarSyncStatusResults?.data?.initialCalendarSyncStatus?.status]);
    return {
      isSyncing,
    };
  },
  usePromoCardConditions: () => {
    const microsoftIdentityResults = useSubscribedQuery(MicrosoftIdentity, {
      notifyOnNetworkStatusChange: true,
    });
    const isAuthed =
      microsoftIdentityResults?.data?.microsoftIdentity?.microsoftId &&
      (microsoftIdentityResults?.data?.microsoftIdentity?.authorizedScopes || []).includes?.(CALENDAR_SCOPE);
    return {
      showPromoCard:
        (!isAuthed &&
          !microsoftIdentityResults.loading &&
          featureActive('NUCWEB-609') &&
          featureActive('NUCWEB-780')) ||
        featureActive('NUCWEB-799-DEBUG'),
    };
  },
});

export const ScheduleTabContainer = withApolloClient(composeHooks(hooks)(ScheduleTab));
