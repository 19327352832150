import * as React from 'react';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { responseTypes } from '@sb-itops/fetch-wrapper/src/constants';
import { getLoggedInUserId } from '@sb-firm-management/redux/firm-management';

const log = getLogger('useCreateEmail');

export function useCreateEmail() {
  const [isCreatingEmail, setIsCreatingEmail] = React.useState(false);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);

  async function createEmail(
    matterId,
    subject = '',
    bodyContentType = 'html',
    bodyContent = '',
    toAddresses = [],
    ccAddresses = [],
    bccAddresses = [],
    attachments = [],
  ) {
    try {
      setIsCreatingEmail(true);
      const userId = getLoggedInUserId();
      const response = await fetchPostP({
        path: `/integration/endpoint-locator/email/:accountId/get-create-draft-email-url`,
        fetchOptions: {
          body: JSON.stringify({
            userId,
            matterId,
            subject,
            bodyContentType,
            bodyContent,
            toAddresses,
            ccAddresses,
            bccAddresses,
            attachments,
          }),
        },
        responseType: responseTypes.text,
        skipCamelCase: true,
      });
      if (!response.body?.url) {
        setIsUnauthorized(true);
        displayErrorToUser(`Email integration is not enabled.`);
      }
      return response.body;
    } catch (error) {
      log.error(`Failed to create email: `, error);
      displayErrorToUser(`Failed to create email for matter ${matterId}. Please try again later.`);
    } finally {
      setIsCreatingEmail(false);
    }
    return undefined;
  }

  return {
    isUnauthorized,
    isCreatingEmail,
    createEmail,
  };
}
