import { gql } from '@apollo/client';

// Can be used to get default contact for contact typeahead

const query = gql`
  query ContactOption($contactId: ID!) {
    contact(id: $contactId) {
      id
      displayName
      displayNameFull
    }
  }
`;

const notificationIds = ['CustomerManagementWebQuery.EntityUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const ContactOption = {
  query,
  notificationIds,
};
