angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  $stateProvider
    .state('settings.archie', {
      template: '<sbb-archie-iframe path="$ctrl.path" style="flex: 1;"></sbb-archie-iframe>',
      url: '/archie',
      controller: function ($stateParams) {
        const ctrl = this;
        ctrl.path = $stateParams.path || '/settings';
      },
      controllerAs: '$ctrl',
      data: {
        navHighlights: ['archie']
      },
      params: {
        path: '',
      },
    });
});
