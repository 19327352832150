import { createSelector } from 'reselect';
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { firmManagement } from '@sb-firm-management/redux';
import { getDisplayName } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getContactStatusLabel } from '@sb-customer-management/business-logic/contacts/services';

import domain from '../domain';
import getByCategory from './get-by-category';

const api = cacheFactory({
  domain,
  name: 'contacts-summary',
  keyPath: 'entityId',
  ngCacheName: 'sbContactsMbService',
  syncType: syncTypes.SINCE,
  immutable: false,
});

export const { getList, getMap, updateCache, clearCache, UPDATE_CACHE } = api;

export const getByIds = (ids) => ids.map((id) => getById(id));

const getCurrentContactsSelector = createSelector(
  (contacts) => contacts,
  (contacts) => contacts.filter((contact) => !contact.removed && contact.type !== 'GroupOfPeople'),
);

const getAllContactsSelector = createSelector(
  (contacts) => contacts,
  (contacts) => contacts.filter((contact) => contact.type !== 'GroupOfPeople'),
);

export const getCurrentContacts = () => getCurrentContactsSelector(getList());

export const getAllContacts = () => getAllContactsSelector(getList());

export const getContactsByCategory = (contactsByRole, category, includeDeleted) => {
  if (includeDeleted) {
    // true if BB-7912 to view and restore deleted contact is switched on
    return getByCategory(getAllContacts(), contactsByRole, category, getMap());
  }
  // defaulting to existing behaviour
  return getByCategory(getCurrentContacts(), contactsByRole, category, getMap());
};

export const getById = (id) => {
  if (id === '23b3857d-5c5b-4871-8fbc-35a7cb670f5a') {
    const firmDetails = firmManagement.getFirmDetails();

    return {
      accountId: firmDetails && firmDetails.accountId,
      displayName: firmDetails && firmDetails.firmName,
      entityId: '23b3857d-5c5b-4871-8fbc-35a7cb670f5a',
    };
  }

  return getMap()[id];
};

// N.B.
// We have both showLastNameFirst and showLastNameFirstFull options.
// I couldn't simply add option 'includeMiddleName' or something similar, because we manually format the name only
// when showLastNameFirst is true. When showLastNameFirst is false, we directly use displayName which
// is already formatted with middle name (if it exists) so we don't actually have any control over it.
// Therefore, I added entirely new option showLastNameFirstFull to keep everything backward compatible
// as we don't want to display middle name in most of the places
export const getContactDisplay = (id, { showLastNameFirst, hideDeletedStatus, showLastNameFirstFull } = {}) => {
  const contact = getById(id);

  if (!contact) {
    return '';
  }

  if (contact.type === 'GroupOfPeople' && contact.personIds && typeof contact.personIds === 'string') {
    // For some reason smokeball allow circular references of group of people.
    // Those cases are filtered out to prevent infinite recursion.
    return contact.personIds
      .split(';')
      .filter((contactId) => contactId !== id)
      .map((contactId) => getContactDisplay(contactId, { showLastNameFirst, hideDeletedStatus, showLastNameFirstFull }))
      .join(' & ');
  }
  return getDisplayName(contact, { showLastNameFirst, hideDeletedStatus, showLastNameFirstFull });
};

export const getContactStatusLabelById = (id) => {
  const contact = getById(id);
  return getContactStatusLabel(contact);
};
