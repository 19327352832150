import { featureActive } from "@sb-itops/feature";

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';

  const BASE_DIRECTORY = 'ng-routes/home/billing/dashboard';

  $stateProvider

  .state('home.billing.dashboard', {
    url: '/dashboard',
    reloadOnSearch: false,
    templateUrl: BASE_DIRECTORY + '/dashboard.html',
    controller: 'SbPartialStateController',
    controllerAs: '$ctrl',
    data: {
      authorized: true,
      navHighlights: ['billing', 'dashboard']
    },
    onEnter: function($timeout, $state) {
      // If entering dashboard and feature switch is not ON, redirect to matters list
      if (!featureActive('NUCWEB-123')) {
        $timeout(() => {
          $state.go('home.billing.matters');
        });
      }
    },
  });

});
