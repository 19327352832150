/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FormLabel, useTranslation, IField } from '@sb-itops/react';
import classnames from 'classnames';
import { TSchemeValuesFor } from '@sb-itops/region-schemes';
import { AddressAutocomplete } from '../address-autocomplete';
import Styles from './AddressForm.module.scss';

type AddressFormGbProps = {
  addressScheme: TSchemeValuesFor<'addressScheme'>;
  formDisabled?: boolean;
  formInitialised?: boolean;
  buildingLevel?: IField<string>;
  unitNumber?: IField<string>;
  streetNumber?: IField<string>;
  streetName?: IField<string>;
  city?: IField<string>;
  state?: IField<string>;
  zipCode?: IField<string>;
  county?: IField<string>;
  locality?: IField<string>;
  onFieldValueUpdated: (update: Record<string, string | number | undefined>) => void;
  onAddressAutocompleteSelected: (addressInfo: Record<string, string>) => void;
  submitFailed?: boolean;
  optional?: boolean;
  optionalExceptState?: boolean;
  useManualEntrySwitch?: boolean;
  validateForm: () => void;
  forceShowState?: boolean;
};

export const AddressFormGb = ({
  formInitialised,
  buildingLevel,
  unitNumber,
  streetNumber,
  streetName,
  city,
  zipCode,
  onFieldValueUpdated,
  formDisabled,
  submitFailed,
  validateForm,
  optional,
  optionalExceptState,
  onAddressAutocompleteSelected,
  county,
  locality,
  forceShowState,
  useManualEntrySwitch,
}: AddressFormGbProps) => {
  const { t } = useTranslation();
  const [manualEntry, setManualEntry] = useState(false);

  if (!formInitialised) {
    return null;
  }

  const isErrorClassnames = (field, style) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, style) : '';

  const formIsOptional = optional || optionalExceptState;

  return (
    <div className={Styles.addressForm}>
      <fieldset disabled={formDisabled}>
        {(!useManualEntrySwitch || (!manualEntry && !streetName?.value)) && (
          <div className="row">
            <div className="col-xs-12">
              <AddressAutocomplete
                label={
                  useManualEntrySwitch && !streetName?.value && !optional && !optionalExceptState
                    ? 'Address Search *'
                    : 'Address Search'
                }
                onSelect={onAddressAutocompleteSelected}
              />
              {useManualEntrySwitch && (
                <a className={Styles.link} onClick={() => setManualEntry(true)}>
                  Enter Address Manually
                </a>
              )}
            </div>
          </div>
        )}
        {(!useManualEntrySwitch || manualEntry || streetName?.value) && (
          <>
            <div className="row">
              <div className="col-xs-6">
                <label>Flat / Unit</label>
                <input
                  name="Unit Number"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(unitNumber, Styles.formControl))}
                  value={unitNumber?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ unitNumber: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-6">
                <label>Property Name</label>
                <input
                  name="buildingLevel"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(buildingLevel, Styles.formControl))}
                  value={buildingLevel?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ buildingLevel: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-2">
                <FormLabel
                  label="Street No."
                  field={streetNumber}
                  submitFailed={submitFailed}
                  optional={formIsOptional}
                  uppercase={false}
                />
                <input
                  name="streetNumber"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(streetNumber, Styles.formControl))}
                  value={streetNumber?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ streetNumber: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-10">
                <FormLabel
                  label="Street Name"
                  field={streetName}
                  submitFailed={submitFailed}
                  optional={formIsOptional}
                  uppercase={false}
                />
                <input
                  name="streetName"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(streetName, Styles.formControl))}
                  value={streetName?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ streetName: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <FormLabel
                  label={t('capitalizeAllWords', { val: 'locality' })}
                  field={locality}
                  optional={formIsOptional}
                  submitFailed={submitFailed}
                  uppercase={false}
                />
                <input
                  name="locality"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(locality, Styles.formControl))}
                  value={locality?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ locality: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className="col-xs-6">
                <FormLabel
                  label={t('capitalizeAllWords', { val: 'suburb' })}
                  field={city}
                  optional={formIsOptional}
                  submitFailed={submitFailed}
                  uppercase={false}
                />
                <input
                  name="city"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(city, Styles.formControl))}
                  value={city?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ city: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
            </div>

            <div className="row">
              <div className={forceShowState ? 'col-xs-4' : 'col-xs-6'}>
                <FormLabel
                  label="County"
                  field={county}
                  optional={formIsOptional}
                  submitFailed={submitFailed}
                  uppercase={false}
                />
                <input
                  name="county"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(county, Styles.formControl))}
                  value={county?.value || ''}
                  onChange={(e) => {
                    onFieldValueUpdated({ county: e.target.value });
                  }}
                  onBlur={validateForm}
                />
              </div>
              <div className={forceShowState ? 'col-xs-4' : 'col-xs-6'}>
                <FormLabel
                  label={t('capitalizeAllWords', { val: 'postcode' })}
                  field={zipCode}
                  submitFailed={submitFailed}
                  optional={formIsOptional}
                  uppercase={false}
                />
                <input
                  name="zipCode"
                  type="text"
                  className={classnames('form-control', isErrorClassnames(zipCode, Styles.formControl))}
                  value={zipCode?.value || ''}
                  onBlur={validateForm}
                  onChange={(e) => {
                    onFieldValueUpdated({ zipCode: e.target.value });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};
