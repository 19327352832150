"use strict";

angular
  .module("sb.billing.webapp")
  .controller("sbViewMatterArchieController", function ($stateParams, sbLinkService) {
    const ctrl = this;
    ctrl.matterId = $stateParams.matterId;
    ctrl.path = $stateParams.path;
    ctrl.onClickLink = sbLinkService.onClickLink;
  });
