import {
  BANK_BALANCE_TYPE,
  getBalanceType,
} from '@sb-billing/redux/bank-account-settings';
import { store } from '@sb-itops/redux';
import { saveRecentInvoice } from '@sb-billing/redux/recent-invoices';
import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible, isModalVisible } from '@sb-itops/redux/modal-dialog';
import {
  getOperatingAccount,
} from '@sb-billing/redux/bank-account';
import { hasFacet } from '@sb-itops/region-facets';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { isNewTheme } from 'web/services/theme';
import { hasBillingAccess } from 'web/services/user-session-management';
import { resourceIds } from "@sb-itops/business-logic/authorisation";

angular
  .module('sb.billing.webapp')
  .controller('SbPartialStateController', function($rootScope, $scope, $state, $stateParams, $location, sbLoggerService, sbUnsavedChangesService, sbMessageDisplayService, sbEnvironmentConfigService, sbLinkService, sbBankAccountService, sbTabService, sbXeroService, sbLocalisationService, sbOverrideRedirectService, sbExpenseService) {
    'use strict';
    const ctrl = this;

    ctrl.disableLODInvoicePaymentModal = sbExpenseService.cacheHasEntities() && !featureActive('BB-14929') && !featureActive('BB-14091');

    // window.SbPartialStateController = ctrl;

    const log = sbLoggerService.getLogger('SbPartialStateController');
    let persistanceKey;
    let findUnsavedChanges;

    // log.setLogLevel('info');
    ctrl.matterContactBalanceEnabled = () => getBalanceType() === BANK_BALANCE_TYPE.matterContact;
    ctrl.sbParams = _.cloneDeep($stateParams);
    ctrl.sbParams.sbSubState = getSubSState($state.current.name);
    ctrl.sbParamsAll = _.cloneDeep($state.params); // pass all params, including children https://stackoverflow.com/questions/23081397/ui-router-stateparams-vs-state-params
    ctrl.sbData = { stateName: $state.current.name };
    ctrl.sbQuery = _.cloneDeep($location.search());
    if ($state.current.data.subscribeToReduxStore) {
      ctrl.reduxData = $state.current.data.subscribeToReduxStore(ctrl.sbParamsAll);
    }
    ctrl.region = sbEnvironmentConfigService.getRegion();

    ctrl.view = {
      showDebug: supportDebugSelectors.getShowDebug(store.getState()),
    }

    // handle links, for matters, invoices, contacts, etc
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.overrideRedirect = sbOverrideRedirectService.overrideNext;
    ctrl.closeCurrentTab = sbTabService.closeCurrent;
    ctrl.closeTab = sbTabService.close;
    ctrl.getXeroSettings = sbXeroService.getSettings;
    ctrl.isXeroConnected = sbXeroService.isAuthorized;
    ctrl.isFeatureActive = featureActive;
    ctrl.saveRecentInvoice = $stateParams.openedFromTab ? null : saveRecentInvoice;
    const operatingAccount = getOperatingAccount();
    ctrl.operatingAccountId = operatingAccount && operatingAccount.id;
    ctrl.isStatutoryDepositMatter = sbBankAccountService.isStatutoryDepositMatter;
    ctrl.t = sbLocalisationService.t;
    ctrl.hasFacet = hasFacet;
    ctrl.hasBillingAccess= hasBillingAccess;
    ctrl.isNewTheme = isNewTheme();
    ctrl.authorizationResourceIds = resourceIds;

    if ($state.current.data) {
      ctrl.sbRouteData = _.cloneDeep($state.current.data);

      if ($state.current.data.derivePersistanceKey) {
        persistanceKey = $state.current.data.derivePersistanceKey($state.current.name, ctrl.sbParams);
        ctrl.sbUnsavedChanges = sbUnsavedChangesService.loadMemory(persistanceKey);
        findUnsavedChanges = $state.current.data.findUnsavedChanges;
      }
    }

    ctrl.showMessage = showMessage;
    ctrl.setModalDialogVisible = setModalDialogVisible;
    ctrl.isModalVisible = isModalVisible;

    $scope.$on('$stateChangeSuccess', (event, state) => {
      // clone state params
      ctrl.sbParams = _.cloneDeep($stateParams);
      ctrl.sbParams.sbSubState = getSubSState(state.name);
      ctrl.sbParamsAll = _.cloneDeep($state.params); // pass all params, including children https://stackoverflow.com/questions/23081397/ui-router-stateparams-vs-state-params
      ctrl.sbData.stateName = $state.current.name;
      ctrl.sbQuery = _.cloneDeep($location.search());
    
      // set nav highlights
      ctrl.sbNavHighlights = {};
      if (state.data) {
        ctrl.sbRouteData = _.cloneDeep(state.data);

        if (state.data.navHighlights) {
          _.each(state.data.sbNavHighlights, highlight => (ctrl.sbNavHighlights[highlight] = true));
        }
      }
    });

    $scope.$on('$locationChangeSuccess', () => {
      ctrl.sbQuery = _.cloneDeep($location.search());
    });

    ctrl.dataChangeFunction = (key, doc) => {
      log.info('data change', key, doc);
      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }
    };

    function getSubSState(name) {
      const subState = name.match(/^.*\.([^.]*)$/);
      return subState ? subState[1] : name;
    }

    function showMessage(type, message, error) {
      if (!_.includes(['success', 'info', 'warn', 'error'], type)) {
        log.warn(`invalid message display type: ${type}`);
        return;
      }
      sbMessageDisplayService[type](buildMessage(message, error));
    }

    function buildMessage(msg, err) {
      return sbMessageDisplayService
        .builder()
        .text(msg)
        .conditionalText(': {0}', err && err.message);
    }

    let unsubscribeFromReduxStore;

    $scope.$on('$destroy', () => {
      if (persistanceKey && findUnsavedChanges) {
        const changes = findUnsavedChanges(ctrl.sbData, ctrl.sbParams);
        sbUnsavedChangesService.saveMemory(persistanceKey, changes);
      }

      // we are listening the root, we need to unregister event on destroy otherwise the event will be leaked after the destory happens
      unregisterStateChange();
      unregisterScopeStateChange();

      if (unsubscribeFromReduxStore) {
        unsubscribeFromReduxStore()
      }
    });

    /*------------------------------------------------------------------------------------------------------------
     * SUBSCRIBE FEATURE
     * ----------------
     * Using this feature, partial-state-controller consumers can pass (and subscribe to!) redux store params in routes.
     * This will set the query params in the URL that is occasionally used in angular controllers to pass state to children
     * 
     * Simply define a subscribe function on the route data object that returns the desired param object.
     * If set, this controller will subscribe to the redux store and update the current state params with the redux object on store change
     * 
     * See /home/billing/accounts/transactions/ledger/routes.js for an example
      -----------------------------------------------------------------------------------------------------------*/
    if ($state.current.data && $state.current.data.subscribeToReduxStore) {
      unsubscribeFromReduxStore = store.subscribe(_.throttle(() => {
        // It seems it is possible for the throttle to trigger after the component has dismounted
        if ($state.current.data.subscribeToReduxStore) {
          const reduxData = $state.current.data.subscribeToReduxStore();
          $scope.$applyAsync(() => {
            ctrl.reduxData = reduxData;
          });
        }
      }, 50));
    }
    // Code above runs when redux store changes and not when state changes. I observed that when changing a state (changing route),
    // this means the code above may run with the previous state as there is redux update before we change the state.
    // This causes issues when previous state don't have subscribeToReduxStore but new one does as this function never run.
    // For this reason, I added code below which is executed after the state changes so we end up with correct reduxData.
    const unregisterScopeStateChange = $scope.$on(
      '$stateChangeSuccess',
      (event, toState /*, toParams , fromState */) => {
        const stateData = toState.data;
        if (stateData && stateData.subscribeToReduxStore) {
          const reduxData = stateData.subscribeToReduxStore();
          $scope.$applyAsync(() => {
            ctrl.reduxData = reduxData;
          });
        }
      })

    /*------------------------------------------------------------------------------------------------------------
     * REMEMBER FEATURE
     * ----------------
     * define a top level parent state that IS NOT ABSTRACT, then given that child
     * states inherit data from parent states and the parent state has 'remember: true'
     * and also 'rememberParent: some.parent.state.name' then:
     *
     *  - if the FROM state has 'remember: true' then we need to save it against the key of rememberParent
     *
     *  - if the TO state has 'remember: true' and the TO state's name matches 'rememberParent' then
     *    this is a special case. Here we get the last child state for this parent that was remembered
     *    and we navigate there.
     *
     *  - In cases where we arrive at a parent that does not yet have any remembered child state
     *    we go to the 'rememberDefaultChild' if set
    -----------------------------------------------------------------------------------------------------------*/
    const unregisterStateChange = $rootScope.$on(
      '$stateChangeSuccess',
      (event, toState, toParams, fromState) => {
        const fromRem = !!_.get(fromState, 'data.remember');
        const toRem = !!_.get(toState, 'data.remember');
        const fromRoot = _.get(fromState, 'data.rememberParent');
        const toRoot = _.get(toState, 'data.rememberParent');
        const toDefault = _.get(toState, 'data.rememberDefaultChild');

        if (fromRem && fromRoot) {
          log.info(`remember exit ${fromState.name}`);
          sbUnsavedChangesService.saveMemory(`${fromRoot}.state.name`, fromState.name);
          sbUnsavedChangesService.saveMemory(`${fromRoot}.state.params`, fromState.params);
        }

        if (toRem && toState.name === toRoot) {
          log.info(`remember enter to:${toState.name}`);
          const restoreStateName = sbUnsavedChangesService.loadMemory(`${toRoot}.state.name`);
          const restoreStateParams = sbUnsavedChangesService.loadMemory(`${toRoot}.state.params`);
          log.info(`restore state name:${restoreStateName}`);
          if (_.isEmpty(restoreStateName)) {
            if (toDefault) {
              log.warn(`going to default:${toDefault}`);
              $state.go(toDefault); // NB state must support no params
            }
          } else {
            $state.go(restoreStateName, restoreStateParams);
          }
        }
      }
    );
  });
