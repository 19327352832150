
import { validateMultiple as isEmailValid, validateSingle as isSingleEmailValid, validateMaxEmailsNumber } from '@sb-itops/email';
import { CORRESPONDENCE_STATUS, sentViaTypes } from '@sb-billing/business-logic/correspondence-history';
import { getPersonInitials } from '@sb-billing/business-logic/invoice-via-communicate';
import { getPaymentsByInvoiceId } from '@sb-billing/redux/payments';
import { getTotalsForInvoiceId } from '@sb-billing/redux/invoice-totals';
import { integerToDate, today as nowDateOnly } from '@sb-itops/date';
import uuid from '@sb-itops/uuid';
import { featureActive } from '@sb-itops/feature';
import { store } from '@sb-itops/redux';
import { getLocalRegionQueryString }from '@sb-itops/app-env';
import * as messageDisplay from '@sb-itops/message-display';
import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { getFirmName, getPhoneNumber, getStaffByPersonId, getStaffEmailDetails } from '@sb-firm-management/redux/firm-management';
import { determineEInvoiceEnabled } from '@sb-billing/business-logic/einvoice';
import { applyEmailHtmlWrapper, buttonStyles } from '@sb-itops/email';
import {
  applyInvoiceHeader,
  buildInvoiceEmailWithSummaryText,
  createSummaryBox, 
  createPayNowButton,
  createPayNowLink,
  createViewInvoiceButton,
} from '@sb-billing/business-logic/invoice-emailing';
import { getById as getMatterById, getMatterDisplay } from '@sb-matter-management/redux/matters';
import { getById as getMatterTotalsById } from '@sb-billing/redux/matter-totals';
import { formatContactSalutation, formatContactsEmails } from '@sb-customer-management/business-logic/contacts-summary/services';
import { getMap as getBankAccountBalancesMap } from '@sb-billing/redux/bank-account-balances';
import { getMatterTrustBalanceAllTypes } from '@sb-billing/redux/bank-account-balances.2/selectors';
import {
  getCurrentConfigurationByMatterId,
} from '@sb-billing/redux/billing-configuration';
import {
  getInvoiceLatestVersion,
  getInvoiceSummariesByFilter,
  invoiceStatuses,
  getInvoicePDFVersionId,
} from '@sb-billing/redux/invoices';
import * as invoicePreDraftFeature from '@sb-billing/redux/invoice-pre-draft';
import { getActiveProvider, isPaymentProviderEnabledForBankAccount } from '@sb-billing/redux/payment-provider-settings/selectors';
import { getConfig as getDefaultMatterBillingConfig } from '@sb-billing/redux/default-matter-billing-configuration';
import { decodeAndClean64Html } from '@sb-itops/html';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';
import { getById as getMatterInvoiceSettings, isPayButtonEnabledForFirmAndMatter } from '@sb-billing/redux/matter-invoice-settings';
import { getById as getEInvoiceSettings } from '@sb-billing/redux/einvoice-settings';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { getTemplateByIdOrFirmDefault } from '@sb-billing/redux/invoice-settings-template';
import { getAccountId } from 'web/services/user-session-management';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

const { getVersionById: getPreDraftInvoiceById } = invoicePreDraftFeature.selectors;

angular
  .module('@sb-billing/services')
  .service('sbInvoiceSendService', function(
    sbLoggerService,
    sbGenericEndpointService,
    sbInvoicingService,
    sbInvoiceTotalsService,
    sbSimpleContactMbService,
    sbMessageDisplayService,
    sbMattersMbService,
    sbCorrespondenceHistoryService,
    sbLocalisationService
  ) {
    const that = this;
    const log = sbLoggerService.getLogger('sbInvoiceSendService');

    that.createInvoiceEmailPreviewP = createInvoiceEmailPreviewP;
    that.createInvoiceCommunicatePreviewP = createInvoiceCommunicatePreviewP;
    that.getInterpolatedValuesForEmail = getInterpolatedValuesForEmail;
    that.getInterpolatedValuesForCommunicate = getInterpolatedValuesForCommunicate;
    that.sendInvoiceEmailRequestsP = sendInvoiceEmailRequestsP;
    that.sendInvoiceCommunicateRequestsP = sendInvoiceCommunicateRequestsP;
    that.isPaymentProviderEnabledForInvoicePayment = isPaymentProviderEnabledForInvoicePayment;

    async function sendInvoiceEmailRequestsP(invoiceEmailRequests) {
      const invoiceEmailRequestsArray = Array.isArray(invoiceEmailRequests) ? invoiceEmailRequests : [invoiceEmailRequests];

      // Because we use different message templates in the modal for regular invoice emails
      // compared to consolidated emails it's not possible to alternate between the two for
      // each invoiceEmailRequest. The following rules determine wheter we should consolidate:
      const shouldConsolidate = invoiceEmailRequestsArray.length === 1 && invoiceEmailRequestsArray[0].consolidate === true;
      if (shouldConsolidate) {
        await sendConsolidatedInvoiceEmailRequestP(...invoiceEmailRequestsArray);
        return;
      }

      const sendRequests = prepareSendRequestsForEachInvoiceAndDebtor({ invoiceSendRequests: invoiceEmailRequestsArray, preDraftMode: false, sentVia: sentViaTypes.EMAIL });

      // Set correspondence status to "In progress" for all requests
      await applyCorrespondenceChangesetByInvoiceSendDetails(sendRequests, { status: CORRESPONDENCE_STATUS.inProgress });

      try {
        const firmDetails = {
          staffDetails: getStaffEmailDetails(),
          firmName: getFirmName(),
          firmPhoneNumber: getPhoneNumber(),
          paymentProviderEnabledForInvoicePayment: isPaymentProviderEnabledForInvoicePayment(),
        };

        // Create the email requests
        const emailRequests = generateInvoiceEmailRequests({
          invoiceEmailRequests: await populateMissingInfo(sendRequests),
          firmDetails,
        });

        // If no requests are valid, exit early
        if (emailRequests.valid.length === 0 && emailRequests.invalid.length !== 0) {
          sendCorrespondenceHistoryNotification(emailRequests.invalid)
          return;
        }

        // Convert the InvoiceEmail object into the shape the send-batch endpoint expects.
        // send-batch endpoint only accepts single invoices, and prepareSendRequestsForEachInvoiceAndDebtor returns
        // invoiceIds as an array, we need to convert invoiceIds to invoiceId before sending
        const formattedRequests = emailRequests.valid.map(emailRequest => ({
          invoiceId: emailRequest.invoiceIds[0],
          to: emailRequest.toAddress,
          from: emailRequest.replyToAddress,
          subject: emailRequest.subject,
          body: emailRequest.sendAsIs ? emailRequest.message : undefined, // by-passes .net email wrapper
          message: !emailRequest.sendAsIs ? emailRequest.message : undefined, // send using .net email wrapper
          bcc: emailRequest.bcc || undefined,
          cc: emailRequest.cc || undefined,
          correspondenceId: emailRequest.correspondenceId,
          debtorId: emailRequest.debtorId,
        }));

        const message = {
          emailRequests: formattedRequests,
          eInvoiceEnabled: featureActive('BB-5725'),
        };
        const responseBody = await dispatchCommand({
          type: 'Integration.SendInvoices',
          message,
        });

        const failedInvoiceIds =
          responseBody.failureInvoiceIds && responseBody.failureInvoiceIds.length > 0
            ? responseBody.failureInvoiceIds
            : undefined;
        // send `corresponse start` request for the success ones
        // and send `correspondence failed` for the failed ones in the partial failure case
        emailRequests.valid.forEach((emailReq) => {
          // Note: failedInvoiceIds is an array of InvoiceIds that failed in a partial success case.
          if (failedInvoiceIds && failedInvoiceIds.some((invoiceId) => emailReq.invoiceIds.includes(invoiceId))) {
            emailReq.errorMessage = 'Failed to send this email in the batch';
          }
        });

        sendCorrespondenceHistoryNotification([...emailRequests.valid, ...emailRequests.invalid]);
      } catch(err) {
        log.error(err);

        if (
          (err.data && err.data.message === emailMessages.notAllowedToSendEmailsServer) ||
          (err.payload && err.payload.body && err.payload.body.message === emailMessages.notAllowedToSendEmailsServer)
        ) {
          messageDisplay.error(emailMessages.notAllowedToSendEmailsDisplay);
        } else {
          messageDisplay.error(`Failed to send invoice email(s), please try again later`);
        }
      }
    }

    async function sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests) {
      const invoiceCommunicateRequestsArray = Array.isArray(invoiceCommunicateRequests) ? invoiceCommunicateRequests : [invoiceCommunicateRequests];
      const sendRequests = prepareSendRequestsForEachInvoiceAndDebtor({ invoiceSendRequests: invoiceCommunicateRequestsArray, preDraftMode: false, sentVia: sentViaTypes.COMMUNICATE });
      // Set correspondence status to "In progress" for all requests
      await applyCorrespondenceChangesetByInvoiceSendDetails(sendRequests, { status: CORRESPONDENCE_STATUS.inProgress });

      try {
        const firmDetails = {
          staffDetails: getStaffEmailDetails(),
          firmName: getFirmName(),
          firmPhoneNumber: getPhoneNumber(),
          paymentProviderEnabledForInvoicePayment: isPaymentProviderEnabledForInvoicePayment(),
        };

        // Create the communicate requests
        const communicateRequests = generateInvoiceCommunicateRequests({
          invoiceCommunicateRequests: await populateMissingInfo(sendRequests),
          firmDetails
        });

        // If no requests are valid, exit early
        if (communicateRequests.valid.length === 0 && communicateRequests.invalid.length !== 0) {
          sendCorrespondenceHistoryNotification(communicateRequests.invalid)
          return;
        }

        const formattedCommunicateMessage = communicateRequests.valid.map(communicateRequest => ({
          requestId: uuid(),
          matterId: communicateRequest.matterId,
          matterTitle: communicateRequest.matterTitle,
          matterNumber: communicateRequest.matterNumber,
          matterDescription:communicateRequest.matterDescription,
          invoiceId: communicateRequest.invoiceIds[0],
          invoicePdfVersionId: communicateRequest.invoicePdfVersionId,
          invoiceNumber: communicateRequest.invoiceNumber,
          to: communicateRequest.toAddress,
          fromUserId: communicateRequest.fromUserId,
          replyToAddress:communicateRequest.replyToAddress,
          body: communicateRequest.message,
          linksMap: communicateRequest.linksMap,
          debtorId: communicateRequest.debtorId,
          debtorFirstName: communicateRequest.debtorFirstName,
          debtorLastName: communicateRequest.debtorLastName,
          debtorInitials: communicateRequest.debtorInitials,
          debtorRoleDisplay: '',
          correspondenceId: communicateRequest.correspondenceId,
        }));

        await sbGenericEndpointService.postPayloadP(`/billing/invoice/send-batch-via-communicate`, undefined, { communicateMessages: formattedCommunicateMessage })

        sendCorrespondenceHistoryNotification([...communicateRequests.valid, ...communicateRequests.invalid]);
      } catch(err) {
        log.error(err);
        sendRequests.forEach(sendRequest => {
          sendCorrespondenceHistoryNotification([{
            ...sendRequest,
            errorMessage: 'Failed to send Client Portal message'
          }], sendRequest.correspondenceId)
        });
        messageDisplay.error(`Failed to send Communicate message, please try again later`);
      }
    }

    // Currently the endpoint only takes single invoices, so we'll convert all the
    // requests to contain only a single invoice. Once the endpoint can take
    // multiple invoices, we'll still need to group the emails by the same matter
    // in order for the matter-related interpolated data to work correctly
    // Add correspondenceId and sentVia to prepare for correspondence change, also make sure debtor info is here
    function prepareSendRequestsForEachInvoiceAndDebtor({ invoiceSendRequests, preDraftMode, sentVia }) {
      const splittedInvoiceSendRequestArray = invoiceSendRequests.reduce((acc, invoiceSendRequest) => {
        invoiceSendRequest.invoiceIds.forEach(invoiceId => {
          const invoiceFromCache = (preDraftMode && getPreDraftInvoiceById(store.getState(), { invoiceId })) || getInvoiceLatestVersion(invoiceId);
          const invoice = { ...invoiceFromCache }; // make a copy so we can base64 decode it
          // Communicate only has fromUserId, but replyToAddress is required for updating the correspondence history
          const { email: staffEmailAddress } = getStaffEmailDetails({ userId: invoiceSendRequest.template.fromUserId}) || {};

          if (!preDraftMode) {
            // invoice in pre-draft-mode comes from relevant redux feature and summary is stored in
            // plain html. invoice fetched from cache is stored in base64, thus needs to be decoded
            invoice.summary = decodeAndClean64Html({ base64EncodedHtml: invoice.summary });
          }

          // If the debtorId was passed through, or the invoice only has 1 debtor,
          // add any relevant data. Otherwise, we need to create a request for each
          // of the debtors
          if (invoiceSendRequest.debtorId || (invoice && invoice.debtors && invoice.debtors.length === 1)) {
            acc.push({
              ...invoiceSendRequest,
              invoiceIds: [invoice && invoice.invoiceId],
              invoices: [invoice], // Keep this as an array to future-proof it for when we enable multiple invoice sending on one email/Communicate
              invoiceNumber: invoice.invoiceNumber,
              matterId: invoice && invoice.matterId,
              debtorId: invoiceSendRequest.debtorId || invoice.debtors[0].id,
              correspondenceId: uuid(),
              sentVia,
              replyToAddress: invoiceSendRequest.template.replyToAddress || staffEmailAddress,
            });
          } else if (invoice && invoice.debtors && invoice.debtors.length > 1) {
            // Split the request into multiple by the debtorIds from the invoice
            invoice.debtors.forEach(debtor => {
              acc.push({
                ...invoiceSendRequest,
                invoiceIds: [invoice && invoice.invoiceId],
                invoices: [invoice],
                invoiceNumber: invoice.invoiceNumber,
                matterId: invoice && invoice.matterId,
                debtorId: debtor.id,
                correspondenceId: uuid(),
                sentVia,
                replyToAddress: invoiceSendRequest.template.replyToAddress || staffEmailAddress,
                template: {
                  ...invoiceSendRequest.template,
                  // If the email address was supplied in the request, likely it's not the same one for all the debtors
                  // We remove it here, and will retrieve when processing the request via the debtorId
                  toAddress: "",
                }
              })
            })
          }
        });

        return acc;
      }, []);

      return splittedInvoiceSendRequestArray;
    }
  
    function generateInvoiceEmailRequests({ invoiceEmailRequests, firmDetails, isPreviewMode = false, quickPaymentsTotalAmount = 0, preDraftMode }) {
      const requests = {
        valid: [],
        invalid: []
      };

      invoiceEmailRequests.forEach(emailRequest => {
        if (!isPreviewMode && !isEmailValid(emailRequest.template.toAddress)) {
          requests.invalid.push({
            ...emailRequest,
            errorMessage: `Cannot send email due to missing/invalid contact email address`,
          });
        } else if (!isPreviewMode && !validateMaxEmailsNumber(emailRequest.template.toAddress)) {
          requests.invalid.push({
            ...emailRequest,
            errorMessage: 'A maximum of five recipients are allowed',
          });
        } else {
          requests.valid.push(
            prepareInvoiceEmail({ emailRequest, firmDetails, isPreviewMode, quickPaymentsTotalAmount, preDraftMode }),
          )
        }
      }, requests);

      return requests;
    }

    function generateInvoiceCommunicateRequests({ invoiceCommunicateRequests,firmDetails, isPreviewMode = false, quickPaymentsTotalAmount = 0 }) {
      const requests = {
        valid: [],
        invalid: []
      };

      invoiceCommunicateRequests.forEach(communicateRequest => {
        if (!isPreviewMode && !isEmailValid(communicateRequest.template.toAddress)) {
          requests.invalid.push({
            ...communicateRequest,
            errorMessage: `Cannot send Client Portal due to missing/invalid contact email address`,
          });
        } else {
          requests.valid.push(
            prepareInvoiceCommunicateRequest({ communicateRequest, firmDetails,isPreviewMode, quickPaymentsTotalAmount }),
          )
        }
      }, requests);

      return requests;
    }

    async function populateMissingInfo(invoiceEmailRequests) {
      const emailRequests = await Promise.all(invoiceEmailRequests.map(async emailRequest => {
        if (!emailRequest.debtorId || !emailRequest.matterId) {
          return emailRequest;
        }

        try {
          const debtorInfo = await getCustomerInfo(emailRequest.debtorId, emailRequest.matterId);

          return {
            ...emailRequest,
            template: {
              ...emailRequest.template,
              toAddress: isEmailValid(emailRequest.template.toAddress)
                ? emailRequest.template.toAddress
                : formatContactsEmails(debtorInfo),
              debtorSalutation: formatContactSalutation(debtorInfo),
            }
          };
        } catch (err) {
          log.error('Failed to retrieve customer info', err);

          // Return the original emailRequest object to be marked as invalid when validating the email request
          return emailRequest;
        }
      }, []));

      return emailRequests;
    }

    async function prepareConsolidatedInvoiceEmail(invoiceEmail, consolidate = false) {
      const firmDetails = {
        staffDetails: getStaffEmailDetails(),
        firmName: getFirmName(),
        firmPhoneNumber: getPhoneNumber(),
      };

      const debtorInfo = await getCustomerInfo(invoiceEmail.debtorId); // Consolidated billing is always for the same debtor across 1..N invoices.

      // Transform data into the shape the endpoint expects
      const consolidatedEmailRequest = {
        to: invoiceEmail.toAddress,
        from: invoiceEmail.replyToAddress,
        bcc: invoiceEmail.bcc || undefined, // Express validator v2.17.1 .optional() expects the value not to exist, cannot handle 'falsey' values. Fix is in v2.18.0
        cc: invoiceEmail.cc || undefined,
        subject: invoiceEmail.subject
          .replace(/\[USER\]|\[USER_NAME\]/g, firmDetails.staffDetails.name || '')
          .replace(/\[FIRM_NAME\]/g, firmDetails.firmName || '')
          .replace(/\[DEBTOR\]|\[DEBTOR_NAME\]/g, formatContactSalutation(debtorInfo) || 'customer'),
        message: invoiceEmail.message,
        debtorId: invoiceEmail.debtorId,
        combine: invoiceEmail.combine,
        correspondenceId: invoiceEmail.correspondenceId,
        // the person we want to address on the cover letter
        addresseeId: featureActive('BB-4416') && consolidate ? invoiceEmail.debtorId : undefined,
      };

      // Interpolate the placeholders in the emailData message
      if (!consolidatedEmailRequest.interpolatedMessage) {
        consolidatedEmailRequest.interpolatedMessage = consolidatedEmailRequest.message
          .replace(/\[USER\]|\[USER_NAME\]/g, firmDetails.staffDetails.name || '')
          .replace(/\[FIRM_NAME\]/g, firmDetails.firmName || '')
          .replace(/\[DEBTOR\]|\[DEBTOR_NAME\]/g, formatContactSalutation(debtorInfo) || 'customer');
      }

      consolidatedEmailRequest.consolidatedGroups = getConsolidatedRequestBody(invoiceEmail.invoiceIds, invoiceEmail.debtorId);
      return consolidatedEmailRequest;
    }

    function prepareInvoiceEmail({ emailRequest, firmDetails, isPreviewMode = false, quickPaymentsTotalAmount, preDraftMode = false }) {
      const accountId = getAccountId();
      const matterInvoiceSettings = getMatterInvoiceSettings(emailRequest.matterId);
      const eInvoiceSettings = getEInvoiceSettings(accountId);
      const eInvoiceEnabled = featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch)
        ? determineEInvoiceEnabled({ matterInvoiceSettings, eInvoiceSettings })
        : featureActive('BB-5725');
      let dodFeatureEnabled = eInvoiceEnabled && featureActive('BB-6865');
      const newEmailTemplateEnabled = true;
      const showPayNowButton = isPayButtonEnabledForFirmAndMatter({ matterId: emailRequest.matterId }) &&
        emailRequest.invoices.every(invoice =>
          invoice.debtors.length === 1 && // Single debtor invoices
          invoice.debtors[0].id === emailRequest.invoices[0].debtors[0].id && // where all debtors are the same
          invoice.merchantPaymentReference);

      const invoiceIds = emailRequest.invoices.map(invoice => invoice.invoiceId);
      const matterTrustBalance =  emailRequest.matterId ? getMatterTrustBalanceAllTypes(getBankAccountBalancesMap(), { matterId: emailRequest.matterId }) : {};
      const trustBalance = matterTrustBalance[balanceTypes.BALANCE] || 0;
      const trustBalanceAvailable = matterTrustBalance[balanceTypes.AVAILABLE] || 0;

      // Calculate totals for all invoices in the request
      const invoicesSummary = calculateTotalOfInvoicesSummary({ invoices: emailRequest.invoices, trustBalance: trustBalanceAvailable, invoiceTotalDurationsByIdMap: emailRequest.invoiceTotalDurationsByIdMap });
  
      invoicesSummary.earliestDueDate = emailRequest.invoices.reduce((prev, invoice) => (!prev || invoice.dueDate < prev) ? invoice.dueDate : prev, '');

      // Retrieve all relevant matter details
      const matter = getMatterById(emailRequest.matterId);

      const billingConfig = getCurrentConfigurationByMatterId(emailRequest.matterId) || {};
      const defaultMatterBillingConfig = getDefaultMatterBillingConfig() || {};

      const trustRetainerActive = billingConfig.minimumTrustRetainerActive && defaultMatterBillingConfig.minimumTrustRetainerActive;

      const replenishUpTo = trustRetainerActive ? billingConfig.trustRetainerReplenishAmount : null;
      const matterDetails = {
        matterTitle: getMatterDisplay(matter),
        matterTotals: JSON.parse(JSON.stringify(getMatterTotalsById(matter.matterId))), // This seems to return a mutable redux object?? string and parse to not mutate redux
        attorneyResponsible: getStaffByPersonId(matter.attorneyResponsibleId) || {},
        originatingAttorney: getStaffByPersonId(matter.originatingAttorneyId) || {},
        personAssisting: getStaffByPersonId(matter.personAssistingId) || {},
        replenishAmount: trustRetainerActive && (replenishUpTo - trustBalance > 0) ? replenishUpTo - trustBalance : 0,
      }

      // BB-13093 If sending from draft/edit the current invoice totals haven't been added to matter amount due
      // Need to opdate the value
      if (preDraftMode) {
        matterDetails.matterTotals.unpaid = getMatterTotalsById(matter.matterId).unpaid + invoicesSummary.invoiceTotals.unpaid;
      }

      // Retrieve outstanding amounts
      const { unpaid } = sbInvoiceTotalsService.getTotalsForDebtorId(emailRequest.debtorId);
      const invoiceSummariesFilterOptions = {
        debtorId: emailRequest.debtorId,
        overdueOnly: true,
        status: invoiceStatuses.FINAL,
      }
      const overdueInvoices = getInvoiceSummariesByFilter(invoiceSummariesFilterOptions);
      const totals = {
        unpaid,
        pastDue: overdueInvoices.reduce((acc, overdueInvoice) => acc + getTotalsForInvoiceId(overdueInvoice.invoiceId).unpaid, 0),
      }
     
      // Add pre-draft values, if any
      if (invoicesSummary.preDraftInvoiceTotal) {
        totals.unpaid += invoicesSummary.preDraftInvoiceTotal;
        totals.pastDue += invoicesSummary.preDraftInvoicePastDue;
      }
      
      let finalMessage = emailRequest.template.message;

      // this function is also called when render email preview, we can bypass the
      // application of extra html wrappers which is needed only for sending the email
      if (!isPreviewMode) {   
        const isSingleInvoice = emailRequest.invoices && emailRequest.invoices.length === 1;

        // it's agreed that invoice summary will be put behind BB-6865 even though it's
        // not technically to do with the description on demand feature, another word 
        // invoice summary text is applicable for all invoices in general. Invoice summary
        // is assumed to be decoded by the time it reaches this point (it's stored in base64 encoding)
        if (dodFeatureEnabled && isSingleInvoice) {
          // If we only have 1 invoice, we check its template's DoD settings to determine if DoD should be enabled
          // We use current template settings, not the settings at the time of finalising invoice.
          const invoice = emailRequest.invoices[0];
          const currentInvoiceTemplate = getTemplateByIdOrFirmDefault(invoice.templateId) || {};
          const dodEnabledForInvoice = currentInvoiceTemplate.settings && currentInvoiceTemplate.settings.eInvoiceOptions && currentInvoiceTemplate.settings.eInvoiceOptions.enableDescriptionOnDemand;
          
          if (dodEnabledForInvoice) {
            // Replacing [PAY_NOW_BUTTON] with [PAY_NOW_LINK] when dod is enabled, to avoide duplicate pay now button with summary box
            // [PAY_NOW_LINK] is not exposed on UI, only used here
            const messageWithPayNowLink = emailRequest.template.message.replace(/\[PAY_NOW_BUTTON\]/g, '[PAY_NOW_LINK]');
            finalMessage = buildInvoiceEmailWithSummaryText(messageWithPayNowLink);
          } else {
            // The DoD feature is globally enabled, but it is not enabled for template for this invoice
            // In this case we consider DoD disabled
            dodFeatureEnabled = !!dodEnabledForInvoice;
          }
        }
        
        if (!(dodFeatureEnabled && isSingleInvoice) && newEmailTemplateEnabled) {
          // Replacing [PAY_NOW_BUTTON] with [PAY_NOW_LINK] when using new email template, to avoid duplicate pay now button with invoice header
          // [PAY_NOW_LINK] is not exposed on UI, only used here
          const messageWithPayNowLink = emailRequest.template.message.replace(/\[PAY_NOW_BUTTON\]/g, '[PAY_NOW_LINK]');
          // use new email template, for this we need to inject a head with the invoice number
          // and the view invoice button at the top of the email, .Net used to do this
          const messageWithInvoiceHeader = applyInvoiceHeader({ emailBody: messageWithPayNowLink, showPayNowButton, showViewInvoiceButton: eInvoiceEnabled });
          finalMessage = applyEmailHtmlWrapper({ emailBody: messageWithInvoiceHeader, });
        }
      }
      
      // Optimistically subtract any quick payments from the totals
      // Payments come from Trust/Operating Retainer accounts and should be valid
      if (quickPaymentsTotalAmount) {
        totals.unpaid -= quickPaymentsTotalAmount;

        invoicesSummary.invoiceTotals.unpaid = invoicesSummary.invoiceTotals.unpaid > quickPaymentsTotalAmount
          ? invoicesSummary.invoiceTotals.unpaid - quickPaymentsTotalAmount
          : 0;
      }

      if (invoicesSummary.lessFundsInTrust) {
        totals.unpaid -= invoicesSummary.lessFundsInTrust;
      }

      let cc = emailRequest.template.cc;
      let bcc = emailRequest.template.bcc;
      if (emailRequest.useMatterCcBcc) {
        const { bCCAddresses = [], cCAddresses = [] } = getMatterEmailSettings(emailRequest.matterId) || {};
        // if there is an email in bcc, it means user used "Send a copy to myself" and we wanna include it
        if (isSingleEmailValid(bcc) && bCCAddresses.indexOf(bcc) === -1) {
          bCCAddresses.push(bcc);
        }
        cc = cCAddresses.join(', ');
        bcc = bCCAddresses.join(', ');
      }

      // send as is means don't wrap email with old .net email template, instead use new email templates
      const sendAsIs = newEmailTemplateEnabled || dodFeatureEnabled;

      // Return as InvoiceEmail object
      return {
        toAddress: emailRequest.template.toAddress,
        replyToAddress: emailRequest.template.replyToAddress,
        bcc,
        cc,
        subject: interpolateDataForEmail({ text: emailRequest.template.subject, debtorSalutation: emailRequest.template.debtorSalutation, firmDetails, matterDetails, invoices: emailRequest.invoices, invoicesSummary, totals, isPreviewMode: true, eInvoiceEnabled, dodFeatureEnabled, newEmailTemplateEnabled, showPayNowButton }) || `Invoice for ${getMatterDisplay(matter)}`,
        message: interpolateDataForEmail({ text: finalMessage, debtorSalutation: emailRequest.template.debtorSalutation, firmDetails, matterDetails, invoices: emailRequest.invoices, invoicesSummary, totals, isPreviewMode, eInvoiceEnabled, dodFeatureEnabled, newEmailTemplateEnabled, showPayNowButton }),
        invoiceIds,
        debtorId: emailRequest.debtorId,
        correspondenceId: emailRequest.correspondenceId,
        sentVia: sentViaTypes.EMAIL,
        sendAsIs, // send without old .net email template wrapper
      }
    }

    function prepareInvoiceCommunicateRequest({ communicateRequest, firmDetails, isPreviewMode = false, quickPaymentsTotalAmount }) {
      const accountId = getAccountId();
      const matterInvoiceSettings = getMatterInvoiceSettings(communicateRequest.matterId);
      const eInvoiceSettings = getEInvoiceSettings(accountId);
      const eInvoiceEnabled = featureActive('BB-5725') && hasFacet(facets.eInvoiceUserDefinedSwitch)
        ? determineEInvoiceEnabled({ matterInvoiceSettings, eInvoiceSettings })
        : featureActive('BB-5725');
      const showPayNowButton = isPayButtonEnabledForFirmAndMatter({ matterId: communicateRequest.matterId }) && communicateRequest.invoices.every(invoice =>
        invoice.debtors.length === 1 && // Single debtor invoices
        invoice.debtors[0].id === communicateRequest.invoices[0].debtors[0].id && // where all debtors are the same
        invoice.merchantPaymentReference);

      const invoiceIds = communicateRequest.invoices.map(invoice => invoice.invoiceId);
      const matterTrustBalance =  communicateRequest.matterId ? getMatterTrustBalanceAllTypes(getBankAccountBalancesMap(), { matterId: communicateRequest.matterId }) : {};
      const trustBalance = matterTrustBalance[balanceTypes.BALANCE] || 0;
      const trustBalanceAvailable = matterTrustBalance[balanceTypes.AVAILABLE] || 0;

      // Calculate totals for all invoices in the request
      const invoicesSummary = calculateTotalOfInvoicesSummary({ invoices: communicateRequest.invoices, trustBalance: trustBalanceAvailable, invoiceTotalDurationsByIdMap: communicateRequest.invoiceTotalDurationsByIdMap });
      invoicesSummary.earliestDueDate = communicateRequest.invoices.reduce((prev, invoice) => (!prev || invoice.dueDate < prev) ? invoice.dueDate : prev, '');

      // Retrieve all relevant matter details
      const matter = getMatterById(communicateRequest.matterId);

      const billingConfig = getCurrentConfigurationByMatterId(communicateRequest.matterId) || {};
      const defaultMatterBillingConfig = getDefaultMatterBillingConfig() || {};

      const trustRetainerActive = billingConfig.minimumTrustRetainerActive && defaultMatterBillingConfig.minimumTrustRetainerActive;

      const replenishUpTo = trustRetainerActive ? billingConfig.trustRetainerReplenishAmount : null;
      const matterDetails = {
        matterTitle: getMatterDisplay(matter), // matterNumber-clientName-matterTypeName
        matterTitleWithoutMatterNumber: getMatterDisplay(matter, false, false, false, false), // clientName-matterTypeName
        matterTotals: getMatterTotalsById(matter.matterId),
        attorneyResponsible: getStaffByPersonId(matter.attorneyResponsibleId) || {},
        originatingAttorney: getStaffByPersonId(matter.originatingAttorneyId) || {},
        personAssisting: getStaffByPersonId(matter.personAssistingId) || {},
        replenishAmount: trustRetainerActive && (replenishUpTo - trustBalance > 0) ? replenishUpTo - trustBalance : 0,
      }

      // Retrieve outstanding amounts
      const { unpaid } = sbInvoiceTotalsService.getTotalsForDebtorId(communicateRequest.debtorId);
      const invoiceSummariesFilterOptions = {
        debtorId: communicateRequest.debtorId,
        overdueOnly: true,
        status: invoiceStatuses.FINAL,
      }
      const overdueInvoices = getInvoiceSummariesByFilter(invoiceSummariesFilterOptions);
      const totals = {
        unpaid,
        pastDue: overdueInvoices.reduce((acc, overdueInvoice) => acc + getTotalsForInvoiceId(overdueInvoice.invoiceId).unpaid, 0),
      }

      // Add pre-draft values, if any
      if (invoicesSummary.preDraftInvoiceTotal) {
        totals.unpaid += invoicesSummary.preDraftInvoiceTotal;
        totals.pastDue += invoicesSummary.preDraftInvoicePastDue;
      }

      // Optimistically subtract any quick payments from the totals
      // Payments come from Trust/Operating Retainer accounts and should be valid
      if (quickPaymentsTotalAmount) {
        totals.unpaid -= quickPaymentsTotalAmount;

        invoicesSummary.invoiceTotals.unpaid = invoicesSummary.invoiceTotals.unpaid > quickPaymentsTotalAmount
          ? invoicesSummary.invoiceTotals.unpaid - quickPaymentsTotalAmount
          : 0;
      }

      if (invoicesSummary.lessFundsInTrust) {
        totals.unpaid -= invoicesSummary.lessFundsInTrust
      }

      const debtorInitials = getPersonInitials({ firstName: communicateRequest.template.debtorFirstName, lastName: communicateRequest.template.debtorLastName});
      const invoicePdfVersionId = getInvoicePDFVersionId(invoiceIds[0]);

      const { text, linksMap } = interpolateDataForCommunicate({ text: communicateRequest.template.message, debtorSalutation: communicateRequest.template.debtorSalutation, firmDetails, matterDetails, invoices: communicateRequest.invoices, invoicesSummary, totals, isPreviewMode, eInvoiceEnabled, showPayNowButton })
   

      // Return as InvoiceCommunicate object
      return {
        matterId: communicateRequest.matterId,
        matterTitle: matterDetails.matterTitleWithoutMatterNumber,
        matterNumber: matter.matterNumber,
        matterDescription: matter.description,
        toAddress: communicateRequest.template.toAddress,
        fromUserId: communicateRequest.template.fromUserId,
        replyToAddress: communicateRequest.replyToAddress,
        debtorFirstName: communicateRequest.template.debtorFirstName,
        debtorLastName: communicateRequest.template.debtorLastName,
        debtorInitials: debtorInitials,
        invoicePdfVersionId: invoicePdfVersionId,
        invoiceNumber: communicateRequest.invoiceNumber,
        message: text,
        linksMap: linksMap,
        invoiceIds,
        debtorId: communicateRequest.debtorId,
        correspondenceId: communicateRequest.correspondenceId,
        sentVia: sentViaTypes.COMMUNICATE,
      }
    }

    function calculateTotalOfInvoicesSummary({ invoices, trustBalance, invoiceTotalDurationsByIdMap }) {
      const invoicesSummary = invoices.reduce((acc, invoice) => {
        const showLessFundsInTrust = (featureActive('BB-6398') && invoice.additionalOptions.showLessFundsInTrust && trustBalance > 0);
 
        // Pre-draft invoice
        if (invoice.preDraftInvoiceTotals) {
          const preDraftTotalDuration = invoiceTotalDurationsByIdMap[invoice.invoiceId];

          return {
            invoiceTotals: {
              unpaid: acc.invoiceTotals.unpaid + invoice.preDraftInvoiceTotals.total - (showLessFundsInTrust ? Math.min(invoice.preDraftInvoiceTotals.total, trustBalance) : 0),
            },
            lessFundsInTrust: acc.lessFundsInTrust + (showLessFundsInTrust ? Math.min(invoice.preDraftInvoiceTotals.total, trustBalance) : 0),
            invoiceTotalDuration: acc.invoiceTotalDuration + preDraftTotalDuration,
            // To be used in summary values:
            preDraftInvoiceTotal: (acc.preDraftInvoiceTotal || 0) + invoice.preDraftInvoiceTotals.total,
            preDraftInvoicePastDue: (acc.preDraftInvoicePastDue || 0) + (integerToDate(invoice.dueDate) < nowDateOnly() ? invoice.preDraftInvoiceTotals.total : 0),
          }
        }

        // Regular invoice
        const invoiceTotals = getTotalsForInvoiceId(invoice.invoiceId);
        const invoiceTotalDuration = invoiceTotalDurationsByIdMap[invoice.invoiceId];
        return {
          invoiceTotals: {
            unpaid: acc.invoiceTotals.unpaid + invoiceTotals.unpaid - (showLessFundsInTrust ? Math.min(invoiceTotals.unpaid, trustBalance) : 0),
          },
          lessFundsInTrust: acc.lessFundsInTrust + (showLessFundsInTrust ? Math.min(invoiceTotals.unpaid, trustBalance) : 0),
          invoiceTotalDuration: acc.invoiceTotalDuration + invoiceTotalDuration,
        }
      }, {
        invoiceTotals: {
          unpaid: 0,
        },
        lessFundsInTrust: 0,
        invoiceTotalDuration: 0,
      });

      return invoicesSummary;
    }

    function interpolateSharedPlainTextData({ text, debtorSalutation, firmDetails, matterDetails, invoicesSummary, totals, t }) {
      return text
        .replace(/\[AMOUNT_DUE\]|\[DEBTOR_AMOUNT_DUE\]/g, totals.unpaid || totals.unpaid === 0 ? t('cents', {val: totals.unpaid}) : '') // All outstanding for the debtor, [AMOUNT_DUE] is replaced by [DEBTOR_AMOUNT_DUE]
        .replace(/\[INVOICE_AMOUNT_DUE\]/g, invoicesSummary.invoiceTotals.unpaid || invoicesSummary.invoiceTotals.unpaid === 0 ?  t('cents', {val: invoicesSummary.invoiceTotals.unpaid}) : '')
        .replace(/\[MATTER_AMOUNT_DUE\]/g, matterDetails.matterTotals.unpaid || matterDetails.matterTotals.unpaid === 0 ? t('cents', {val: matterDetails.matterTotals.unpaid}) : '')
        .replace(/\[PAST_DUE\]/g, totals.pastDue || totals.pastDue === 0 ? t('cents', {val: totals.pastDue}) : '') // All past due for the debtor
        .replace(/\[HOURS\]/g, (invoicesSummary.invoiceTotalDuration / 60).toFixed(2))
        .replace(/\[DATE_DUE\]/g, invoicesSummary.earliestDueDate ? t('date', {yyyymmdd: invoicesSummary.earliestDueDate}) : '')
        .replace(/\[DEBTOR_NAME\]/g, debtorSalutation || 'customer')
        .replace(/\[FIRM_NAME\]/g, firmDetails.firmName || '')
        .replace(/\[USER_NAME\]/g, firmDetails.staffDetails.name || '')
        .replace(/\[MATTER_TITLE\]|\[MATTER_NAME\]/g, matterDetails.matterTitle || '') // [MATTER_TITLE] is replaced by [MATTER_NAME]
        .replace(/\[PHONE_NUMBER\]/g, firmDetails.firmPhoneNumber || '')
        .replace(/\[PERSON_RESPONSIBLE\]/g, matterDetails.attorneyResponsible.name || '')
        .replace(/\[PERSON_ASSISTING\]/g, (matterDetails.personAssisting && matterDetails.personAssisting.name) || '')
        .replace(/\[ATTORNEY_RESPONSIBLE\]/g, (matterDetails.attorneyResponsible && matterDetails.attorneyResponsible.name) || '')
        .replace(/\[ORIGINATING_ATTORNEY\]|\[INTRODUCER\]/g, (matterDetails.originatingAttorney && matterDetails.originatingAttorney.name) || '')
        .replace(/\[EVERGREEN_AMOUNT_REQUESTED\]/g, matterDetails.replenishAmount || matterDetails.replenishAmount === 0 ? t('cents', {val: matterDetails.replenishAmount}) : '')
        ;
    }


    function interpolateDataForEmail({ text, debtorSalutation, firmDetails, matterDetails, invoices, invoicesSummary, totals, isPreviewMode, eInvoiceEnabled, dodFeatureEnabled, newEmailTemplateEnabled, showPayNowButton }) {
      // replace SUMMARY_BOX placeholder if it exist and DOD enabled
      // In preview mode (interpolating the values to show in the editor), we skip
      // replacing [SUMMARY_BOX] as the HTML code does not look right in the editor
      // [SUMMARY_BOX] needs to be replaced first because includes placeholders such as [AMOUNT_DUE]
      if (dodFeatureEnabled) {
        if (!isPreviewMode) {
          text = text.replace(/(:?<p>)?\[SUMMARY_BOX\](:?<\/p>)?/g,  createSummaryBox(invoices[0].summary))
        }
      } else {
        text = text
        .replace(/(:?<p>)?\[SUMMARY_BOX\](:?<\/p>)?/g, '') // Remove the placeholder if DOD not enabled
      }

      text = interpolateSharedPlainTextData({ text, debtorSalutation, firmDetails, matterDetails, invoicesSummary, totals, t: sbLocalisationService.t });

      // pay now button
      let emailIncludesPayNowButton;
      if (showPayNowButton) {

        if (!isPreviewMode) {
          emailIncludesPayNowButton = text.includes(`[PAY_NOW_BUTTON]`);
          //In AU/GB LOCAL env, we need ?(AU|GB) in the pay link route
          //Otherwise, it will fail to load payment details
          const regionQueryString = getLocalRegionQueryString();
          const invoicePaymentUrl = `[LAWPAY_DOMAIN]/${regionQueryString}#/payment/${invoices[0].merchantPaymentReference}`

          const payNowButton = newEmailTemplateEnabled || dodFeatureEnabled
            ? createPayNowButton({ url:  invoicePaymentUrl})
            : `
<div style="padding-bottom: 30px; height: 50px ">
<table cellspacing="0" width="100%" cellpadding="0" align="left" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;">
  <tr>
    <td style="height: 20px;border-collapse: collapse;" align="left"></td>
  </tr>
  <tr>
    <td align="center" width="300" height="40" bgcolor="#63BD00" style="-webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px;color: #ffffff;display: block;border-collapse: collapse;">
      <a href="{downloadLink}" style="font-size: 14px;font-weight: bold;font-family: sans-serif;text-decoration: none;line-height: 40px;width: 100%;display: inline-block;color: orange;">
        <span style="color: #ffffff;">
          Make Payment
        </span>
      </a>
    </td>
  </tr>
</table>
</div>`.replace('{downloadLink}', invoicePaymentUrl);
          const payNowLink = createPayNowLink({ url: invoicePaymentUrl })

          // In preview mode (interpolating the values to show in the editor), we skip
          // replacing [PAY_NOW_BUTTON] as the HTML code does not look right in the editor
          text = text
            .replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p>)?/g, payNowButton)
            .replace(/(:?<p>)?\[PAY_NOW_LINK\](:?<\/p>)?/g, payNowLink);
        }
      } else {     
        emailIncludesPayNowButton = false;   
        text = text
          .replace(/(:?<p>)?\[PAY_NOW_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '') // Remove the placeholder - mirror evergreen retainer handling of Quill paragraphs
          .replace(/(:?<p>)?\[PAY_NOW_LINK\](:?<\/p><p><br \/><\/p>)?/g, '')
      }

      // view invoice button
      // this is currently only used by the new email template, but in the future
      // we can also make [VIEW_INVOICE_BUTTON] a template placeholder if we want
      if (eInvoiceEnabled && (newEmailTemplateEnabled || dodFeatureEnabled)) {
        if (!isPreviewMode) {

          let viewInvoiceButton;

          // if email already includes Pay Now button make View View Details a secondary button
          if (emailIncludesPayNowButton) {
            viewInvoiceButton = createViewInvoiceButton({ url: '[EINVOICE_LINK]', style: buttonStyles.OUTLINE });
          } else {
            viewInvoiceButton = createViewInvoiceButton({ url: '[EINVOICE_LINK]', style: buttonStyles.SOLID });
          }

          // In preview mode (interpolating the values to show in the editor), we skip
          // replacing [VIEW_INVOICE_BUTTON] as the HTML code does not look right in the editor
          text = text
            .replace(/(:?<p>)?\[VIEW_INVOICE_BUTTON\](:?<\/p>)?/g, viewInvoiceButton)
        }
      } else {
        text = text
          .replace(/(:?<p>)?\[VIEW_INVOICE_BUTTON\](:?<\/p><p><br \/><\/p>)?/g, '') // Remove the placeholder - mirror evergreen retainer handling of Quill paragraphs
      }

      text = text
        .replace(/\[DEBTOR\]/g, debtorSalutation)
        .replace(/\[USER\]/g, firmDetails.staffDetails.name);

      return text;
    }

    function interpolateDataForCommunicate({ text, debtorSalutation, firmDetails, matterDetails, invoices, invoicesSummary, totals, isPreviewMode, eInvoiceEnabled, showPayNowButton }) {
      let linksMap = {};

      text = interpolateSharedPlainTextData({ text, debtorSalutation, firmDetails, matterDetails, invoicesSummary, totals, t: sbLocalisationService.t });

      if (showPayNowButton) {
        if (isPreviewMode) {
          text = text.replace(/\[PAY_NOW_BUTTON\]/g, 'PAY NOW');
        } else {
          //In AU/GB LOCAL env, we need ?(AU|GB) in the pay link route
          //Otherwise, it will fail to load payment details
          const regionQueryString = getLocalRegionQueryString();
          const invoicePaymentUrl = `[LAWPAY_DOMAIN]/${regionQueryString}#/payment/${invoices[0].merchantPaymentReference}`

          text = text.replace(/\[PAY_NOW_BUTTON\]/g, '[communicate:hyperlink:payNowButtonLink]')
          linksMap.payNowButtonLink = { label: 'PAY NOW', link: invoicePaymentUrl };
        }
      } else {
        // UI does control when to prepopulate this placeholder based on settings, code in this block just in case when user add the placeholder by themselves without required settings.
        text = text.replace(/\[PAY_NOW_BUTTON\]/g, '');
      }
      // view details button
      if (eInvoiceEnabled) {
        if (isPreviewMode) {
          text = text.replace(/\[VIEW_DETAILS_BUTTON\]/g, 'VIEW DETAILS');
        } else {
          text = text.replace(/\[VIEW_DETAILS_BUTTON\]/g, '[communicate:hyperlink:viewDetailsButtonLink]');
          linksMap.viewDetailsButtonLink = { label: 'VIEW DETAILS', link: '[EINVOICE_LINK]' }; // endpoint is looking for [EINVOICE_LINK] to replace with the eInvoice link
        }
      } else {
        // UI does control when to prepopulate this placeholder based on settings, code in this block just in case when user add the placeholder by themselves without required settings.
        text = text.replace(/\[VIEW_DETAILS_BUTTON\]/g, '');
      }

      return { text, linksMap };
    }

    async function sendConsolidatedInvoiceEmailRequestP(invoiceEmailRequest) {
      // Convert the request into an `InvoiceEmail` object
      const invoiceEmail = {
        toAddress: invoiceEmailRequest.template.toAddress,
        replyToAddress: invoiceEmailRequest.template.replyToAddress,
        bcc: invoiceEmailRequest.template.bcc,
        cc: invoiceEmailRequest.template.cc,
        subject: invoiceEmailRequest.template.subject,
        message: invoiceEmailRequest.template.message,
        invoiceIds: invoiceEmailRequest.invoiceIds,
        debtorId: invoiceEmailRequest.debtorId,
        combine: invoiceEmailRequest.combine,
        correspondenceId: uuid(),
      }

      try {
        // Create an optimistic correspondence entity, flagging the invoices in the email as in progress.
        await applyCorrespondenceChangesetByInvoiceSendDetails([invoiceEmail], { status: CORRESPONDENCE_STATUS.inProgress });

        const consolidatedEmailRequest = await prepareConsolidatedInvoiceEmail(invoiceEmail, invoiceEmailRequest.consolidate);
        const message = {
          ...consolidatedEmailRequest,
          interpolatedMessage: undefined, // we want to replace interpolatedMessage with body
          body: consolidatedEmailRequest.interpolatedMessage,
          // Add feature flags as command manager doesn't have access to them directly
          eInvoiceEnabled: featureActive('BB-5725'),
          useWeasyPrint: featureActive('BB-12383'),
          optimiseImageHandling: featureActive('BB-13988'),
          useJsdom: featureActive('BB-14081'),
        };

        // Send the consolidated invoice email.
        await dispatchCommand({
          type: 'Integration.SendConsolidatedInvoices',
          message,
        });

        // Create a new correspondence history entity for the email operation.
        // Fire and forget because the opdate has already set 'in progress' for this entity.
        sendCorrespondenceHistoryNotification([invoiceEmail]);
      } catch(err) {
        log.error('failed to send consolidated invoice email', err);

        if (
          (err.data && err.data.message === emailMessages.notAllowedToSendEmailsServer) ||
          (err.payload && err.payload.body && err.payload.body.message === emailMessages.notAllowedToSendEmailsServer)
        ) {
          messageDisplay.error(emailMessages.notAllowedToSendEmailsDisplay);
        } else {
          messageDisplay.error(`Failed to send consolidated invoice email, please try again later`);
        }

        throw err;
      }
    }

    function applyCorrespondenceChangesetByInvoiceSendDetails(invoiceSendDetails, changes) {
      return sbCorrespondenceHistoryService
        .applyCorrespondenceChangesetByInvoiceSendDetails(invoiceSendDetails, changes)
        .catch(err => {
          log.error(`Failed to opdate correspondence requests ${JSON.stringify(invoiceSendDetails.map(email => email.correspondenceId))}`, err);
        });
    }

    //copied from `sbInvoicePreviewService` temporarily
    function getConsolidatedRequestBody(invoiceIds, debtorId) {
      return invoiceIds
        .map(invoiceId => {
          const invoiceVersion = sbInvoicingService.getInvoice(invoiceId);
          const paymentIds = _.map(getPaymentsByInvoiceId(invoiceId), payment => payment.paymentId);

          return {
            invoiceId,
            debtorId, // Consolidated emails are only sent to a single debtor, however invoices can have multiple debtors and not in the same order across invoices
            matterId: invoiceVersion.matterId,
            versionId: invoiceVersion.versionId,
            paymentIds
          };
        })
        .reduce((all, invoice) => {
          if (all[invoice.debtorId]) {
            all[invoice.debtorId].invoices.push({
              invoiceId: invoice.invoiceId,
              paymentIds: invoice.paymentIds,
              versionId: invoice.versionId
            });
          } else {
            all[invoice.debtorId] = {
              matterId: invoice.matterId,
              debtorId: invoice.debtorId,
              invoices: [
                {
                  invoiceId: invoice.invoiceId,
                  paymentIds: invoice.paymentIds,
                  versionId: invoice.versionId
                }
              ]
            };
          }

          return all;
        }, {});
    }

    // Creates an invoice preview - interpolated subject and message values
    // preDraftMode is when creating/updating an invoice to handle any unsaved values
    async function createInvoiceEmailPreviewP({ invoiceEmailRequest, preDraftMode, quickPaymentsTotalAmount = 0 }) {
      return await getInterpolatedValuesForEmail({ invoiceEmailRequest, preDraftMode, isPreviewMode: true, quickPaymentsTotalAmount });
    }

    // Creates an invoice preview - interpolated message values
    // preDraftMode is when creating/updating an invoice to handle any unsaved values
    async function createInvoiceCommunicatePreviewP({ invoiceCommunicateRequest, preDraftMode, quickPaymentsTotalAmount = 0 }) {
      return await getInterpolatedValuesForCommunicate({ invoiceCommunicateRequest, preDraftMode, isPreviewMode: true, quickPaymentsTotalAmount });
    }

    function isPaymentProviderEnabledForInvoicePayment() {
      const providerType = getActiveProvider();
      return providerType && isPaymentProviderEnabledForBankAccount({ bankAccountId: getOperatingAccount().id, providerType });
    }

    /**
     * Get Interpolated Values - Replace embjectail subject and message tokens with values
     *
     * @param {object} params
     * @param {object} params.invoiceEmailRequest
     * @param {boolean} params.preDraftMode - Is the invoice in pre-draft mode (ie, never saved)
     * @param {boolean} [params.isPreviewMode=false] - True if we are showing the preview of the email in the UI
     * @param {number} [params.quickPaymentsTotalAmount=0] - Quick payments total, used to optimistically subtract from invoice/debtor owing amounts
     * @returns {Promise} Promise object containing `{ subject, message }`
     */
    async function getInterpolatedValuesForEmail({ invoiceEmailRequest, preDraftMode, isPreviewMode = false, quickPaymentsTotalAmount = 0 }) {
      if (!invoiceEmailRequest || Array.isArray(invoiceEmailRequest) || (invoiceEmailRequest.invoiceIds.length !== 1 && !invoiceEmailRequest.consolidate)) {
        throw new Error('Invoice email preview currently only supports single invoice email requests');
      }

      try {
        const shouldConsolidate = invoiceEmailRequest.consolidate;
        if (shouldConsolidate) {
          // Convert the request into an `InvoiceEmail` object
          const invoiceEmail = {
            toAddress: invoiceEmailRequest.template.toAddress,
            replyToAddress: invoiceEmailRequest.template.replyToAddress,
            bcc: invoiceEmailRequest.template.bcc,
            cc: invoiceEmailRequest.template.cc,
            subject: invoiceEmailRequest.template.subject,
            message: invoiceEmailRequest.template.message,
            invoiceIds: invoiceEmailRequest.invoiceIds,
            debtorId: invoiceEmailRequest.debtorId,
            combine: invoiceEmailRequest.combine,
            correspondenceId: uuid(),
          }
          const {subject, interpolatedMessage} =  await prepareConsolidatedInvoiceEmail(invoiceEmail, shouldConsolidate);
          return { subject, message: interpolatedMessage };
        }
        const sendRequests = prepareSendRequestsForEachInvoiceAndDebtor({ invoiceSendRequests: [invoiceEmailRequest], preDraftMode, sentVia: sentViaTypes.EMAIL });

        const firmDetails = {
          staffDetails: getStaffEmailDetails(),
          firmName: getFirmName(),
          firmPhoneNumber: getPhoneNumber(),
          paymentProviderEnabledForInvoicePayment: isPaymentProviderEnabledForInvoicePayment(),
        };

        // Create the email request objects - returned object will be in form { valid: [], invalid: [] }
        const emailRequests = generateInvoiceEmailRequests({
          invoiceEmailRequests: await populateMissingInfo(sendRequests),
          firmDetails,
          isPreviewMode,
          quickPaymentsTotalAmount,
          preDraftMode,
        });

        // If no requests are valid, exit early
        if (emailRequests.valid.length === 0 && emailRequests.invalid.length !== 0) {
          return;
        }

        const [{ subject, message }] = emailRequests.valid;
        return { subject, message };

      } catch (err) {
        log.error(err);
        return 'error';
      }
    }

    /**
     * Get Interpolated Values - Replace communicate message tokens with values
     * Our default message ONLY has these placeholders:[DEBTOR_NAME],[MATTER_TITLE],[AMOUNT_DUE],[DATE_DUE]
     * Might need to support templates and other placeholders in future
     *
     * @param {object} params
     * @param {object} params.invoiceCommunicateRequest
     * @param {boolean} params.preDraftMode - Is the invoice in pre-draft mode (ie, never saved)
     * @param {boolean} [params.isPreviewMode=false] - True if we are showing the preview of the email in the UI
     * @param {number} [params.quickPaymentsTotalAmount=0] - Quick payments total, used to optimistically subtract from invoice/debtor owing amounts
     * @returns {Promise} Promise object containing `{ message }`
     */
    async function getInterpolatedValuesForCommunicate({ invoiceCommunicateRequest, preDraftMode, isPreviewMode = false, quickPaymentsTotalAmount = 0 }) {
        if (!invoiceCommunicateRequest || Array.isArray(invoiceCommunicateRequest)) {
          throw new Error('Invoice communicate preview currently only supports single invoice communicate requests');
        }

        try {
          const sendRequests = prepareSendRequestsForEachInvoiceAndDebtor({ invoiceSendRequests: [invoiceCommunicateRequest], preDraftMode, sentVia: sentViaTypes.COMMUNICATE });

          const firmDetails = {
            staffDetails: getStaffEmailDetails(),
            firmName: getFirmName(),
            firmPhoneNumber: getPhoneNumber(),
            paymentProviderEnabledForInvoicePayment: isPaymentProviderEnabledForInvoicePayment(),
          };

          // Create the communicate request objects - returned object will be in form { valid: [], invalid: [] }
          const communicateRequests = generateInvoiceCommunicateRequests({
            invoiceCommunicateRequests: await populateMissingInfo(sendRequests),
            firmDetails,
            isPreviewMode,
            quickPaymentsTotalAmount,
          });

          // If no requests are valid, exit early
          if (communicateRequests.valid.length === 0 && communicateRequests.invalid.length !== 0) {
            return;
          }

          const [{ message, linksMap }] = communicateRequests.valid;
          return { message, linksMap };

        } catch (err) {
          log.error(err);
          return 'error';
        }
    }

    async function getCustomerInfo(debtorId, matterId) {
      const contactInfoData = await sbSimpleContactMbService.getPeopleP(debtorId);
      const contactInfo = contactInfoData.filter(info => info.salutation);

      if (contactInfo && contactInfo.length) {
        return contactInfo;
      }

      const matterClientIds = sbMattersMbService.getById(matterId).clientCustomerIds;
      const matterClientsInfoData = await Promise.all(matterClientIds.map(sbSimpleContactMbService.getPeopleP));
      const matterClientsInfo = _.flatten(matterClientsInfoData).filter(info => info.salutation);

      return matterClientsInfo;
    }

    function sendCorrespondenceHistoryNotification(emailRequests, correspondenceIdsByInvoice) {
      sbCorrespondenceHistoryService.saveP(emailRequests, correspondenceIdsByInvoice).catch(err => {
        log.error(`Failed to update ${emailRequests.length} correspondence request(s)`, err);
      });
    }

  });
