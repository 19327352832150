import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import  { transactionType }from '@sb-billing/business-logic/transactions/entities/constants'

import { toggleShowSystemDateApi as toggleShowSystemDateAccountsApi } from 'web/redux/route/home-billing-accounts-transactions';
import { toggleShowSystemDateApi as toggleShowSystemDateMatterApi } from 'web/redux/route/home-billing-matter-transactions';
import { toggleShowSystemDateApi as toggleShowSystemDateContactApi } from 'web/redux/route/home-billing-contact-transactions';

angular.module('sb.billing.webapp').controller('SbAccountTransactionListController', function ($scope, $uibModal, $stateParams, sbLoggerService, sbLocalisationService, sbLinkService) {
  const that = this;
  const log = sbLoggerService.getLogger('SbAccountTransactionListController');
  const systemDateLabel = sbLocalisationService.t('capitalizeAllWords', { val: 'enteredDateLabel' });
  const systemDateColumn =  { class: 'date', name: 'systemDateColumn', label: systemDateLabel, sort: sortByDate };

  that.goToTxn = goToTxn;
  that.onClickLink = sbLinkService.onClickLink;
  that.t = sbLocalisationService.t;
  that.showSystemDateColumn = showSystemDateColumn;
  that.displaySystemDate = displaySystemDate;

  // Configuration for the underlying generic table.
  $scope.listConfig = {
    stateKey: 'account-transaction-list_' + ($scope.id),
    showFooter: true,
    columns: generateColumns(),
  };

  function generateColumns() {
    const columns = [];
    const transactionDateLabel = sbLocalisationService.t('capitalizeAllWords', { val: 'transactionDateLabel' });

    columns.push({class: 'date', label: transactionDateLabel, sort: sortByDate, defaultSort: true, defaultSortOrder: 'desc'});
    if (showSystemDateField()) {
      columns.push(systemDateColumn);
    }
    columns.push({class: 'description', label: 'Description'});
    columns.push({class: 'matter', label: 'Matter', show: !!$scope.showMatter});
    columns.push({class: 'contact', label: 'Contact', show: !!$scope.showContact});
    columns.push({class: 'amount', label: 'Debit'});
    columns.push({class: 'amount', label: 'Credit'});
    columns.push({class: 'balance', label: 'Balance'});
    columns.push({class: 'context-menu', label: ''});

    return columns;
  }

  that.$onInit = () => {
    // Set up the provider post processor.
    $scope.provider.setPostProcessor(postProcess);
  };

  function sortByDate(entries, sortOrder) {
    return _.sortByOrder(entries, ['effectiveDate', 'timestamp', 'processOrder'], [sortOrder, sortOrder, sortOrder]);
  }

  /**
   * Calculates summary data for the table footer.
   * Called by the bound list provider (function set via provider.setPostProcessor).
   * @param ledgerEntries
   */
  function postProcess(accountEntries) {
    // Footer summary.
    that.summary = {
      debitTotal: 0,
      creditTotal: 0,
      balanceTotal: 0
    };

    const sorted = _.sortByOrder(accountEntries, ['effectiveDate', 'timestamp'], ['asc', 'asc']);
    _.each(sorted, (txn, index) => {

      if (!isMatterContactBalanceType()) {
        switch (txn.type) {
          case transactionType.Deposit: // Deliberate fall-through to MatterAdjustmentReversal
          case transactionType.DepositReversal: // Deliberate fall-through to MatterAdjustmentReversaln
          case transactionType.MatterAdjustment: // Deliberate fall-through to MatterAdjustmentReversal
          case transactionType.MatterAdjustmentReversal:
            break;
          case transactionType.InvoicePayment: // Deliberate fall-through to InvoicePaymentReversal
          case transactionType.InvoicePaymentReversal: {
            // If the invoice payment is from a direct source, we need to show the contact.
            // This is only relevant in the operating transaction list in the case of overpayments in US, where a direct payment
            // of an invoice effectively creates a deposit with the overpaid amount.
            if (txn.source) {
              break;
            }
            // Deliberate fall-through to VendorPaymentReversal
          }
          // eslint-disable-next-line no-fallthrough
          case transactionType.BankFees: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.BankFeesReversal: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.WithholdingTax: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.WithholdingTaxReversal: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.Interest: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.InterestReversal: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.Transfer: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.VendorPayment: // Deliberate fall-through to VendorPaymentReversal
          case transactionType.VendorPaymentReversal:
            txn.contactId = undefined;
            break;
          default:
            throw new Error(`Encountered unknown transaction type: ${JSON.stringify(txn.type)}`);
        }
      }

      // Imbue each transaction with a running balance.
      // NOTE: process order makes sure that if we have any duplicate timestamps the display
      // order will match the processing order to keep the running balance in sync.
      txn.balance = txn.waived ? that.summary.balanceTotal += (txn.amount - txn.waived) : that.summary.balanceTotal += txn.amount;
      txn.processOrder = index;
      txn.bulkMatterDisplay = txn.isBulkDeposit ? `${txn.matterIds.length} matter(s)` : '';
      that.summary.creditTotal += (txn.amount >= 0 ? txn.amount : 0);
      that.summary.debitTotal += (txn.amount < 0 ? Math.abs(txn.amount) : 0);
    });

    log.info('Returning post processed account entries', accountEntries);
    return sorted;
  }

  function goToTxn(event, txn) {
    event.target.className !== 'link-button' && event.target.tagName !== 'A' && $uibModal.open({
      templateUrl: 'ng-components/bank-account-transaction-details/modal/bank-account-transaction-details-modal.html',
      controller: 'SbBankAccountTransactionDetailsModalController',
      size: 'lg',
      resolve: {
        transactionId: () => txn && txn.id || null,
        transaction: () => txn,
        matterId: () => $stateParams.matterId,
        contactId: () => $stateParams.contactId,
        showHidden: () => $scope.showHidden,
      },
      backdrop: 'static',
    });
  }

  function showSystemDateField() {
    let showSystemDate = toggleShowSystemDateAccountsApi.getState().showSystemDate;
    if ($scope.listType === 'matter') {
      showSystemDate = toggleShowSystemDateMatterApi.getState().showSystemDate;
    } else if ($scope.listType === 'contact') {
      showSystemDate = toggleShowSystemDateContactApi.getState().showSystemDate;
    }

    return showSystemDate;
  }

  function showSystemDateColumn() {
    const showSystemDate = showSystemDateField();

    // Add or remove the column only when the showSystemDate is toggled on
    addOrRemoveShowSystemDateColumn(showSystemDate);

    return showSystemDate;
  }

  function addOrRemoveShowSystemDateColumn(showSystemDate) {
    $scope.$applyAsync(() => {
      const isColumnAlreadyExisting = $scope.listConfig.columns.find(column => column.name === 'systemDateColumn') !== undefined;

      if (showSystemDate && !isColumnAlreadyExisting) {
        $scope.listConfig.columns.splice(1, 0, systemDateColumn);
      } else if (!showSystemDate && isColumnAlreadyExisting) {
        const index = $scope.listConfig.columns.findIndex(column => column.name === 'systemDateColumn');
        $scope.listConfig.columns.splice(index, 1);
      }
    });
  }

  function displaySystemDate(transactionType) {
    const showSystemDate = showSystemDateField();

    return (showSystemDate && transactionType && transactionType.toLowerCase() !== 'transfer');
  }
});
