import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { featureActive } from '@sb-itops/feature';
import { SlidingToggle, useTranslation } from '@sb-itops/react';
import { Table, Column } from '@sb-itops/react/table';

import { StaffColorBlock } from 'web/components/staff-color-picker/StaffColorBlock';
import { MessageModal } from 'web/components/message-modal';

import { AddStaffUser } from '../add-staff-user';
import Styles from './StaffAccounts.module.scss';

const StaffAccounts = React.memo(
  ({
    allUsersHaveSmokeballAccess,
    isFirmOwner,
    showFormerStaff,
    staffList,
    addStaffUser,
    showDebug,
    dataLoading,
    showCannotEditStaffMessageModal,
    // functions & callbacks
    closeCannotEditStaffMessageModal,
    openCannotEditStaffMessageModal,
    setAddStaffUser,
    setShowFormerStaff,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={Styles.staffAccounts}>
        {!allUsersHaveSmokeballAccess && (
          <p className={Styles.description}>
            {`Staff can be added to Smokeball to track work against them without requiring a ${t('licence')}. If a staff
        member needs to login to Smokeball, then they will require a user ${t('licence')}.`}
          </p>
        )}
        {allUsersHaveSmokeballAccess && (
          <p className={Styles.description}>
            {`Every new user will require a ${t('licence')} and be included in your subscription automatically. 
If a user no longer works for the firm, mark them as ‘former staff’ to keep the history but remove the ${t(
              'licence',
            )} fee from your subscription.`}
          </p>
        )}
        {isFirmOwner && addStaffUser ? (
          <AddStaffUser initialValues={addStaffUser} onClose={() => setAddStaffUser()} />
        ) : (
          <>
            <MessageModal
              title="Cannot Edit Staff"
              isVisible={showCannotEditStaffMessageModal}
              onConfirm={() => closeCannotEditStaffMessageModal()}
            >
              <p>Editing an existing user can only be performed by a Firm Owner account.</p>
            </MessageModal>
            <div className={Styles.addStaff}>
              <div
                className={classnames('link-button', !isFirmOwner && Styles.disabled)}
                title={!isFirmOwner ? 'Adding new users can only be performed by a Firm Owner account' : ''}
                onClick={isFirmOwner ? () => setAddStaffUser({}) : () => {}}
              >
                <i className="icon icon-add-3" />
                {allUsersHaveSmokeballAccess ? 'ADD USER' : 'ADD STAFF/USER'}
              </div>
              <div className={Styles.formerToggle}>
                <SlidingToggle
                  scope="former-staff-toggle"
                  onChange={(name, value) => setShowFormerStaff(value)}
                  selected={showFormerStaff}
                />
                <div onClick={() => setShowFormerStaff(!showFormerStaff)} className={Styles.formerLabel}>
                  Show former staff
                </div>
              </div>
            </div>
            <Table
              dataLoading={dataLoading}
              className="table-shadow"
              style={{
                height: `${Math.min(600, 30 + staffList.length * 38)}px`,
              }}
              onRowClick={
                isFirmOwner ? ({ rowData }) => setAddStaffUser(rowData) : () => openCannotEditStaffMessageModal()
              }
              list={staffList}
            >
              <Column
                dataKey="name"
                label="Name"
                flexGrow={showDebug ? 3 : 6}
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer={({ rowData }) =>
                  rowData.isFormerStaff ? (
                    <>
                      {rowData.name} <span className={Styles.formerBadge}>FORMER STAFF</span>
                    </>
                  ) : (
                    rowData.name
                  )
                }
              />
              {showDebug && <Column flexGrow={6} dataKey="id" label="Person ID" />}
              {showDebug && <Column flexGrow={6} dataKey="userId" label="User ID" />}
              <Column flexGrow={6} dataKey="email" label="Email" />
              {featureActive('NUCWEB-436') && (
                <Column
                  flexGrow={showDebug ? 1 : 1}
                  dataKey="Color"
                  label="Color"
                  classNameColumn={Styles.colorBox}
                  headerClassName={Styles.centeredHeading}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRenderer={({ rowData }) => (
                    <StaffColorBlock
                      style={{ marginLeft: '14px' }}
                      colorFill={rowData.colorFill}
                      colorStroke={rowData.colorStroke}
                    />
                  )}
                />
              )}
              <Column
                flexGrow={showDebug ? 1 : 3}
                dataKey="sbAccess"
                label={allUsersHaveSmokeballAccess ? `Paid ${t('licence')}` : 'Smokeball Access'}
                headerClassName={Styles.centeredHeading}
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer={({ rowData }) => rowData.sbAccess && <i className="billable-icon" />}
              />
              {featureActive('BB-13182') && (
                <Column
                  flexGrow={showDebug ? 1 : 2}
                  dataKey="twoFactorEnabled"
                  label="2FA Enabled"
                  headerClassName={Styles.centeredHeading}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRenderer={({ rowData }) => rowData.twoFactorEnabled && <i className="billable-icon" />}
                />
              )}
            </Table>
          </>
        )}
      </div>
    );
  },
);

StaffAccounts.displayName = 'StaffAccounts';

StaffAccounts.propTypes = {
  showFormerStaff: PropTypes.bool.isRequired,
  staffList: PropTypes.array.isRequired,
  addStaffUser: PropTypes.object,
  showDebug: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  allUsersHaveSmokeballAccess: PropTypes.bool.isRequired,
  isFirmOwner: PropTypes.bool.isRequired,
  showCannotEditStaffMessageModal: PropTypes.bool.isRequired,
  // functions & callbacks
  closeCannotEditStaffMessageModal: PropTypes.func.isRequired,
  openCannotEditStaffMessageModal: PropTypes.func.isRequired,
  setAddStaffUser: PropTypes.func.isRequired,
  setShowFormerStaff: PropTypes.func.isRequired,
};

StaffAccounts.defaultProps = {
  addStaffUser: undefined,
};

export default StaffAccounts;
