import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getIsFirmOwner, getProductTier, isUserBillBoost } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';
import { useSelector } from 'react-redux';
import StaffSettings from './StaffSettings';

const options = {
  ACCOUNTS: 'Accounts',
  PERMISSIONS: 'Permissions',
  TARGETS: 'Targets',
};

const hooks = () => ({
  useOptions: () => {
    const [selectedTab, setSelectedTab] = useState('');
    const isFirmOwner = getIsFirmOwner();

    const availableTabs = [];

    if (!featureActive('BB-14190')) {
      availableTabs.push(options.ACCOUNTS);
    }

    if (isFirmOwner && featureActive('BB-13941')) {
      availableTabs.push(options.PERMISSIONS);
    }

    if (isFirmOwner && featureActive('BB-14531') && ['SMK004', 'SMK002', 'SMK003'].includes(getProductTier())) {
      // SMK004: Boost, SMK002: Grow, SMK003: Prosper
      availableTabs.push(options.TARGETS);
    }

    if (!selectedTab && availableTabs.length > 0) {
      setSelectedTab(availableTabs[0]);
    }

    const isBillBoost = useSelector(isUserBillBoost);

    return {
      isBillBoost,
      availableTabs,
      selectedTab,
      onTabChange: setSelectedTab,
      options,
    };
  },
});

export const StaffSettingsContainer = composeHooks(hooks)(StaffSettings);

StaffSettingsContainer.displayName = 'StaffSettingsContainer';

export default withReduxProvider(StaffSettingsContainer);
