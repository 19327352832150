/**
 * Please note that this is the redux cache version of the LOD Add Contact
 * Inline Form found in:
 * monorepo/apps/smokeball-billing-web/src/components/add-contact-inline-form
 *
 * Please consider whether any changes should be reflected in both containers.
 */
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { getRegion } from '@sb-itops/region';
import * as messageDisplay from '@sb-itops/message-display';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { createEntityBankDetails } from '@sb-billing/redux/entity-bank-details';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { isUtbmsEnabled } from '@sb-billing/redux/utbms-settings';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { CreateEditContactSchema } from '../contact-create-edit/CreateEditContactSchema';
import { AddContactInlineForm } from './AddContactInlineForm';

function marshalEntityBankDetails({ contactId, formFields }) {
  const entityBankDetails = {
    id: contactId,
    accountName: formFields.accountName,
    bankName: formFields.bankName,
    bankBranchNumber: formFields.bankBranchNumber,
    accountNumber: formFields.accountNumber,
  };
  return entityBankDetails;
}

const createContactViaDispatcher = async ({ formFields }) => {
  if (![contactTypes.PERSON, contactTypes.COMPANY, contactTypes.TRUST].includes(formFields.contactType)) {
    throw new Error(`Unsupported contact type '${formFields.contactType}'`);
  }

  let type = 'Integration.CreateCompanyContact';
  if (formFields.contactType === contactTypes.PERSON) {
    type = 'Integration.CreatePersonContact';
  } else if (formFields.contactType === contactTypes.TRUST) {
    type = 'Integration.CreateTrustContact';
  }

  const message = { isUtbmsEnabled: isUtbmsEnabled(), contactFields: formFields };

  const dispatchResult = await dispatchCommand({ type, message });
  return dispatchResult.contact.id;
};

const create = async ({ formFields }) => {
  const newContactId = await createContactViaDispatcher({ formFields });

  const bankAccountFields = formFields.bankAccountFields;

  const entityBankDetails = marshalEntityBankDetails({ contactId: newContactId, formFields: bankAccountFields });

  if (
    (bankAccountFields.accountName ||
      bankAccountFields.bankName ||
      bankAccountFields.bankBranchNumber ||
      bankAccountFields.accountNumber) &&
    entityBankDetails.id
  ) {
    await createEntityBankDetails(entityBankDetails);
  }
  return newContactId;
};

const scope = 'add-contact-inline-form.2';

const hooks = () => ({
  useFormData: ({ onContactCreated }) => {
    const { selectors: formSelectors, operations: formOperations } = useScopedFeature(forms, scope);
    const { formSubmitting } = useSelector(formSelectors.getFormState);

    const dispatch = useDispatch();
    return {
      scope,
      isSubmitDisabled: formSubmitting,
      onSave: async (event) => {
        event.preventDefault();
        await dispatch(formOperations.validateSchema({ schema: CreateEditContactSchema(getRegion()) }));
        try {
          await dispatch(
            formOperations.submitFormWithValidationP({
              submitFnP: async (formFields) => {
                const contactId = await create({ formFields });

                onContactCreated(contactId);
              },
            }),
          );
        } catch (err) {
          messageDisplay.error('Failed to save contact');
        }
      },
    };
  },
});

export const AddContactInlineFormContainer = withReduxProvider(composeHooks(hooks)(AddContactInlineForm));

AddContactInlineFormContainer.displayName = 'AddContactInlineFormContainer';

AddContactInlineFormContainer.propTypes = {
  onContactCreated: PropTypes.func,
  messageDisplay: PropTypes.object,
  showBankDetailsTab: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

AddContactInlineFormContainer.defaultProps = {
  onContactCreated: () => {},
  messageDisplay: { success: () => {} },
  showBankDetailsTab: false,
};

export default AddContactInlineFormContainer;
