import * as React from 'react';
import { ContactOptions } from 'web/graphql/queries';
import { debounce } from '@sb-itops/nodash';
import { useSubscribedLazyQuery } from './use-subscribed-lazy-query';

/**
 * Provides the data and functionality required by (LOD) ContactTypeahead component
 *
 * @param {Object} params
 * @param {number} [params.limit]
 * @param {Array<string>} [params.rolesToInclude]
 * @param {Array<string>} [params.sortDirections]
 * @param {Array<string>} [params.sortFieldNames]
 * @returns {Object}
 */
export function useContactTypeaheadData({
  rolesToInclude,
  limit,
  sortDirections,
  sortFieldNames,
  excludeTypes,
  includeTypes,
} = {}) {
  const [getContactOptions, contactOptionsResult] = useSubscribedLazyQuery(ContactOptions, {
    context: { skipRequestBatching: true },
    variables: {
      limit: typeof limit === 'number' ? limit : 25,
      sort: {
        fieldNames: Array.isArray(sortFieldNames) ? sortFieldNames : ['displayName'],
        directions: Array.isArray(sortDirections) ? sortDirections : ['ASC'],
      },
      filter: {
        includeRoles: Array.isArray(rolesToInclude) ? rolesToInclude : undefined,
        excludeTypes,
        includeTypes,
      },
    },
  });

  const queryData = contactOptionsResult.data;
  const { contactSearch } = queryData || {};

  const contactOptions = React.useMemo(() => {
    const options = (contactSearch?.results || []).map((contact) => ({
      value: contact.id,
      label: contact.displayNameFull,
      data: contact,
    }));

    return options;
  }, [contactSearch?.results]);

  const getContactOptionsBySearchText = debounce(
    (searchText = '') => {
      getContactOptions({
        variables: {
          searchText,
          offset: 0,
        },
      });
    },
    300, // wait in milliseconds
    { leading: false },
  );

  const onFetchContactOptions = (searchText = '') => {
    // When the select component loses focus, it executes this
    // function with an empty string, returning different results.
    if (searchText.length > 2) {
      getContactOptionsBySearchText(searchText);
    }

    return searchText;
  };

  const hasNextPage = contactSearch?.pageInfo?.hasNextPage;

  const onFetchMoreContactOptions = async () => {
    if (!hasNextPage) {
      return undefined;
    }

    const fetchMoreResults = await contactOptionsResult.fetchMore({
      variables: {
        offset: queryData.contactSearch.results.length || 0,
      },
    });

    return fetchMoreResults;
  };

  return {
    contactOptions,
    contactOptionsDataLoading: contactOptionsResult.loading,
    contactOptionsHasMore: hasNextPage || false,
    onFetchContactOptions,
    onFetchMoreContactOptions,
  };
}
