import { createInvoiceReminderLoader } from './load-invoice-reminders-data';
import sortInvoiceReminders from './sort-invoice-reminders';
import { overdueByFilters, showFilters, filterInvoiceReminders } from './filter-invoice-reminders';
import { featureActive } from '@sb-itops/feature';
import { store } from '@sb-itops/redux';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import * as reminderFilters from 'web/redux/route/home-billing-bills-account-reminders';
import * as viewContactBillsFilters from 'web/redux/route/home-billing-view-contact-bills';
import {
  MARK_AS_SENT_MODAL_ID as MARK_AS_SENT_NEW_MODAL_ID,
} from 'web/components';
import {
  sentViaTypes,
  sentViaTypeLabels,
  operationTypes
} from '@sb-billing/business-logic/correspondence-history';

angular
  .module('sb.billing.webapp')
  .controller('sbAccountRemindersViewController', function(
    $state,
    $scope,
    sbAccountRemindersPreviewService,
    sbUnsavedChangesService,
    sbAccountRemindersService,
    sbAsyncOperationsService,
    sbInvoicingService,
    sbInvoiceTotalsService,
    sbContactsMbService,
    sbMatterTotalsService,
    sbLinkService,
  ) {
    const ctrl = this;
    const SAVED_DATA_KEY = 'sbAccountRemindersView';

    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.overdueByFilters = overdueByFilters;
    ctrl.showFilters = showFilters;
    ctrl.emailReminder = emailReminder;
    ctrl.bulkEmailReminders = bulkEmailReminders;
    ctrl.bulkEmailRemindersConfirmed = bulkEmailRemindersConfirmed;
    ctrl.sendBulkEmailReminders = sendBulkEmailReminders;
    ctrl.onMarkAsSentClicked = onMarkAsSentClicked;
    ctrl.onCloseMarkAsSentModal = onCloseMarkAsSentModal;
    ctrl.bulkMarkAsSentReminders = bulkMarkAsSentReminders;
    ctrl.combineInPdf = combineInPdf;
    ctrl.hasFacet = hasFacet;

    ctrl.onSortTriggered = onSortTriggered;
    ctrl.onExpandAllToggled = onExpandAllToggled;
    ctrl.onReminderExpanded = onReminderExpanded;
    ctrl.onReminderCollapsed = onReminderCollapsed;
    ctrl.onReminderSelectionChanged = onReminderSelectionChanged;
    ctrl.onSelectAllToggled = onSelectAllToggled;
    ctrl.onInvoiceSelectionChanged = onInvoiceSelectionChanged;
    ctrl.onPreviewInvoice = onPreviewInvoice;
    ctrl.onInvoiceCountClicked = onInvoiceCountClicked;
    ctrl.onCloseReminderModal = onCloseReminderModal;

    ctrl.isCombineInProgress = isCombineInProgress;
    ctrl.onViewLastReminder = onViewLastReminder;
    ctrl.onPreviewReminder = onPreviewReminder;
    ctrl.generatePreviewReminderBase64PdfP = generatePreviewReminderBase64PdfP;
    ctrl.generateLastReminderBase64PdfP = generateLastReminderBase64PdfP;

    ctrl.invoiceReminders = [];
    ctrl.totalNbInvoices = 0;
    ctrl.totalNbReminders = 0;

    ctrl.viewState = {
      nbInvoicesSelected: 0,
      nbRemindersExpanded: 0,
      expandedReminders: {},
      allRemindersExpanded: false,
      allRemindersSelected: false,
      selectedRows: {},
      sortBy: 'debtor',
      sortDirection: 'asc',
      emailReminderModalVisible: false,
      // label do not need always to be the same as value. the value need to be a unique identifier, if not provided the ToggleListFilter component will generate one for us.
      matterStatusFilterOptions: [
        { label: 'Open', value: 'Open' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Closed', value: 'Closed' },
      ],

      overdueByFilterOptions: overdueByFilters
        .filter(({ key }) => key !== 'all')
        .map(({ display, key }) => ({ label: display, value: key })),

      sendPreferencesFilterOptions: [
       // option values are strings because they are used as keys in the filterOptions object
        { value: String(sentViaTypes.EMAIL), label: sentViaTypeLabels.EMAIL },
        { value: String(sentViaTypes.MAIL), label: sentViaTypeLabels.MAIL },
        ...(hasFacet(facets.eBillingSendingPreference)
          ? [{ value: String(sentViaTypes.E_BILLING), label: sentViaTypeLabels.E_BILLING }]
          : []),
        { value: String(sentViaTypes.OTHER), label: sentViaTypeLabels.OTHER },
      ]
    };

    const { viewState } = sbUnsavedChangesService.loadMemory(SAVED_DATA_KEY) || {};
    ctrl.viewState = _.isEmpty(viewState) ? ctrl.viewState : viewState;

    const unsubscribeFromStore = store.subscribe(_.throttle(() => {
      updateInvoiceReminders();
    }, 100));

    $scope.$on('$destroy', () => {
      if (unsubscribeFromStore) {
        unsubscribeFromStore();
      }
    });

    const reloadInvoiceRemindersData = createInvoiceReminderLoader({
      sbInvoicingService,
      sbInvoiceTotalsService,
      sbContactsMbService,
      sbAccountRemindersService,
      sbMatterTotalsService,
    });

    updateInvoiceReminders();

    const unregister = $scope.$on('$destroy', () => {
      sbUnsavedChangesService.saveMemory(SAVED_DATA_KEY, {
        viewState: ctrl.viewState,
      });
      unregister();
    });

    // TODO: deregister.
    $scope.$on('smokeball-data-update-sbMattersMbService', updateInvoiceReminders);
    $scope.$on('smokeball-data-update-sbInvoicingService', updateInvoiceReminders);
    $scope.$on('smokeball-data-update-sbInvoiceTotalsService', updateInvoiceReminders);
    $scope.$on('smokeball-data-update-sbAccountRemindersService', updateInvoiceReminders);
    $scope.$on('smokeball-data-update-sbMatterTotalsService', updateInvoiceReminders);

    function updateInvoiceReminders() {
      const expandedReminders = ctrl.viewState.expandedReminders;

      // Get all data.
      let newInvoiceRemindersData = reloadInvoiceRemindersData();

      const filters = reminderFilters.selectors.getFilters(store.getState());

      // Filter.
      newInvoiceRemindersData = filterInvoiceReminders({
        filters: {
          ...filters,
          expandedReminders,
          matterStatuses: filters.matterFilterStatusSelected,
          matterTypes: filters.matterTypeFilterSelected,
          overdueBy: filters.overdueByFilterSelected,
          sendPreferences: filters.sendPreferencesSelected,
        },
        remindersAndInvoices: newInvoiceRemindersData,
      });

      // Sort.
      const { sortBy, sortDirection } = ctrl.viewState;

      $scope.$applyAsync(() => {
        ctrl.invoiceReminders = sortInvoiceReminders({
          remindersAndInvoices: newInvoiceRemindersData,
          sortBy,
          sortDirection,
        });
      }); // Trigger a digest cycle to update the reminder list. It wasn't triggered for some reason https://smokeball.atlassian.net/browse/BB-15027

      // Update the selected reminders to factor in that some reminders may no longer be present in the newly filtered data results.
      ctrl.viewState.nbRemindersExpanded = 0;
      ctrl.totalNbReminders = 0;

      ctrl.viewState.nbInvoicesSelected = 0;
      ctrl.totalNbInvoices = 0;

      ctrl.viewState.selectedRows = ctrl.invoiceReminders.reduce((selectedRows, reminderOrInvoice) => {
        if (reminderOrInvoice.type !== 'REMINDER') {
          return selectedRows;
        }

        const reminder = reminderOrInvoice;

        ++ctrl.totalNbReminders;
        if (ctrl.viewState.expandedReminders[reminder.matterDebtorId]) {
          ++ctrl.viewState.nbRemindersExpanded;
        }

        if (ctrl.viewState.selectedRows[reminder.matterDebtorId]) {
          selectedRows[reminder.matterDebtorId] = true;
        }

        // Match all of the currently unfiltered reminder's invoice selection states to the existing invoice selection states,
        ctrl.totalNbInvoices += reminderOrInvoice.invoices.length;
        reminderOrInvoice.invoices.forEach((invoice) => {
          const debtorInvoiceId = `${invoice.debtorId}|${invoice.invoiceId}`;
          if (ctrl.viewState.selectedRows[debtorInvoiceId]) {
            ++ctrl.viewState.nbInvoicesSelected;
            selectedRows[debtorInvoiceId] = true;
          }
        });

        return selectedRows;
      }, {});

      ctrl.viewState.allRemindersSelected =
        ctrl.totalNbInvoice > 0
          ? ctrl.viewState.nbInvoicesSelected === ctrl.totalNbInvoices
          : ctrl.viewState.allRemindersSelected;
      ctrl.viewState.allRemindersExpanded =
        ctrl.totalNbReminders > 0
          ? ctrl.viewState.nbRemindersExpanded === ctrl.totalNbReminders
          : ctrl.viewState.allRemindersExpanded;
    }

    function onSortTriggered({ sortBy, sortDirection }) {
      ctrl.viewState.sortBy = sortBy;
      ctrl.viewState.sortDirection = sortDirection;
      ctrl.invoiceReminders = sortInvoiceReminders({
        remindersAndInvoices: ctrl.invoiceReminders,
        sortBy,
        sortDirection,
      });
    }

    function onSelectAllToggled(allRemindersSelected) {
      ctrl.viewState.allRemindersSelected = !allRemindersSelected;

      if (!ctrl.viewState.allRemindersSelected) {
        ctrl.viewState.selectedRows = {};
        return;
      }

      // Loop through all reminders and invoices, setting each one as selected.
      ctrl.viewState.nbInvoicesSelected = 0;
      ctrl.viewState.selectedRows = ctrl.invoiceReminders.reduce((selectedRows, reminderOrInvoice) => {
        if (reminderOrInvoice.type === 'REMINDER') {
          selectedRows[reminderOrInvoice.matterDebtorId] = true;
          if (!ctrl.viewState.expandedReminders[reminderOrInvoice.matterDebtorId]) {
            reminderOrInvoice.invoices.forEach((invoice) => {
              ++ctrl.viewState.nbInvoicesSelected;
              selectedRows[`${invoice.debtorId}|${invoice.invoiceId}`] = true;
            });
          }
        } else {
          ++ctrl.viewState.nbInvoicesSelected;
          selectedRows[`${reminderOrInvoice.debtorId}|${reminderOrInvoice.invoiceId}`] = true;
        }

        return selectedRows;
      }, {});
    }

    function onReminderSelectionChanged({ reminder }) {
      const { selectedRows } = ctrl.viewState;
      const reminderSelected = !selectedRows[reminder.matterDebtorId];
      selectedRows[reminder.matterDebtorId] = reminderSelected;

      // When a reminder selection changes, all invoices under the reminder must match the reminder's selection
      reminder.invoices.forEach((invoice) => {
        const debtorInvoiceId = `${invoice.debtorId}|${invoice.invoiceId}`;
        const invoiceSelected = selectedRows[debtorInvoiceId];
        if (invoiceSelected !== reminderSelected) {
          selectedRows[debtorInvoiceId] = reminderSelected;
          ctrl.viewState.nbInvoicesSelected += reminderSelected ? 1 : -1;
        }
      });

      // When an invoice selection changes, it could cause a change in the overall selection state of the list, update accordingly.
      ctrl.viewState.allRemindersSelected = ctrl.viewState.nbInvoicesSelected === ctrl.totalNbInvoices;

      notifyRemindersUpdate();
    }

    function onInvoiceSelectionChanged({ invoice }) {
      const { selectedRows } = ctrl.viewState;
      const debtorInvoiceId = `${invoice.debtorId}|${invoice.invoiceId}`;
      selectedRows[debtorInvoiceId] = !selectedRows[debtorInvoiceId];
      ctrl.viewState.nbInvoicesSelected += selectedRows[debtorInvoiceId] ? 1 : -1;

      // When an invoice selection changes, it could cause a change in the overall selection state of the list, update accordingly.
      ctrl.viewState.allRemindersSelected = ctrl.viewState.nbInvoicesSelected === ctrl.totalNbInvoices;

      // When an invoice selection changes, it could cause a change in the overall selection state for the reminder, update accordingly.
      const reminder = invoice.reminder;
      selectedRows[reminder.matterDebtorId] = reminder.invoices.every((invoice) => selectedRows[`${invoice.debtorId}|${invoice.invoiceId}`]);
      notifyRemindersUpdate();
    }

    function onExpandAllToggled() {
      const isExpanded = !ctrl.viewState.allRemindersExpanded;

      ctrl.invoiceReminders.forEach((reminderOrInvoice) => {
        if (reminderOrInvoice.type === 'REMINDER') {
          ctrl.viewState.expandedReminders[reminderOrInvoice.matterDebtorId] = isExpanded;
        }
      });

      updateInvoiceReminders();
    }

    function onReminderExpanded(reminderToExpand) {
      ctrl.viewState.expandedReminders[reminderToExpand.matterDebtorId] = true;
      updateInvoiceReminders();
    }

    function onReminderCollapsed(reminderToCollapse) {
      ctrl.viewState.expandedReminders[reminderToCollapse.matterDebtorId] = false;
      updateInvoiceReminders();
    }

    // Used to trigger a react component update. No digest cycle in react, triggers re-render on ref change.
    function notifyRemindersUpdate() {
      ctrl.invoiceReminders = ctrl.invoiceReminders.slice(0);
    }

    function marshallActiveReminders() {
      const { selectedRows } = ctrl.viewState;

      // For action execution, a reminder is considered 'active' if at least one of its invoices is selected.
      return ctrl.invoiceReminders.reduce((activeReminders, reminderOrInvoice) => {
        if (reminderOrInvoice.type === 'INVOICE') {
          return activeReminders;
        }

        const reminder = reminderOrInvoice;
        const selectedInvoices = reminder.invoices.filter((invoice) => selectedRows[`${invoice.debtorId}|${invoice.invoiceId}`]);
        if (selectedInvoices.length > 0) {
          activeReminders.push({
            ...reminder,
            invoices: selectedInvoices,
          });
        }

        return activeReminders;
      }, []);
    }

    function marshallSelectedInvoices(reminder) {
      const { selectedRows } = ctrl.viewState;
      const marshalledReminder = {
        ...reminder,
        invoices: reminder.invoices.filter((invoice) => selectedRows[`${invoice.debtorId}|${invoice.invoiceId}`]),
      };

      if (marshalledReminder.invoices.length === 0) {
        marshalledReminder.invoices = reminder.invoices;
      }

      return marshalledReminder;
    }

    function emailReminder(reminder) {
      if (featureActive('BB-11448')) {
        ctrl.viewState.emailReminderModalVisible = true;
      }
      ctrl.remindersCount = (reminder && 1) || 0;
      const marshalledReminder = marshallSelectedInvoices(reminder);
      ctrl.confirmedReminders = [marshalledReminder];
      if (!featureActive('BB-11448')) {
        ctrl.confirmSendReminders();
      }
    }

    function onCloseReminderModal() {
      ctrl.viewState.emailReminderModalVisible = false;
    }

    function bulkEmailReminders() {
      const reminders = marshallActiveReminders();
      if (featureActive('BB-11448')) {
        ctrl.viewState.emailReminderModalVisible = true;
      }
      ctrl.remindersCount = (reminders && reminders.length) || 0;
      ctrl.confirmedReminders = reminders;
      if (!featureActive('BB-11448')) {
        ctrl.confirmSendReminders();
      }
    }

    function bulkEmailRemindersConfirmed() {
      sbAccountRemindersService.sendReminders(ctrl.confirmedReminders);
    }

    function sendBulkEmailReminders(invoiceReminderEmailRequest) {
      const remindersWithEmailRequests = ctrl.confirmedReminders.map((reminder) => {
        reminder.emailRequest = invoiceReminderEmailRequest;
        return reminder;
      });
      sbAccountRemindersService.sendReminders(remindersWithEmailRequests);
    }

    function bulkMarkAsSentReminders() {
      const reminders = marshallActiveReminders();
      ctrl.remindersCount = (reminders && reminders.length) || 0;
      ctrl.confirmedRemindersToMarkAsSent = reminders;
      ctrl.operationType = operationTypes.INVOICE_REMINDER;

      // LOD
      if (featureActive('BB-14422')) {
        setModalDialogVisible({
          modalId: MARK_AS_SENT_NEW_MODAL_ID,
          props: {
            selectedItems: ctrl.confirmedRemindersToMarkAsSent,
            scope: 'AccountRemindersView/mark-as-sent-modal',
            operationType: operationTypes.INVOICE_REMINDER,
          },
        });
      } else {
        // Legacy
        ctrl.viewState.showMarkAsSentModal = true;
      }
    }

    function onMarkAsSentClicked({ reminder, operationType }) {
      ctrl.remindersCount = (reminder && 1) || 0;
      const marshalledReminder = marshallSelectedInvoices(reminder);
      ctrl.confirmedRemindersToMarkAsSent = [marshalledReminder];
      ctrl.operationType = operationType;

      // LOD
      if (featureActive('BB-14422')) {
        setModalDialogVisible({
          modalId: MARK_AS_SENT_NEW_MODAL_ID,
          props: {
            selectedItems: ctrl.confirmedRemindersToMarkAsSent,
            scope: 'AccountRemindersView/mark-as-sent-modal',
            operationType: operationTypes.INVOICE_REMINDER,
          },
        });
      } else {
        // Legacy
        ctrl.viewState.showMarkAsSentModal = true;
      }
    }

    function onCloseMarkAsSentModal() {
      ctrl.confirmedRemindersToMarkAsSent = undefined;
      ctrl.viewState.showMarkAsSentModal = false;
    }

    function isCombineInProgress() {
      return (
        sbAsyncOperationsService.nbActiveOperations(
          sbAsyncOperationsService.supportedOperations.COMBINE_ACCOUNT_REMINDERS,
        ) > 0
      );
    }

    function combineInPdf() {
      sbAsyncOperationsService.startCombineAccountReminders(marshallActiveReminders());
    }

    function generatePreviewReminderBase64PdfP(requestData) {
      const marshalledReminder = marshallSelectedInvoices(requestData);
      return sbAccountRemindersPreviewService.getSinglePdfPreviewBase64P(marshalledReminder);
    }

    function generateLastReminderBase64PdfP(reminder) {
      return sbAccountRemindersPreviewService.getLastPdfPreviewBase64P(reminder.lastReminderId);
    }

    function onPreviewReminder(reminder) {
      ctrl.previewReminderViewPdf({ requestData: reminder });
    }

    function onViewLastReminder(reminder) {
      ctrl.previewLastReminderViewPdf({ requestData: reminder });
    }

    function onPreviewInvoice(invoice) {
      ctrl.showInvoicePreview(invoice);
    }

    function onInvoiceCountClicked(contactId) {
      store.dispatch(viewContactBillsFilters.actions.onFilterInvoiceStatus(['OVERDUE']));
      $state.go('home.billing.view-contact.bills', { contactId });
    }
  });
