import { useEffect, useState } from 'react';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withOnLoad, useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getLogger } from '@sb-itops/fe-logger';
import * as forms from '@sb-itops/redux/forms2';
import * as tabsFeature from '@sb-itops/redux/tabs';
import * as bankReconciliationsFeature from 'web/redux/route/home-billing-create-bank-reconciliation';
import {
  getByBankAccountId as getTransactionList,
  getById as getTransactionById,
} from '@sb-billing/redux/transactions';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { getByBankAccountId as getDepositSlipsList } from '@sb-billing/redux/deposit-slip';
import {
  generateTransactionList,
  generateDepositSlipsList,
} from '@sb-billing/business-logic/bank-reconciliation/transaction-list';
import { getById as getBankAccount } from '@sb-billing/redux/bank-account';
import {
  isAdjustmentsInvalid,
  isBalancesInvalid,
  isEndDateInvalid,
} from '@sb-billing/business-logic/bank-reconciliation/summary';
import { getById as getPaymentById } from '@sb-billing/redux/payments';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { dateToInteger, integerToDate } from '@sb-itops/date';
import PropTypes from 'prop-types';
import { consolidateTrustTransactions } from '@sb-billing/business-logic/transactions/services';
import {
  getLastCompleted as getLastCompletedBankRecon,
  getLatest as getLatestBankReconciliation,
  saveRecon,
  cancelRecon,
} from '@sb-billing/redux/bank-reconciliations';
import { getSettings as getBankReconSetup } from '@sb-billing/redux/bank-reconciliation-setup.2';
import * as messageDisplay from '@sb-itops/message-display';
import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { setModalDialogVisible, isModalVisible } from '@sb-itops/redux/modal-dialog';
import { useReduxActionOnce } from 'web/hooks';
import { getFirmTimezone } from '@sb-firm-management/redux/firm-management/firm';
import { getById as getBulkDepositById } from '@sb-billing/redux/bulk-deposit';
import { BillingCreateBankReconcilationRoute, BANK_RECON_TABS } from './BillingCreateBankReconcilationRoute';
import { marshalRecon } from './marshal-recon';
import { generateSummaryData } from './generate-summary-data';

const log = getLogger('BillingCreateBankReconcilationRouteContainer');

const finaliseReconModalId = 'billing-create-bank-reconciliation-finalise-confirm-modal';

const hooks = ({ onClickLink, closeCurrentTab, onTransactionClick, trustAccountId }) => ({
  useGetTransactions: () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tabsScope = `billing-create-bank-reconciliation-tabs-${trustAccountId}`;
    const formScope = `billing-create-bank-reconciliation-form-${trustAccountId}`;
    const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;

    // Default to system time zone while waiting for fetch
    const [ianaTimezone, setIanaTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
      const fetchTimezone = async () => {
        const { ianaTimeZone } = await getFirmTimezone();
        setIanaTimezone(ianaTimeZone);
      };
      fetchTimezone();
    }, []);

    const {
      selectors: { getSelectedTab },
      actions: { setSelectedTab, clearSelectedTab },
    } = useScopedFeature(tabsFeature, tabsScope);
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, formScope);
    const formState = useSelector(formSelectors.getFormState);
    const { formInitialised } = formState;
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { startDate, endDate, openingCashBookBalance } = formState?.fields;

    const {
      selectors: { getSelectedTransactionIds, getSelectedDepositSlipIds, getSelectedConsolidatedIds, getAdjustments },
      actions: { setAdjustments, toggleItems, clearState: clearBankReconState },
    } = useScopedFeature(bankReconciliationsFeature, bankRecScope);

    const [triggerValidate, setTriggerValidate] = useState(false);
    const [finalisingRecon, setFinalisingRecon] = useState(false);
    const [validatingFinalise, setValidatingFinalise] = useState(false);
    const [passedFinaliseValidation, setPassedFinaliseValidation] = useState(false);

    useEffect(() => {
      if (passedFinaliseValidation && !isModalVisible({ modalId: finaliseReconModalId })) {
        setModalDialogVisible({ modalId: finaliseReconModalId });
        setPassedFinaliseValidation(false);
      }
    }, [passedFinaliseValidation]);

    const selectedDepositSlipIds = useSelector(getSelectedDepositSlipIds);
    const selectedTransactionIds = useSelector(getSelectedTransactionIds);
    const selectedConsolidatedIds = useSelector(getSelectedConsolidatedIds);
    const adjustments = useSelector(getAdjustments);

    let bankReconciliation = getLatestBankReconciliation(trustAccountId);
    const lastCompletedBankRecon = getLastCompletedBankRecon(trustAccountId);
    const bankReconSetup = getBankReconSetup(trustAccountId);

    const getUnbankedTransactionIds = () => {
      // If there hasn't been any bank recons, get from setup, otherwise get from last completed bank recon
      // If latest bank recon was a draft it would not have a populated unbankedTransactionIds
      if (!lastCompletedBankRecon) {
        return bankReconSetup?.unbankedTransactionIds || [];
      }
      return lastCompletedBankRecon.unbankedTransactionIds;
    };

    const unbankedTransactionIds = getUnbankedTransactionIds();
    const unbankedTransactions = unbankedTransactionIds.map((txnId) => getTransactionById(txnId));

    // Should get/show all transactions in date range plus unbanked from last completed recon / setup
    let transactions = generateTransactionList({
      transactions: getTransactionList(trustAccountId),
      filters: {
        startDate: startDate?.value,
        endDate: endDate?.value,
        bankReconciliation,
        ianaTimezone,
      },
      filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
      unbankedTransactionIds,
    });

    // Need to get unbanked from child transaction since consolidateTrustTransactions doesn't have a concept of unbanked
    const consolidatedTransactions = consolidateTrustTransactions({
      transactions: transactions.filter((tx) => !tx.depositSlipId), // Don't want to show bulk deposits that are part of deposit slips
      getPaymentById,
      getInvoiceNumberById,
      getBulkDepositById,
      t,
      consolidatedOnly: true,
    }).map((consolidated) => ({
      ...consolidated,
      unbanked: consolidated.transactions[0].unbanked,
    }));

    const getConsolidatedTransactionById = (id) =>
      consolidatedTransactions.find((consolidated) => consolidated.id === id);

    // Used to make a reverse mapping from txn => parent consolidated
    const childTxnIdToParentConsolidatedIdMap = consolidatedTransactions.reduce((acc, consolidated) => {
      consolidated.transactionIds.forEach((txId) => {
        acc[txId] = consolidated;
      });
      return acc;
    }, {});

    // filtering out child consolidated transactions and deposit slips from list
    transactions = transactions.filter((tx) => !childTxnIdToParentConsolidatedIdMap[tx.id] && !tx.depositSlipId);

    const initFormAndRedux = () => {
      dispatch(clearSelectedTab());
      setTriggerValidate(true);
      if (!formInitialised) {
        const fieldValues = {
          adjustments: undefined,
          bankStatementBalance: undefined,
        };

        if (lastCompletedBankRecon && Array.isArray(lastCompletedBankRecon.adjustments)) {
          dispatch(
            setAdjustments({
              adjustments: lastCompletedBankRecon.adjustments
                .filter((adj) => !adj.isReconciled)
                .map((adj) => ({ ...adj, isNew: false })),
            }),
          );
        }

        /**
         * bankReconciliation = latestBankReconciliation
         * Possible load options:
         * 1) bankReconciliation = undefined, lastCompletedBankReconciliation = undefined => get info from setup
         * 2) bankReconciliation = draft, lastCompletedBankReconciliation = undefined / different id => get info from bankReconciliation (draft)
         * 3) bankReconciliation = lastCompletedBankReconciliation => no draft exists, set up new recon from lastCompleted
         */

        if (!bankReconciliation && !lastCompletedBankRecon) {
          if (!bankReconSetup.isRemoved) {
            fieldValues.startDate = bankReconSetup.reconciliationStartDate;
            fieldValues.openingCashBookBalance = bankReconSetup.initialLedgerBalance;
          }
        } else if (bankReconciliation && bankReconciliation.id !== lastCompletedBankRecon?.id) {
          fieldValues.endDate = bankReconciliation.endDate;
          fieldValues.startDate = bankReconciliation.startDate;
          fieldValues.openingCashBookBalance = bankReconciliation.openingLedgerBalance;
          fieldValues.bankStatementBalance = bankReconciliation.bankStatementBalance;

          // overwrite selected adjustments
          dispatch(
            setAdjustments({
              adjustments: Array.isArray(bankReconciliation.adjustments) ? bankReconciliation.adjustments : [],
            }),
          );

          let initialSelectedConsolidateds = [];
          const initialSelectedTxns = [];

          const selectedBulkDeposits = (bankReconciliation.bulkDepositIds || []).reduce((acc, id) => {
            const original = getConsolidatedTransactionById(id);
            acc.push(original);
            return acc;
          }, []);
          initialSelectedConsolidateds = initialSelectedConsolidateds.concat(selectedBulkDeposits);

          // select bulk deposit reversals
          const selectedBulkDepositReversals = (bankReconciliation.bulkDepositReversalIds || []).map((id) =>
            getConsolidatedTransactionById(`${id}_reversal`),
          );
          initialSelectedConsolidateds = initialSelectedConsolidateds.concat(selectedBulkDepositReversals);

          // Need to reconstruct consolidatedTxns since bankReconciliation.transactionIds contains both (consolidated children)
          bankReconciliation.transactionIds.forEach((txId) => {
            if (childTxnIdToParentConsolidatedIdMap[txId]) {
              initialSelectedConsolidateds.push(childTxnIdToParentConsolidatedIdMap[txId]);
              return;
            }
            const txn = transactions.find((tx) => tx.id === txId);
            // The txn must be valid to be included in the initial list.
            if (!txn) {
              return;
            }
            initialSelectedTxns.push(txn);
          });
          dispatch(
            toggleItems({
              depositSlipIds: bankReconciliation.depositSlipIds.filter((id) => depositSlips.find((ds) => ds.id === id)),
              transactionIds: initialSelectedTxns.map((tx) => tx.id),
              consolidatedIds: initialSelectedConsolidateds.map((consolidated) => consolidated.id),
            }),
          );
        } else if (bankReconciliation.id === lastCompletedBankRecon.id) {
          fieldValues.startDate = dateToInteger(
            moment(integerToDate(bankReconciliation.endDate)).add(1, 'day').toDate(),
          );
          fieldValues.openingCashBookBalance = bankReconciliation.closingCashBookBalance;
        }

        if (!fieldValues.endDate) {
          const endOfMonth = +moment(integerToDate(fieldValues.startDate)).endOf('month').format('YYYYMMDD');
          const yesterday = +moment().subtract(1, 'days').format('YYYYMMDD');
          fieldValues.endDate = endOfMonth > yesterday ? yesterday : endOfMonth;
        }

        dispatch(formActions.initialiseForm({ fieldValues }));
        setTriggerValidate(true);
      }
    };

    const depositSlips = generateDepositSlipsList({
      depositSlips: getDepositSlipsList(trustAccountId),
      filters: {
        startDate: startDate?.value,
        endDate: endDate?.value,
        bankReconciliation,
        ianaTimezone,
      },
      filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
      getTransactionById,
      unbankedTransactionIds,
    });

    const summaryData = generateSummaryData({
      openingCashBookBalance,
      transactions,
      depositSlips,
      unbankedTransactions,
      consolidatedTransactions,
      selectedDepositSlipIds,
      selectedTransactionIds,
      selectedConsolidatedIds,
      adjustments,
    });

    const validateFn = (formFieldValues) => {
      const { startDate: startDateAsInt, endDate: endDateAsInt, bankStatementBalance } = formFieldValues;
      const formErrors = {};

      if (
        isAdjustmentsInvalid({
          adjustments: summaryData.adjustments,
          startDateAsInt,
          endDateAsInt,
        })
      ) {
        formErrors.adjustments = 'New adjustments must fall in the reconciliation period';
      }
      if (
        isBalancesInvalid({
          summaryData,
          bankStatementBalance,
        })
      ) {
        formErrors.bankStatementBalance = 'Please ensure balances reconcile before continuing';
      }

      const endDateErrors = isEndDateInvalid({
        startDateAsInt,
        endDateAsInt,
        cannotReconcileAcrossMultipleMonth: hasFacet(facets.bankRecCannotReconcileAcrossMultipleMonths),
        isFinalise: validatingFinalise,
      });

      if (endDateErrors.reconcileDate) {
        formErrors.endDate = 'Please select end date';
      } else if (endDateErrors.sameMonthEndOfMonth && hasFacet(facets.bankRecCannotReconcileAcrossMultipleMonths)) {
        formErrors.endDate = 'You cannot reconcile across multiple months';
      } else if (endDateErrors.endDate && !endDateErrors.sameMonthEndOfMonth) {
        formErrors.endDate = 'You cannot reconcile to today or a date in the future';
      }

      /**
       * This is needed to show the confirmation modal when the final validation for reconciliation is successful
       * we don't want to show error message if the user is just saving as draft, we only want to show the error if they try to reconcile to today
       * because many clients reconcile every day and save as draft.
       * We stop reconciling on current date because of past escalations where another transaction was done after reconciliation
       * which then gets lost as reconciliations are done on the basis of a day, and the next reconciliation must start on the next day
       * after the last one
       */
      if (validatingFinalise && Object.keys(formErrors).length === 0) {
        setPassedFinaliseValidation(true);
      }
      setValidatingFinalise(false);
      return formErrors;
    };

    if (triggerValidate) {
      // after loading field values, it takes another render to generate the required summaryData before we can validate it
      dispatch(formOperations.validateForm({ validateFn }));
      setTriggerValidate(false);
    }

    // Clear the form if the tab is closed, otherwise old values will stick around if it is opened again
    useReduxActionOnce('smokeball-tab-closed', ([tab]) => {
      if (tab.type === 'bank-reconciliation') {
        // User will see flicker if form cleared in current tick as react will re-render faster than angular can close the tab
        setTimeout(() => {
          dispatch(formActions.clearForm());
          dispatch(clearBankReconState());
          // Clear the tab selection on exit
          dispatch(clearSelectedTab());
        }, 0);
      }
    });

    return {
      trustAccountId,
      trustAccountName: getBankAccount(trustAccountId)?.accountName,
      formScope,
      selectedTab: useSelector(getSelectedTab) || BANK_RECON_TABS.reconciliation,
      transactions,
      consolidatedTransactions,
      depositSlips,
      bankReconciliation,
      summaryData,
      finalisingRecon,
      ianaTimezone,
      getConsolidatedTransactionById,
      validateFn,
      setTriggerValidate,
      onTransactionClick,
      onLoad: () => {
        initFormAndRedux();
      },
      onChangeSelectedTab: (tabId) => {
        dispatch(setSelectedTab({ tab: tabId }));
      },
      onCancel: async () => {
        bankReconciliation = getLatestBankReconciliation(trustAccountId);
        if (bankReconciliation && bankReconciliation.status === 'InProgress') {
          try {
            await cancelRecon({ reconciliationId: bankReconciliation.id });
            messageDisplay.warn('Reconciliation cancelled');
          } catch (err) {
            messageDisplay.error('Failed to cancel reconciliation');
            log.error('Failed to cancel reconciliation', err);
          }
        }

        closeCurrentTab();
        // Form state is reset after 'smokeball-tab-closed' received
        onClickLink({ type: 'bankReconciliationHomeSpecificAccount', id: trustAccountId });
      },
      onSave: async () => {
        try {
          await dispatch(
            formOperations.submitFormP({
              submitFnP: async (formData) => {
                const data = marshalRecon({
                  startDate: formData.startDate,
                  endDate: formData.endDate,
                  bankStatementBalance: formData.bankStatementBalance,
                  openingLedgerBalance: formData.openingCashBookBalance,
                  trustAccountId,
                  adjustments,
                  consolidatedTransactions,
                  selectedDepositSlipIds,
                  selectedTransactionIds,
                  selectedConsolidatedIds,
                  existingDraftBankReconciliation:
                    bankReconciliation && bankReconciliation.status === 'InProgress' ? bankReconciliation : undefined,
                });

                data.finalizeReconciliation = false;
                await saveRecon({ data, t });
              },
            }),
          );
          onClickLink({ type: 'bankReconciliationHomeSpecificAccount', id: trustAccountId });

          messageDisplay.success('Draft saved successfully.');
          closeCurrentTab();
          // Form state is reset after 'smokeball-tab-closed' received
        } catch (err) {
          messageDisplay.error('Failed to save the draft');
        }
      },
      onValidateFinalise: () => {
        setValidatingFinalise(true);
        setTriggerValidate(true);
      },
      onRecon: async ({ runEndOfMonthReporting }) => {
        try {
          setFinalisingRecon(true);
          let opdateId = '';
          await dispatch(
            formOperations.submitFormP({
              submitFnP: async (formData) => {
                const data = marshalRecon({
                  startDate: formData.startDate,
                  endDate: formData.endDate,
                  bankStatementBalance: formData.bankStatementBalance,
                  openingLedgerBalance: formData.openingCashBookBalance,
                  trustAccountId,
                  adjustments,
                  consolidatedTransactions,
                  selectedDepositSlipIds,
                  selectedTransactionIds,
                  selectedConsolidatedIds,
                  existingDraftBankReconciliation:
                    bankReconciliation && bankReconciliation.status === 'InProgress' ? bankReconciliation : undefined,
                });
                data.finalizeReconciliation = true;
                data.reconciledTimestamp = new Date().toISOString();
                data.generateEndOfMonthReport = !!runEndOfMonthReporting;
                opdateId = data.id;
                await saveRecon({ data, generateEndOfMonthReport: runEndOfMonthReporting, t });
              },
            }),
          );
          setFinalisingRecon(false);

          closeCurrentTab();
          // Form state is reset after 'smokeball-tab-closed' received
          onClickLink({
            type: 'bankReconciliation',
            id: { bankReconciliationId: opdateId, waitingForNotification: true },
          });
          messageDisplay.success(`Reconciliation ${t('finalised')} successfully.`);
        } catch (err) {
          messageDisplay.error(`Failed to ${t('finalise')} reconciliation.`);
        }
      },
    };
  },
});

export const BillingCreateBankReconcilationRouteContainer = withReduxProvider(
  composeHooks(hooks)(withOnLoad(BillingCreateBankReconcilationRoute)),
);

BillingCreateBankReconcilationRouteContainer.displayName = 'BillingCreateBankReconcilationRouteContainer';

BillingCreateBankReconcilationRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  trustAccountId: PropTypes.string,
  onTransactionClick: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
};

BillingCreateBankReconcilationRouteContainer.defaultProps = {
  trustAccountId: undefined,
};
