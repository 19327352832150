import { getFirmDetails } from '@sb-firm-management/redux/firm-management';

export const clientSdkGetFirm = async () => {
  const details = getFirmDetails() || {};
  return {
    id: details.accountId,
    name: details.firmName || '',
    acn: details.acn || '',
    abn: details.abn || '',
  };
};
