/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useState, useRef } from 'react';
import { Table, utils } from '@sb-itops/react/table';
import {
  Button,
  RealLoadingBar,
  Checkbox,
  InputWithAddon,
  Column,
  SlidingToggle,
  TabListV2,
  Tooltip,
  Spinner,
} from '@sb-itops/react';
import { fetchGetP, fetchDeleteP, fetchPatchP } from '@sb-itops/redux/fetch';
import { ContextMenu } from '@sb-itops/react/context-menu';
import classNames from 'classnames';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { downloadZip, InputWithMeta } from 'client-zip';
import { Staff, Documents, Folder, Expanded, File, Document, DocumentResults } from 'types';
import { FileDetailQuery, FileDetailDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';
import { featureActive } from '@sb-itops/feature';
import { PromoCard, SendFilesViaCommunicateFormModal } from 'web/react-redux';
import { useMetric, sendMetric } from 'web/services/metrics';
import { setFileManagerInstalled } from 'web/redux/features/application-state/actions';
import { useDispatch } from 'react-redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INFOTRACK_MODAL_ID } from 'web/containers';
import smokeballLogoSrc from 'web/assets/smokeball-icon.svg';
import Styles from './DocumentsTab.module.scss';
import { IconSelector } from './icon-selector';
import { FileTree } from './file-tree';
import { ReplaceModal, DeleteModal, MoveModal, SingleInputModal, ViewFileModal, CreateLetterModal } from './modals';
import { DocumentList } from './DocumentList';
import { FileManagerDownloadModal } from './modals/FileManagerDownloadModal';
import {
  Arrows,
  Bookmark,
  BookmarkFilled,
  Download,
  Ellipsis,
  FolderIcon,
  Garbage,
  Share,
  Text,
  ArrowsSpin,
  OutlookLogo,
} from '../icons';

const { timestampLocalisedRenderer } = utils;
interface IDocumentsTabProps {
  getPersonByUserId: (id: string) => Staff;
  documents: Documents;
  loading: boolean;
  isFileManagerInstalled: boolean;
  searchFilter: string;
  setSearchFilter: (searchTerm: string) => void;
  searchResults: DocumentResults[] | undefined;
  matterName: string;
  error: string;
  tab: string;
  setTab: (tab: string) => void;
  selectedPath: string;
  setSelectedPath: (payload: string) => void;
  sortDirection: 'asc' | 'desc';
  sortBy: string;
  setSort: (payload: { sortBy: string; sortDirection: 'asc' | 'desc' }) => void;
  matterId: string;
  createNewFolder: (payload: string) => Promise<void>;
  selectedFolder: Folder;
  expanded: Expanded;
  setExpanded: (payload: Expanded) => void;
  uploadFiles: (payload: FileList | globalThis.File[] | null) => Promise<void>;
  replaceFile: (payload: FileList | globalThis.File[] | null, file: File) => Promise<void>;
  showRenameModal: Document | false;
  setShowRenameModal: (payload: Document | false) => void;
  showMoveModal: Document[] | false;
  setShowMoveModal: (payload: Document[] | false) => void;
  showDeleteModal: Document[] | false;
  setShowDeleteModal: (payload: Document[] | false) => void;
  showReplaceModal: { onReplace: () => void; onRename: () => void } | false;
  setShowReplaceModal: (payload: { onReplace: () => void; onRename: () => void } | false) => void;
  showNewFolderModal: boolean;
  setShowNewFolderModal: (payload: boolean) => void;
  showShareModal: { documents: Document[]; isLiving: boolean } | false;
  setShowShareModal: (payload: { documents: Document[]; isLiving: boolean } | false) => void;
  onDownload: (data: { rowData: File }) => void;
  showFileDetailModal: File | false;
  setShowFileDetailModal: (payload: File | false) => void;
  setDocuments: (payload: Documents) => void;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  getFileDetail: Function;
  showCreateLetterModal: boolean;
  setShowCreateLetterModal: (payload: boolean) => void;
  showDownloadFileManagerModal: Document | false;
  setShowDownloadFileManagerModal: (payload: Document | false) => void;
  oneDriveIsUnauthorized: boolean;
  isOpeningDocumentOnWeb: boolean;
  openDocumentOnWeb: (fileId: string) => Promise<void>;
  onClickLink: (options: any) => void;
}

export const tabList = [
  {
    label: 'All',
    id: 'All',
  },
  {
    label: 'Documents',
    id: 'Documents',
  },
  {
    label: 'Emails',
    id: 'Emails',
  },
];

// handle drag events
const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

const isConflict = (targetFolder: Folder, files: FileList | globalThis.File[] | { name: string }[] | null) => {
  if (
    Object.values(targetFolder.contents).some((i) =>
      Array.from(files || []).some((f) => f.name === `${i.data.name}${(i as File).data.fileExtension}`),
    )
  ) {
    return true;
  }
  return false;
};

const renameFile = (selectedFolder: Folder, originalFile: globalThis.File, i = 0): globalThis.File => {
  const fileNameSplit = originalFile.name.split('.');
  const extension = fileNameSplit.pop();
  const newName = i === 0 ? originalFile.name : `${fileNameSplit.join('.')} (${i}).${extension}`;
  if (isConflict(selectedFolder, [{ name: newName }])) {
    return renameFile(selectedFolder, originalFile, i + 1);
  }
  const newFile = new window.File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
  return newFile;
};

const log = getLogger('matter_documents');

const getFolderInDocs = (documents: Documents, path: string) =>
  path
    .split('.')
    .reduce((prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev), documents as Folder);

const CheckboxRenderer =
  (selectedItems, onSelect) =>
  ({ rowData }) =>
    rowData.type === 'folder' ? null : (
      <div className={Styles.alignCenter}>
        <Checkbox
          className={Styles.checkbox}
          onChange={() => onSelect({ rowData, event: {} }, true)}
          checked={!!selectedItems[rowData.data.id]}
        />
      </div>
    );

const IconRenderer = ({ rowData }) => (
  <div className={Styles.alignCenter}>
    {rowData.type === 'folder' ? <FolderIcon /> : <IconSelector isSolid extension={rowData.data.fileExtension} />}
  </div>
);

const isLoading = (file: File) => file?.loading !== undefined || file?.data?.isUploaded === false;

const ActionsRenderer = ({
  onRename,
  onMove,
  onDelete,
  onReplace,
  onShare,
  onLaunchInfoTrack,
  rowData,
  onClickLink,
  matterId,
}) =>
  !isLoading(rowData) && (
    <ContextMenu
      position="right-start"
      className={Styles.contextMenu}
      arrow={false}
      // eslint-disable-next-line react/no-unstable-nested-components
      body={({ close }) => (
        <div className={classNames(Styles.createBillContextMenuBody, 'list-group')}>
          {featureActive('NUCWEB-749') && (
            <Tooltip title="Ask Archie about this Document" delay={100} interactive={false}>
              <button
                type="button"
                className="list-group-item"
                onClick={(e) => {
                  e.stopPropagation();

                  sendMetric('DocumentsOpenArchie');

                  // Also accepts folderIds param
                  const path = `?matterId=${matterId}&fileIds=${encodeURIComponent(rowData.data.id)}`;

                  onClickLink({ type: 'matterArchie', id: matterId, params: [path] });

                  close();
                }}
              >
                Ask Archie
              </button>
            </Tooltip>
          )}
          {featureActive('LOOP-561') && (
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                onShare(false);
                close();
              }}
            >
              Send a copy
            </button>
          )}
          {rowData.type === 'file' && (
            <button
              type="button"
              className="list-group-item"
              onClick={() => {
                onReplace();
                close();
              }}
            >
              Replace File
            </button>
          )}
          <button
            type="button"
            className="list-group-item"
            onClick={() => {
              onRename();
              close();
            }}
          >
            Rename
          </button>
          <button
            type="button"
            className="list-group-item"
            onClick={() => {
              onMove();
              close();
            }}
          >
            Move
          </button>
          {featureActive('IN-1799') && (
            <>
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onLaunchInfoTrack('InfoTrack Search');
                  close();
                }}
              >
                InfoTrack Search
              </button>
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onLaunchInfoTrack('InfoTrack File & Serve');
                  close();
                }}
              >
                InfoTrack File &amp; Serve
              </button>
              <button
                type="button"
                className="list-group-item"
                onClick={() => {
                  onLaunchInfoTrack('Send for E-Signature');
                  close();
                }}
              >
                Send for E-Signature
              </button>
            </>
          )}
          <button
            type="button"
            className={classNames('list-group-item', Styles.redText)}
            onClick={() => {
              onDelete();
              close();
            }}
          >
            Delete
          </button>
        </div>
      )}
    >
      <div className={Styles.actionMenuItem}>
        <Ellipsis />
      </div>
    </ContextMenu>
  );

const DateCellRenderer = ({ rowData, dataKey }) => (
  <div>{timestampLocalisedRenderer({ cellData: rowData.data[dataKey] })}</div>
);

const DateTimeCellRenderer = ({ rowData, dataKey }) => (
  <div>{timestampLocalisedRenderer({ cellData: rowData.data[dataKey], format: 'hhmm a DD/MM/YYYY' })}</div>
);
const DataRenderer = ({ rowData, dataKey }) => <div>{rowData.data[dataKey]}</div>;

export const DocumentsTabV2 = (props: IDocumentsTabProps) => {
  const {
    onClickLink,
    documents,
    loading,
    sortDirection,
    sortBy,
    setSort,
    matterId,
    createNewFolder,
    selectedFolder,
    selectedPath,
    setSelectedPath,
    expanded,
    setExpanded,
    getPersonByUserId,
    uploadFiles,
    showRenameModal,
    setShowRenameModal,
    showMoveModal,
    setShowMoveModal,
    showDeleteModal,
    setShowDeleteModal,
    showReplaceModal,
    setShowReplaceModal,
    showNewFolderModal,
    setShowNewFolderModal,
    showFileDetailModal,
    setShowFileDetailModal,
    showShareModal,
    setShowShareModal,
    setDocuments,
    replaceFile,
    matterName,
    getFileDetail,
    fileDetailResult,
    onDownload,
    searchFilter,
    setSearchFilter,
    searchResults,
    showCreateLetterModal,
    setShowCreateLetterModal,
    showDownloadFileManagerModal,
    setShowDownloadFileManagerModal,
    isFileManagerInstalled,
    tab,
    setTab,
    oneDriveIsUnauthorized,
    isOpeningDocumentOnWeb,
    openDocumentOnWeb,
  } = props;

  const dispatch = useDispatch();

  const lineItems = Object.values(selectedFolder.contents);

  useMetric('NavMatterDocumentsV2');

  const [selection, setSelection] = useState({});
  const [showFavourites, setShowFavourites] = useState(false);
  const [showDeleted] = useState(false);
  // const [showDeleted, setShowDeleted] = useState(false);
  const [documentToReplace, setDocumentToReplace] = useState<File>();
  const [updateLoading, setUpdateLoading] = useState(false);

  const inputMultiRef = useRef<HTMLInputElement>(null);
  const inputSingleRef = useRef<HTMLInputElement>(null);

  const selectedItems = Object.entries(selection)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => value)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([key, value]) => key);

  const moveFromTo = async (doc: Document, folderPath: string) => {
    try {
      if (isLoading(doc as File)) {
        messageDisplay.error('Please wait until file has finished uploading');
        return;
      }
      if ((doc as File).folderPath === folderPath) {
        return;
      }
      if (folderPath.includes((doc as File).data.id)) {
        messageDisplay.error('A folder cannot be moved to one of its children');
        return;
      }
      const newDocs = { ...documents };
      const oldSelectedFolder = getFolderInDocs(newDocs, doc.folderPath);

      const folderId = folderPath.split('.')[folderPath.split('.').length - 1];
      setUpdateLoading(true);

      const newSelectedFolder = getFolderInDocs(newDocs, folderPath);
      newSelectedFolder.contents[doc.data.id] = oldSelectedFolder.contents[doc.data.id];
      newSelectedFolder.contents[doc.data.id].folderPath = newSelectedFolder.folderPath
        ? `${newSelectedFolder.folderPath}.${newSelectedFolder.data.id}`
        : newSelectedFolder.data?.id || '';
      delete oldSelectedFolder.contents[doc.data.id];
      setDocuments(newDocs);
      if (doc.type === 'folder') {
        await fetchPatchP({
          path: `/matter-management/matter/folder/:accountId/${matterId}/${doc.data.id}`,
          fetchOptions: {
            body: JSON.stringify({
              parentFolderId: folderId || null,
            }),
          },
        });
      } else {
        await fetchPatchP({
          path: `/matter-management/matter/file/:accountId/${matterId}/${doc.data.id}`,
          fetchOptions: {
            body: JSON.stringify({
              folderId: folderId || null,
            }),
          },
        });
      }
      setUpdateLoading(false);
      setShowMoveModal(false);
      setSelection({});
    } catch (e) {
      messageDisplay.error('Something went wrong moving your document. Please try again later');
      log.error(e);
    }
  };

  const onSelect = async ({ rowData, event }, multi) => {
    // We are only interested in the selected items
    const selectedKeys = Object.keys(selection).filter((key) => selection[key]);
    if (rowData.type === 'folder') {
      setSelectedPath(selectedPath ? `${selectedPath}.${rowData.data.id}` : rowData.data.id);
    } else if (event.shiftKey && selectedKeys.length <= 1) {
      // Select all items between current selection and clicked item
      const initialKey = selectedKeys.length ? selectedKeys[0] : null;
      const newKey = rowData.data.id;
      let foundKeys = 0;
      setSelection(
        lineItems.reduce((acc, curr, i) => {
          if (initialKey === curr.data.id) {
            // Dont de-select the initial selection
            foundKeys += 1;
            return acc;
          }
          // Select current selection or if none are selected, select from the first item
          if (newKey === curr.data.id || (!initialKey && i === 0)) {
            foundKeys += 1;
            return { ...acc, [curr.data.id]: !selection[curr.data.id] };
          }
          if (foundKeys === 1) {
            return { ...acc, [curr.data.id]: !selection[curr.data.id] };
          }
          return acc;
        }, selection),
      );
    } else if (event.ctrlKey || event.shiftKey || multi) {
      // Shift key acts like ctrl if more than 1 item is selected
      setSelection({ ...selection, [rowData.data.id]: !selection[rowData.data.id] });
    } else {
      setSelection({ [rowData.data.id]: !selection[rowData.data.id] });
    }
  };

  const ActionsMenu = useCallback(
    ({ item }: { item: Document }) => (
      <div className={classNames(Styles.actionMenu)}>
        <Tooltip title="Rename" delay={100} interactive={false}>
          <div
            className={Styles.actionMenuItem}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (isLoading(item as File)) {
                messageDisplay.error('Please wait until file has finished uploading');
                return;
              }
              setShowRenameModal(item);
            }}
          >
            <Text />
          </div>
        </Tooltip>
        <Tooltip title="Move" delay={100} interactive={false}>
          <div
            className={Styles.actionMenuItem}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (isLoading(item as File)) {
                messageDisplay.error('Please wait until file has finished uploading');
                return;
              }
              setShowMoveModal([item]);
            }}
          >
            <Arrows />
          </div>
        </Tooltip>
        <Tooltip title="Delete" delay={100} interactive={false}>
          <div
            className={Styles.actionMenuItem}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (isLoading(item as File)) {
                messageDisplay.error('Please wait until file has finished uploading');
                return;
              }
              setShowDeleteModal([item]);
            }}
          >
            <Garbage />
          </div>
        </Tooltip>
      </div>
    ),
    [setShowRenameModal, setShowMoveModal, setShowDeleteModal],
  );

  const onFavourite = useCallback(
    async (rowData) => {
      setUpdateLoading(true);
      try {
        const isFavorite = !rowData.data.isFavorite;
        const newDocs = { ...documents };
        const newSelectedFolder = getFolderInDocs(newDocs, rowData.folderPath);
        (newSelectedFolder.contents[rowData.data.id] as File).data.isFavorite = isFavorite;
        setDocuments(newDocs);
        await fetchPatchP({
          path: `/matter-management/matter/file/:accountId/${matterId}/${rowData.data.id}`,
          fetchOptions: {
            body: JSON.stringify({
              isFavorite,
            }),
          },
        });
      } catch (err) {
        messageDisplay.error('Error saving changes, please try again later');
        // reverse opdate
        setDocuments(documents);
      }
      setUpdateLoading(false);
    },
    [documents],
  );

  const TableActionsMenu = useCallback(
    ({ rowData }: { rowData: File }) => {
      const isFileEditEnabled =
        !['.eml', '.msg'].includes(rowData.data.fileExtension) && Object.keys(selectedItems).length < 2;

      const editOnWebAvailable =
        featureActive('NV-5392') &&
        rowData.type === 'file' &&
        rowData.data?.fileExtension === '.docx' &&
        oneDriveIsUnauthorized === false;

      const showOpenDesktop = featureActive('NUCWEB-728') && rowData.type === 'file' && isFileEditEnabled;

      return (
        <div className={classNames(Styles.actionMenu)}>
          {rowData.type === 'file' && (
            <div className={Styles.actionMenuItem}>
              <ContextMenu
                position="left-start"
                className={Styles.contextMenu}
                arrow={false}
                // eslint-disable-next-line react/no-unstable-nested-components
                body={({ close }) => (
                  <div className={classNames(Styles.createBillContextMenuBody, 'list-group')}>
                    <button
                      type="button"
                      className="list-group-item"
                      onClick={() => {
                        setShowFileDetailModal(rowData);
                        close();
                      }}
                    >
                      Preview
                    </button>
                    {showOpenDesktop && (
                      <button
                        type="button"
                        className="list-group-item"
                        onClick={() => {
                          if (!isFileEditEnabled) {
                            return;
                          }

                          sendMetric(editOnWebAvailable ? 'DocumentsOpenDesktop' : 'DocumentsOpen');

                          // smokeballfilemanager://DownloadFile?matterId=MATTERID&fileId=FILEID&fileName=FILENAME.EXTENSION
                          document.location = `smokeballfilemanager://DownloadFile?matterId=${encodeURIComponent(
                            matterId,
                          )}&fileId=${encodeURIComponent(rowData.data.id)}&fileName=${encodeURIComponent(
                            `${rowData.data.name || ''}${rowData.data.fileExtension || ''}`,
                          )}`;
                          setTimeout(() => {
                            if (isFileManagerInstalled) {
                              return;
                            }
                            if (document.hasFocus()) {
                              setShowDownloadFileManagerModal(rowData);
                            } else {
                              dispatch(setFileManagerInstalled({ isFileManagerInstalled: true }));
                            }
                          }, 100);
                          close();
                        }}
                      >
                        {editOnWebAvailable ? 'Open in Desktop' : 'Open'}
                      </button>
                    )}
                    {editOnWebAvailable && (
                      <button
                        type="button"
                        className={classNames(isFileEditEnabled ? '' : Styles.disabledStyles, 'list-group-item')}
                        disabled={isOpeningDocumentOnWeb}
                        onClick={(e) => {
                          e.stopPropagation();

                          sendMetric('DocumentsOpenWeb');

                          openDocumentOnWeb(rowData.data.id);

                          close();
                        }}
                      >
                        Open in Web
                      </button>
                    )}
                  </div>
                )}
              >
                <Tooltip title="Open" delay={100} interactive={false}>
                  <div
                    onClick={(e) => {
                      if (!editOnWebAvailable && !showOpenDesktop) {
                        setShowFileDetailModal(rowData);
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    className={Styles.actionMenuItem}
                  >
                    <IconSelector extension={rowData.data.fileExtension} />
                  </div>
                </Tooltip>
              </ContextMenu>
            </div>
          )}

          {rowData.type === 'file' && (
            <Tooltip title="Download" delay={100} interactive={false}>
              <div
                className={Styles.actionMenuItem}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onDownload({ rowData });
                }}
              >
                <Download />
              </div>
            </Tooltip>
          )}

          {rowData.type === 'file' && (
            <Tooltip title="Bookmark" delay={100} interactive={false}>
              <div
                className={Styles.actionMenuItem}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onFavourite(rowData);
                }}
              >
                {rowData.data.isFavorite ? <BookmarkFilled fill="#1C2230" /> : <Bookmark />}
              </div>
            </Tooltip>
          )}
          <Tooltip title="Share a link" delay={100} interactive={false}>
            <div
              className={Styles.actionMenuItem}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShowShareModal({ documents: [rowData], isLiving: true });
              }}
            >
              <Share />
            </div>
          </Tooltip>
          <div className={Styles.actionMenuItem} onClick={() => {}}>
            {ActionsRenderer({
              onClickLink,
              matterId,
              rowData,
              onReplace: () => {
                setDocumentToReplace(rowData);
                inputSingleRef?.current?.click();
              },
              onRename: () => {
                setShowRenameModal(rowData);
              },
              onMove: () => {
                setShowMoveModal([rowData]);
              },
              onDelete: () => {
                setShowDeleteModal([rowData]);
              },
              onShare: (isLiving: boolean) => {
                setShowShareModal({ documents: [rowData], isLiving });
              },
              onLaunchInfoTrack: (title: string) => {
                setModalDialogVisible({
                  modalId: INFOTRACK_MODAL_ID,
                  props: {
                    matterId,
                    documentId: rowData.data.id,
                    title,
                  },
                });
              },
            })}
          </div>
        </div>
      );
    },
    [oneDriveIsUnauthorized, selectedItems, onFavourite, onDownload],
  );

  const StaffRenderer = useCallback(
    ({ rowData }) => (
      <div className={Styles.staffCell}>
        <div className={Styles.staffData}>
          {rowData.data.ownerId ? getPersonByUserId(rowData.data.ownerId)?.name : null}
        </div>
        <TableActionsMenu rowData={rowData} />
      </div>
    ),
    [getPersonByUserId, TableActionsMenu],
  );

  const NameRenderer = useCallback(
    ({ rowData }) => (
      <div className={Styles.item}>
        {rowData.type === 'file' && !isLoading(rowData) ? (
          <a
            onClick={(e) => {
              e.stopPropagation();
              setShowFileDetailModal(rowData);
            }}
          >
            {rowData.data.name}
          </a>
        ) : (
          <div>{rowData.data.name}</div>
        )}
        <div className={Styles.loading}>
          <RealLoadingBar loading={isLoading(rowData)} progress={rowData.loading || 0} />
        </div>
        {rowData.type === 'file' && !isLoading(rowData) && (
          <div
            className={classNames(Styles.favouriteIcon, !rowData.data.isFavorite && Styles.favouriteHover)}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              onFavourite(rowData);
            }}
          >
            {rowData.data.isFavorite ? <BookmarkFilled fill="#F2600C" /> : <Bookmark />}
          </div>
        )}
      </div>
    ),
    [onFavourite, setShowFileDetailModal],
  );

  // triggers when file is dropped
  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0] && inputMultiRef.current) {
        const files = e.dataTransfer.files;
        if (isConflict(selectedFolder, files)) {
          setShowReplaceModal({
            onReplace: async () => {
              // eslint-disable-next-line no-restricted-syntax
              for (const f of files || []) {
                if (isConflict(selectedFolder, [f])) {
                  await replaceFile(
                    files,
                    Object.values(selectedFolder.contents).find(
                      (i) => `${i.data.name}${(i as File).data.fileExtension}` === f.name,
                    ) as File,
                  );
                } else {
                  await uploadFiles([f]);
                }
              }
              setShowReplaceModal(false);
            },
            onRename: async () => {
              // eslint-disable-next-line no-restricted-syntax
              for (const f of files || []) {
                const newFile = renameFile(selectedFolder, f);
                await uploadFiles([newFile]);
              }
              setShowReplaceModal(false);
            },
          });
        } else {
          uploadFiles(files);
        }
      }
    },
    [uploadFiles, renameFile, replaceFile, selectedFolder],
  );

  return (
    <div
      onDrop={handleDrop}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      className={classNames('master-detail-panel', Styles.containerV2)}
    >
      {featureActive('NUCWEB-779') && (
        <div className={Styles.promoCard}>
          <PromoCard
            scope="connectOutlookAddin"
            onSetup={() => {
              sendMetric('promoCardClicked', { scope: 'connectOutlookAddin' });
              window.open(
                'https://support.smokeball.com/hc/en-gb/articles/27531981252503-Connect-Outlook-to-Smokeball-Boost',
                '_blank',
                'noopener,noreferrer',
              );
            }}
            title="Connect Outlook Email"
            text="Assign emails and attachments to Smokeball matters from Outlook with a simple click."
          >
            <div className={Styles.promoImages}>
              <OutlookLogo />
              <ArrowsSpin />
              <img src={smokeballLogoSrc} alt="smokeball" />
            </div>
          </PromoCard>
        </div>
      )}
      <TabListV2 selectedId={tab} altStyle list={tabList} onTabSelectionChange={setTab} />
      <div className={Styles.container}>
        {/* This is a custom toast/loading component in a custom position and without a timeout for this page. If you want to copy this, please make a generic component first */}
        {isOpeningDocumentOnWeb && (
          <div className={Styles.loadingToast}>
            <div>
              <Spinner small />
            </div>
            <span>Please wait while we process your request.</span>
          </div>
        )}
        {showNewFolderModal && (
          <SingleInputModal
            initial=""
            label="Name"
            onClose={() => {
              setShowNewFolderModal(false);
            }}
            title={selectedPath === '' ? 'New Folder' : 'New Subfolder'}
            onSubmit={async (name) => {
              setUpdateLoading(true);
              try {
                await createNewFolder(name);
                setShowNewFolderModal(false);
              } catch (error) {
                messageDisplay.error('Error creating new folder, please try again later');
              }
              setUpdateLoading(false);
            }}
            formSubmitting={updateLoading}
          />
        )}
        {showRenameModal && (
          <SingleInputModal
            initial={showRenameModal.data.name}
            label="Name"
            onClose={() => {
              setShowRenameModal(false);
            }}
            title="Rename"
            onSubmit={async (newName) => {
              setUpdateLoading(true);
              try {
                if (showRenameModal.type === 'folder') {
                  await fetchPatchP({
                    path: `/matter-management/matter/folder/:accountId/${matterId}/${showRenameModal.data.id}`,
                    fetchOptions: {
                      body: JSON.stringify({
                        name: newName,
                      }),
                    },
                  });
                } else {
                  await fetchPatchP({
                    path: `/matter-management/matter/file/:accountId/${matterId}/${showRenameModal.data.id}`,
                    fetchOptions: {
                      body: JSON.stringify({
                        fileName: newName,
                      }),
                    },
                  });
                }
                const newDocs = { ...documents };
                const newSelectedFolder = getFolderInDocs(newDocs, showRenameModal.folderPath);
                newSelectedFolder.contents[showRenameModal.data.id].data.name = newName;
                setDocuments(newDocs);
                setShowRenameModal(false);
              } catch (err) {
                messageDisplay.error('Error saving changes, please try again later');
              }
              setUpdateLoading(false);
            }}
            formSubmitting={updateLoading}
          />
        )}
        {showMoveModal && (
          <MoveModal
            documents={documents}
            onClose={() => {
              setShowMoveModal(false);
            }}
            onSubmit={async (folderPath) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const doc of showMoveModal) {
                await moveFromTo(doc, folderPath);
              }
            }}
            formSubmitting={updateLoading}
          />
        )}
        {showFileDetailModal && (
          <ViewFileModal
            onClose={() => {
              setShowFileDetailModal(false);
            }}
            matterId={matterId}
            file={showFileDetailModal}
            formSubmitting={updateLoading}
            getFileDetail={getFileDetail}
            fileDetailResult={fileDetailResult}
            getPersonByUserId={getPersonByUserId}
            setShowShareModal={setShowShareModal}
            onFileEditing={(file: Document) => {
              setShowDownloadFileManagerModal(file);
            }}
            onUriHandled={() => dispatch(setFileManagerInstalled({ isFileManagerInstalled: true }))}
            isFileManagerInstalled={isFileManagerInstalled}
            oneDriveIsUnauthorized={oneDriveIsUnauthorized}
            isOpeningDocumentOnWeb={isOpeningDocumentOnWeb}
            openDocumentOnWeb={openDocumentOnWeb}
          />
        )}
        {showReplaceModal && (
          <ReplaceModal
            onClose={() => {
              setShowReplaceModal(false);
            }}
            onRename={showReplaceModal.onRename}
            onReplace={showReplaceModal.onReplace}
            formSubmitting={updateLoading}
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            onClose={() => {
              setShowDeleteModal(false);
            }}
            documents={showDeleteModal}
            onSubmit={async () => {
              // eslint-disable-next-line no-restricted-syntax
              for (const doc of showDeleteModal || []) {
                try {
                  setUpdateLoading(true);
                  if (doc.type === 'folder') {
                    await fetchDeleteP({
                      path: `/matter-management/matter/folder/:accountId/${matterId}/${doc.data.id}`,
                      fetchOptions: {},
                    });
                  } else {
                    await fetchDeleteP({
                      path: `/matter-management/matter/file/:accountId/${matterId}/${doc.data.id}`,
                      fetchOptions: {},
                    });
                  }
                  const newDocs = { ...documents };
                  const newSelectedFolder = getFolderInDocs(newDocs, doc.folderPath);

                  delete newSelectedFolder.contents[doc.data.id];
                  setDocuments(newDocs);
                  setUpdateLoading(false);
                  setShowDeleteModal(false);
                  setSelection({});
                } catch (e) {
                  messageDisplay.error('Something went wrong deleting your document. Please try again later');
                  log.error(e);
                }
              }
            }}
            formSubmitting={updateLoading}
          />
        )}
        {showShareModal && (
          <SendFilesViaCommunicateFormModal
            documents={showShareModal?.documents ?? []}
            matterId={matterId}
            isLiving={showShareModal?.isLiving ?? false}
            isVisible
            onClose={() => setShowShareModal(false)}
          />
        )}
        {showCreateLetterModal && (
          <CreateLetterModal
            matterId={matterId}
            isFileManagerInstalled={isFileManagerInstalled}
            onClose={() => setShowCreateLetterModal(false)}
          />
        )}
        {showDownloadFileManagerModal && (
          <FileManagerDownloadModal onClose={() => setShowDownloadFileManagerModal(false)} />
        )}
        <div className={Styles.panelFilter}>
          <InputWithAddon
            className={Styles.search}
            icon="search-icon"
            placeholder="Search file"
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value || '')}
          />
          <div className={Styles.filters}>
            <div className={Styles.showFavourites}>
              <span onClick={() => setShowFavourites(!showFavourites)} className={Styles.label}>
                Show Only Bookmarked
              </span>
              <SlidingToggle
                scope="show-bookmarked-docs"
                onChange={(name, value) => setShowFavourites(value)}
                selected={showFavourites}
              />
            </div>
            {/* This functionality does not exist in the API right now */}
            {/* <div className={Styles.showFavourites}>
              <span onClick={() => setShowDeleted(!showDeleted)} className={Styles.label}>
                Show Deleted
              </span>
              <SlidingToggle
                scope="show-deleted-docs"
                onChange={(name, value) => setShowDeleted(value)}
                selected={showDeleted}
              />
            </div> */}
          </div>
          <FileTree
            documents={documents}
            setExpanded={setExpanded}
            setSelectedPath={setSelectedPath}
            selectedPath={selectedPath}
            expanded={expanded}
            ActionsMenu={ActionsMenu}
            moveFromTo={moveFromTo}
          />
        </div>
        <div className={classNames('panel-body', Styles.documentsBody)}>
          <div className={classNames('ribbon', 'panel', 'panel-primary')}>
            <form id="hidden-form">
              <input
                ref={inputMultiRef}
                className={Styles.hiddenFileInput}
                type="file"
                multiple
                onChange={(ev) => {
                  const files = ev.target.files;
                  if (!files) {
                    return;
                  }
                  if (isConflict(selectedFolder, files)) {
                    setShowReplaceModal({
                      onReplace: async () => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const f of files || []) {
                          if (isConflict(selectedFolder, [f])) {
                            await replaceFile(
                              files,
                              Object.values(selectedFolder.contents).find(
                                (i) => `${i.data.name}${(i as File).data.fileExtension}` === f.name,
                              ) as File,
                            );
                          } else {
                            await uploadFiles([f]);
                          }
                        }
                        setShowReplaceModal(false);
                        // Clear file input
                        if (inputMultiRef.current) {
                          inputMultiRef.current.value = '';
                          inputMultiRef.current.type = 'text';
                          inputMultiRef.current.type = 'file';
                        }
                      },
                      onRename: async () => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const f of files || []) {
                          const newFile = renameFile(selectedFolder, f);
                          await uploadFiles([newFile]);
                        }
                        setShowReplaceModal(false);
                        // Clear file input
                        if (inputMultiRef.current) {
                          inputMultiRef.current.value = '';
                          inputMultiRef.current.type = 'text';
                          inputMultiRef.current.type = 'file';
                        }
                      },
                    });
                  } else {
                    uploadFiles(files).then(() => {
                      // Clear file input
                      if (inputMultiRef.current) {
                        inputMultiRef.current.value = '';
                        inputMultiRef.current.type = 'text';
                        inputMultiRef.current.type = 'file';
                      }
                    });
                  }
                }}
              />
              <input
                ref={inputSingleRef}
                className={Styles.hiddenFileInput}
                type="file"
                onChange={(ev) => {
                  if (documentToReplace) {
                    replaceFile(ev.target.files, documentToReplace);
                  }
                }}
              />
            </form>
            <Button
              onClick={() => {
                setShowNewFolderModal(true);
              }}
            >
              {selectedPath === '' ? 'New Folder' : 'New Subfolder'}
            </Button>
            <Button
              onClick={() => {
                inputMultiRef?.current?.click();
              }}
            >
              Upload Document
            </Button>
            {featureActive('NV-5121') && (
              <Button
                onClick={() => {
                  sendMetric('CreateLetterModal');
                  setShowCreateLetterModal(true);
                }}
              >
                Create Letter
              </Button>
            )}
            {featureActive('NUCWEB-749') && selectedItems.length > 0 && (
              <Tooltip
                title={`Ask Archie about ${selectedItems.length > 1 ? 'these Documents' : 'this Document'}`}
                delay={100}
                interactive={false}
              >
                <Button
                  onClick={() => {
                    sendMetric('DocumentsOpenArchie');

                    // Also accepts folderIds param
                    const path = `?matterId=${matterId}&fileIds=${selectedItems.map(encodeURIComponent).join(',')}`;

                    onClickLink({ type: 'matterArchie', id: matterId, params: [path] });
                  }}
                >
                  {`Ask Archie (${selectedItems.length})`}
                </Button>
              </Tooltip>
            )}
            {selectedItems.length === 1 && (
              <Button
                onClick={() => {
                  const id = selectedItems[0];
                  const file = selectedFolder.contents[id];
                  if (file.type === 'file') {
                    if (isLoading(file as File)) {
                      messageDisplay.error('Please wait until file has finished uploading');
                      return;
                    }
                    onDownload({ rowData: file });
                  }
                }}
              >
                Download Document
              </Button>
            )}
            {searchFilter.length > 2 && (
              <Button
                onClick={() => {
                  setSearchFilter('');
                }}
              >
                Back to File Browser
              </Button>
            )}
            {selectedItems.length > 1 && (
              <Button
                onClick={async () => {
                  sendMetric('DocumentsV2BulkDownload');
                  messageDisplay.info('Collecting your documents, please wait');
                  const files: InputWithMeta[] = [];
                  try {
                    for (let i = 0; i < selectedItems.length; i += 1) {
                      const id = selectedItems[i];
                      const file = selectedFolder.contents[id];
                      if (file.type === 'file') {
                        if (isLoading(file as File)) {
                          messageDisplay.error('Please wait until file has finished uploading');
                        } else {
                          const apolloClient = getApolloClient();
                          const fileDetail = await apolloClient.query({
                            query: FileDetailDocument,
                            variables: { id: file.data.id },
                            fetchPolicy: 'network-only',
                          });

                          if (fileDetail?.data?.fileDetail?.versions?.[0]?.isCancelled) {
                            messageDisplay.error(
                              `The upload of ${
                                file.data.name + file.data.fileExtension
                              } was cancelled so this document was skipped`,
                            );
                          } else if (fileDetail?.data?.fileDetail?.versions?.[0]?.isUploaded === false) {
                            messageDisplay.error(
                              `${
                                file.data.name + file.data.fileExtension
                              } has not finished uploading yet so this document was skipped`,
                            );
                          } else {
                            const {
                              body: { downloadUrl },
                            } = await fetchGetP({
                              path: `/matter-management/matter/file/:accountId/${matterId}/${file.data.id}/download`,
                              fetchOptions: {},
                            });
                            const fileToDownload = await fetch(downloadUrl);
                            files.push({
                              name: file.data.name + file.data.fileExtension,
                              lastModified: new Date(),
                              input: fileToDownload,
                            });
                          }
                        }
                      }
                    }
                    const blob = await downloadZip(files).blob();
                    // make and click a temporary link to download the Blob
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `${matterName || 'matter_files'}.zip`;
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(link.href);
                  } catch (e) {
                    log.error(e);
                    messageDisplay.error(
                      'Something went wrong collecting your documents, please try downloading them individually',
                    );
                  }
                }}
              >
                {`Bulk Download (${selectedItems.length})`}
              </Button>
            )}
            {selectedItems.length > 1 && (
              <Button
                onClick={() => {
                  const files = selectedItems.map((id) => selectedFolder.contents[id]);
                  if (files.some((f) => !!isLoading(f as File))) {
                    messageDisplay.error('Please wait until file has finished uploading');
                    return;
                  }
                  sendMetric('DocumentsV2BulkMove');
                  setShowMoveModal(files);
                }}
              >
                {`Bulk Move (${selectedItems.length})`}
              </Button>
            )}
            {selectedItems.length > 1 && (
              <Button
                onClick={() => {
                  const files = selectedItems.map((id) => selectedFolder.contents[id]);
                  if (files.some((f) => !!isLoading(f as File))) {
                    messageDisplay.error('Please wait until file has finished uploading');
                    return;
                  }
                  sendMetric('DocumentsV2BulkDelete');
                  setShowDeleteModal(files);
                }}
              >
                {`Bulk Delete (${selectedItems.length})`}
              </Button>
            )}
          </div>
          {searchFilter.length > 2 && (
            <DocumentList
              entities={searchResults?.filter((result) => {
                const isEmail = ['.msg', '.eml'].includes(result.extension);
                if (tab === 'Emails') {
                  return isEmail;
                }
                if (tab === 'Documents') {
                  return !isEmail;
                }
                return true;
              })}
              getPersonByUserId={getPersonByUserId}
              searchMeetsMinLength
            />
          )}
          {searchFilter.length < 3 && (
            <Table
              className={Styles.documentsTable}
              onRowClick={({ rowData }) => {
                if (rowData.type === 'file') {
                  setShowFileDetailModal(rowData);
                } else if (rowData.type === 'folder') {
                  setSelectedPath(selectedPath ? `${selectedPath}.${rowData.data.id}` : rowData.data.id);
                }
              }}
              list={lineItems
                .filter((i) => {
                  const file = i as File;
                  const favouriteFilter = !showFavourites || file.data.isFavorite || i.type === 'folder';
                  const deletedFilter = !showDeleted || file.data.isDeleted || i.type === 'folder';
                  const isEmail = ['.msg', '.eml'].includes(file.data.fileExtension);
                  let emailFilter = true;
                  if (tab === 'Emails') {
                    emailFilter = isEmail;
                  }
                  if (tab === 'Documents') {
                    emailFilter = !isEmail && i.type !== 'folder';
                  }
                  return favouriteFilter && emailFilter && deletedFilter;
                })
                .sort((a, b) => {
                  if (!sortBy) {
                    return 0;
                  }
                  let aMapped = a?.data?.[sortBy];
                  let bMapped = b?.data?.[sortBy];
                  if (sortBy === 'ownerId') {
                    aMapped = getPersonByUserId(aMapped)?.name;
                    bMapped = getPersonByUserId(bMapped)?.name;
                  }
                  aMapped = aMapped?.toLowerCase() || aMapped;
                  bMapped = bMapped?.toLowerCase() || bMapped;
                  if (a.type !== b.type) {
                    return a.type === 'file' ? 1 : -1;
                  }
                  if (aMapped === bMapped) {
                    return 0;
                  }
                  if (!aMapped) {
                    return -1;
                  }
                  if (!bMapped) {
                    return 1;
                  }
                  if (sortDirection === 'asc') {
                    return aMapped > bMapped ? 1 : -1;
                  }
                  return aMapped < bMapped ? 1 : -1;
                })}
              sort={setSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              dataLoading={loading || updateLoading}
              rowClassName={({ rowData }) => classNames(selection[rowData?.data?.id] && Styles.selectedRow, Styles.row)}
            >
              <Column
                key="icon"
                dataKey="icon"
                label=""
                width={34}
                cellRenderer={CheckboxRenderer(selection, onSelect)}
                disableSort
              />
              <Column
                key="icon"
                className={Styles.noPadding}
                dataKey="icon"
                label=""
                width={34}
                cellRenderer={IconRenderer}
                disableSort
              />
              <Column key="name" dataKey="name" label="Name" cellRenderer={NameRenderer} flexGrow={2} />
              {tab === 'Emails' && <Column key="to" dataKey="to" label="To" cellRenderer={DataRenderer} flexGrow={1} />}
              {tab === 'Emails' && (
                <Column key="from" dataKey="from" label="From" cellRenderer={DataRenderer} flexGrow={1} />
              )}
              <Column
                key="ownerId"
                className={Styles.actionListColumn}
                dataKey="ownerId"
                label="Staff"
                cellRenderer={StaffRenderer}
                flexGrow={1}
              />
              <Column
                key="dateModified"
                dataKey="dateModified"
                label="Date modified"
                cellRenderer={DateTimeCellRenderer}
                width={160}
              />
              <Column
                key="dateCreated"
                dataKey="dateCreated"
                label="Date created"
                cellRenderer={DateCellRenderer}
                width={120}
              />
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

DocumentsTabV2.displayName = 'DocumentsTabV2';
