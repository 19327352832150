import { gql } from '@apollo/client';

// Always include relevant ID fields to enable caching
const query = gql`
  query FirmLogo {
    firm {
      id
      logoBase64
    }
  }
`;

const notificationIds = ['FirmManagementNotifications.FirmLogoUpdated'];

/**
 * @type {import('./types').SubscribedQuery}
 */
export const FirmLogo = {
  query,
  notificationIds,
};
