import * as React from 'react';
import PropTypes from 'prop-types';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { ADD_INVOICE_PAYMENT_MODAL_ID } from 'web/components';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { debounce } from '@sb-itops/nodash';
import {
  AddInvoicePaymentModalData,
  BankReconciliationLatestCompletedData,
  InitBankAccountSettings,
  InitOperatingBankAccount,
  InitTrustChequePrintSettings,
  TrustChequeAvailableNumbers,
  InvoiceOverpaymentAccountData,
} from 'web/graphql/queries';
import {
  useCacheQuery,
  useContactTypeaheadData,
  usePaymentSourcesData,
  useSubscribedLazyQuery,
  useSubscribedQuery,
} from 'web/hooks';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { getLogger } from '@sb-itops/fe-logger';
import { getRegion } from '@sb-itops/region';
import { getDefaultTrustChequePrintSettings } from '@sb-billing/business-logic/cheques';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
  bankAccountTypeEnum,
  bankAccountState,
  bankAccountStateByValue,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import {
  deriveInvoiceOverpaymentAccountId,
  getBankAccountName,
} from '@sb-billing/business-logic/bank-account/services';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import { useTranslation } from '@sb-itops/react';

import { AddInvoicePaymentModalFormsContainer } from './AddInvoicePaymentModal.forms.container';

const { sortByProperty } = require('@sb-itops/nodash');

const REGION = getRegion();
const log = getLogger('AddInvoicePaymentModal.container');

const hooks = () => ({
  useSessionManagement: () => ({
    accountId: getAccountId(),
    userId: getUserId(),
  }),
  useAddInvoicePaymentModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: ADD_INVOICE_PAYMENT_MODAL_ID });

    return {
      onModalClose,
    };
  },
  useBankReconciliationData: () => {
    const [getBankRecLatestCompleted, bankRecLatestCompletedResults] = useSubscribedLazyQuery(
      BankReconciliationLatestCompletedData,
      {
        variables: {},
      },
    );

    const onFetchBankRecLatestCompleted = ({ bankAccountId }) => {
      if (!bankAccountId) {
        // eslint-disable-next-line no-console
        console.warn('onFetchBankRecLatestCompleted missing bankAccountId');
        return;
      }

      getBankRecLatestCompleted({
        variables: {
          bankAccountId,
        },
      });
    };

    const { bankReconciliationLatestCompletedByBankAccount, bankReconciliationSetup } =
      bankRecLatestCompletedResults?.data || {};

    return {
      bankReconciliationLatestCompletedByBankAccount,
      bankReconciliationSetup,
      bankReconciliationLoading: bankRecLatestCompletedResults.loading,
      onFetchBankRecLatestCompleted,
    };
  },
  useContactTypeaheadData: () => {
    const {
      contactOptions: paidByContactOptions,
      contactOptionsDataLoading: paidByContactOptionsDataLoading,
      contactOptionsHasMore: paidByContactOptionsHasMore,
      onFetchContactOptions: onFetchPaidByContactOptions,
      onFetchMoreContactOptions: onFetchMorePaidByContactOptions,
    } = useContactTypeaheadData();

    return {
      paidByContactOptions,
      paidByContactOptionsDataLoading,
      paidByContactOptionsHasMore,
      onFetchPaidByContactOptions,
      onFetchMorePaidByContactOptions,
    };
  },
  useBankAccountSettingsData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const isMatterContactBalanceFirm =
      bankAccountSettingsData?.bankAccountSettings?.bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];
    const createPDFReceiptOnTrustPayment =
      bankAccountSettingsData?.bankAccountSettings?.createPDFReceiptOnTrustPayment || false;

    return {
      bankAccountSettings: bankAccountSettingsData?.bankAccountSettings,
      isMatterContactBalanceFirm,
      createPDFReceiptOnTrustPayment,
    };
  },
  useAddInvoicePaymentModalData: ({ invoiceId }) => {
    const isMatterDescriptionRequiredForTrustDeposit = hasFacet(facets.matterDescriptionRequired);
    const isMatterClientRequiredForTrustDeposit = hasFacet(facets.matterClientRequiredForTrustDeposit);

    const {
      data: invoiceData,
      loading: isInvoiceLoading,
      error: invoiceError,
    } = useSubscribedQuery(AddInvoicePaymentModalData, {
      skip: !invoiceId,
      variables: {
        id: invoiceId,
        isMatterClientRequiredForTrustDeposit,
        isMatterDescriptionRequiredForTrustDeposit,
      },
    });

    if (invoiceError) {
      throw new Error(invoiceError);
    }

    const invoice = invoiceData?.invoice;

    return {
      invoice,
      isInvoiceLoading,
      isMatterClientRequiredForTrustDeposit,
      isMatterDescriptionRequiredForTrustDeposit,
      matterId: invoice?.matter?.id,
    };
  },
  useTrustChequePrintSettings: () => {
    const { data: trustChequePrintSettingsData } = useCacheQuery(InitTrustChequePrintSettings.query);
    const allTrustChequePrintSettings = trustChequePrintSettingsData?.trustChequePrintSettings || [];

    const isTrustChequePrintingActiveForBankAccountId = (bankAccountId) => {
      const trustAccountChequeSettings =
        bankAccountId && allTrustChequePrintSettings.find((settings) => settings.id === bankAccountId);

      return (trustAccountChequeSettings || getDefaultTrustChequePrintSettings({ region: REGION })).printingActive;
    };

    return {
      isTrustChequePrintingActiveForBankAccountId,
    };
  },
  useOperatingBankAccount: () => {
    const { data: operatingBankAccountData } = useCacheQuery(InitOperatingBankAccount.query);
    const operatingAccount = operatingBankAccountData?.bankAccounts?.[0];

    return {
      operatingAccount,
    };
  },
  useTrustChequeData: () => {
    const [getAvailableTrustChequeNumbers, trustChequeNumberResults] = useSubscribedLazyQuery(
      TrustChequeAvailableNumbers,
      {
        context: { skipRequestBatching: true },
        variables: {},
      },
    );

    const onFetchAvailableTrustChequeNumbers = debounce(
      ({ bankAccountId, trustChequeReference }) => {
        if (!bankAccountId) {
          log.warn('onFetchAvailableTrustChequeNumbers missing bankAccountId');
          return;
        }

        if (trustChequeReference && !/^[0-9]+$/.test(trustChequeReference)) {
          log.warn('onFetchAvailableTrustChequeNumbers cannot fetch non-numeric cheque reference');
          return;
        }

        getAvailableTrustChequeNumbers({
          variables: {
            filter: {
              bankAccountId,
              chequeNumberFrom: trustChequeReference,
              quantity: 1,
            },
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const data = trustChequeNumberResults.data?.trustChequeAvailableNumbers;

    const lastTrustChequeNumber = data?.lastChequeNumber;
    const nextTrustChequeNumber = data?.availableChequeNumbers?.length ? data.availableChequeNumbers[0] : undefined;

    return {
      lastTrustChequeNumber,
      nextTrustChequeNumber,
      trustChequeNumberBankAccountId: data?.bankAccountId,
      trustChequeNumberLoading: trustChequeNumberResults.loading,
      onFetchAvailableTrustChequeNumbers,
    };
  },
});

const dependentHooks = () => ({
  usePaymentSourcesData: ({ isMatterContactBalanceFirm }) => {
    const { paymentSourceOptions, paymentSourceOptionsLoading, onFetchPaymentSourceOptions } = usePaymentSourcesData({
      allowOverdraw: hasFacet(facets.allowOverdraw),
      includeCombined: isMatterContactBalanceFirm,
      includeDirectOptions: true,
      isMatterContactBalanceFirm,
    });

    const onGetPaymentSourceOptions = ({ matterId, effectiveDate } = {}) =>
      onFetchPaymentSourceOptions({
        matterId,
        effectiveDate,
        accountTypes: [bankAccountTypeEnum.TRUST, bankAccountTypeEnum.OPERATING, bankAccountTypeEnum.CREDIT],
      });

    return {
      paymentSourceOptions,
      paymentSourceOptionsLoading,
      onGetPaymentSourceOptions,
    };
  },
  useInvoiceOverpaymentToTrustAccountData: ({ matterId, accountId, bankAccountSettings }) => {
    const { t } = useTranslation();
    const isOverpaymentGoingToTrustAccount = !hasFacet(facets.operatingAccount);
    const isTrustAccountPerStateFacetEnabled = hasFacet(facets.trustAccountPerState);

    const { data, loading, error } = useSubscribedQuery(InvoiceOverpaymentAccountData, {
      skip: !isOverpaymentGoingToTrustAccount || !matterId,
      variables: {
        matterId,
        bankAccountFilter: {
          accountTypes: [bankAccountTypeEnum.TRUST],
          state: bankAccountStateByValue[bankAccountState.OPEN],
        },
        matterTrustBankAccountsFilter: {
          checkTransactions: true,
          state: [bankAccountStateByValue[bankAccountState.OPEN]],
        },
        matterTypesFilter: {
          matterIds: [matterId],
          excludeMatterTypeWithoutMatter: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    const orderedMatterTrustAccounts = React.useMemo(() => {
      if (!data?.matterTrustBankAccounts) {
        return undefined;
      }

      const mappedResult = data.matterTrustBankAccounts.map((ta) => ({
        ...ta,
        label: getBankAccountName(ta, t),
      }));

      return sortByProperty(mappedResult, 'label', 'asc', false);
    }, [data?.matterTrustBankAccounts, t]);

    const firmTrustAccounts = data?.bankAccounts;
    const matterTrustAccounts = orderedMatterTrustAccounts;
    const matterLocation = data?.matterTypeList?.results?.[0]?.location;
    const matterBillingConfiguration = data?.matter?.billingConfiguration;

    // The overpaymentTrustAccountId is passed as the argument to onFetchBankRecLatestCompleted
    //  * Which will validate the trust account used for the overpayment
    //    * Checks if it has been reconciled for that date
    const overpaymentTrustAccountId = React.useMemo(() => {
      if (!isOverpaymentGoingToTrustAccount || loading || !matterId) {
        return undefined;
      }

      const operatingAccountId = `${accountId}/Operating`;
      const legacyTrustAccountId = `${accountId}/Trust`;

      return deriveInvoiceOverpaymentAccountId({
        bankAccountSettings,
        firmTrustAccounts,
        isOverpaymentGoingToTrustAccount,
        isTrustAccountPerStateFacetEnabled,
        legacyTrustAccountId,
        matterBillingConfiguration,
        matterLocation,
        matterTrustAccounts,
        operatingAccountId,
      });
    }, [
      accountId,
      matterId,
      bankAccountSettings,
      loading,
      firmTrustAccounts,
      isOverpaymentGoingToTrustAccount,
      isTrustAccountPerStateFacetEnabled,
      matterBillingConfiguration,
      matterLocation,
      matterTrustAccounts,
    ]);

    return {
      isOverpaymentGoingToTrustAccount,
      isTrustAccountPerStateFacetEnabled,
      overpaymentTrustAccountId,
    };
  },
});

export const AddInvoicePaymentModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(AddInvoicePaymentModalFormsContainer))),
);

AddInvoicePaymentModalContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  printCheques: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  bankAccountType: PropTypes.string,
};

AddInvoicePaymentModalContainer.defaultProps = {
  bankAccountType: undefined,
};

AddInvoicePaymentModalContainer.displayName = 'AddInvoicePaymentModalContainer';
