'use strict';

angular.module('sb.billing.webapp').controller('PreferencesController', function ($state, $scope, sbLocalisationService) {
  const that = this;
  const nonNavigableStates = ['login', 'pre-authentication', 'post-authentication', 'home.billing.goto-invoice'];

  that.t = sbLocalisationService.t;

  $scope.previousState = '';
  $scope.previousParams = {};
  $scope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
    if ($scope.previousState === '' && !nonNavigableStates.includes(from.name)) {
      $scope.previousState = from.name;
      $scope.previousParams = fromParams;
    }
    setTab();
  });

  that.close = function () {
    if ($scope.previousState && $scope.previousState !== '' && !$scope.previousState.match(/^preferences/)) {
      if (!_.isEmpty($scope.previousParams)) {
        $state.go($scope.previousState, $scope.previousParams);
      }
      else {
        $state.go($scope.previousState);
      }
    }
    else {
      $state.go('home.billing.dashboard');
    }
  };

  function setTab() {
    that.navHighlights = {};
    if ($state.current.data && $state.current.data.navHighlights) {
      _.each($state.current.data.navHighlights, function(tabName) {
        that.navHighlights[tabName] = true;
      });
    }
  }

});
