import { gql } from '@apollo/client';

const query = gql`
  query PaymentSourcesTrustData(
    $matterId: ID!
    $balanceAsOfDate: Int
    $includeContactBalances: Boolean!
    $includeMatterBalances: Boolean!
    $includeTrustAccountsWithBalanceAsOfDate: Boolean!
    $bankAccountBalanceFilter: BankAccountBalanceFilter
    $trustBankAccountFilter: MatterBankAccountFilter
  ) {
    # For trust accounts
    matterTrustBankAccounts(matterId: $matterId, filter: $trustBankAccountFilter) {
      id
      accountType
      accountName
      displayName
      bankAccountBalances(filter: $bankAccountBalanceFilter) {
        id
        balance
        contactBalances @include(if: $includeContactBalances) {
          contact {
            displayName
            displayNameFull
          }
          contactId
          matterId
          balance
          availableBalance
          protectedBalance
        }
        matterBalances @include(if: $includeMatterBalances) {
          matterId
          adjustment
          balance
          availableBalance
          protectedBalance
        }
      }
      trustToOfficeNumberingSettings {
        useManualNumbering
      }
    }

    # For trust accounts when we need balance at date. This is needed in addition to matterTrustBankAccounts.bankAccountBalances.matterBalances
    matterBalanceTrust(id: $matterId, balanceAsOfDate: $balanceAsOfDate)
      @include(if: $includeTrustAccountsWithBalanceAsOfDate) {
      matterId
      adjustment
      availableBalance
      balance
      isOverdrawn
      protectedBalance
      unpresentedChequesBalance
      bankAccountId
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const PaymentSourcesTrustData = {
  query,
  notificationIds,
};
