import React, { useEffect, useState } from 'react';
import { SbClientSdk } from 'web/react-redux/components/sb-client-sdk';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getArchieUrl } from 'web/services/archie-urls';
import { sendMetric } from 'web/services/metrics';

const Container = (props) => {
  const [url, setUrl] = useState<string>();

  const { onClickLink, matterId, path } = props;

  useEffect(() => {
    sendMetric('OpenArchie');

    getArchieUrl().then((v) => {
      setUrl(v + (path || ''));
    });
  }, []);

  return url ? <SbClientSdk sdkAppUrl={url} onClickLink={onClickLink} matterId={matterId} /> : null;
};

export const ArchieIframeContainer = withReduxProvider(Container);
