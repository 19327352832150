import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { MARK_AS_SENT_MODAL_ID } from 'web/components';
import { useSubscribedQuery } from 'web/hooks';
import { MarkAsSentModalData } from 'web/graphql/queries';
import { operationTypes, getExternalSentViaOptions } from '@sb-billing/business-logic/correspondence-history';
import { MarkAsSentModalFormsContainer } from './MarkAsSentModal.forms.container';

const hooks = () => ({
  useMarkAsSentModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: MARK_AS_SENT_MODAL_ID });

    return {
      onModalClose,
    };
  },
  useMatterCorrespondencePreferences: ({ operationType, selectedItems, scope }) => {
    let firstInvoiceId;
    if (operationType === operationTypes.INVOICE) {
      firstInvoiceId = selectedItems[0];
    } else if (operationType === operationTypes.INVOICE_REMINDER) {
      const firstInvoice = selectedItems[0].invoices?.[0];
      firstInvoiceId = firstInvoice?.invoiceId;
    }

    const { data, loading: isModalDataLoading } = useSubscribedQuery(MarkAsSentModalData, {
      variables: {
        id: firstInvoiceId,
      },
    });

    const { matter } = data?.invoice || {};
    const matterCommunicationSettings = matter?.matterCommunicationSettings || {};
    const matterCorrespondencePreferences = matterCommunicationSettings.correspondencePreferences || [];

    return {
      isModalDataLoading,
      matterCorrespondencePreferences,
      operationType,
      selectedItems,
      scope,
    };
  },
  useSentViaOptions: () => {
    const externalSentViaOptions = getExternalSentViaOptions({
      isEBillingAvailable: hasFacet(facets.eBillingSendingPreference),
    });
    return {
      externalSentViaOptions,
    };
  },
});

export const MarkAsSentModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(MarkAsSentModalFormsContainer)),
);

MarkAsSentModalContainer.propTypes = {
  scope: PropTypes.string,
  operationType: PropTypes.oneOf(Object.values(operationTypes)),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        matterId: PropTypes.string,
        debtorId: PropTypes.string,
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            invoiceId: PropTypes.string,
          }),
        ),
      }),
    ]),
  ).isRequired,
};

MarkAsSentModalContainer.defaultProps = {
  scope: 'mark-as-sent-modal',
  operationType: operationTypes.INVOICE, // Default to invoice type
};

MarkAsSentModalContainer.displayName = 'MarkAsSentModalContainer';
