import PropTypes from 'prop-types';
import { useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from '@sb-itops/react';
import { entryTypeLabelToValueMap, entryTypeLabels } from '@sb-billing/business-logic/shared/entities';
import {
  type as BALANCE_TYPE,
  byName as BALANCE_BY_NAME,
} from '@sb-billing/business-logic/bank-account-settings/entities/constants';
import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import { calculateFeeAmountTax } from '@sb-billing/business-logic/fee/services';
import { dateRangeTypes } from '@sb-itops/date';
import { featureActive } from '@sb-itops/feature';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import composeHooks from '@sb-itops/react-hooks-compose';
import { isModalVisible, setModalDialogHidden, setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import * as panelExpanderFeature from '@sb-itops/redux/panel-expander';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';

import {
  BillingBulkActions,
  InitBankAccountSettings,
  InitFirmTaxSettings,
  InvoiceSettingsTemplateFirmDefault,
  MatterTypesFilter,
  StaffMembersList,
  UnbilledMatterExpenses,
  UnbilledMatterFees,
  UnbilledMatterTableData,
} from 'web/graphql/queries';
import { useCacheQuery, usePagination, useSubscribedQuery } from 'web/hooks';
import * as bulkBillFeature from 'web/redux/route/home-billing-bills-bulk-bill';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { groupMattersByClient } from './group-matters-by-client';
import { BulkCreateInvoicesRoute } from './BulkCreateInvoicesRoute';

const FETCH_LIMIT = 100;
const SCOPE = 'bulk-create-invoice-route';
const SCOPE_PAGINATION = `${SCOPE}/pagination`;
const SCOPE_SORTING = `${SCOPE}/sorting`;
const SCOPE_GROUP_BY_CLIENTS_PRIMARY_SORT = `${SCOPE}/group-by-clients-primary-sort`;
const BULK_CREATE_INVOICES_MODAL_ID = 'BULK_CREATE_INVOICES_MODAL_ID';
const REGION = getRegion();

// translates CreateInvoiceTable field name to graphql field name
const sortByMap = {
  totalAmount: 'unbilledAmountExcTax',
  unbillableAmount: 'nonBillableAmountExcTax',
};

const hooks = () => ({
  useBulkCreateInvoicesModal: () => {
    const isBulkCreateInvoicesModalVisible = useSelector(() =>
      isModalVisible({ modalId: BULK_CREATE_INVOICES_MODAL_ID }),
    );
    const openBulkCreateInvoicesModal = () => {
      setModalDialogVisible({ modalId: BULK_CREATE_INVOICES_MODAL_ID });
    };
    const closeBulkCreateInvoicesModal = () => {
      setModalDialogHidden({ modalId: BULK_CREATE_INVOICES_MODAL_ID });
    };
    return {
      isBulkCreateInvoicesModalVisible,
      openBulkCreateInvoicesModal,
      closeBulkCreateInvoicesModal,
    };
  },
  useDisplayOptions: () => ({
    excludeFormerStaff: true,
    excludeStaffWithoutMatter: true,
    showOperatingBalanceFilter: hasFacet(facets.operatingAccount),
  }),
  useFilterTabs: () => {
    const { selectors, actions } = useScopedFeature(panelExpanderFeature, `${SCOPE}/filterPanel`);
    const expanded = useSelector(selectors.getExpanded);
    const dispatch = useDispatch();

    return {
      expanded,
      toggleExpanded: () => {
        dispatch(actions.toggleExpanded());
      },
    };
  },
  useFilterValues: () => {
    const {
      groupByClient,
      allMatters: showAllMatters,
      minValue: minimumUnbilledAmount,
      dateListFilter,
      billingTypesSelected,
      entryTypesSelected,
      billingFrequencySubTypesSelected,
      matterTypes: matterTypesSelected,
      // matterTypesDisplayed,
      matterFilterStatusSelected,
      selectedAttorneys,
      minTrustRetainerSelected,
      minOperatingRetainerSelected,
    } = useSelector(bulkBillFeature.selectors.getFilters);
    return {
      groupByClient,
      showAllMatters,
      minimumUnbilledAmount,
      dateListFilter,
      billingTypesSelected,
      entryTypesSelected,
      billingFrequencySubTypesSelected,
      matterTypesSelected,
      // matterTypesDisplayed,
      matterFilterStatusSelected,
      selectedAttorneys,
      minimumTrustBalance: minTrustRetainerSelected,
      minimumOperatingBalance: minOperatingRetainerSelected,
    };
  },
  useFilterOptions: () => {
    const { t } = useTranslation();

    const { matterStatusFilterOptions, billingTypeFilterOptions, getLocalisedEntryTypeFilterOptions } =
      bulkBillFeature.types;
    return {
      matterStatusFilterOptions,
      billingTypeFilterOptions,
      entryTypeFilterOptions: getLocalisedEntryTypeFilterOptions(t),
    };
  },
  useFilterChangeHandlers: () => {
    const {
      setGroupByClient,
      setAllMatters,
      onChangeMinUnbilled,
      onChangeIssueDateFilter,
      onBillingTypeFilterChanged,
      onEntryTypeFilterChanged,
      onBillingFrequencyFilterChanged,
      onMatterTypeFilterChanged,
      onSelectMatterStatusFilter,
      setAttorneysFilter,
      onTrustRetainerInputValueChanged: onMinimumTrustBalanceChanged,
      onTrustRetainerClearClick: onMinimumTrustBalanceCleared,
      onOperatingRetainerInputValueChanged: onMinimumOperatingBalanceChanged,
      onOperatingRetainerClearClick: onMinimumOperatingBalanceCleared,
      resetFilters,
    } = bindActionCreators(bulkBillFeature.actions, useDispatch());
    return {
      setGroupByClient,
      setAllMatters,
      onChangeMinUnbilled,
      onChangeIssueDateFilter,
      onBillingTypeFilterChanged,
      onEntryTypeFilterChanged,
      onBillingFrequencyFilterChanged,
      onMatterTypeFilterChanged,
      onSelectMatterStatusFilter,
      setAttorneysFilter,
      onMinimumTrustBalanceChanged,
      onMinimumTrustBalanceCleared,
      onMinimumOperatingBalanceChanged,
      onMinimumOperatingBalanceCleared,
      resetFilters,
    };
  },
  useMatterSelection: () => {
    const dispatch = useDispatch();
    const selectedMatters = useSelector(bulkBillFeature.selectors.getSelectedMatters);
    const {
      selectedItems: selectedMatterIds,
      toggleItems,
      deselectAllItems,
    } = useMultipleItemSelection({
      scope: SCOPE,
    });

    const onToggleSelectMatters = (mattersToToggle) => {
      const matterIdsToToggle = mattersToToggle.map((matter) => {
        // keep a list of selected matters so we can calculate the following warnings
        // 1) hasUnpaidAD
        // 2) zeroBalanceInvoiceCount
        if (selectedMatterIds[matter.matterId]) {
          delete selectedMatters[matter.matterId];
        } else {
          selectedMatters[matter.matterId] = matter;
        }

        return matter.matterId;
      });
      toggleItems(matterIdsToToggle);

      dispatch(bulkBillFeature.actions.setSelectedMatters(selectedMatters));
    };

    const deselectAllMatters = () => {
      deselectAllItems();
      dispatch(bulkBillFeature.actions.setSelectedMatters({}));
    };

    return { onToggleSelectMatters, selectedMatterIds, selectedMatters, deselectAllMatters };
  },
  useShowUnbilledMatterEntries: () => {
    const [matterIdToShowUnbilledEntries, setMatterIdToShowUnbilledEntries] = useState(undefined);
    const onClickRow = (matter) => {
      if (matter?.rowData?.matterId) {
        setMatterIdToShowUnbilledEntries(matter.rowData.matterId);
      }
    };

    return {
      onClickRow,
      matterIdToShowUnbilledEntries,
    };
  },
  usePagination: () => {
    const {
      currentPage: mattersCurrentPage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: SCOPE_PAGINATION,
      fetchLimit: FETCH_LIMIT,
    });

    const mattersOnPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    return { mattersCurrentPage, mattersOnPageChange, getPagination };
  },
  useSorting: () => {
    // main sort
    const {
      sortBy: mattersSortBy,
      setSortBy,
      sortDirection: mattersSortDirection,
      setSortDirection,
    } = useSort({
      scope: SCOPE_SORTING,
      initialSortBy: 'matterSummaryLine',
      initialSortDirection: 'asc',
    });

    // primary sort for group by client mode
    const { sortDirection: groupByClientPrimarySortDirection, setSortDirection: setGroupByClientPrimarySortDirection } =
      useSort({
        scope: SCOPE_GROUP_BY_CLIENTS_PRIMARY_SORT,
        initialSortBy: 'matterSummaryLine',
        initialSortDirection: 'asc',
      });

    return {
      mattersSortBy,
      mattersSortDirection,
      setSortBy,
      setSortDirection,
      groupByClientPrimarySortDirection,
      setGroupByClientPrimarySortDirection,
    };
  },
  useScopes: () => ({
    matterFilterScope: `${SCOPE}/matterFilter`,
  }),
});

const queryHooks = ({
  // filter values
  groupByClient,
  showAllMatters,
  minimumUnbilledAmount,
  dateListFilter,
  billingTypesSelected,
  entryTypesSelected,
  billingFrequencySubTypesSelected,
  matterTypesSelected,
  // matterTypesDisplayed,
  matterFilterStatusSelected,
  selectedAttorneys,
  minimumTrustBalance,
  minimumOperatingBalance,

  // sorting & pagination
  mattersSortBy,
  mattersSortDirection,
  setSortBy,
  setSortDirection,
  groupByClientPrimarySortDirection,
  setGroupByClientPrimarySortDirection,
  selectedMatters,
  mattersCurrentPage,
  getPagination,

  // others
  excludeFormerStaff,
  excludeStaffWithoutMatter,
  matterIdToShowUnbilledEntries,
  showOperatingBalanceFilter,
}) => ({
  useBankAccountSettingsData: () => {
    const { data: bankAccountSettingsData } = useCacheQuery(InitBankAccountSettings.query);
    const isUsingContactMatterBalance =
      bankAccountSettingsData?.bankAccountSettings?.bankBalanceType === BALANCE_BY_NAME[BALANCE_TYPE.matterContact];
    return {
      isUsingContactMatterBalance,
    };
  },
  useBillingBulkActionsData: () => {
    const { data } = useSubscribedQuery(BillingBulkActions, {
      variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES },
    });

    const isBulkCreateInvoicesInProgress = data?.billingBulkActionList?.totalCount > 0;
    return {
      isBulkCreateInvoicesInProgress,
    };
  },
  useStaffMemberFilterData: () => {
    const { data, error } = useSubscribedQuery(
      StaffMembersList,
      {
        variables: { filter: { excludeFormerStaff, excludeStaffWithoutMatter } },
      },
      {
        notificationIds: [...StaffMembersList.notificationIds, 'MatterManagementWebQuery.MatterUpdated'],
      },
    );

    if (error) {
      throw new Error(error);
    }

    const staffMembers =
      data?.staffMembersList?.results.map((staff) => ({ ...staff, name: `${staff.firstName} ${staff.lastName}` })) ||
      [];

    return { staffMembers };
  },
  useMatterTypesFilterData: () => {
    const matterTypes = useRef();
    const { data, error, loading } = useSubscribedQuery(MatterTypesFilter, {
      variables: {
        filter: {
          excludeMatterTypeWithoutMatter: true,
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    if (!loading && data?.matterTypeList?.results) {
      matterTypes.current = data?.matterTypeList?.results;
    }

    return { matterTypes: matterTypes.current };
  },
  useFirmDefaultInvoiceSettingsTemplate: () => {
    const { data, error } = useSubscribedQuery(InvoiceSettingsTemplateFirmDefault);

    if (error) {
      throw new Error(error);
    }

    const includeNonBillableItems =
      data?.invoiceSettingsTemplateFirmDefault?.settings?.defaultLayout?.includeNonBillableItems || false;
    const paymentDueDays = Number.isFinite(data?.invoiceSettingsTemplateFirmDefault?.settings?.paymentDueDays)
      ? data?.invoiceSettingsTemplateFirmDefault?.settings?.paymentDueDays
      : 7;

    return { includeNonBillableItems, paymentDueDays };
  },
  useUnbilledMatterTableData: () => {
    const dispatch = useDispatch();

    // Map filter type to actual Billing Types
    // 1. 'Contingency' maps to 'Contingency ($)' and 'Contingency (%)' and
    // 2. 'Fixed' maps to 'Fixed Fee' and 'Fixed Fee per Appearance'
    const billingTypes = (billingTypesSelected || []).reduce((acc, item) => {
      if (item === 'Contingency') {
        acc.push('Contingency ($)');
        acc.push('Contingency (%)');
        return acc;
      }

      if (item === 'Fixed') {
        acc.push('Fixed Fee');
        acc.push('Fixed Fee per Appearance');
        return acc;
      }

      acc.push(item);
      return acc;
    }, []);

    // Map entry type to actual Entry Types (integers)
    const entryTypes = featureActive('BB-9228') ? convertToIntEntryTypes(entryTypesSelected) : undefined;

    // Graphql enums for matter status are all lowercase (Igor said we should stick to upper case when possible)
    const matterStatus = (matterFilterStatusSelected || []).map((status) => status.toLowerCase());

    let sort;
    if (groupByClient) {
      // 1. in group by client mode, the primary sort is always by firstClientDisplayName
      // 2. the default secondary sort is always matterSummarySort
      // 3. if user clicks to sort by another column, that column becomes the secondary sort
      const primarySortDirection = `${groupByClientPrimarySortDirection || 'ASC'}`.toUpperCase();
      if (mattersSortBy === 'matterSummaryLine') {
        sort = {
          fieldNames: ['firstClientDisplayName', 'matterSummaryLine'],
          directions: [primarySortDirection, 'ASC'],
        };
      } else {
        sort = {
          fieldNames: ['firstClientDisplayName', sortByMap[mattersSortBy] || mattersSortBy],
          directions: [primarySortDirection, `${mattersSortDirection || 'ASC'}`.toUpperCase()],
        };
      }
    } else if (mattersSortBy) {
      // matter list mode (A-Z)
      if (mattersSortBy === 'matterSummaryLine') {
        sort = {
          fieldNames: [sortByMap[mattersSortBy] || mattersSortBy],
          directions: [`${mattersSortDirection || 'ASC'}`.toUpperCase()],
        };
      } else {
        // by default use matterSummaryLine as secondary sort
        sort = {
          fieldNames: [sortByMap[mattersSortBy] || mattersSortBy, 'matterSummaryLine'],
          directions: [`${mattersSortDirection || 'ASC'}`.toUpperCase(), 'ASC'],
        };
      }
    }

    const entryStartDate =
      !dateListFilter.filterType || dateListFilter.filterType === dateRangeTypes.ALL
        ? undefined
        : dateListFilter.startDate;
    const entryEndDate =
      !dateListFilter.filterType || dateListFilter.filterType === dateRangeTypes.ALL
        ? undefined
        : dateListFilter.endDate;

    // Please note we check billingTypesSelected and not billingTypes length.
    // The reason is we want to distinguish between ALL selected and NOTHING selected. The issue is that
    // when ALL is selected, we represent it as "[]" to backend so backend doesn't filter by billing type at all (and therefore displays all).
    // This is mainly because in DB matters may have billing type NULL and we want to display them when ALL is selected.
    //
    // Therefore, to skip the query below, if we know the filter selection would result in empty result,
    // we check billingTypesSelected, which is "undefined" if ALL is selected, but it is "[]" if NOTHING is selected..
    //
    // This is different for e.g. entry type, where if we want to display all, we just pass array of all entry types. Same for matter status.
    const skipMattersQuery =
      matterStatus.length === 0 || billingTypesSelected?.length === 0 || entryTypes?.length === 0;

    const {
      data,
      error,
      loading: mattersLoading,
    } = useSubscribedQuery(UnbilledMatterTableData, {
      skip: skipMattersQuery,
      context: { skipRequestBatching: true },
      variables: {
        unbilledMatterListFilter: {
          minimumUnbilledAmount: showAllMatters ? undefined : minimumUnbilledAmount,
          entryDate: {
            from: entryStartDate,
            to: entryEndDate,
          },
          billingTypes,
          entryTypes,
          billingFrequencySubTypes: billingFrequencySubTypesSelected,
          matterStatus,
          matterTypes: matterTypesSelected,
          attorneyResponsibleIds: selectedAttorneys,
          minimumTrustBalance,
          minimumOperatingBalance: showOperatingBalanceFilter ? minimumOperatingBalance : undefined,
          groupByClient,
        },
        offset: mattersCurrentPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort,
      },
    });

    if (error) {
      throw new Error(error);
    }

    const matters = useMemo(() => {
      const updatedSelectedMatters = { ...selectedMatters };
      const mattersOnCurrentPage = (data?.unbilledMatterList?.results || []).map((unbilledMatter) => {
        const matterTypeName = unbilledMatter.matterType?.name;
        const matterSummaryLine = getMatterDisplay(unbilledMatter, matterTypeName, true, false, true, true, true);
        const matterTitle = getMatterDisplay(unbilledMatter, matterTypeName, false, false, false, true, true);
        const matter = {
          matterId: unbilledMatter.matterId,
          hasDebtor: unbilledMatter?.clients?.length > 0,
          matterSummaryLine,
          totalAmount: unbilledMatter.unbilledAmountExcTax,
          writtenOffAmountExcTax: unbilledMatter.writtenOffAmountExcTax,
          unbillableAmount: unbilledMatter.nonBillableAmountExcTax,
          lastInvoicedDate: unbilledMatter.lastInvoicedDate,
          hasUnpaidAD: unbilledMatter.hasUnpaidAnticipatedDisbursements,
          // below fields are passed to the invoice-bulk-bill/lod endpoint so we don't have to fetch these again
          matterNumber: unbilledMatter.matterNumber,
          matterTitle,
          matterDescription: unbilledMatter.description,
          clientDisplay: unbilledMatter.clientDisplay,
          matterTypeName,
          clients: unbilledMatter.clients,
        };
        if (updatedSelectedMatters[matter.matterId]) {
          updatedSelectedMatters[matter.matterId] = matter;
        }
        return matter;
      });
      dispatch(bulkBillFeature.actions.setSelectedMatters(updatedSelectedMatters));
      return mattersOnCurrentPage;
    }, [data?.unbilledMatterList?.results, dispatch]);

    // group by client mode if applicable
    let clientMatters = [];
    let expandedClients = useSelector(bulkBillFeature.selectors.getExpandedClients);
    if (groupByClient) {
      const groupingResults = groupMattersByClient({ matters, expandedClients });
      clientMatters = groupingResults.clientMatters;
      expandedClients = groupingResults.expandedClients;
    }
    const onToggleExpandClient = (client) => {
      expandedClients[client.clientId] = !expandedClients[client.clientId];
      dispatch(bulkBillFeature.actions.setExpandedClients(expandedClients));
    };

    // pagination
    const mattersTotalCount = data?.unbilledMatterList?.totalCount || 0;
    const { hidePagination, totalNumberOfPages: mattersTotalNumberOfPages } = getPagination({
      totalCount: mattersTotalCount,
      loading: mattersLoading,
    });

    const mattersOnSort = (sortProps) => {
      if (sortProps.sortBy === 'matterSummaryLine') {
        setGroupByClientPrimarySortDirection(sortProps.sortDirection);
      }
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      clientMatters,
      expandedClients,
      onToggleExpandClient,
      hidePagination,
      mattersLoading,
      matters,
      mattersOnSort,
      mattersTotalCount,
      mattersTotalNumberOfPages,
    };
  },
  useLoadMatterEntriesData: () => {
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);
    const { taxRate: firmTaxRateBasisPoints } = firmTaxSettingsData?.firmTaxSettings || {};

    const { data } = useSubscribedQuery(InvoiceSettingsTemplateFirmDefault);
    const includeNonBillableItems =
      data?.invoiceSettingsTemplateFirmDefault?.settings?.defaultLayout?.includeNonBillableItems || false;

    const entryStartDate =
      !dateListFilter.filterType || dateListFilter.filterType === dateRangeTypes.ALL
        ? undefined
        : dateListFilter.startDate;
    const entryEndDate =
      !dateListFilter.filterType || dateListFilter.filterType === dateRangeTypes.ALL
        ? undefined
        : dateListFilter.endDate;

    const includeExpenseEntries = !featureActive('BB-9228') || entryTypesSelected.includes(entryTypeLabels.EXPENSE);
    const includeFeeEntries =
      !featureActive('BB-9228') ||
      entryTypesSelected.some((et) => [entryTypeLabels.FIXED, entryTypeLabels.TIME].includes(et));

    // Map entry type to actual Entry Types (integers)
    const entryTypes = featureActive('BB-9228') ? convertToIntEntryTypes(entryTypesSelected) : undefined;

    const feesResults = useSubscribedQuery(UnbilledMatterFees, {
      skip: !matterIdToShowUnbilledEntries || !includeFeeEntries,
      variables: {
        matterId: matterIdToShowUnbilledEntries,
        includeNonBillableItems,
        feeDate: {
          from: entryStartDate,
          to: entryEndDate,
        },
        entryTypes,
      },
    });

    const expensesResults = useSubscribedQuery(UnbilledMatterExpenses, {
      skip: !matterIdToShowUnbilledEntries || !includeExpenseEntries,
      variables: {
        matterId: matterIdToShowUnbilledEntries,
        includeNonBillableItems,
        expenseDate: {
          from: entryStartDate,
          to: entryEndDate,
        },
      },
    });

    const unbilledFees = feesResults.data?.unbilledFees || [];
    const unbilledExpenses = expensesResults.data?.unbilledExpenses || [];
    const loading = feesResults.loading || expensesResults.loading;

    const billableEntries = [];
    const nonBillableEntries = [];
    const preselectedExpenseIds = [];
    const preselectedFeeIds = [];
    if (!loading) {
      unbilledFees.forEach((feeEntry) => {
        // calculate amountExcTax since this is not calculated and stored in fee entry
        const entry = { ...feeEntry };
        const { amountExclTax } = calculateFeeAmountTax({
          fee: entry,
          taxRate: firmTaxRateBasisPoints,
          region: REGION,
        });
        entry.amountExcTax = amountExclTax; // sticking with graphql naming convention which uses amountExcTax

        // sort billable and non-billable entries
        if (entry.isBillable === false) {
          nonBillableEntries.push(entry);
        } else {
          billableEntries.push(entry);
        }
        preselectedFeeIds.push(entry.id);
      });

      unbilledExpenses.forEach((entry) => {
        if (entry.isBillable === false) {
          nonBillableEntries.push(entry);
        } else {
          billableEntries.push(entry);
        }
        preselectedExpenseIds.push(entry.id);
      });
    }

    return {
      matterEntriesLoading: loading,
      matterBillableEntries: billableEntries,
      matterNonBillableEntries: nonBillableEntries,
      preselectedExpenseIds,
      preselectedFeeIds,
    };
  },
});

// We sort the entry types so apollo caching works correctly
const convertToIntEntryTypes = (entryTypesSelected) =>
  (entryTypesSelected || []).map((entryTypeLabel) => entryTypeLabelToValueMap[entryTypeLabel]).sort();

export const BulkCreateInvoicesRouteContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(BulkCreateInvoicesRoute))),
);

BulkCreateInvoicesRouteContainer.displayName = 'BulkCreateInvoicesRouteContainer';

BulkCreateInvoicesRouteContainer.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

BulkCreateInvoicesRouteContainer.defaultProps = {};
