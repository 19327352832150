import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getLogger } from '@sb-itops/fe-logger';

import { useSelector } from 'react-redux';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getAuthToken, getAccessToken } from 'web/services/user-session-management';
import { getFirmDetails, getLoggedInUserId } from '@sb-firm-management/redux/firm-management';
import {
  WebQueryMatters,
  SbClientSdkRolesData,
  SbClientSdkContactsData,
  SbClientSdkAllStaffData,
  InitStaffSettings,
} from 'web/graphql/queries';
import uuid from '@sb-itops/uuid';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { subscribeToApolloEntityOpdateEvents } from 'web/services/apollo';
import { checkSbClientSdkWhitelistThrows } from 'web/services/sb-apigw';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { SelectFilesModalContainer } from 'web/containers';
import { CreateLetterModal } from 'web/components/documents-tab/modals';
import moment from 'moment';
import { useCacheQuery } from 'web/hooks';
import HtmlToRtfBrowser from '@sb-billing/html-to-rtf-browser';
import * as messageDisplay from '@sb-itops/message-display';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { fetchGetP } from '@sb-itops/redux/fetch';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import {
  clientSdkGetAllStaff,
  clientSdkGetContact,
  clientSdkGetMatter,
  clientSdkGetRoles,
  clientSdkGetStaff,
  clientSdkGetMatterItems,
  clientSdkGetRelationships,
} from './getters';
import { useClientSdkCreateEmail } from './hooks';
import { SbClientSdkIframe } from './SbClientSdkIframe';
import { mapContactToCommunicateRole } from './mappers';
import MapFiles from './mappers/map-files';
import { clientSdkGetFirm } from './getters/client-sdk-get-firm';

declare const SB_BUILD_VERSION: string;
const LOG = getLogger('SbClientSdk');
const htmlToRtf = new HtmlToRtfBrowser();

export const SDK_MODAL_ID = 'SDK_MODAL_ID';

export const SbClientSdk = withApolloClient(
  ({
    onClickLink,
    onClose,
    matterId,
    sdkAppUrl,
  }: {
    onClose?: () => void;
    onClickLink: (options: { type: string; id: string; params?: string[] }) => void;
    matterId?: string;
    sdkAppUrl: string;
  }) => {
    const showDebug = useSelector(supportDebugSelectors.getShowDebug);
    const appUrl = showDebug ? 'https://stagingsdk.smokeball.com.au/' : sdkAppUrl;
    const authToken: any = useSelector(() => getAuthToken());
    const accessToken: any = getAccessToken();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [sessionId] = useState(uuid());
    const subscriptions = useRef({});
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);
    const [showSelectFilesModal, setShowSelectFilesModal] = useState(false);
    const [showCreateLetterModal, setShowCreateLetterModal] = useState(false);
    const [selectFilesMessageId, setSelectFilesMessageId] = useState<string>();
    const [letterPayload, setLetterPayload] = useState<any>();
    const { clientSdkCreateEmail } = useClientSdkCreateEmail();

    const postMessage = useCallback(
      (messageId, payload) => {
        const contentWindow = iframeRef?.current?.contentWindow;
        if (!contentWindow) {
          return;
        }

        contentWindow.postMessage(
          {
            messageId,
            payload,
          },
          appUrl,
        );
      },
      [iframeRef, appUrl],
    );

    const navigateToTab = (tabnumber) => {
      // From SDK
      // export enum MatterTabs {
      //   Matter = 0,
      //   Emails = 1,
      //   Events = 2,
      //   Tasks = 3,
      //   Timeline = 4,
      //   InfoTrack = 5,
      //   Communicate = 8,
      //   Memos = 9,
      //   Activity = 10,
      //   Parenting = 11,
      //   Property = 12,
      //   MatterPerformance = 13,
      //   Intake = 14
      // }
      // TODO map links properly
      switch (tabnumber) {
        case 0:
          if (matterId) {
            onClickLink({ type: 'matter', id: matterId });
          }
          break;
        // unimplemented
        // case 2:
        //   break;
        // case 3:
        //   break;
        // case 8:
        //   break;
        // case 9:
        //   break;
        // case 1:
        // case 4:
        // case 5:
        // case 10:
        // case 11:
        // case 12:
        // case 13:
        // case 14:
        default:
          break;
      }
    };

    type SupportedUpdates = 'Contact' | 'Matter' | 'AllStaff' | 'MatterRoles';

    const handleEntityUpdate = async (id: string, entityType: SupportedUpdates, needsReload = false) => {
      if (!id || !entityType) {
        return;
      }

      switch (entityType) {
        case 'Contact': {
          if (subscriptions.current?.['contacts.observe']) {
            const contact = await clientSdkGetContact(id, needsReload);

            postMessage(subscriptions.current['contacts.observe'], { contacts: [contact] });
          }

          break;
        }
        case 'Matter': {
          if (subscriptions.current[id]) {
            const m = await clientSdkGetMatter(id, needsReload);
            postMessage(subscriptions.current[id], m);
          }

          break;
        }
        case 'AllStaff': {
          if (subscriptions.current?.['staff.observe']) {
            const staff = await clientSdkGetAllStaff();
            postMessage(subscriptions.current['staff.observe'], { staff });
          }

          break;
        }
        case 'MatterRoles': {
          if (subscriptions.current['roles.observe']) {
            if (matterId) {
              const roles = await clientSdkGetRoles(matterId);
              postMessage(subscriptions.current['roles.observe'], [roles]);
            }
          }

          break;
        }
        default:
          LOG.warn('unhandled entityType: ', entityType);
      }
    };

    type TApolloOpdate = {
      eventType: 'ENTITY_UPDATED' | 'ENTITY_ADDED' | 'ENTITY_REMOVED';
      typeName: 'Contact';
      optimisticEntity: unknown[] | unknown;
    };

    useEffect(() => {
      const unsubOpdates = subscribeToApolloEntityOpdateEvents({
        onEntityOpdated: ({ optimisticEntity, typeName, eventType }: TApolloOpdate) => {
          if (eventType !== 'ENTITY_UPDATED') {
            return;
          }

          if (!optimisticEntity) {
            return;
          }

          const opEnts = Array.isArray(optimisticEntity) ? optimisticEntity : [optimisticEntity];

          switch (typeName) {
            case 'Contact': {
              // eslint-disable-next-line no-restricted-syntax
              for (const opEnt of opEnts) {
                if (opEnt.id) {
                  handleEntityUpdate(opEnt.id, 'Contact');
                }
              }
              break;
            }
            // I would like to handle other things here but we have not implemented updating them in sdk yet.
            default: {
              // this is ok we don't handle all opdates
            }
          }
        },
      });

      const unsubMatterData = subscribeToNotifications({
        notificationIds: WebQueryMatters.notificationIds,
        callback: async (payload) => {
          const payloadSplit = payload.split('|');
          if (payloadSplit.length !== 2) {
            return;
          }
          const [action, id] = payloadSplit;
          if (action === 'MatterUpdated') {
            handleEntityUpdate(id, 'Matter', true);
          }
        },
      });

      const unsubContactData = subscribeToNotifications({
        notificationIds: SbClientSdkContactsData.notificationIds,
        callback: (payload) => {
          const [notType, id] = payload.split('|');
          if (notType === 'EntityUpdated') {
            handleEntityUpdate(id, 'Contact', true);
          }
        },
      });

      const unsubStaffData = subscribeToNotifications({
        notificationIds: SbClientSdkAllStaffData.notificationIds,
        callback: async (payload) => {
          const payloadSplit = payload.split('|');
          if (payloadSplit.length !== 2) {
            return;
          }
          const [action, id] = payloadSplit;

          if (id && action === 'PersonUpdated') {
            handleEntityUpdate(id, 'AllStaff', true);
          }
        },
      });

      const unsubRoleData = subscribeToNotifications({
        notificationIds: SbClientSdkRolesData.notificationIds,
        callback: async (payload) => {
          const payloadSplit = payload.split('|');
          if (payloadSplit.length !== 2) {
            return;
          }
          const [action, id] = payloadSplit;
          if (action === 'RoleEntityMappingUpdated' && id === matterId) {
            // this actually listens for changes on matter not roles
            handleEntityUpdate(id, 'MatterRoles', true);
          }
        },
      });

      return () => {
        unsubOpdates();
        unsubMatterData();
        unsubContactData();
        unsubStaffData();
        unsubRoleData();
      };
    }, []);

    useEffect(() => {
      let clientId: string | undefined;
      const SbClientSdkMessageHandler = async (e) => {
        const hostURL = new URL(appUrl);
        const origin = hostURL.origin;
        if (e.origin !== origin) {
          return;
        }

        switch (e.data.operation) {
          case 'system.init': {
            const incommingClientId = e.data.payload.clientId;
            if (incommingClientId) {
              clientId = incommingClientId;
            }
            postMessage(e.data.messageId, {
              sessionId,
              firmId: getFirmDetails().accountId,
              userId: getLoggedInUserId(),
              matterId,
            });
            break;
          }
          case 'firm.get': {
            const m = await clientSdkGetFirm();
            postMessage(e.data.messageId, m);
            break;
          }
          case 'matters.get': {
            const m = await clientSdkGetMatter(e.data.payload.id || matterId);
            postMessage(e.data.messageId, m);
            break;
          }
          case 'contacts.get': {
            const contact = await clientSdkGetContact(e.data.payload.id);
            postMessage(e.data.messageId, contact);
            break;
          }
          case 'staff.get': {
            const staff = await clientSdkGetStaff(e.data.payload.id);
            postMessage(e.data.messageId, staff);
            break;
          }
          case 'staff.all': {
            const staff = await clientSdkGetAllStaff();
            postMessage(e.data.messageId, staff);
            break;
          }
          case 'roles.get': {
            const roles = await clientSdkGetRoles(e.data.payload.matterId || matterId);
            postMessage(e.data.messageId, roles);
            break;
          }
          case 'relationships.get': {
            if (!matterId) {
              throw 'Matter id missing';
            }

            const relationships = await clientSdkGetRelationships(
              e.data.payload.matterId || matterId,
              e.data.payload.roleId,
            );
            postMessage(e.data.messageId, relationships);
            break;
          }
          case 'memos.openNew': {
            if (!matterId) {
              messageDisplay.error(`Cannot open a new memo when not inside a matter`);
              break;
            }
            const marshalledData = {
              id: uuid(),
              matterId,
              title: e.data.payload.title,
              createdByStaff: staffData?.loggedInStaff,
              userStaff: staffData?.loggedInStaff,
              createdDate: moment().toISOString(),
              timestamp: moment().toISOString(),
              rtf: htmlToRtf.convertHtmlToRtf(e.data.payload.text),
              isDeleted: false,
              text: Buffer.from(htmlToRtf.convertHtmlToRtf(e.data.payload.text)).toString('base64'),
            };
            await dispatchCommand({ type: 'MatterManagement.Memos.Messages.AddMemo', message: marshalledData });
            onClickLink({ id: matterId!, type: 'matterMemos', params: [marshalledData.id] });
            break;
          }
          case 'matterItems.get': {
            const matterItems = await clientSdkGetMatterItems(e.data.payload.matterId || matterId);
            postMessage(e.data.messageId, matterItems);
            break;
          }
          case 'layouts.get': {
            // TODO
            postMessage(e.data.messageId, {
              matterId,
              versionId: '',
            });
            break;
          }
          // case 'layouts.observe':
          case 'contacts.observe':
          case 'roles.observe':
          case 'staff.observe': {
            subscriptions.current = { ...subscriptions.current, [e.data.operation]: e.data.messageId };
            break;
          }
          case 'matters.observe': {
            subscriptions.current = { ...subscriptions.current, [e.data.payload.id]: e.data.messageId };
            break;
          }
          case 'host.version': {
            const split = SB_BUILD_VERSION.split('.');
            const major = split[0] || 0;
            const minor = split[1] || 0;
            const build = split[2] || 0;
            const revision = SB_BUILD_VERSION;
            postMessage(e.data.messageId, {
              major,
              minor,
              build,
              revision,
            });
            break;
          }
          case 'host.close': {
            if (onClose) {
              onClose();
            } else {
              LOG.error('unimplemented sdk call:', e.data);
            }
            break;
          }
          case 'files.open': {
            try {
              const fileId = e.data.payload?.id;
              const versionId = e.data.payload?.versionId;
              let path = `/matter-management/matter/file/:accountId/${matterId}/${fileId}/download`;
              if (versionId) {
                path += `?versionId=${versionId}`;
              }

              const [
                { body: file },
                {
                  body: { downloadUrl },
                },
              ] = await Promise.all([
                fetchGetP({
                  path: `/matter-management/matter/file/:accountId/${matterId}/${fileId}`,
                  fetchOptions: {},
                }),
                fetchGetP({
                  path,
                  fetchOptions: {},
                }),
              ]);

              const pdfBlob = await fetch(downloadUrl).then((res) => res.blob());
              const downloadBlobURL = URL.createObjectURL(pdfBlob);

              const link = document.createElement('a');
              link.download = file.name + file.fileExtension;

              link.href = downloadBlobURL;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(downloadBlobURL);
            } catch (err: any) {
              LOG.error(err);
              messageDisplay.error('Something went wrong downloading your document. Please try again later');
            }
            break;
          }
          case 'files.select': {
            setSelectFilesMessageId(e.data.messageId);
            setShowSelectFilesModal(true);
            break;
          }
          case 'host.selectTab': {
            // TODO
            navigateToTab(e.data.tab);
            break;
          }
          case 'host.modal': {
            setModalDialogVisible({
              modalId: SDK_MODAL_ID,
              props: {
                matterId,
                url: e.data.payload?.url,
                title: e.data.payload?.title,
              },
            });
            break;
          }
          case 'auth.token': {
            try {
              if (!clientId) {
                throw new Error('client asked for auth.token but clientId not set');
              }

              await checkSbClientSdkWhitelistThrows(clientId);
              postMessage(e.data.messageId, accessToken);
            } catch (err) {
              LOG.warn('sdk client auth failed', err);
              postMessage(e.data.messageId, null);
            }

            break;
          }
          case 'auth.idToken': {
            try {
              if (!clientId) {
                throw new Error('client asked for auth.token but clientId not set');
              }

              await checkSbClientSdkWhitelistThrows(clientId);
              postMessage(e.data.messageId, authToken);
            } catch (err) {
              LOG.warn('sdk client auth failed', err);
              postMessage(e.data.messageId, null);
            }

            break;
          }
          // TODO: Change createMessage implementation to open a modal
          case 'communicate.createMessage':
          case 'communicate.sendMessage': {
            try {
              const contactIds: string[] = e.data.payload.contactIds || [];
              const matterRoles = await clientSdkGetRoles(e.data.payload.matterId || matterId);
              const m = await clientSdkGetMatter(e.data.payload.matterId || matterId);

              if (!m) {
                throw new Error('missing matter, cannot create message');
              }

              const contacts = await Promise.all(contactIds.map((id) => clientSdkGetContact(id)));
              const commRoles = contacts.map((c) => mapContactToCommunicateRole(c, matterRoles.roles));

              const command = {
                message: {
                  authToken,
                  message: e.data.payload.message,
                  matterId: e.data.payload.matterId || matterId,
                  conversationDetails: {
                    matterId: e.data.payload.matterId || matterId,
                    userIds: e.data.payload.staffIds || [],
                    description: m.reLine || '',
                    descriptionAutomation: m.descriptionAutomation || '',
                    externalRoles: commRoles || [],
                    SmsPhoneNumber: [],
                    matterNumber: m.number || '',
                    IsMfaRequired: false,
                  },
                  files: e.data.payload.attachments,
                  source: 'sdk.communicate.sendMessage',
                },
                type: 'ItOps.Communicate.Messages.AddFilesToCommunicate',
              };

              await dispatchCommand(command);
              LOG.info(`dispatched`, command);
            } catch (err) {
              LOG.error('failed to send communicate message', err);
            }

            break;
          }
          case 'contacts.update': {
            const payload = e.data.payload;

            const command = {
              type: 'CustomerManagement.PatchSmokeballContact',
              message: {
                contactId: payload.id,
                smokeballContact: {
                  person: payload.person && { email: payload.person.email },
                  company: payload.company && { email: payload.company.email },
                },
              },
            };

            await dispatchCommand(command);
            const contact = await clientSdkGetContact(payload.id);
            postMessage(e.data.messageId, contact);

            break;
          }
          case 'correspondence.letter': {
            setLetterPayload(e.data.payload);
            setShowCreateLetterModal(true);
            break;
          }
          case 'correspondence.email': {
            clientSdkCreateEmail(matterId, e.data.payload);
            break;
          }
          // This is a non-standard SDK api, implemented so integrated products can navigate around the billing main pages which is currently unsupported by the SDK (which only supports matter tabs)
          case 'host.webNavigate': {
            onClickLink({
              type: e.data?.type || e.data?.payload?.type,
              id: e.data?.id || e.data?.payload?.type,
              params: e.data?.params || e.data?.payload?.params,
            });
            break;
          }
          default:
            LOG.error('unimplemented sdk call:', e.data);
        }
      };
      window.addEventListener('message', SbClientSdkMessageHandler);
      return () => {
        window.removeEventListener('message', SbClientSdkMessageHandler);
      };
    }, []);

    const props = { sdkAppUrl: appUrl };

    return (
      <>
        <SbClientSdkIframe ref={iframeRef} {...props} />
        {showSelectFilesModal && matterId && (
          <SelectFilesModalContainer
            matterId={matterId}
            onSelectDocuments={(documents) =>
              Promise.resolve(
                postMessage(
                  selectFilesMessageId,
                  documents.map((d) => MapFiles(d)).filter((d) => d != null),
                ),
              )
            }
            onCancel={() => postMessage(selectFilesMessageId, [])}
            onClose={() => {
              setShowSelectFilesModal(false);
              setSelectFilesMessageId(undefined);
            }}
          />
        )}
        {showCreateLetterModal && matterId && (
          <CreateLetterModal
            matterId={matterId}
            selectedEntityId={letterPayload?.to?.[0]?.entityId}
            body={letterPayload?.body}
            onClose={() => setShowCreateLetterModal(false)}
          />
        )}
      </>
    );
  },
);
