import { useCacheQuery } from 'web/hooks';
import { InitFirmUtbmsSettings } from 'web/graphql/queries';
import { facets, hasFacet } from '@sb-itops/region-facets';

/**
 * useFirmUtbmsSettings
 *
 * @returns {Object} Containing select firm UTBMS settings
 */
export function useFirmUtbmsSettings() {
  const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

  const isUtbmsFacetActive = hasFacet(facets.utbms);

  const isUtbmsEnabledForFirm = isUtbmsFacetActive && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled;
  const areUtbmsCodesRequiredByFirm =
    isUtbmsFacetActive && isUtbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;

  return {
    areUtbmsCodesRequiredByFirm,
    isUtbmsEnabledForFirm,
  };
}
