import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, SlidingToggle, useTranslation } from '@sb-itops/react';
import classnames from 'classnames';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';

import { ControlledMoneyAccountsTable } from '../controlled-money-accounts-table';
import { ControlledMoneyAccountAddEditModal } from '../controlled-money-account-add-edit-modal';
import { ControlledMoneyAccountCloseReopenModal } from '../controlled-money-account-close-reopen-modal';
import { AddBankFeeInterestFormModal } from '../bank-fee-interest-add-modal';
import { ControlledMoneyLedgerTransactionTable } from '../controlled-money-ledger-transaction-table';

import Styles from './MatterControlledMoneyAccounts.module.scss';

const EDIT_CMA_MODAL = 'edit-cma-modal';
const ADD_CMA_MODAL = 'add-cma-modal';
const CLOSE_REOPEN_CMA_MODAL = 'close-reopen-cma-modal';
const BANK_FEE_MODAL = 'bank-fee-modal';
const BANK_INTEREST_MODAL = 'bank-interest-modal';

export const MatterControlledMoneyAccounts = ({
  matterId,
  cmaId,
  cmaAccountName,
  cmaBsb,
  cmaAccountNumber,
  isCmaInactive,
  showClosedAccounts,
  toggleClosedAccounts,
  controlledMoneyAccountsByMatter,
  onTransactionsSort,
  onAccountsSort,
  sortAccountsDirection,
  sortAccountsBy,
  sortTransactionsDirection,
  sortTransactionsBy,
  onClickLink,
  transactions,
  feesButtonLabel,
  modal,
  openDepositFundsModal,
  openVendorPaymentEntrySplitPayorsModal,
  downloadReport,
  isDownloading,
  addToVisibleClosedIdMap,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState('');

  const onCloseModal = () => {
    setShowModal('');
  };

  return (
    <div className={classnames('panel-body', Styles.controlledMoneyAccounts)}>
      {(!cmaId || cmaId === 'all') && (
        <>
          <div className={classnames('ribbon', 'panel', 'panel-primary', Styles.ribbon)}>
            <Button onClick={() => setShowModal(ADD_CMA_MODAL)}>Add Account</Button>
            <ControlledMoneyAccountAddEditModal
              isVisible={showModal === ADD_CMA_MODAL}
              onClose={onCloseModal}
              matterId={matterId}
              onClickLink={onClickLink}
            />
            {cmaId === 'all' && (
              <div className={Styles.toggleContainer}>
                <SlidingToggle
                  scope="showClosedAccounts"
                  name="showClosedAccounts"
                  selected={showClosedAccounts}
                  onChange={toggleClosedAccounts}
                />
                <div className={Styles.labelContainer}>Show Closed Accounts</div>
              </div>
            )}
          </div>
          <ControlledMoneyAccountsTable
            matterId={matterId}
            showClosedAccounts={showClosedAccounts}
            cmAccounts={controlledMoneyAccountsByMatter}
            onSort={onAccountsSort}
            sortBy={sortAccountsBy}
            sortDirection={sortAccountsDirection}
            onClickLink={onClickLink}
            addToVisibleClosedIdMap={addToVisibleClosedIdMap}
          />
        </>
      )}
      {cmaId && cmaId !== 'all' && (
        <>
          <div className={classnames(Styles.ribbon, Styles.header)}>
            <div className={classnames(Styles.title, 'capitalise')}>{cmaAccountName}</div>
            <div className={Styles.subtitle}>{`${t('bankBranchNumber')}: ${cmaBsb} | Acc: ${cmaAccountNumber}`}</div>
          </div>
          <div className={classnames('ribbon', 'panel', 'panel-primary')}>
            <Button onClick={openDepositFundsModal} disabled={isCmaInactive}>
              Deposit Funds
            </Button>
            <Button onClick={openVendorPaymentEntrySplitPayorsModal} disabled={isCmaInactive}>
              Payment
            </Button>
            <Button onClick={() => setShowModal(BANK_FEE_MODAL)} disabled={isCmaInactive}>
              {feesButtonLabel}
            </Button>
            <Button onClick={() => setShowModal(BANK_INTEREST_MODAL)} disabled={isCmaInactive}>
              Interest
            </Button>
            <AddBankFeeInterestFormModal
              isVisible={showModal === BANK_FEE_MODAL || showModal === BANK_INTEREST_MODAL}
              onClose={onCloseModal}
              matterId={matterId}
              bankAccountId={cmaId}
              isFee={showModal === BANK_FEE_MODAL}
              allowFutureDate
            />
            <Button
              locked={isDownloading}
              disabled={isDownloading}
              onClick={() => {
                const matter = getMatterById(matterId);
                downloadReport({ matter, showHidden: false, bankAccountId: cmaId });
              }}
            >
              Print
            </Button>

            <div className={Styles.separator} />

            {isCmaInactive ? (
              <Button onClick={() => setShowModal(CLOSE_REOPEN_CMA_MODAL)}>Reopen Account</Button>
            ) : (
              <Button onClick={() => setShowModal(EDIT_CMA_MODAL)}>Account Settings</Button>
            )}
            <ControlledMoneyAccountAddEditModal
              isVisible={showModal === EDIT_CMA_MODAL}
              onClose={onCloseModal}
              matterId={matterId}
              accountId={cmaId}
              onAccountClose={() => setShowModal(CLOSE_REOPEN_CMA_MODAL)}
            />
            {showModal === CLOSE_REOPEN_CMA_MODAL && (
              <ControlledMoneyAccountCloseReopenModal
                onClose={onCloseModal}
                matterId={matterId}
                bankAccountId={cmaId}
              />
            )}
          </div>
          <ControlledMoneyLedgerTransactionTable
            transactions={transactions}
            sortDirection={sortTransactionsDirection}
            sortBy={sortTransactionsBy}
            onSort={onTransactionsSort}
            onClickLink={onClickLink}
            modal={modal}
          />
        </>
      )}
    </div>
  );
};

MatterControlledMoneyAccounts.displayName = 'MatterControlledMoneyAccounts';

MatterControlledMoneyAccounts.propTypes = {
  matterId: PropTypes.string.isRequired,
  cmaId: PropTypes.string.isRequired,
  cmaAccountName: PropTypes.string,
  cmaBsb: PropTypes.string,
  cmaAccountNumber: PropTypes.string,
  isCmaInactive: PropTypes.bool.isRequired,
  showClosedAccounts: PropTypes.bool,
  isDownloading: PropTypes.bool,
  toggleClosedAccounts: PropTypes.func.isRequired,
  controlledMoneyAccountsByMatter: PropTypes.array,
  onAccountsSort: PropTypes.func.isRequired,
  onTransactionsSort: PropTypes.func.isRequired,
  modal: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object),
  sortAccountsDirection: PropTypes.oneOf(['asc', 'desc']),
  sortAccountsBy: PropTypes.string,
  sortTransactionsBy: PropTypes.string,
  sortTransactionsDirection: PropTypes.oneOf(['asc', 'desc']),
  onClickLink: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
  openVendorPaymentEntrySplitPayorsModal: PropTypes.func,
  openDepositFundsModal: PropTypes.func.isRequired,
  addToVisibleClosedIdMap: PropTypes.func,
  feesButtonLabel: PropTypes.string.isRequired,
};

MatterControlledMoneyAccounts.defaultProps = {
  transactions: [],
  sortAccountsDirection: undefined,
  sortAccountsBy: undefined,
  sortTransactionsDirection: undefined,
  sortTransactionsBy: undefined,
  showClosedAccounts: false,
  isDownloading: false,
  controlledMoneyAccountsByMatter: [],
  openVendorPaymentEntrySplitPayorsModal: undefined,
  downloadReport: undefined,
  cmaAccountName: undefined,
  cmaBsb: undefined,
  cmaAccountNumber: undefined,
  addToVisibleClosedIdMap: undefined,
};
