import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient, withReduxProvider } from 'web/react-redux/hocs';
import { setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { DEPOSIT_FUNDS_MODAL_ID } from 'web/components';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import { debounce, sortByOrder } from '@sb-itops/nodash';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import { isStatutoryDepositMatter, getBankAccountName } from '@sb-billing/business-logic/bank-account/services';
import { useContactTypeaheadData, useFirmUtbmsSettings, useSubscribedLazyQuery, useSubscribedQuery } from 'web/hooks';
import {
  MatterSummaries,
  ContactOption,
  MatterSummaryData,
  MatterTrustBankAccountsData,
  BankAccountDetails,
  BankReconciliationLatestCompletedData,
  MatterBalanceTrustAsOfDate,
  DepositFundsMatterData,
} from 'web/graphql/queries';
import {
  bankAccountState,
  bankAccountStateByValue,
  bankAccountTypeEnum,
} from '@sb-billing/business-logic/bank-account/entities/constants';
import { DepositFundsModalFormsContainer } from './DepositFundsModal.forms.container';

const hooks = () => ({
  useDepositFundsModalProps: () => {
    const onModalClose = () => setModalDialogHidden({ modalId: DEPOSIT_FUNDS_MODAL_ID });

    return {
      onModalClose,
    };
  },
  useDefaultData: ({ matterId, contactId }) => {
    const {
      data: matterData,
      loading: defaultMatterDataLoading,
      error: matterDataError,
    } = useSubscribedQuery(MatterSummaryData, {
      skip: !matterId,
      variables: {
        id: matterId,
      },
    });

    const {
      data: defaultContactData,
      loading: defaultContactDataLoading,
      error: defaultContactDataError,
    } = useSubscribedQuery(ContactOption, {
      skip: !contactId,
      variables: {
        contactId,
      },
    });

    if (matterDataError) {
      throw new Error(matterDataError);
    }
    if (defaultContactDataError) {
      throw new Error(defaultContactDataError);
    }

    return {
      defaultMatterLoading: defaultMatterDataLoading,
      defaultContactLoading: defaultContactDataLoading,
      defaultMatter: matterData?.matter,
      defaultContact: defaultContactData?.contact,
    };
  },
  useBankAccountDetails: () => {
    const variables = {
      bankAccountFilter: {
        accountTypes: [bankAccountTypeEnum.TRUST],
        // To determine statutory deposit matter (SDM) ids we use all trust accounts, including closed ones.
        // This is because we allow closing trust accounts which has SDM associated. We need to cover scenario when we
        // deposit into open trust account on matter, which is SDM for another trust account, which may be open or closed.
        state: hasFacet(facets.statutoryDepositMatter)
          ? [bankAccountStateByValue[bankAccountState.OPEN], bankAccountStateByValue[bankAccountState.CLOSED]]
          : [bankAccountStateByValue[bankAccountState.OPEN]],
      },
      includeBeneficiaries: false,
    };
    if (hasFacet(facets.operatingAccount)) {
      variables.bankAccountFilter.accountTypes.push(bankAccountTypeEnum.OPERATING);
    }

    const {
      data: bankAccountsData,
      loading: bankAccountsLoading,
      error: bankAccountsError,
    } = useSubscribedQuery(BankAccountDetails, {
      variables,
    });

    if (bankAccountsError) {
      throw new Error(bankAccountsError);
    }

    return {
      bankAccounts: bankAccountsData?.bankAccounts,
      isBankAccountsLoading: bankAccountsLoading,
    };
  },
  useMatterTrustBankAccountsData: () => {
    const [lastMatterId, setLastMatterId] = useState();
    const [getMatterTrustBankAccounts, matterTrustBankAccountsResult] = useSubscribedLazyQuery(
      MatterTrustBankAccountsData,
      {
        variables: {
          filter: {
            checkTransactions: true,
            state: [bankAccountStateByValue[bankAccountState.OPEN]],
          },
        },
      },
    );

    const onFetchMatterTrustBankAccountIds = ({ matterId } = {}) => {
      setLastMatterId(matterId);

      if (!matterId) {
        return null;
      }

      return getMatterTrustBankAccounts({ variables: { matterId } });
    };

    // memo to get ids only
    const matterTrustBankAccountIds = useMemo(() => {
      if (
        !lastMatterId ||
        matterTrustBankAccountsResult.loading ||
        matterTrustBankAccountsResult.variables?.matterId !== lastMatterId
      ) {
        return undefined; // We return undefined so we can skip validation until after data is fetched
      }

      return matterTrustBankAccountsResult.data?.matterTrustBankAccounts?.map(({ id }) => id) || [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMatterId, matterTrustBankAccountsResult.loading, matterTrustBankAccountsResult.data]);

    return {
      matterTrustBankAccountIdsLoading: matterTrustBankAccountsResult.loading,
      matterTrustBankAccountIds,
      onFetchMatterTrustBankAccountIds,
    };
  },
  useMatterSummariesData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(MatterSummaries, {
      context: { skipRequestBatching: true },
      variables: {
        includeMatterHourlyRate: false,
        filter: {
          matterStatus: ['pending', 'open'],
        },
        limit: 25,
        sort: {
          fieldNames: ['statusOpen', 'matterStarted'],
          directions: ['DESC', 'DESC'],
        },
      },
    });

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
  useFirmUtbmsSettings: () => {
    const { isUtbmsEnabledForFirm } = useFirmUtbmsSettings();

    return {
      isUtbmsEnabledForFirm,
    };
  },
  useReceivedFromTypeaheadData: () => {
    const {
      contactOptions: receivedFromContactOptions,
      contactOptionsDataLoading: receivedFromContactOptionsDataLoading,
      contactOptionsHasMore: receivedFromContactOptionsHasMore,
      onFetchContactOptions: onFetchReceivedFromContactOptions,
      onFetchMoreContactOptions: onFetchMoreReceivedFromContactOptions,
    } = useContactTypeaheadData();

    return {
      receivedFromContactOptions,
      receivedFromContactOptionsDataLoading,
      receivedFromContactOptionsHasMore,
      onFetchReceivedFromContactOptions,
      onFetchMoreReceivedFromContactOptions,
    };
  },
  useDrawerTypeaheadData: () => {
    const {
      contactOptions: drawerContactOptions,
      contactOptionsDataLoading: drawerContactOptionsDataLoading,
      contactOptionsHasMore: drawerContactOptionsHasMore,
      onFetchContactOptions: onFetchDrawerContactOptions,
      onFetchMoreContactOptions: onFetchMoreDrawerContactOptions,
    } = useContactTypeaheadData();

    return {
      drawerContactOptions,
      drawerContactOptionsDataLoading,
      drawerContactOptionsHasMore,
      onFetchDrawerContactOptions,
      onFetchMoreDrawerContactOptions,
    };
  },
  useBankReconciliationData: () => {
    const [getBankRecLatestCompleted, bankRecLatestCompletedResults] = useSubscribedLazyQuery(
      BankReconciliationLatestCompletedData,
      {
        variables: {},
      },
    );

    const onFetchBankRecLatestCompleted = ({ bankAccountId }) => {
      if (!bankAccountId) {
        // eslint-disable-next-line no-console
        console.warn('onFetchBankRecLatestCompleted missing bankAccountId');
        return;
      }

      getBankRecLatestCompleted({
        variables: {
          bankAccountId,
        },
      });
    };

    const { bankReconciliationLatestCompletedByBankAccount, bankReconciliationSetup } =
      bankRecLatestCompletedResults?.data || {};

    return {
      bankReconciliationLatestCompletedByBankAccount,
      bankReconciliationSetup,
      bankReconciliationLoading: bankRecLatestCompletedResults.loading,
      onFetchBankRecLatestCompleted,
    };
  },
  useMatterTrustBalance: () => {
    const [lastParams, setLastParams] = useState({ matterId: undefined, effectiveDate: undefined });
    const [getMatterTrustBalances, matterTrustBalancesResult] = useSubscribedLazyQuery(MatterBalanceTrustAsOfDate, {
      variables: {},
    });

    // We need balance only for statutory deposit matter validation so we can skip otherwise
    const supportsStatutoryDepositMatter = hasFacet(facets.statutoryDepositMatter);

    const onFetchMatterTrustBalances = ({ matterId, effectiveDate }) => {
      setLastParams({ matterId, effectiveDate });
      if (!supportsStatutoryDepositMatter || !matterId || !effectiveDate) {
        return null;
      }

      return getMatterTrustBalances({ variables: { matterId, balanceAsOfDate: effectiveDate } });
    };

    const matterTrustBalancesMap = useMemo(() => {
      if (!supportsStatutoryDepositMatter) {
        return {};
      }

      if (
        !lastParams.matterId ||
        !lastParams.effectiveDate ||
        matterTrustBalancesResult.loading ||
        matterTrustBalancesResult.variables?.matterId !== lastParams.matterId ||
        matterTrustBalancesResult.variables?.balanceAsOfDate !== lastParams.effectiveDate
      ) {
        return undefined;
      }

      return (matterTrustBalancesResult?.data?.matterBalanceTrust || []).reduce((acc, mb) => {
        if (mb.matterId === lastParams.matterId) {
          acc[mb.bankAccountId] = mb;
        }
        return acc;
      }, {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportsStatutoryDepositMatter, lastParams, matterTrustBalancesResult.data, matterTrustBalancesResult.loading]);

    return {
      matterTrustBalancesMap,
      matterTrustBalancesLoading: matterTrustBalancesResult.loading,
      onFetchMatterTrustBalances,
    };
  },
  useMatterDetails: () => {
    const [lastMatterId, setLastMatterId] = useState();

    const [getMatterDetails, matterDetailsResult] = useSubscribedLazyQuery(DepositFundsMatterData, {
      variables: {},
    });

    const onFetchMatterDetails = ({ matterId }) => {
      setLastMatterId(matterId);
      if (!matterId) {
        return null;
      }
      return getMatterDetails({ variables: { matterId } });
    };

    const matterDetails = useMemo(() => {
      if (!lastMatterId || matterDetailsResult.loading || matterDetailsResult.variables?.matterId !== lastMatterId) {
        return undefined;
      }

      return matterDetailsResult.data?.matter;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMatterId, matterDetailsResult.data, matterDetailsResult.loading]);

    return {
      matterDetailsLoading: matterDetailsResult.loading,
      onFetchMatterDetails,
      matterDetails,
    };
  },
});

const dependentHooks = () => ({
  useCombineBankAccountsWithCMA: ({ isBankAccountsLoading, bankAccounts }) => {
    const { t } = useTranslation();
    const [lastMatterId, setLastMatterId] = useState();

    const [getControlledMoneyAccounts, controlledMoneyAccountsResult] = useSubscribedLazyQuery(BankAccountDetails, {
      variables: {},
    });

    const onFetchControlledMoneyAccounts = ({ matterId } = {}) => {
      if (!hasFacet(facets.CMA)) {
        return null;
      }

      setLastMatterId(matterId);

      if (!matterId) {
        return null;
      }

      const variables = {
        bankAccountFilter: {
          accountTypes: [bankAccountTypeEnum.CONTROLLEDMONEY],
          state: [bankAccountStateByValue[bankAccountState.OPEN]],
          associatedMatterId: matterId,
        },
        includeBeneficiaries: true,
      };

      return getControlledMoneyAccounts({
        variables,
      });
    };

    const controlledMoneyAccounts = controlledMoneyAccountsResult.data?.bankAccounts;

    const { bankAccountOptions, trustAccountsWithStatutoryDepositMatter } = useMemo(
      () => processBankAccounts({ bankAccounts, controlledMoneyAccounts, t, matterId: lastMatterId }),
      [bankAccounts, controlledMoneyAccounts, t, lastMatterId],
    );

    const checkIsStatutoryDepositMatter = ({ matterId }) => {
      if (isBankAccountsLoading || !matterId) {
        return false;
      }

      return isStatutoryDepositMatter({
        matterId,
        trustBankAccounts: trustAccountsWithStatutoryDepositMatter,
        supportsStatutoryDepositMatter: hasFacet(facets.statutoryDepositMatter),
      });
    };

    return {
      bankAccountOptions,
      bankAccountOptionsLoading: isBankAccountsLoading || controlledMoneyAccountsResult.loading,
      checkIsStatutoryDepositMatter,
      onFetchControlledMoneyAccounts,
    };
  },
});

const processBankAccounts = ({ bankAccounts, controlledMoneyAccounts, t, matterId }) => {
  const { trust, operating, trustAccountsWithStatutoryDepositMatter } = (bankAccounts || []).reduce(
    (acc, ba) => {
      if (ba.accountType === bankAccountTypeEnum.OPERATING) {
        acc.operating.push({ label: t('operatingRetainer'), value: ba.id, data: ba });
      } else if (ba.accountType === bankAccountTypeEnum.TRUST) {
        if (ba.state !== bankAccountState.CLOSED) {
          acc.trust.push({ label: getBankAccountName(ba, t), value: ba.id, data: ba });
        }
        if (ba.statutoryDepositMatterId) {
          acc.trustAccountsWithStatutoryDepositMatter.push(ba);
        }
      }

      return acc;
    },
    { trust: [], operating: [], trustAccountsWithStatutoryDepositMatter: [] },
  );

  const cmaUnsorted = (controlledMoneyAccounts || []).reduce((acc, cma) => {
    if (cma.associatedMatterId === matterId && cma.state !== bankAccountState.CLOSED) {
      acc.push({ label: getBankAccountName(cma, t), value: cma.id, data: cma });
    }

    return acc;
  }, []);

  const sortedTrust = sortByOrder(trust, ['label'], ['asc']);
  const sortedCMA = sortByOrder(cmaUnsorted, ['label'], ['asc']);

  return {
    bankAccountOptions: sortedTrust.concat(sortedCMA).concat(operating),
    trustAccountsWithStatutoryDepositMatter,
  };
};

export const DepositFundsModalContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(DepositFundsModalFormsContainer))),
);

DepositFundsModalContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  bankAccountId: PropTypes.string,
  onClickLink: PropTypes.func.isRequired,
};

DepositFundsModalContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  bankAccountId: undefined,
};

DepositFundsModalContainer.displayName = 'DepositFundsModalContainer';
