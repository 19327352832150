import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

angular.module('sb.billing.webapp').directive('sbActionRequestRetainer', function (sbTrustRetainersService, sbMessageDisplayService, sbLoggerService) {
  'use strict';

  return {
    'restrict': 'A',
    'require': { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {

      var log = sbLoggerService.getLogger('sbActionRequestRetainer');
      ctrl.group.setAction(sendRetainerRequest);

      function sendRetainerRequest(data) {
        ctrl.group.setLock(true);
        log.warn('sending retainer request:', JSON.stringify(data));

        return dispatchCommand({
          type: 'Integration.RequestDeposit',
          message: { emailRequests: [data] },
        })
          .then((responseBody) => {
            // RequestDeposit returns 207 if one or more requests fail.
            // Since we are sending only 1 request, if we get 207 it means failure. We manually throw so user see failure.
            if (responseBody.failureMatterIds && responseBody.failureMatterIds.length > 0) {
              throw new Error();
            }

            sbMessageDisplayService.success(
              sbMessageDisplayService.builder().text('Request retainer email sent successfully'),
            );
          })
          .catch((err) => {
            if (err.payload && err.payload.body && err.payload.body.message === emailMessages.notAllowedToSendEmailsServer) {
              sbMessageDisplayService.error(emailMessages.notAllowedToSendEmailsDisplay);
            } else {
              sbMessageDisplayService.error(
                sbMessageDisplayService
                  .builder()
                  .text('Failed to send request for retainer')
                  .conditionalText(': {val}', err.message),
              );
            }
          })
          .finally(() => {
            ctrl.group.setLock(false);
          });
      }
    }
  };
});
