import composeHooks from '@sb-itops/react-hooks-compose';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { featureActive } from '@sb-itops/feature';
import { useState } from 'react';
import { sendMetric } from 'web/services/metrics';
import { PromoCardBase } from './PromoCardBase';
import { useCacheQuery } from '../../../hooks';
import { withApolloClient, withReduxProvider } from '../../hocs';
import { InitUserBillingAttributes } from '../../../graphql/queries';

const useHooks = ({
  scope,
}: {
  onSetup: () => void;
  children: React.JSX.Element;
  scope: string;
  title: string;
  text: string;
}) => ({
  useUserBillingAttributesData: () => {
    const [instantDismiss, setInstantDismiss] = useState(false);
    const { data, error, loading } = useCacheQuery(InitUserBillingAttributes.query);

    if (error) {
      throw new Error(error);
    }

    const viewedMessages = data?.userBillingAttributes?.viewedMessages || [];

    // This is obviously not going to work well if we use this technique in multiple places, however I suspect this is a once off.
    const dismissedMessageOnce = viewedMessages.includes(scope);
    const dismissedMessageTwice = viewedMessages.includes(`${scope}_1`);
    const dismissedMessageThrice = viewedMessages.includes(`${scope}_2`);

    const showCard = !instantDismiss && (!dismissedMessageOnce || featureActive('NUCWEB-799-DEBUG')) && !loading;
    const showBanner =
      !showCard &&
      !instantDismiss &&
      (!dismissedMessageTwice || !dismissedMessageThrice || featureActive('NUCWEB-799-DEBUG')) &&
      !loading;

    return {
      showCard,
      showBanner,
      onDismiss: () => {
        sendMetric('promoCardDismissed', { scope });
        setInstantDismiss(true);
        if (!dismissedMessageOnce) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: scope },
          });
        } else if (!dismissedMessageTwice) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: `${scope}_1` },
          });
        } else if (!dismissedMessageThrice) {
          dispatchCommand({
            type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
            message: { viewedMessage: `${scope}_2` },
          });
        }
      },
    };
  },
});

const PromoCardContainer = withApolloClient(withReduxProvider(composeHooks(useHooks)(PromoCardBase)));

export default PromoCardContainer;
