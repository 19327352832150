'use strict';

const { getTypeAsOptions } = require('@sb-itops/enum-helpers');

const splitBillingMethods = Object.freeze({
  SPLIT_EVENLY: 0,
  USE_RATIO: 1,
});

const splitBillingMethodLabels = Object.freeze({
  SPLIT_EVENLY: 'Split Evenly',
  USE_RATIO: 'By Percentage',
});

const splitBillingMethodOptions = getTypeAsOptions({
  typeLabels: splitBillingMethodLabels,
  typeValues: splitBillingMethods,
});

module.exports = {
  splitBillingMethods,
  splitBillingMethodLabels,
  splitBillingMethodOptions,
};
