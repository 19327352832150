import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { featureActive } from '@sb-itops/feature';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { opdateCache, rollbackOpdateCache } from './index';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

const getValidAccountTypes = () => {
  const validAccountTypes = [];
  if (hasFacet(facets.CMA)) {
    validAccountTypes.push(bankAccountTypeEnum.CONTROLLEDMONEY);
  }
  if (hasFacet(facets.trustAccountBankFee)) {
    validAccountTypes.push(bankAccountTypeEnum.TRUST);
  }
  return validAccountTypes;
};

// Only to be used for Controlled Money Accounts and Trust account in GB
export const addBankAccountFee = (feeDetails) => {
  if (!getValidAccountTypes().includes(feeDetails?.accountType)) {
    throw new Error('Invalid Account Type');
  }

  const addBankAccountFeeThunk = async () => {
    // Apply to save optimistically.
    const feeOpdate = buildOpdate(feeDetails);
    opdateCache({ optimisticEntities: [feeOpdate] });

    // Apply the save in the backend.
    try {
      if (featureActive('BB-13870')) {
        await dispatchCommand({
          type: 'Billing.Accounts.Messages.Commands.DebitBankFee',
          message: feeDetails,
        });
      } else {
        const newBankAccountFee = {
          ...feeDetails,
          accountId: getAccountId(),
          userId: getUserId(),
        };

        const accountType = newBankAccountFee.accountType.toUpperCase();
        const path = `/billing/bank-account/fee/:accountId/${accountType}`;
        const fetchOptions = { body: JSON.stringify(newBankAccountFee) };
        await fetchPostP({ path, fetchOptions });
      }
    } catch (err) {
      // Roll back the opdate.
      rollbackOpdateCache({ optimisticEntities: [feeOpdate] });

      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(addBankAccountFeeThunk);
};

function buildOpdate(feeDetails) {
  return {
    ...feeDetails,
    id: feeDetails.transactionId,
    type: feeDetails.type || transactionType.BankFees,
    amount: -feeDetails.amount,
    description: feeDetails.description || 'Bank Fees',
    note: feeDetails.internalNote,
    timestamp: new Date().toISOString(),
  };
}
