/* eslint-disable no-await-in-loop */
import { getPersonByUserId, getLoggedInUserId } from '@sb-firm-management/redux/firm-management';
import { useState, useEffect, useRef } from 'react';
import { fetchGetP, fetchPatchP, fetchPostP } from '@sb-itops/redux/fetch';
import { useIsMounted } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { Documents, Folder, File, Expanded } from 'types';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { toCamelCase } from '@sb-itops/camel-case';

const scope = 'MATTER_DOCUMENTS';

const log = getLogger(scope);

interface IMatterDocumentsContainerProps {
  matterId: string;
  hideUploadingDocs?: boolean;
}

const getFolderInDocs = (documents: Documents, path: string) =>
  path
    .split('.')
    .reduce((prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev), documents as Folder);

export const useMatterDocumentsHook = ({ matterId, hideUploadingDocs }: IMatterDocumentsContainerProps) => {
  const [loading, setLoading] = useState(1);
  const isMounted = useIsMounted();
  const [documents, setDocuments] = useState<Documents>({
    type: 'folder',
    contents: {},
    fetched: false,
    folderPath: '',
  });
  const [expanded, setExpanded] = useState({ root: true } as Expanded);
  const [error, setError] = useState('');
  const [rawSelectedPath, setSelectedPath] = useState('');

  const graphqlCache = useRef({});

  const selectedFolder: Folder = rawSelectedPath
    .split('.')
    .reduce(
      (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : (prev as Folder)),
      documents as Folder,
    );

  // The currently selected path must be a path we can resolve, otherwise the user's selection will not match the display.
  // In 99 % of cases, selectedPath will equal rawSelectedPath
  const selectedPath = selectedFolder.folderPath
    ? `${selectedFolder.folderPath}.${selectedFolder?.data?.id || ''}`
    : selectedFolder?.data?.id || '';

  const fetchFolder = async (
    folders: Folder | Documents,
    path: string,
    currentFolder: string,
    refetch: boolean,
    folderId?: string,
  ) => {
    const suffix = folderId ? `/${folderId}` : '';
    const newPath = path ? `${path}.${folderId || ''}` : folderId || '';
    const existingFolder = getFolderInDocs(documents, newPath);
    if (existingFolder && graphqlCache.current[newPath]) {
      // eslint-disable-next-line no-param-reassign
      folders.contents = existingFolder.contents;
      if (!refetch) {
        await Promise.all(
          Object.values(folders.contents).map((f) =>
            fetchFolder(folders.contents[f.data.id] as Folder, newPath, currentFolder, refetch, f.data.id),
          ),
        );
        return;
      }
    }
    if (newPath === currentFolder || currentFolder.startsWith(newPath)) {
      if (isMounted?.current) {
        setLoading(1);
      }
      let hasMore;
      let offset = 0;
      const limit = 500;
      do {
        const { body } = await fetchGetP({
          path: `/matter-management/matter/folder/:accountId/${matterId}${suffix}?limit=${limit}&offset=${offset}`,
        });

        hasMore = body.hasMore;
        offset += limit;

        // eslint-disable-next-line no-param-reassign
        folders.fetched = true;

        Object.keys(folders.contents).forEach((k) => {
          if (folders.contents[k].type === 'file' && !(folders.contents[k] as File).isOpdate) {
            // eslint-disable-next-line no-param-reassign
            delete folders.contents[k];
          }
        });

        body.files.forEach((file) => {
          if (!file.isCancelled && !file.isDuplicate && !(hideUploadingDocs && file.isUploaded === false)) {
            // eslint-disable-next-line no-param-reassign
            folders.contents[file.id] = {
              type: 'file',
              data: file,
              folderPath: newPath,
            };
          }
        });
        if (isMounted?.current) {
          graphqlCache.current = { ...graphqlCache.current, [newPath]: true };
        }

        await Promise.all(
          body.folders.map((f) => {
            // eslint-disable-next-line no-param-reassign
            folders.contents[f.id] = {
              type: 'folder',
              data: f,
              fetched: (folders.contents[f.id] as Folder)?.fetched || false,
              contents: {
                ...((folders.contents[f.id] as Folder)?.contents || {}),
              },
              folderPath: newPath,
            } as Folder;
            return fetchFolder(folders.contents[f.id] as Folder, newPath, currentFolder, refetch, f.id);
          }),
        );
      } while (hasMore);
    }
  };

  useEffect(() => {
    const unsub = subscribeToNotifications({
      notificationIds: ['ManageFiles2Notifications'],
      callback: (documentUpdate) => {
        const message = documentUpdate.includes('MessageId') ? toCamelCase(JSON.parse(documentUpdate)) : documentUpdate;
        if (message?.messageId === 'FilesUpdated' && message?.entityIds?.length) {
          message.entityIds.forEach(async (mId: string) => {
            if (matterId === mId) {
              const newDocs: Documents = {
                type: 'folder',
                contents: {},
                fetched: false,
                folderPath: '',
              };
              await fetchFolder(newDocs, '', selectedPath, true);
              if (isMounted?.current) {
                setDocuments(newDocs);
                setLoading(0);
              }
            }
          });
        }
      },
    });
    return unsub;
    // Not directly dependent on documents, but the fetchFolder function is
  }, [selectedPath, documents]);

  useEffect(() => {
    // Get file structure
    (async () => {
      const finalFolders: Documents = {
        type: 'folder',
        contents: {},
        fetched: false,
        folderPath: '',
      };

      await fetchFolder(finalFolders, '', selectedPath, false);

      if (isMounted?.current) {
        setDocuments(finalFolders);
        setLoading(0);
      }
    })().catch((err) => {
      if (isMounted?.current) {
        setError(err.message);
      }
    });
  }, [selectedPath]);

  // File upload listener
  async function uploadFiles(files: FileList | globalThis.File[] | null) {
    if (!files) {
      return;
    }
    if (files.length > 20) {
      messageDisplay.error('A maximum of 20 documents can be uploaded at once');
      return;
    }

    setLoading(files.length);

    for (let i = 0; i < files.length; i += 1) {
      try {
        const file = files[i];
        const {
          body: { fileId, uploadUrl },
          // eslint-disable-next-line no-await-in-loop
        } = await fetchPostP({
          path: `/matter-management/matter/file/:accountId/${matterId}/`,
          fetchOptions: {
            body: JSON.stringify({
              matterId,
              fileName: file.name,
              folderId: selectedFolder?.data?.id || null,
            }),
          },
        });
        const fileSplit = file.name.split('.');
        const fileExtension = fileSplit.length > 1 ? `.${fileSplit.pop()}` : '';
        const fileName = fileSplit.join('.');

        const newDocs = { ...documents };
        const scopedFolder = selectedPath;
        const newSelectedFolder = scopedFolder
          .split('.')
          .reduce((prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev), newDocs);
        newSelectedFolder.contents[fileId] = {
          type: 'file',
          folderPath: scopedFolder,
          data: {
            name: fileName,
            fileExtension,
            ownerId: getLoggedInUserId(),
            dateCreated: new Date().toISOString(),
            dateModified: new Date().toISOString(),
            id: fileId,
            sizeBytes: file.size,
          },
          loading: 1,
          isOpdate: true,
        } as File;
        setDocuments(newDocs);

        // XHR request required here so we can track the progress of the upload. This feature is unlikely to be implemented in the fetch API anytime soon.
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', uploadUrl);
        xhr.setRequestHeader('Content-Type', '');
        xhr.addEventListener('load', () => {
          if (isMounted?.current) {
            setLoading((r) => r - 1);
            setDocuments((d) => {
              const newDocs2 = { ...d };
              const newSelectedFolder2 = scopedFolder
                .split('.')
                .reduce(
                  (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
                  newDocs2 as Folder,
                );
              if (xhr.status === 200) {
                delete (newSelectedFolder2.contents[fileId] as File).loading;
                messageDisplay.success('File upload complete');
                return newDocs2;
              }
              delete newSelectedFolder2.contents[fileId];
              messageDisplay.error('File upload failed');
              return newDocs2;
            });
          }
        });
        xhr.addEventListener('error', () => {
          if (isMounted?.current) {
            setLoading((r) => r - 1);
            setDocuments((d) => {
              const newDocs2 = { ...d };
              const newSelectedFolder2 = scopedFolder
                .split('.')
                .reduce(
                  (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
                  newDocs2 as Folder,
                );
              delete newSelectedFolder2.contents[fileId];
              messageDisplay.error('File upload failed');
              return newDocs2;
            });
          }
        });
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const complete = Math.round((event.loaded / event.total) * 100);
            if (isMounted?.current) {
              setDocuments((d) => {
                const newDocs2 = { ...d };
                const newSelectedFolder2 = scopedFolder
                  .split('.')
                  .reduce(
                    (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
                    newDocs2 as Folder,
                  );
                (newSelectedFolder2.contents[fileId] as File).loading = complete;
                return newDocs2;
              });
            }
          }
        });

        xhr.send(file);
      } catch (e) {
        messageDisplay.error('Something went wrong uploading your document. Please try again later');
        log.error(e);
      }
    }
  }

  async function replaceFile(files: FileList | globalThis.File[] | null, fileToReplace: File) {
    const fileId = fileToReplace?.data?.id;

    if (!files || files.length > 1 || !fileId) {
      return;
    }

    if (fileToReplace.loading) {
      messageDisplay.error('Please wait until file has finished uploading');
      return;
    }

    const file = files[0];

    const fileSplit = file.name.split('.');
    const fileExtension = fileSplit.length > 1 ? `.${fileSplit.pop()}` : '';

    if (fileExtension !== fileToReplace.data.fileExtension) {
      messageDisplay.error('Please select a file of the same type');
      return;
    }

    setLoading(1);

    try {
      const {
        body: { uploadUrl },
        // eslint-disable-next-line no-await-in-loop
      } = await fetchPatchP({
        path: `/matter-management/matter/file/:accountId/${matterId}/${fileId}`,
        fetchOptions: {
          body: JSON.stringify({
            fileName: fileToReplace.data.name + fileToReplace.data.fileExtension,
          }),
        },
      });

      const newDocs = { ...documents };
      const scopedFolder = selectedPath;
      const newSelectedFolder = scopedFolder
        .split('.')
        .reduce((prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev), newDocs);
      newSelectedFolder.contents[fileId] = {
        ...newSelectedFolder.contents[fileId],
        data: {
          ...newSelectedFolder.contents[fileId].data,
          ownerId: getLoggedInUserId(),
          dateModified: new Date().toISOString(),
          sizeBytes: file.size,
        },
        loading: 1,
        isOpdate: true,
      } as File;
      setDocuments(newDocs);

      // XHR request required here so we can track the progress of the upload. This feature is unlikely to be implemented in the fetch API anytime soon.
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', uploadUrl);
      xhr.setRequestHeader('Content-Type', '');
      xhr.addEventListener('load', () => {
        if (isMounted?.current) {
          setLoading((r) => r - 1);
          setDocuments((d) => {
            const newDocs2 = { ...d };
            const newSelectedFolder2 = scopedFolder
              .split('.')
              .reduce(
                (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
                newDocs2 as Folder,
              );
            if (xhr.status === 200) {
              delete (newSelectedFolder2.contents[fileId] as File).loading;
              messageDisplay.success('File upload complete');
              return newDocs2;
            }
            delete (newSelectedFolder2.contents[fileId] as File).loading;
            messageDisplay.error('File upload failed');
            return newDocs2;
          });
        }
      });
      xhr.addEventListener('error', () => {
        if (isMounted?.current) {
          setLoading((r) => r - 1);
          setDocuments((d) => {
            const newDocs2 = { ...d };
            const newSelectedFolder2 = scopedFolder
              .split('.')
              .reduce(
                (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
                newDocs2 as Folder,
              );
            delete (newSelectedFolder2.contents[fileId] as File).loading;
            messageDisplay.error('File upload failed');
            return newDocs2;
          });
        }
      });
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const complete = Math.round((event.loaded / event.total) * 100);
          if (isMounted?.current) {
            setDocuments((d) => {
              const newDocs2 = { ...d };
              const newSelectedFolder2 = scopedFolder
                .split('.')
                .reduce(
                  (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
                  newDocs2 as Folder,
                );
              (newSelectedFolder2.contents[fileId] as File).loading = complete;
              return newDocs2;
            });
          }
        }
      });

      xhr.send(file);
    } catch (e) {
      messageDisplay.error('Something went wrong uploading your document. Please try again later');
      log.error(e);
    }
  }

  return {
    getPersonByUserId,
    uploadFiles,
    replaceFile,
    expanded,
    setExpanded,
    setDocuments,
    createNewFolder: async (name) => {
      const { body } = await fetchPostP({
        path: `/matter-management/matter/folders/:accountId/${matterId}`,
        fetchOptions: {
          body: JSON.stringify({
            name,
            parentFolderId: selectedFolder?.data?.id || null,
          }),
        },
      });
      const newDocs = { ...documents };

      const newSelectedFolder = selectedPath
        .split('.')
        .reduce<Folder>(
          (prev, curr) => (prev.contents[curr] ? (prev.contents[curr] as Folder) : prev),
          newDocs as Folder,
        );
      newSelectedFolder.contents[body.id] = {
        type: 'folder',
        data: { name, id: body.id },
        contents: {},
        fetched: false,
        folderPath: selectedPath,
      };
      setDocuments(newDocs);
    },
    selectedFolder,
    selectedPath,
    setSelectedPath,
    documents,
    loading: !!loading,
    error,
  };
};
