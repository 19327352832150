import { emailMessages } from '@sb-billing/business-logic/shared/entities';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

angular.module('sb.billing.webapp').directive('sbActionSendTransactionReceipt', function (sbTransactionReceiptService, sbMessageDisplayService) {
  'use strict';

  return {
    restrict: 'A',
    require: { group: 'sbComposeGroup' },
    link: function (scope, element, attrs, ctrl) {
      ctrl.group.setAction(sendReceipt);

      function sendReceipt (data) {
        if (data) {
          ctrl.group.setLock(true);

          const message = {
            ...data,
            sendTo: undefined,
            message: undefined,
            to: data.sendTo,
            body: data.message,
          }

          return dispatchCommand({
            type: 'Billing.Accounts.Messages.Commands.SendReceipt',
            message,
          })
            .catch((err) => {
              sbMessageDisplayService.error(
                sbMessageDisplayService
                  .builder()
                  .text('Failed to send transaction receipt')
                  .conditionalText(' #{0}', data.id)
                  .conditionalText(': {val}', err.message)
                  .conditionalText('. {0}', err.payload && err.payload.body && err.payload.body.message === emailMessages.notAllowedToSendEmailsServer ? emailMessages.notAllowedToSendEmailsDisplay : '')
              );
            }).finally(() => {
              ctrl.group.setLock(false);
            });
        }

        sbMessageDisplayService.error('Failed to find transaction receipt.');
        throw new Error('no transaction receipt data');
      }

    }
  };
});
