import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import moment from 'moment';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { opdateCache, rollbackOpdateCache, buildReversalTransactionOpdates } from './index';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

const getValidAccountTypes = () => {
  const validAccountTypes = [];
  if (hasFacet(facets.CMA)) {
    validAccountTypes.push(bankAccountTypeEnum.CONTROLLEDMONEY);
  }
  if (hasFacet(facets.trustAccountBankFee)) {
    validAccountTypes.push(bankAccountTypeEnum.TRUST);
  }
  return validAccountTypes;
};

// Only to be used for Controlled Money Accounts and Trust account in GB
export const reverseBankAccountInterest = (transaction) => {
  const { transactionId } = transaction;

  if (!transactionId) {
    throw new Error('Invalid Transaction, you need a transaction id for reversing a transaction');
  }
  if (!getValidAccountTypes().includes(transaction?.accountType)) {
    throw new Error('Invalid Account Type');
  }

  const reverseBankAccountInterestThunk = async () => {
    const reversalTransaction = buildReversalInterestTransaction(transaction);

    const opdateReversalTransaction = buildReversalInterestTransactionOpdates({
      originalTransaction: transaction,
      reversalTransactionId: reversalTransaction.newTransactionId,
    });

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: opdateReversalTransaction });

    try {
      const path = `/billing/bank-account/reversal/:accountId/interest/${transactionId}`;
      const fetchOptions = { body: JSON.stringify(reversalTransaction) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: opdateReversalTransaction });

      throw err;
    }
  };

  return store.dispatch(reverseBankAccountInterestThunk);
};

const buildReversalInterestTransaction = (transaction) => {
  const { bankAccountId, accountType, reason, deleteTransaction, allowOverdraw } = transaction;
  const accountId = getAccountId();
  const userId = getUserId();
  return {
    bankAccountId,
    accountType,
    newTransactionId: uuid(),
    accountId,
    userId,
    effectiveDate: +moment().format('YYYYMMDD'),
    reason,
    hideTransactions: !!deleteTransaction,
    allowOverdraw: !!allowOverdraw,
  };
};

const buildReversalInterestTransactionOpdates = ({ originalTransaction, reversalTransactionId }) => {
  const reversalOpdates = buildReversalTransactionOpdates({
    originalTransaction,
    reversalTransactionId,
  });
  // reversalOpdates is [reversalTransaction, originalTransactionUpdated]
  // unlike regular transaction, bank interest opdate should have a reason and no note
  reversalOpdates[0].reason = reversalOpdates[0].note;
  delete reversalOpdates[0].note;

  return reversalOpdates;
};
