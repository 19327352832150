import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as messageDisplay from '@sb-itops/message-display';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { getSchemeValue } from '@sb-itops/region-schemes';

import { StaffSchema } from '@sb-firm-management/validation';
import { firmManagement } from '@sb-firm-management/redux';
import { getById as getStaffFeeConfigById } from '@sb-billing/redux/staff-fee-configuration';
import { saveStaffFeeConfiguration } from '@sb-billing/redux/staff-fee-configuration/save-staff-fee-configuration';
import { getById as getRateByStaffId } from '@sb-billing/redux/staff-hourly-rate';
import { saveStaffHourlyRate } from '@sb-billing/redux/staff-hourly-rate/save-staff-hourly-rate';
import { isUtbmsEnabled } from '@sb-billing/redux/utbms-settings';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { isUserBillBoost } from 'web/services/user-session-management';

import AddStaffUser from './AddStaffUser';

const { createStaff, updateStaff, getLoggedInStaff, getAllStaff } = firmManagement;

const scope = 'add-staff-users';

export const AddStaffUserContainer = (props) => {
  const [showFormerStaffModal, setShowFormerStaffModal] = useState(false);
  const [showUserConflictModal, setShowUserConflictModal] = useState(false);

  const { selectors: formSelectors, actions: formActions, operations: formOperations } = useScopedFeature(forms, scope);

  const { formInitialised, fields: formFields, submitFailed, formSubmitting } = useSelector(formSelectors.getFormState);

  const {
    id,
    firstName,
    lastName,
    title,
    phoneAreaCode,
    phoneNumber,
    cellAreaCode,
    cellNumber,
    email,
    role,
    isFormerStaff,
    hourlyRate,
    enterTimeAsUnits,
    timekeeperClassificationCode,
    sbAccess,
    twoFA,
    initials,
    colorFill,
    colorStroke,
  } = formFields;

  const loggedInStaff = getLoggedInStaff();
  const showFormerStaffField = !id || !id.value || id.value !== loggedInStaff?.id;

  const allUsersHaveSmokeballAccess = useSelector(isUserBillBoost);

  const showSmokeballAccessField = !allUsersHaveSmokeballAccess && (!id || !id.value || id.value !== loggedInStaff?.id);

  const dispatch = useDispatch();

  const onFieldValueUpdated = (fieldValues) => {
    dispatch(formActions.updateFieldValues({ fieldValues }));
  };
  const validateForm = () => {
    dispatch(formOperations.validateForm({ schema: StaffSchema, validateFn: validateEmailAddress }));
  };
  const validateEmailAddress = (fieldValues) => {
    const errors = {};

    const allStaff = getAllStaff();
    const emailInUseStaff = allStaff.find((staff) => staff.email === fieldValues.email && staff.id !== fieldValues.id);
    if (emailInUseStaff?.email) {
      const former = emailInUseStaff.isFormerStaff ? ' (Former)' : '';
      errors.email = `The email address '${emailInUseStaff.email}' has already been used for ${emailInUseStaff.name}${former}`;
    }

    return errors;
  };

  const onIsFormerStaffFieldUpdated = () => {
    if (
      sbAccess.value &&
      !isFormerStaff.value && // Comparing existing value, not the new value passed through onChange
      (!isFormerStaff.originalValue || (id && !id.value))
    ) {
      setShowFormerStaffModal(true);
    } else {
      onFieldValueUpdated({ isFormerStaff: !isFormerStaff.value });
      if (!allUsersHaveSmokeballAccess && sbAccess.value) {
        onFieldValueUpdated({ sbAccess: false });
      }
    }
  };
  const onIsFormerStaffModalCancel = () => setShowFormerStaffModal(false);
  const onIsFormerStaffModalProceed = () => {
    onFieldValueUpdated({ isFormerStaff: true });
    if (sbAccess.value) {
      onFieldValueUpdated({ sbAccess: false });
    }
    setShowFormerStaffModal(false);
  };

  useEffect(() => {
    const staffConfig = props.initialValues.id ? getStaffFeeConfigById(props.initialValues.id) : {};
    const staffHourlyRate = (props.initialValues.id && getRateByStaffId(props.initialValues.id)?.rate) || 0;

    dispatch(
      formActions.initialiseForm({
        fieldValues: {
          id: '',
          userId: '',
          firstName: '',
          lastName: '',
          title: '',
          phoneAreaCode: '',
          phoneNumber: '',
          cellAreaCode: '',
          cellNumber: '',
          isFormerStaff: false,
          twoFA: false,
          email: '',
          role: '',
          initials: '',
          colorFill: null,
          colorStroke: null,
          hourlyRate: staffHourlyRate,
          ...props.initialValues,
          sbAccess: allUsersHaveSmokeballAccess || props.initialValues?.sbAccess || false,
          enterTimeAsUnits: staffConfig?.enterTimeAsUnits || false,
          timekeeperClassificationCode: staffConfig?.timekeeperClassificationCode?.value || '',
        },
      }),
    );
    const onUnload = () => dispatch(formActions.clearForm());
    return onUnload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddStaffUser
      {...props}
      {...{
        onSave: async (event) => {
          event.preventDefault();
          await dispatch(formOperations.validateForm({ schema: StaffSchema, validateFn: validateEmailAddress }));
          try {
            await dispatch(
              formOperations.submitFormWithValidationP({
                submitFnP: async (formData) => {
                  const staffEntity = {
                    ...formData,
                    initials:
                      formData.initials || `${formData.firstName[0] || ''}${formData.lastName[0] || ''}`.toUpperCase(),
                  };

                  let staffId;
                  try {
                    if (formData.id) {
                      staffId = await updateStaff(staffEntity);
                    } else {
                      staffId = await createStaff(staffEntity);
                    }
                  } catch (err) {
                    if (err?.payload?.body?.message?.includes('register')) {
                      setShowUserConflictModal(true);
                      return;
                    }
                    throw err;
                  }

                  await saveStaffHourlyRate({
                    staffId,
                    rate: formData.hourlyRate || 0,
                  });

                  await saveStaffFeeConfiguration({
                    staffId,
                    enterTimeAsUnits: formData.enterTimeAsUnits,
                    timekeeperClassificationCode: formData.timekeeperClassificationCode,
                  });

                  if (props.onClose) {
                    props.onClose();

                    await dispatch(formActions.clearForm());
                  }
                  messageDisplay.success('The staff member has successfully been saved');
                },
              }),
            );
          } catch (err) {
            messageDisplay.error('Failed to save staff member');
          }
        },
        onFieldValueUpdated,
        id,
        firstName,
        lastName,
        title,
        validateForm,
        formDisabled: formSubmitting,
        submitFailed,
        phoneAreaCode,
        phoneNumber,
        cellAreaCode,
        cellNumber,
        colorFill,
        colorStroke,
        formInitialised,
        email,
        isFormerStaff,
        hourlyRate,
        timekeeperClassificationCode,
        enterTimeAsUnits,
        sbAccess,
        twoFA,
        role,
        initials,
        isUtbmsEnabled: isUtbmsEnabled(),
        phoneNumberScheme: getSchemeValue('phoneNumberScheme'),
        showFormerStaffField,
        showSmokeballAccessField,
        allUsersHaveSmokeballAccess,
        showFormerStaffModal,
        onIsFormerStaffModalCancel,
        onIsFormerStaffModalProceed,
        onIsFormerStaffFieldUpdated,
        showUserConflictModal,
        setShowUserConflictModal,
      }}
    />
  );
};

AddStaffUserContainer.displayName = 'AddStaffUserContainer';

AddStaffUserContainer.propTypes = {
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
};

AddStaffUserContainer.defaultProps = {
  initialValues: undefined,
  onClose: undefined,
};

export default withReduxProvider(AddStaffUserContainer);
