import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { todayAsInteger } from '@sb-itops/date';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import {
  printMethods as printMethodsList,
  notApplicablePrintMethodLocalised,
  PrintNow,
  PrintLater,
  PrintNotApplicable,
  PrintManually,
} from '@sb-billing/business-logic/cheques';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { useTranslation } from '@sb-itops/react';
import { PAYMENT_TYPE, PAYMENT_SOURCE } from '@sb-billing/business-logic/payment-source';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import {
  getMinChargeAmountInCents,
  calculateFeeDetails,
  extractFeeSchedule,
  isFirmCardSavingEnabledForBankAccount,
  isErrorSmokeballPreChargeError,
  getChargeErrorMessage,
} from '@sb-billing/business-logic/payment-provider/services';
import { getRegion } from '@sb-itops/region';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';
import { feeCoverageModes } from '@sb-billing/business-logic/payment-provider/entities/constants';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { getAccountId, getUserId } from 'web/services/user-session-management';
import {
  createBatchClientInvoicePaymentChargeRequest,
  createSaveCardRequest,
} from '@sb-billing/business-logic/payment-provider/requests';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { AddPaymentModal, MODAL_STEP } from './AddPaymentModal';
import { paymentTypeEnum } from './AddPaymentModalBody';
import { addPaymentFormSchema } from './AddPaymentForm.yup';
import { marshalData } from './marshal-data';

const REGION = getRegion();
const log = getLogger('AddPaymentModal.forms.container');
const ALLOCATE_FUNDS_GROUP_NAME = 'ALLOCATE_FUNDS';

const hooks = () => ({
  useConfig: () => ({
    supportsEcheque: hasFacet(facets.echeque),
    showReasonField: hasFacet(facets.reasonField) && featureActive('BB-5508'),
  }),
  useAddPaymentForm: ({
    scope,
    matterId: defaultMatterId,
    contactId: defaultContactId,
    printCheques,
    onClickLink,
    onModalClose,

    createPDFReceiptOnTrustPayment,
    isTrustChequePrintingActiveForBankAccountId,
    activeProviderType,
    activeProviderFormattedSettings,
    isMatterContactBalanceFirm,
    checkIsStatutoryDepositMatter,
    operatingAccount,
    areDefaultsLoading,
    defaultMatter,
    defaultContact,

    paymentSourceOptions,
    onGetPaymentSourceOptions,
    hasPaymentProviderConfiguredForSource,

    invoiceSummariesLoading,
    invoiceSummaries,
    onFetchInvoiceSummaries,

    lastTrustChequeNumber,
    nextTrustChequeNumber,
    trustChequeNumberBankAccountId,
    trustChequeNumberLoading,
    onFetchAvailableTrustChequeNumbers,

    bankReconciliationLatestCompletedByBankAccount,
    bankReconciliationSetup,
    bankReconciliationLoading,
    onFetchBankRecLatestCompleted,
  }) => {
    const { t } = useTranslation();
    const [reasonOverridden, setReasonOverridden] = useState(false);
    const [takePaymentNowDisabled, setTakePaymentNowDisabled] = useState(true);
    const [contactFallback, setContactFallback] = useState(undefined);
    const paymentSourceResetRef = useRef();
    const invoiceSummariesDebtorUpdateRef = useRef({ setAsPaidBy: false, setAsFallback: false });

    const addPaymentForm = useForm({
      scope,
      schema: addPaymentFormSchema,
    });
    const {
      formFields,
      formInitialised,
      formSubmitting,
      formValues,
      submitFailed,
      formValid,
      onClearForm,
      onInitialiseForm,
      onValidateForm,
      onSubmitFormWithValidation,
    } = addPaymentForm;

    // On load
    useEffect(
      () => {
        // Fetch invoice summaries for the default matter and contact if they were passed in.
        // This is so we can use the invoice summaries when initialising the form
        onFetchInvoiceSummaries({ matterId: defaultMatterId, debtorId: defaultContactId });

        // Cleanup
        return () => onClearForm();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const printMethodOptions = useMemo(
      () =>
        [notApplicablePrintMethodLocalised(t), ...printMethodsList].map((pm) => ({
          label: pm.display,
          value: pm.value,
        })),
      [t],
    );

    const paymentSourceSelected = paymentSourceOptions.find((pso) => pso?.value === formValues?.paymentSourceId);
    const totalPayments = Object.values(formValues?.payments || {}).reduce(
      (runningTotal, invoicePayment) => runningTotal + invoicePayment,
      0,
    );

    const validateForm = () => {
      const minAmountAllowed = activeProviderType
        ? getMinChargeAmountInCents({ providerType: activeProviderType, region: REGION })
        : 0;

      // We can't use paymentSourceSelected here because it may have stale value at the time we call validateForm.
      // Therefore, we create map of paymentSourceOptions and pass it to the validation where the formValues are always up to date.
      const paymentSourceOptionsMap = paymentSourceOptions.reduce(
        (acc, paymentSource) => ({
          ...acc,
          [paymentSource.value]: paymentSource,
        }),
        {},
      );

      const validateCtx = {
        t,
        minAmountAllowed,
        paymentSourceOptionsMap,
        allowOverdraw: hasFacet(facets.allowOverdraw),
        isMatterContactBalanceFirm,
        reasonOnInvoicePaymentsEnabled: featureActive('BB-5508'),
        isTrustChequePrintingActiveForBankAccountId,
        isTrustToOfficeNumberingEnabled,
        supportsTtoNumbering: hasFacet(facets.ttoNumbering),
        nextTrustChequeNumber,
        lastTrustChequeNumber,
        // both bank recs and bank recs setup are expected to be for specific (and same) trust account
        bankReconciliationLatestCompleted: bankReconciliationLatestCompletedByBankAccount,
        bankReconciliationSetup,
      };

      onValidateForm(validateCtx);
    };

    const onSetFieldValue = ({ field, value }) => {
      addPaymentForm.onFieldValueSet(field, value);
      if (submitFailed) {
        validateForm();
      }
    };
    const onUpdateFieldValues = (fieldValues) => {
      addPaymentForm.onUpdateFields(fieldValues);
      if (submitFailed) {
        validateForm();
      }
    };

    /**
     * Form initialisation
     */
    const isReadyToInitialiseForm = !areDefaultsLoading && !formInitialised && !invoiceSummariesLoading;

    if (isReadyToInitialiseForm) {
      const defaultValues = getDefaultValues();
      onInitialiseForm(defaultValues);
      reloadPaymentSources({
        matterId: defaultValues.matterId,
        paymentType: defaultValues.paymentType,
        effectiveDate: defaultValues.effectiveDate,
        shouldResetSelection: true,
      });
    }

    function getDefaultValues() {
      const debtors = getDebtorsFromInvoiceSummaries(invoiceSummaries);
      const debtor = debtors.length === 1 ? debtors[0] : undefined;
      setContactFallback(defaultContact || debtor);

      return {
        matterId: defaultMatterId,
        paidById: defaultContactId || debtor?.id, // contactId
        clientId: defaultContactId, // client/debtor
        // We need to store both contact id and contact itself. This is so we can run validation against the id fields and error them,
        // but still have the contact object itself so we can add it in default contact options. When we set an object in the forms,
        // it doesn't keep the field propertires (such as isInvalid, invalidReason etc.) so it is not the best for validation purposes.
        paidByContact: defaultContact || debtor,
        clientContact: defaultContact,
        effectiveDate: todayAsInteger(),
        comment: undefined,
        amount: 0,
        reference: undefined,
        reason: undefined,
        chequeMemo: undefined,
        paymentSourceId: undefined,
        takePaymentNow: false,
        printingMethodId: printMethodOptions?.[0]?.value,
        pdfOnTrustPayment: createPDFReceiptOnTrustPayment,
        paymentType: defaultContactId ? paymentTypeEnum.CLIENT : paymentTypeEnum.MATTER,
        payments: undefined,
        // take payment now fields
        saveCardDetails: false,
        paymentMethod: undefined,
      };
    }

    const updateDefaultReason = ({ paymentSource }) => {
      if (reasonOverridden) {
        return;
      }
      let newReason = '';
      if (paymentSource?.paymentType !== PAYMENT_TYPE.trust) {
        newReason = '';
      } else {
        newReason = `${t('capitalize', { val: 'trustToOfficeTransferLabel' })} for costs and outlays`;
      }
      onUpdateFieldValues({ reason: newReason });
    };

    const defaultPaidByContactOptions = getContactTypeaheadDefaultOptions({
      contact: formValues.paidByContact,
      fallbackContact: contactFallback,
    });
    const defaultDebtorContactOptions = getContactTypeaheadDefaultOptions({ contact: formValues.clientContact });
    const defaultMatterSummaries = formInitialised ? getMatterTypeaheadDefaultOption({ matter: defaultMatter }) : [];

    // Callbacks
    function reloadPaymentSources({ matterId, effectiveDate, paymentType, shouldResetSelection = true }) {
      paymentSourceResetRef.current = shouldResetSelection;
      if (paymentType === paymentTypeEnum.MATTER) {
        onGetPaymentSourceOptions({ matterId, effectiveDate });
      } else if (paymentType === paymentTypeEnum.CLIENT) {
        // We don't pass matterId for client which will return only direct payment sources
        onGetPaymentSourceOptions();
      }
    }

    function reloadInvoiceSummaries({ matterId, debtorId, paymentType }) {
      if (paymentType === paymentTypeEnum.MATTER) {
        onFetchInvoiceSummaries({ matterId });
      } else if (paymentType === paymentTypeEnum.CLIENT) {
        onFetchInvoiceSummaries({ debtorId });
      }
    }

    const onPaymentTypeChange = (newPaymentType) => {
      onSetFieldValue({ field: 'payments', value: {} }); // reset any payments
      onUpdateFieldValues({ paymentType: newPaymentType });
      // when payment type changes from Matter to Client/Debtor or vice versa, paymentSource has to be reset to default
      reloadPaymentSources({
        matterId: formValues?.matterId,
        effectiveDate: formValues?.effectiveDate,
        paymentType: newPaymentType,
        shouldResetSelection: true,
      });

      reloadInvoiceSummaries({
        matterId: formValues?.matterId,
        debtorId: formValues?.clientId,
        paymentType: newPaymentType,
      });
    };

    const onTakePaymentNowToggled = (_, val) => {
      if (val === true) {
        const effectiveDate = todayAsInteger();
        onUpdateFieldValues({
          takePaymentNow: val,
          effectiveDate,
          reference: '',
        });
        reloadPaymentSources({
          matterId: formValues?.matterId,
          effectiveDate,
          paymentType: formValues?.paymentType,
          shouldResetSelection: false,
        });
        return;
      }

      onUpdateFieldValues({ takePaymentNow: val });
    };

    const onChangePrintingMethod = (newPrintingMethod) => {
      const newPrintingMethodId = newPrintingMethod?.value;
      onUpdateFieldValues({ printingMethodId: newPrintingMethodId });

      if (paymentSourceSelected?.paymentType !== PAYMENT_TYPE.trust) {
        return;
      }

      if (
        isReferenceReadonly({
          paymentSource: paymentSourceSelected,
          printingMethodId: newPrintingMethodId,
          takePaymentNow: formValues?.takePaymentNow,
          isTrustChequePrintingActiveForBankAccountId,
        })
      ) {
        onUpdateFieldValues({ reference: '' });
      } else if (newPrintingMethodId === PrintManually) {
        onUpdateFieldValues({ reference: undefined }); // set to undefined so we know to set it to next number available

        // Fetch the cheque numbers if we don't already have the value for the selected trust account
        // Scenario: Set Print Manually and increment the default cheque number, then
        // change to Not Applicable, then change back to Print Manually
        if (
          !!paymentSourceSelected?.bankAccountId &&
          paymentSourceSelected.bankAccountId !== trustChequeNumberBankAccountId
        ) {
          onFetchAvailableTrustChequeNumbers({
            bankAccountId: paymentSourceSelected.bankAccountId,
            trustChequeReference: undefined,
          });
        }
      }
      // when readonlyReference is false and it is printNotApplicable we need to clear the reference, since this means that
      // the numbering is disabled
      else if (newPrintingMethodId === PrintNotApplicable) {
        onUpdateFieldValues({ reference: '' });
      }
    };

    const onReferenceChange = (newReference) => {
      // Skip validation as we need to perform it once we have fetched the
      // nextTrustChequeNumber value. This is provided via validateForm as
      // context to yup schema
      addPaymentForm.onUpdateFields({ reference: newReference });
      onFetchAvailableTrustChequeNumbers({
        bankAccountId: paymentSourceSelected.bankAccountId,
        trustChequeReference: newReference,
      });
    };

    // Changing the form value will trigger immediate validation before we can
    // fetch the new nextTrustChequeNumber value. Instead we wait until loading
    // is complete. Can't rely on nextTrustChequeNumber changing because if the
    // user enters any used value, nextTrustChequeNumber is likely going to stay
    // the same
    useEffect(() => {
      if (nextTrustChequeNumber && trustChequeNumberLoading === false) {
        validateForm();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextTrustChequeNumber, trustChequeNumberLoading, formValues?.reference]);

    useEffect(() => {
      if (bankReconciliationLoading === false) {
        validateForm();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankReconciliationLoading, bankReconciliationLatestCompletedByBankAccount, bankReconciliationSetup]);

    const onPaymentsListChange = (invoicePayments) => {
      updateDefaultReason({ paymentSource: paymentSourceSelected });
      onSetFieldValue({ field: 'payments', value: invoicePayments });
    };

    const onReasonChange = (newReason) => {
      setReasonOverridden(true);
      onUpdateFieldValues({ reason: newReason });
    };

    const onPaymentSourceChange = (newPaymentSource) => {
      const fieldsToUpdate = { paymentSourceId: newPaymentSource?.value };

      if (
        isReferenceReadonly({
          paymentSource: newPaymentSource,
          printingMethodId: formValues?.printingMethodId,
          takePaymentNow: !!(activeProviderType && formValues?.takePaymentNow),
          isTrustChequePrintingActiveForBankAccountId,
        })
      ) {
        fieldsToUpdate.reference = '';
      } else if (
        newPaymentSource?.paymentType === PAYMENT_TYPE.trust &&
        formValues?.printingMethodId === PrintManually
      ) {
        onUpdateFieldValues({ reference: undefined }); // set to undefined so we know to set it to next number available
        onFetchAvailableTrustChequeNumbers({
          bankAccountId: newPaymentSource.bankAccountId,
          trustChequeReference: undefined,
        });
      }

      if (activeProviderType) {
        const paymentProviderIsConfigured = hasPaymentProviderConfiguredForSource(newPaymentSource);
        if (newPaymentSource?.paymentSource === PAYMENT_SOURCE.creditCard && paymentProviderIsConfigured) {
          setTakePaymentNowDisabled(false);
        } else if (
          hasFacet(facets.echeque) &&
          newPaymentSource?.paymentSource === PAYMENT_SOURCE.eCheck &&
          paymentProviderIsConfigured
        ) {
          setTakePaymentNowDisabled(true);
          const effectiveDate = todayAsInteger();
          fieldsToUpdate.takePaymentNow = true;
          fieldsToUpdate.reference = '';
          fieldsToUpdate.effectiveDate = effectiveDate;
          // We want to reload payment sources as the date might have changed but we want to keep the current selection
          reloadPaymentSources({
            matterId: formValues?.matterId,
            effectiveDate,
            paymentType: formValues?.paymentType,
            shouldResetSelection: false,
          });
        } else {
          setTakePaymentNowDisabled(true);
          fieldsToUpdate.takePaymentNow = false;
        }
      }

      if (newPaymentSource?.paymentType === PAYMENT_TYPE.trust) {
        onFetchBankRecLatestCompleted({ bankAccountId: newPaymentSource?.bankAccountId });
      }

      // if the firm has matter contact balances, change the contactId if the
      // user has selected a non-direct source (trust or operating)
      if (isMatterContactBalanceFirm) {
        const paidByContact = newPaymentSource?.contactId
          ? { id: newPaymentSource.contactId, displayName: newPaymentSource.contactDisplayName }
          : contactFallback;
        fieldsToUpdate.paidById = paidByContact?.id;
        onSetFieldValue({ field: 'paidByContact', value: paidByContact });
      } else {
        const isDirectPayment = newPaymentSource?.paymentType === PAYMENT_TYPE.direct;
        const paidByContact = isDirectPayment ? contactFallback : undefined;
        fieldsToUpdate.paidById = paidByContact?.id;
        onSetFieldValue({ field: 'paidByContact', value: paidByContact });
      }

      onUpdateFieldValues(fieldsToUpdate);
      updateDefaultReason({ paymentSource: newPaymentSource });
    };

    const onEffectiveDateChange = (newEffectiveDate) => {
      onUpdateFieldValues({ effectiveDate: newEffectiveDate });
      reloadPaymentSources({
        matterId: formValues?.matterId,
        effectiveDate: newEffectiveDate,
        paymentType: formValues?.paymentType,
        shouldResetSelection: false,
      });
    };

    const onSelectMatter = (newMatterOption) => {
      onUpdateFieldValues({ matterId: newMatterOption?.value });

      reloadInvoiceSummaries({ matterId: newMatterOption?.value, paymentType: formValues?.paymentType });
      if (
        isMatterContactBalanceFirm ||
        (!isMatterContactBalanceFirm && paymentSourceSelected?.paymentType === PAYMENT_TYPE.direct)
      ) {
        invoiceSummariesDebtorUpdateRef.current = { setAsPaidBy: true, setAsFallback: true };
      }

      reloadPaymentSources({
        matterId: newMatterOption?.value,
        effectiveDate: formValues?.effectiveDate,
        paymentType: formValues?.paymentType,
        shouldResetSelection: true,
      });
      // Reset payments
      onSetFieldValue({ field: 'payments', value: {} });
    };

    const onSelectPaidBy = (newPaidByOption) => {
      setContactFallback({ id: newPaidByOption?.value, displayName: newPaidByOption?.label });
      onUpdateFieldValues({ paidById: newPaidByOption?.value });
      onSetFieldValue({
        field: 'paidByContact',
        value: contactOptionToContact(newPaidByOption),
      });
    };

    const onSelectClient = (newClientOption) => {
      const fieldsToUpdate = {};

      if (newClientOption) {
        reloadInvoiceSummaries({ debtorId: newClientOption?.value, paymentType: formValues?.paymentType });
        invoiceSummariesDebtorUpdateRef.current = { setAsPaidBy: true, setAsFallback: false };

        fieldsToUpdate.clientId = newClientOption?.value;
        onSetFieldValue({
          field: 'clientContact',
          value: contactOptionToContact(newClientOption),
        });
        // Reset payment source to default
        const paymentSourceDefault = paymentSourceOptions.find((s) => s.isDefault);
        fieldsToUpdate.paymentSourceId = paymentSourceDefault?.value;
      } else {
        reloadInvoiceSummaries({ debtorId: undefined, paymentType: formValues?.paymentType });
        fieldsToUpdate.clientId = undefined;
        fieldsToUpdate.paidById = undefined;
        onSetFieldValue({ field: 'clientContact', value: undefined });
        onSetFieldValue({ field: 'paidByContact', value: undefined });
      }

      onUpdateFieldValues(fieldsToUpdate);
      // Reset payments
      onSetFieldValue({ field: 'payments', value: {} });
    };

    // Reset payment sources selection when needed
    useEffect(() => {
      if (paymentSourceResetRef.current) {
        paymentSourceResetRef.current = false;
        const paymentSourceDefault = paymentSourceOptions.find((s) => s.isDefault);
        onPaymentSourceChange(paymentSourceDefault);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentSourceOptions]);

    useEffect(() => {
      if (
        invoiceSummariesLoading ||
        (!invoiceSummariesDebtorUpdateRef.current?.setAsPaidBy &&
          !invoiceSummariesDebtorUpdateRef.current?.setAsFallback)
      ) {
        return;
      }
      const debtors = getDebtorsFromInvoiceSummaries(invoiceSummaries);
      const debtor = debtors.length === 1 ? debtors[0] : undefined;

      if (invoiceSummariesDebtorUpdateRef.current?.setAsPaidBy) {
        onUpdateFieldValues({ paidById: debtor?.id });
        onSetFieldValue({ field: 'paidByContact', value: debtor });
        invoiceSummariesDebtorUpdateRef.current.setAsPaidBy = false;
      }

      if (invoiceSummariesDebtorUpdateRef.current?.setAsFallback) {
        setContactFallback(debtor);
        invoiceSummariesDebtorUpdateRef.current.setAsFallback = false;
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceSummaries, invoiceSummariesLoading]);

    // Set reference to next available number. This is done here so we are sure we have new data already
    if (
      formValues?.printingMethodId === PrintManually &&
      !trustChequeNumberLoading &&
      formValues.reference === undefined &&
      !!nextTrustChequeNumber &&
      !!paymentSourceSelected?.bankAccountId &&
      paymentSourceSelected.bankAccountId === trustChequeNumberBankAccountId
    ) {
      addPaymentForm.onUpdateFields({ reference: nextTrustChequeNumber });
    }

    const getMarshalledData = ({ formData, providerSpecificChargeData }) =>
      marshalData({
        formData,
        paymentSourceSelected,
        operatingAccount,
        invoiceSummaries,
        activeProviderType,
        activeProviderFormattedSettings,
        providerSpecificChargeData,
      });

    const onAddPayment = async () => {
      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            log.info('formData', formData);
            const data = getMarshalledData({ formData, providerSpecificChargeData: undefined });

            await addBatchPayment(data);

            if (
              data.sourceAccountType &&
              data.sourceAccountType.toUpperCase() === bankAccountTypeEnum.TRUST &&
              data.pdfOnTrustPayment === true &&
              // if also printing trust cheque NOW, trust cheque wins and we don't navigate to TTO PDF
              // printing method 1 = Print Now
              !(data.chequePrintActive && formData.printingMethodId === PrintNow)
            ) {
              onClickLink({
                type: 'trustToOfficeTransferReceipt',
                id: { transactionId: data.transactionId, paymentId: data.paymentId },
              });
            } else if (!(data.chequePrintActive && formData.printingMethodId === PrintNow)) {
              messageDisplay.success('Payment added successfully');
            }

            if (paymentSourceSelected?.paymentType === PAYMENT_TYPE.trust && formData.printingMethodId === PrintNow) {
              printCheques({
                chequeId: data.transferBetweenAccountsTransactionId,
                bankAccountId: data.sourceBankAccountId,
              });
            }

            onModalClose();
          },
        });
      } catch (error) {
        log.error('Failed to process payment', error);
        messageDisplay.error('Failed to process batch payments');
      }
    };

    return {
      isModalLoading: areDefaultsLoading || !formInitialised,
      isChequeMemoVisible: isChequeMemoVisible(formValues?.printingMethodId),
      isReferenceReadOnly: isReferenceReadonly({
        paymentSource: paymentSourceSelected,
        printingMethodId: formValues?.printingMethodId,
        takePaymentNow: !!(activeProviderType && formValues?.takePaymentNow),
        isTrustChequePrintingActiveForBankAccountId,
      }),
      isTakePaymentNowDisabled: takePaymentNowDisabled,
      showTakePaymentNowField: !!activeProviderType,
      showPaymentPlanPaymentsWithInterestWarning: shouldShowPaymentPlanPaymentsWithInterestWarning({
        invoiceSummaries,
        payments: formValues?.payments,
      }),
      isDirectPayment: paymentSourceSelected?.paymentType === PAYMENT_TYPE.direct,
      isTrustPayment: paymentSourceSelected?.paymentType === PAYMENT_TYPE.trust,
      isPdfOnTrustPaymentDisabled: isPdfOnTrustPaymentDisabled(formValues?.printingMethodId),
      isTrustChequePrintingActive: isTrustChequePrintingActiveForBankAccountId(paymentSourceSelected?.bankAccountId),
      overdrawWarningProps: getOverdrawWarningProps({
        paymentSource: paymentSourceSelected,
        amount: formValues?.amount,
        matterId: formValues?.matterId,
        checkIsStatutoryDepositMatter,
      }),
      paymentSourceSelected,
      // form
      formData: formValues,
      formErrors: formFields, // Contains data related to form errors
      submitFailed,
      formSubmitting,
      formValid,
      validateForm,
      onSubmitFormWithValidation,
      printMethodOptions,
      balance: (formValues?.amount || 0) - totalPayments,
      // we specifically want to display errors for reference, amount, effectiveDate
      footerErrorMessages: getFooterErrorMessages({
        formValues,
        formFields,
        invoiceSummaries,
        submitFailed,
        t,
      }),
      // callbacks
      getMarshalledData,
      onUpdateFieldValues,
      onPaymentTypeChange,
      onTakePaymentNowToggled,
      onChangePrintingMethod,
      onPaymentsListChange,
      onReasonChange,
      onPaymentSourceChange,
      onEffectiveDateChange,
      onReferenceChange,
      onSelectMatter,
      onSelectClient,
      onSelectPaidBy,
      onAddPayment,
      // typeaheads
      defaultPaidByContactOptions,
      defaultDebtorContactOptions,
      defaultMatterSummaries,
    };
  },
});

const dependentHooks = () => ({
  useTakePayment: ({
    formData: formValues,
    formSubmitting,
    formValid,
    validateForm,
    onSubmitFormWithValidation,
    activeProviderFormattedSettings,
    activeProviderType,
    operatingAccount,
    paidByContactDetailsLoading,
    onGetPaidByContactDetails,
    paidByContactDetails,
    getMarshalledData,
    firmName,
    loggedInStaffName,
    onModalClose,
    onAddPayment,
    bankReconciliationLoading,
    invoiceSummariesLoading,
  }) => {
    const { t } = useTranslation();
    const [currentModalStep, setCurrentModalStep] = useState(MODAL_STEP.ADDING_PAYMENT);
    const [totalChargeAmount, setTotalChargeAmount] = useState(0); // number in modal footer
    const [triggerChargeFormSubmit, setTriggerChargeFormSubmit] = useState(false);
    const [chargeFormReadyForSubmit, setChargeFormReadyForSubmit] = useState(false);
    // Note: this data should not be used in constructing the charge when onChargeFormSubmit fires.
    // Tokenised data will be passed to onChargeFormSubmit() for that purpose.
    // This data is provided by the charge form pre-submission in case the fee calculator requires
    // knowledge related to what has been entered in the charge form, e.g. credit card type.
    const [chargeFormData, setChargeFormData] = useState();
    const [paymentFormData, setPaymentFormData] = useState();

    const isReadyToTakePayment = formValid && chargeFormReadyForSubmit && !paidByContactDetailsLoading;
    const isModalSubmitting = triggerChargeFormSubmit || formSubmitting;

    const clientIsCoveringFee = !!activeProviderFormattedSettings?.clientCoversFeeOnPayments;

    /** Charge notification handling */
    const onTakePaymentSuccess = useCallback(() => {
      messageDisplay.success(
        getTakePaymentMessage(true, {
          amount: paymentFormData?.smokeballFormData?.totalAmount,
          invoices: paymentFormData?.smokeballFormData?.invoices,
          payorDisplayName: paymentFormData?.paidByContactDetails?.displayNameFirstLast,
          source: paymentFormData?.smokeballFormData?.source,
          providerType: activeProviderType,
          t,
        }),
      );

      setPaymentFormData(undefined);
      setTriggerChargeFormSubmit(false);
      onModalClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentFormData, activeProviderType, t]);

    const onTakePaymentFailed = useCallback(
      ({ chargeStatusResult }) => {
        messageDisplay.error(
          getTakePaymentMessage(false, {
            amount: paymentFormData?.smokeballFormData?.totalAmount,
            invoices: paymentFormData?.smokeballFormData?.invoices,
            payorDisplayName: paymentFormData?.paidByContactDetails?.displayNameFirstLast,
            source: paymentFormData?.smokeballFormData?.source,
            chargeStatusResult,
            providerType: activeProviderType,
            t,
          }),
        );

        setPaymentFormData(undefined);
        setTriggerChargeFormSubmit(false);
      },
      [paymentFormData, activeProviderType, t],
    );

    useEffect(() => {
      const callback = (response) => {
        const message = JSON.parse(response);

        if (message.status !== 'FAILED') {
          onTakePaymentSuccess();
        } else {
          onTakePaymentFailed({ chargeStatusResult: message });
        }
      };
      const unsub = subscribeToNotifications({
        notificationIds: ['PaymentProviderChargeCompleted'],
        callback,
      });
      return unsub;
    }, [onTakePaymentFailed, onTakePaymentSuccess]);

    const onModalButtonClick = async () => {
      validateForm();

      messageDisplay.dismissGroup(ALLOCATE_FUNDS_GROUP_NAME);
      const hasPayments = Object.values(formValues?.payments || {}).length > 0;

      if (!hasPayments && formValues?.takePaymentNow === true) {
        messageDisplay.warn(
          messageDisplay
            .builder()
            .text(`Please allocate funds to at least one invoice`)
            .group(ALLOCATE_FUNDS_GROUP_NAME),
        );
        // fall through so form fails and highlight fields
      }

      if (formValues.takePaymentNow === true) {
        if (currentModalStep === MODAL_STEP.TAKING_PAYMENT) {
          setTriggerChargeFormSubmit(true); // manually trigger charge for submission (onTakePayment function)
        } else if (currentModalStep === MODAL_STEP.ADDING_PAYMENT) {
          await onSubmitFormWithValidation({
            submitFnP: async (formData) => {
              onGetPaidByContactDetails(formData?.paidById);
              setCurrentModalStep(MODAL_STEP.TAKING_PAYMENT);
            },
          });
        }
      } else {
        onAddPayment();
      }
    };

    const onTakePayment = async (providerFormData) => {
      log.info(chargeFormData, providerFormData);
      try {
        await onSubmitFormWithValidation({
          submitFnP: async (formData) => {
            log.info('formData', formData);
            const smokeballFormData = getMarshalledData({ formData, providerSpecificChargeData: chargeFormData });

            const staffName = loggedInStaffName || 'firm staff member';
            const feeCoverageMode = activeProviderFormattedSettings?.clientCoversFeeOnPayments
              ? feeCoverageModes.CLIENT_PAYS
              : feeCoverageModes.FIRM_PAYS;
            setPaymentFormData({ providerFormData, smokeballFormData, paidByContactDetails });

            const isCardSavingEnabled =
              operatingAccount?.id &&
              isFirmCardSavingEnabledForBankAccount({
                formattedProviderSpecificSettings: activeProviderFormattedSettings,
                providerType: activeProviderType,
                bankAccountId: operatingAccount.id,
                bankAccountType: bankAccountTypeEnum.OPERATING,
              });

            if (isCardSavingEnabled && smokeballFormData?.saveCardDetails) {
              const savedCard = await onSaveCard({
                providerType: activeProviderType,
                saveCardFormData: { providerFormData, smokeballFormData },
              });

              if (savedCard) {
                await makeCharge({
                  providerType: activeProviderType,
                  paymentFormData: {
                    providerFormData: {
                      ...providerFormData,
                      paymentToken: {
                        ...providerFormData.paymentToken,
                        id: savedCard.token,
                      },
                    },
                    smokeballFormData,
                  },
                  staffName,
                  firmName,
                  feeCoverageMode,
                  t,
                });
              }
            } else {
              await makeCharge({
                providerType: activeProviderType,
                paymentFormData: { providerFormData, smokeballFormData },
                staffName,
                firmName,
                feeCoverageMode,
                t,
              });
            }
          },
        });
      } catch (err) {
        if (isErrorSmokeballPreChargeError(err)) {
          const errText = `The card was declined by the card issuer: ${err.message}`;
          messageDisplay.error(errText);
        } else {
          messageDisplay.error('Failed to process batch payments');
        }
        setTriggerChargeFormSubmit(false);
      }
    };

    const onChargeFormDataChange = (providerSpecificChargeData) => {
      setChargeFormData(providerSpecificChargeData);
      // Since charge form change may change fee and therefore total amount charged, we must recalculate
      if (clientIsCoveringFee) {
        const feeSchedule = clientIsCoveringFee
          ? extractFeeSchedule({
              providerType: activeProviderType,
              formattedProviderSpecificSettings: activeProviderFormattedSettings,
              bankAccountId: operatingAccount?.id,
            })
          : undefined;

        const feeDetails =
          clientIsCoveringFee &&
          calculateFeeDetails({
            providerType: activeProviderType,
            feeSchedule,
            desiredAmountInCents: formValues?.amount || 0,
            providerSpecificFields: providerSpecificChargeData,
          });

        setTotalChargeAmount(feeDetails.effectiveAmountInCents);
      }
    };

    return {
      isSubmitDisabled: shouldDisableSubmit({
        currentModalStep,
        isModalSubmitting,
        isReadyToTakePayment,
        isDataLoading: bankReconciliationLoading || invoiceSummariesLoading,
      }),
      isSubmitLocked: isModalSubmitting,
      currentModalStep,
      totalChargeAmount,
      clientIsCoveringFee,
      onModalButtonClick,

      onChargeFormDataChange,
      onChargeFormSubmit: onTakePayment,
      onChargeFormReadyForSubmit: (isReady) => setChargeFormReadyForSubmit(!!isReady),
      triggerChargeFormSubmit,
    };
  },
});

const getTakePaymentMessage = (
  success,
  { amount, invoices, payorDisplayName, source, chargeStatusResult, providerType, t },
) => {
  const amountWithSymbol = t('cents', { val: amount });

  let message = `A payment of ${amountWithSymbol} `;

  if (invoices.length > 1) {
    message += `for ${invoices.length} invoices `;
  } else {
    message += `against invoice #${invoices[0].invoiceNumber} `;
  }

  message += success ? `was successfully charged ` : `failed to be charged `;

  message += `to ${payorDisplayName}'s ${source}. ${
    !success ? extractErrorMessage({ chargeStatusResult, providerType }) || '' : ''
  }`;

  return message;
};

const extractErrorMessage = ({ chargeStatusResult, providerType }) => {
  if (chargeStatusResult.smokeballResponse) {
    return chargeStatusResult.smokeballResponse?.failure?.message;
  }

  const defaultMessage = 'An unexpected error occurred during transaction processing';
  const { message: errorMessage } = getChargeErrorMessage({
    defaultMessage,
    chargeStatusResult,
    providerType,
  });

  return errorMessage || defaultMessage;
};

const onSaveCard = async ({ providerType, saveCardFormData }) => {
  const payorId = saveCardFormData?.smokeballFormData?.payorId;
  const bankAccountId = saveCardFormData?.smokeballFormData?.bankAccountId;
  const accountId = getAccountId();

  try {
    const newCardRequest = createSaveCardRequest({
      accountId,
      bankAccountId,
      contactId: payorId,
      providerType,
      saveCardFormData,
    });

    const path = `/billing/payment-provider/save-card/${providerType.toLowerCase()}/${accountId}/`;
    const fetchOptions = { body: JSON.stringify(newCardRequest) };
    const response = await fetchPostP({ path, fetchOptions });

    return response.body;
  } catch (err) {
    messageDisplay.error('Failed to save card details');
    throw err;
  }
};

const makeCharge = async ({ providerType, paymentFormData, staffName, firmName, feeCoverageMode, t }) => {
  const newCharge = createBatchClientInvoicePaymentChargeRequest({
    accountId: getAccountId(),
    requestedByUserId: getUserId(),
    providerType,
    paymentFormData,
    staffName,
    firmName,
    feeCoverageMode,
    t,
  });

  const path = `/billing/payment-provider/charge/${providerType.toLowerCase()}/${newCharge.accountId}/`;
  const fetchOptions = { body: JSON.stringify(newCharge) };
  const response = await fetchPostP({ path, fetchOptions });

  return response.body;
};

const addBatchPayment = async (data) => {
  const accountId = getAccountId();
  const payload = { ...data, userId: getUserId() };

  const path = `/billing/payment/${accountId}/batch`;
  const fetchOptions = { body: JSON.stringify(payload) };
  const response = await fetchPostP({ path, fetchOptions });

  return response.body;
};

const shouldDisableSubmit = ({ currentModalStep, isReadyToTakePayment, isModalSubmitting, isDataLoading }) => {
  if (currentModalStep === MODAL_STEP.TAKING_PAYMENT && !isReadyToTakePayment) {
    return true;
  }

  return isModalSubmitting || isDataLoading;
};

const getOverdrawWarningProps = ({ paymentSource, amount, matterId, checkIsStatutoryDepositMatter }) => {
  if (paymentSource?.paymentType !== PAYMENT_TYPE.trust) {
    return {};
  }

  return {
    accountType: bankAccountTypeEnum.TRUST,
    amount,
    matterBankAccountBalance: paymentSource?.balanceAtDate || paymentSource?.balance,
    isStatutoryDepositMatter: checkIsStatutoryDepositMatter({ matterId }),
    hasProtectedFunds: (paymentSource?.protectedBalance || 0) > 0,
  };
};

const getFooterErrorMessages = ({ formValues, formFields, invoiceSummaries, submitFailed, t }) => {
  const footerErrorMessages = [];
  // We want to show reference error message even before submitFailed is true
  if (formValues?.reference !== undefined && formFields?.reference?.invalidReason) {
    footerErrorMessages.push(formFields?.reference?.invalidReason);
  }
  // We want to avoid default error message when field is empty or 0, we just error it out without a message
  if (
    submitFailed &&
    Number.isFinite(formValues?.amount) &&
    formValues?.amount > 0 &&
    formFields?.amount?.invalidReason
  ) {
    footerErrorMessages.push(formFields?.amount?.invalidReason);
  }
  // We want to avoid default error message when field is empty so we show error message only when it is not
  if (submitFailed && formValues?.effectiveDate && formFields?.effectiveDate?.invalidReason) {
    footerErrorMessages.push(formFields?.effectiveDate?.invalidReason);
  }
  // This is not really error which would block submission, but rather a warning
  if (shouldShowAnticipatedDisbursementWarning({ invoiceSummaries, payments: formValues?.payments })) {
    footerErrorMessages.push(`A selected invoice contains an unpaid anticipated ${t('expense')}`);
  }

  return footerErrorMessages;
};

const shouldShowPaymentPlanPaymentsWithInterestWarning = ({ invoiceSummaries, payments }) =>
  !!(invoiceSummaries || []).find((invoiceSummary) => {
    const paymentAmount = payments?.[invoiceSummary.id];
    const hasPaymentPlan = !!invoiceSummary?.listItemProperties?.activePaymentPlanDetails;
    const unpaidExcInterest = invoiceSummary?.totals?.unpaidExcInterest || 0;
    return Number.isFinite(paymentAmount) && hasPaymentPlan && paymentAmount > unpaidExcInterest;
  });

const shouldShowAnticipatedDisbursementWarning = ({ invoiceSummaries, payments }) =>
  !!(
    featureActive('BB-9573') &&
    (invoiceSummaries || []).find((invoiceSummary) => {
      const paymentAmount = payments?.[invoiceSummary.id];
      const hasAD = !!invoiceSummary?.listItemProperties?.hasUnpaidAnticipatedDisbursements;
      return Number.isFinite(paymentAmount) && hasAD;
    })
  );

/**
 * The reference is read-only, if;
 * - The region uses TTO auto numbering, selected account is a trust account, print method is PrintNotApplicable OR
 * - The selected account is a trust account, trust cheque printing is enabled, and the printing method selected is 'Print Now' or 'Print Later' OR
 * - The takePaymentNow toggle set to true
 *
 * We double down on the "selected account is a trust account" just to be sure that the two concepts are independent.
 */
const isReferenceReadonly = ({
  paymentSource,
  printingMethodId,
  takePaymentNow,
  isTrustChequePrintingActiveForBankAccountId,
}) => {
  if (takePaymentNow) {
    return true;
  }

  const isReadOnlyCheque =
    paymentSource?.paymentType === PAYMENT_TYPE.trust &&
    isTrustChequePrintingActiveForBankAccountId(paymentSource.bankAccountId) &&
    (printingMethodId === PrintNow || printingMethodId === PrintLater);

  const isReadOnlyElectronicPayment =
    paymentSource?.paymentType === PAYMENT_TYPE.trust &&
    printingMethodId === PrintNotApplicable &&
    hasFacet(facets.ttoNumbering) &&
    isTrustToOfficeNumberingEnabled(paymentSource?.trustToOfficeNumberingSettings);

  return isReadOnlyCheque || isReadOnlyElectronicPayment;
};

const isTrustToOfficeNumberingEnabled = (trustToOfficeNumberingSettings) =>
  !trustToOfficeNumberingSettings || !trustToOfficeNumberingSettings.useManualNumbering;

const isChequeMemoVisible = (printingMethodId) =>
  hasFacet(facets.chequeMemo) && (printingMethodId === PrintNow || printingMethodId === PrintManually);

const isPdfOnTrustPaymentDisabled = (printingMethodId) =>
  // If Print Now trust cheques is selected, we disable the TTO receipt option as cheques take priority over the receipt
  // The receipt can be generated from accounts > trust > print detail if needed
  printingMethodId === PrintNow;

const getDebtorsFromInvoiceSummaries = (invoiceSummaries) => {
  const debtors = {};
  (invoiceSummaries || []).forEach((invoiceSummary) => {
    invoiceSummary.debtors?.forEach((debtor) => {
      debtors[debtor.id] = debtor.contact;
    });
  });

  return Object.values(debtors);
};

const contactOptionToContact = (contactOption) => {
  if (!contactOption) {
    return undefined;
  }

  return { id: contactOption.value, displayName: contactOption.label };
};

const getContactTypeaheadDefaultOptions = ({ contact, fallbackContact }) => {
  const options = [];

  if (contact) {
    options.push({ data: contact, label: contact.displayNameFull || contact.displayName, value: contact.id });
  }
  if (fallbackContact && contact?.id !== fallbackContact?.id) {
    options.push({
      data: fallbackContact,
      label: fallbackContact.displayNameFull || fallbackContact.displayName,
      value: fallbackContact.id,
    });
  }

  return options;
};

/**
 * getMatterTypeaheadDefaultOption
 *
 * For pre-selected matter, provide the default data for the matter (typeahead) field
 *
 * @param {Object} params
 * @param {Object} params.matter
 * @returns {MatterSummaries}
 */
const getMatterTypeaheadDefaultOption = ({ matter }) => {
  if (!matter) {
    return [];
  }

  const typeahead = [
    matter.matterNumber,
    matter.clientDisplay,
    matter.otherSideDisplay,
    matter.matterType?.name,
    matter.attorneyResponsible?.name,
    matter.attorneyResponsible?.initials,
    matter.description,
  ];
  const defaultMatterSummaries = [
    {
      ...matter,
      display: getMatterDisplay(matter, matter.matterType?.name),
      matterClientNames: matter.clientNames,
      matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
      matterStartedISO: matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '',
      typeahead: typeahead.filter((m) => m).join(' '),
    },
  ];
  return defaultMatterSummaries;
};

export const AddPaymentModalFormsContainer = composeHooks(hooks)(composeHooks(dependentHooks)(AddPaymentModal));

AddPaymentModalFormsContainer.propTypes = {
  scope: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  matterId: PropTypes.string,
  printCheques: PropTypes.func,
  onClickLink: PropTypes.func,
  onModalClose: PropTypes.func.isRequired,

  createPDFReceiptOnTrustPayment: PropTypes.bool.isRequired,
  isTrustChequePrintingActiveForBankAccountId: PropTypes.func.isRequired,
  activeProviderType: PropTypes.string,
  activeProviderFormattedSettings: PropTypes.object.isRequired,
  isMatterContactBalanceFirm: PropTypes.bool.isRequired,
  checkIsStatutoryDepositMatter: PropTypes.func.isRequired,
  operatingAccount: PropTypes.object.isRequired,
  areDefaultsLoading: PropTypes.bool.isRequired,
  defaultMatter: PropTypes.object,
  defaultContact: PropTypes.object,

  paidByContactDetailsLoading: PropTypes.bool.isRequired,
  onGetPaidByContactDetails: PropTypes.func.isRequired,
  paidByContactDetails: PropTypes.object,
  firmName: PropTypes.string,
  loggedInStaffName: PropTypes.string,

  // payment source
  paymentSourceOptions: PropTypes.arrayOf(PropTypes.object),
  onGetPaymentSourceOptions: PropTypes.func.isRequired,
  hasPaymentProviderConfiguredForSource: PropTypes.func.isRequired,
  // invoice summaries
  invoiceSummariesLoading: PropTypes.bool.isRequired,
  invoiceSummaries: PropTypes.arrayOf(PropTypes.object),
  onFetchInvoiceSummaries: PropTypes.func.isRequired,
  // trust cheques
  lastTrustChequeNumber: PropTypes.string,
  nextTrustChequeNumber: PropTypes.string,
  trustChequeNumberLoading: PropTypes.bool.isRequired,
  onFetchAvailableTrustChequeNumbers: PropTypes.func.isRequired,
  // bank recs
  onFetchBankRecLatestCompleted: PropTypes.func.isRequired,
  bankReconciliationLatestCompletedByBankAccount: PropTypes.object,
  bankReconciliationSetup: PropTypes.object,
  bankReconciliationLoading: PropTypes.bool.isRequired,
};
AddPaymentModalFormsContainer.defaultProps = {
  contactId: undefined,
  matterId: undefined,
  printCheques: () => {},
  onClickLink: () => {},
  bankReconciliationLatestCompletedByBankAccount: undefined,
  bankReconciliationSetup: undefined,
  activeProviderType: undefined,
  defaultMatter: undefined,
  defaultContact: undefined,
  firmName: undefined,
  loggedInStaffName: undefined,
  invoiceSummaries: undefined,
  paidByContactDetails: undefined,
  lastTrustChequeNumber: undefined,
  nextTrustChequeNumber: undefined,
};

AddPaymentModalFormsContainer.displayName = 'AddPaymentModalFormsContainer';
