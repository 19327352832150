import React from 'react';
import { Button, buttonTypes, FloatingCard, LinkableText } from '@sb-itops/react';
import adeleThumbnail from 'web/assets/adele-thumbnail.png';
import { CircleInfo, Cross } from 'web/components/icons';
import * as Styles from './PromoCard.module.scss';

interface IPromoCardProps {
  children: React.JSX.Element;
  title: string;
  text: string;
  showCard: boolean;
  showBanner: boolean;
  onSetup: () => void;
  onDismiss: () => void;
  onFindOutMore?: () => void;
}

export const PromoCardBase = (props: IPromoCardProps) => {
  if (!props.showCard) {
    if (!props.showBanner) {
      return null;
    }
    return (
      <div className={Styles.pageBanner}>
        <div className={Styles.bannerContainer}>
          <CircleInfo fill="#024FAC" />
          <div className={Styles.bannerText}>{props.text}</div>
          <LinkableText text="Set Up Now" onClickLink={props.onSetup} asLink inline />
          {props.onFindOutMore && <LinkableText text="Find out more" onClickLink={props.onFindOutMore} asLink inline />}
        </div>
        <div className={Styles.close} onClick={props.onDismiss}>
          <Cross />
        </div>
      </div>
    );
  }

  return (
    <FloatingCard>
      <div className={Styles.promoCard}>
        <div className={Styles.form}>
          <div className={Styles.titleBar}>
            <div className={Styles.icon}>
              <img src={adeleThumbnail} alt="" />
            </div>
            <div>
              <strong>Adele</strong> from Smokeball
            </div>
          </div>
          <div className={Styles.content}>
            <div className={Styles.title}>{props.title}</div>
            <div>{props.text}</div>
          </div>
          <div className={Styles.footer}>
            <Button onClick={props.onSetup}>Set up now</Button>
            {props.onFindOutMore && (
              <Button type={buttonTypes.secondary} onClick={props.onFindOutMore}>
                Find Out More
              </Button>
            )}
            <Button className={Styles.dismissButton} onClick={props.onDismiss} type={buttonTypes.tertiary}>
              Dismiss
            </Button>
          </div>
        </div>
        <div className={Styles.graphic}>{props.children}</div>
      </div>
    </FloatingCard>
  );
};
