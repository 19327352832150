import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchCommand } from '@sb-integration/web-client-sdk';
import * as messageDisplay from '@sb-itops/message-display';
import { debounce } from '@sb-itops/nodash';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as forms from '@sb-itops/redux/forms2';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import uuid from '@sb-itops/uuid';

import { defaultStaffTargetFrequency, staffTargetType } from '@sb-billing/business-logic/staff-targets';

import { StaffTargetGroupUniqueNameCheck } from 'web/graphql/queries';
import { useSubscribedLazyQuery } from 'web/hooks';
import { sendMetric } from 'web/services/metrics';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';

import { AddEditTargetGroupSchema } from './AddEditTargetGroup.schema';
import { AddEditTargetGroup } from './AddEditTargetGroup';

function minutesToHours(minutes) {
  // hours should be rounded to 1 decimal place
  return Number((minutes / 60).toFixed(1));
}

function hoursToMinutes(hoursToOneDecimalPlace) {
  // as hours is always to 1 decimal place, minutes will always be an integer, defaults to 0 if not set
  return hoursToOneDecimalPlace ? hoursToOneDecimalPlace * 60 : 0;
}

const hooks = (props) => ({
  useStaffTargetGroupUniqueNameCheck: () => {
    const [doStaffTargetGroupUniqueNameCheck, staffTargetGroupUniqueNameCheckResponse] = useSubscribedLazyQuery(
      StaffTargetGroupUniqueNameCheck,
    );

    const checkStaffTargetGroupName = debounce(
      ({ targetGroupName }) => {
        doStaffTargetGroupUniqueNameCheck({
          context: { skipRequestBatching: true },
          variables: {
            name: targetGroupName,
            excludeStaffTargetGroupIds: props.staffTargetGroupId ? [props.staffTargetGroupId] : undefined, // exclude current target group
          },
        });
      },
      600, // wait in milliseconds, 300ms is too short which triggers too many requests
      { leading: false },
    );

    // default isUnique = true, it's better to allow saving even if this check fails
    // for whatever reason rather than blocking the saving of the stafftargetgroup all together
    const staffTargetGroupUniqueNameCheck =
      staffTargetGroupUniqueNameCheckResponse.data?.staffTargetGroupUniqueNameCheck;
    const isStaffTargetGroupNameUnique =
      staffTargetGroupUniqueNameCheck && staffTargetGroupUniqueNameCheck.isUnique !== undefined
        ? staffTargetGroupUniqueNameCheck.isUnique
        : true;

    return {
      isStaffTargetGroupNameUnique,
      isCheckingStaffTargetGroupName: staffTargetGroupUniqueNameCheckResponse.loading,
      checkStaffTargetGroupName,
    };
  },
});

const dependentHooks = (props) => ({
  useForm: ({ checkStaffTargetGroupName }) => {
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, 'add-edit-target-group-form');

    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
      formDirty,
      formValidation,
    } = useSelector(formSelectors.getFormState);

    const dispatch = useDispatch();

    if (!formInitialised && props.targetGroupId && props.currentTargetGroup) {
      // edit mode form initialisation
      let billableHours;
      let billableHoursFrequency;
      let billableValue;
      let billableValueFrequency;
      let workedHours;
      let workedHoursFrequency;
      let workedValue;
      let workedValueFrequency;
      let billedHours;
      let billedHoursFrequency;
      let billedValue;
      let billedValueFrequency;
      let collectedValue;
      let collectedValueFrequency;
      props.currentTargetGroup.targets.forEach((target) => {
        // value are stored as cents, i.e. $100 will be 10000
        // hours are stored as minutes, i.e. 60mins will be 60 which needs to be converted to hours for display/editing
        if (target.type === staffTargetType.BILLABLE_HOURS) {
          billableHours = target.value ? minutesToHours(target.value) : undefined;
          billableHoursFrequency = target.frequency;
        }
        if (target.type === staffTargetType.BILLABLE_VALUE) {
          billableValue = target.value ? target.value : undefined;
          billableValueFrequency = target.frequency;
        }
        if (target.type === staffTargetType.WORKED_HOURS) {
          workedHours = target.value ? minutesToHours(target.value) : undefined;
          workedHoursFrequency = target.frequency;
        }
        if (target.type === staffTargetType.WORKED_VALUE) {
          workedValue = target.value ? target.value : undefined;
          workedValueFrequency = target.frequency;
        }
        if (target.type === staffTargetType.BILLED_HOURS) {
          billedHours = target.value ? minutesToHours(target.value) : undefined;
          billedHoursFrequency = target.frequency;
        }
        if (target.type === staffTargetType.BILLED_VALUE) {
          billedValue = target.value ? target.value : undefined;
          billedValueFrequency = target.frequency;
        }
        if (target.type === staffTargetType.COLLECTED_VALUE) {
          collectedValue = target.value ? target.value : undefined;
          collectedValueFrequency = target.frequency;
        }
      });
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            targetGroupName: props.currentTargetGroup.name || '',
            billableHours,
            billableHoursFrequency,
            billableValue,
            billableValueFrequency,
            workedHours,
            workedHoursFrequency,
            workedValue,
            workedValueFrequency,
            billedHours,
            billedHoursFrequency,
            billedValue,
            billedValueFrequency,
            collectedValue,
            collectedValueFrequency,
            isInactive: props.currentTargetGroup?.isInactive || false,
          },
        }),
      );
    }

    const {
      targetGroupName,
      billableHours,
      billableHoursFrequency,
      billableValue,
      billableValueFrequency,
      workedHours,
      workedHoursFrequency,
      workedValue,
      workedValueFrequency,
      billedHours,
      billedHoursFrequency,
      billedValue,
      billedValueFrequency,
      collectedValue,
      collectedValueFrequency,
      isInactive,
    } = formFields;

    useEffect(() => {
      // add mode form initialisation
      if (!props.targetGroupId) {
        dispatch(
          formActions.initialiseForm({
            fieldValues: {
              targetGroupName: '',
              billableHours: undefined,
              billableHoursFrequency: defaultStaffTargetFrequency,
              billableValue: undefined,
              billableValueFrequency: defaultStaffTargetFrequency,
              workedHours: undefined,
              workedHoursFrequency: defaultStaffTargetFrequency,
              workedValue: undefined,
              workedValueFrequency: defaultStaffTargetFrequency,
              billedHours: undefined,
              billedHoursFrequency: defaultStaffTargetFrequency,
              billedValue: undefined,
              billedValueFrequency: defaultStaffTargetFrequency,
              collectedValue: undefined,
              collectedValueFrequency: defaultStaffTargetFrequency,
              isInactive: false,
            },
          }),
        );
      }
      const onUnload = () => {
        dispatch(formActions.clearForm());
      };
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateFn = (fieldValues) => {
      const formErrors = {};

      // TBC - ensure target group name is unique
      // const targetGroupNameMustBeUnique = props.targetGroups.some((targetGroup) => {
      //   if (targetGroup.id === id.value) {
      //     return false;
      //   }

      //   return targetGroup.name.trim() === fieldValues.targetGroupName.trim();
      // });

      // if (targetGroupNameMustBeUnique) {
      //   formErrors.targetGroupName = targetGroupNameMustBeUniqueErrorMessage;
      // }

      // ensure at least one target is set if target group is active
      if (
        !fieldValues.isInactive &&
        !fieldValues.billableHours &&
        !fieldValues.billableValue &&
        !fieldValues.workedHours &&
        !fieldValues.workedValue &&
        !fieldValues.billedHours &&
        !fieldValues.billedValue &&
        !fieldValues.collectedValue
      ) {
        formErrors.atLeastOneTargetRequired = 'At least one target value must be greater than zero.';
      }

      return formErrors;
    };

    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateForm({ schema: AddEditTargetGroupSchema, validateFn }));
    };

    const validateForm = () => {
      dispatch(formOperations.validateForm({ schema: AddEditTargetGroupSchema, validateFn }));
    };

    const onSave = async () => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formData) => {
              // setting defaultStaffTargetFrequency for any target that is not set
              // is important because new target types could be added in the future,
              // for which there won't be any initial value in existing StaffTargetGroup entities
              const saveTargetGroupMessage = {
                group: {
                  id: props.targetGroupId || uuid(),
                  name: formData.targetGroupName.trim(),
                  isInactive: formData.isInactive,
                  targets: [
                    {
                      type: staffTargetType.BILLABLE_HOURS,
                      frequency: formData.billableHoursFrequency || defaultStaffTargetFrequency,
                      value: hoursToMinutes(formData.billableHours),
                    },
                    {
                      type: staffTargetType.BILLABLE_VALUE,
                      frequency: formData.billableValueFrequency || defaultStaffTargetFrequency,
                      value: formData.billableValue || 0,
                    },
                    {
                      type: staffTargetType.WORKED_HOURS,
                      frequency: formData.workedHoursFrequency || defaultStaffTargetFrequency,
                      value: hoursToMinutes(formData.workedHours),
                    },
                    {
                      type: staffTargetType.WORKED_VALUE,
                      frequency: formData.workedValueFrequency || defaultStaffTargetFrequency,
                      value: formData.workedValue || 0,
                    },
                    {
                      type: staffTargetType.BILLED_HOURS,
                      frequency: formData.billedHoursFrequency || defaultStaffTargetFrequency,
                      value: hoursToMinutes(formData.billedHours),
                    },
                    {
                      type: staffTargetType.BILLED_VALUE,
                      frequency: formData.billedValueFrequency || defaultStaffTargetFrequency,
                      value: formData.billedValue || 0,
                    },
                    {
                      type: staffTargetType.COLLECTED_VALUE,
                      frequency: formData.collectedValueFrequency || defaultStaffTargetFrequency,
                      value: formData.collectedValue || 0,
                    },
                  ],
                },
              };

              await dispatchCommand({
                type: 'Billing.Fees.Commands.SaveStaffTargetGroup',
                message: saveTargetGroupMessage,
              });

              if (props.targetGroupId) {
                sendMetric('EditStaffTargetGroup');
              } else {
                sendMetric('AddStaffTargetGroup');
              }

              if (!formData.isInactive) {
                if (formData.billableHours) {
                  sendMetric('StaffTargetGroupWithBillableHours');
                }
                if (formData.billableValue) {
                  sendMetric('StaffTargetGroupWithBillableValue');
                }
                if (formData.workedHours) {
                  sendMetric('StaffTargetGroupWithWorkedHours');
                }
                if (formData.workedValue) {
                  sendMetric('StaffTargetGroupWithWorkedValue');
                }
                if (formData.billedHours) {
                  sendMetric('StaffTargetGroupWithBilledHours');
                }
                if (formData.billedValue) {
                  sendMetric('StaffTargetGroupWithBilledValue');
                }
                if (formData.collectedValue) {
                  sendMetric('StaffTargetGroupWithCollectedValue');
                }
              }

              props.onClose();
              await dispatch(formActions.clearForm());
              messageDisplay.success('Target group has been saved');
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to save target group');
      }
    };

    const onTargetGroupNameChange = (e) => {
      onFieldValueUpdated({ targetGroupName: e.target.value });
      checkStaffTargetGroupName({ targetGroupName: e.target.value });
    };

    return {
      // form fields
      targetGroupId: props.targetGroupId,
      targetGroupName,
      billableHours,
      billableHoursFrequency,
      billableValue,
      billableValueFrequency,
      workedHours,
      workedHoursFrequency,
      workedValue,
      workedValueFrequency,
      billedHours,
      billedHoursFrequency,
      billedValue,
      billedValueFrequency,
      collectedValue,
      collectedValueFrequency,
      isInactive,
      // form state
      formDirty,
      formInitialised,
      formSubmitting,
      formValidation,
      submitFailed,
      // func & callbacks
      onClose: props.onClose,
      onFieldValueUpdated,
      onSave,
      onTargetGroupNameChange,
      validateForm,
    };
  },
});

export const AddEditTargetGroupContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(AddEditTargetGroup))),
);

AddEditTargetGroupContainer.displayName = 'AddEditTargetGroupContainer';

AddEditTargetGroupContainer.propTypes = {
  currentTargetGroup: PropTypes.object, // this is loaded on demand when in edit mode, so could be momemtarily undefined
  targetGroupId: PropTypes.string, // this is set when edit mode
  onClose: PropTypes.func.isRequired,
};

AddEditTargetGroupContainer.defaultProps = {
  targetGroupId: undefined,
  currentTargetGroup: undefined,
};
