import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import uuid from '@sb-itops/uuid';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import moment from 'moment';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { opdateCache, rollbackOpdateCache, buildReversalTransactionOpdates } from './index';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

const getValidAccountTypes = () => {
  const validAccountTypes = [];
  if (hasFacet(facets.CMA)) {
    validAccountTypes.push(bankAccountTypeEnum.CONTROLLEDMONEY);
  }
  if (hasFacet(facets.trustAccountBankFee)) {
    validAccountTypes.push(bankAccountTypeEnum.TRUST);
  }
  return validAccountTypes;
};

// Only to be used for Controlled Money Accounts and Trust account in GB
export const reverseBankAccountFee = (transaction) => {
  const { transactionId: transactionIdToReverse } = transaction;

  if (!transactionIdToReverse) {
    throw new Error('Invalid Transaction, you need a transaction id for reversing a transaction');
  }
  if (!getValidAccountTypes().includes(transaction?.accountType)) {
    throw new Error('Invalid Account Type');
  }

  const reverseBankAccountFeeThunk = async () => {
    const newTransactionId = uuid();

    const opdateReversalTransaction = buildReversalFeeTransactionOpdates({
      originalTransaction: transaction,
      reversalTransactionId: newTransactionId,
    });

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: opdateReversalTransaction });

    try {
      if (featureActive('BB-13870')) {
        const bankAccountFeeReversal = {
          bankAccountId: transaction.bankAccountId,
          accountType: transaction.accountType,
          newTransactionId,
          effectiveDate: +moment().format('YYYYMMDD'),
          reason: transaction.reason,
          hideTransactions: !!transaction.deleteTransaction,
          transactionIdToReverse,
        };

        await dispatchCommand({
          type: 'Billing.Accounts.Messages.Commands.ReverseBankFeeDebit',
          message: bankAccountFeeReversal,
        });
      } else {
        const bankAccountFeeReversal = {
          newTransactionId,
          bankAccountId: transaction.bankAccountId,
          accountType: transaction.accountType,
          accountId: getAccountId(),
          userId: getUserId(),
          effectiveDate: +moment().format('YYYYMMDD'),
          reason: transaction.reason,
          hideTransactions: !!transaction.deleteTransaction,
        };

        const path = `/billing/bank-account/reversal/:accountId/bankfee/${transactionIdToReverse}`;
        const fetchOptions = { body: JSON.stringify(bankAccountFeeReversal) };
        await fetchPostP({ path, fetchOptions });
      }
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: opdateReversalTransaction });

      throw err;
    }
  };

  return store.dispatch(reverseBankAccountFeeThunk);
};

const buildReversalFeeTransactionOpdates = ({ originalTransaction, reversalTransactionId }) => {
  const reversalOpdates = buildReversalTransactionOpdates({
    originalTransaction,
    reversalTransactionId,
  });
  // reversalOpdates is [reversalTransaction, originalTransactionUpdated]
  // unlike regular transaction, bank fee opdate should have a reason and no note
  reversalOpdates[0].reason = reversalOpdates[0].note;
  delete reversalOpdates[0].note;

  return reversalOpdates;
};
