import { regionType, getRegion } from '@sb-itops/region';
import { featureActive } from '@sb-itops/feature';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { bankTransferTypeEnum } from '@sb-billing/business-logic/accounts/payment/bank-transfer-types';

const { Deposit, MatterAdjustment, BankFees, WithholdingTax, Interest, VendorPayment, Transfer } = transactionType;

const regionStrategy = {
  [regionType.AU]: {
    hideInternalNote: ({ transaction }) => transaction && transaction.type === MatterAdjustment,
    showBankDetails: ({ transaction, payment }) => {
      return (
        transaction &&
        transaction.type === VendorPayment &&
        payment &&
        ((payment.source === 'Bank transfer' &&
          payment.bankTransferType === bankTransferTypeEnum.BANK_TRANSFER_OR_DEPOSIT) ||
          (featureActive('BB-5948') && payment.source === 'Direct Debit'))
      );
    },
    showPayeeDetails: ({ transaction, payment }) => {
      return (
        transaction &&
        transaction.type === 'VendorPayment' &&
        payment &&
        payment.source.endsWith('Check') &&
        payment.payeeName
      );
    },
    isTransferTransaction: ({ transaction }) => transaction && transaction.type === Transfer,
    showReasonField: ({ transaction }) => transaction && ![BankFees, WithholdingTax, Interest].includes(transaction.type),
  },
  [regionType.GB]: {
    hideInternalNote: ({ transaction }) => transaction && transaction.type === MatterAdjustment,
    showBankDetails: ({ transaction, payment }) => {
      return (
        transaction &&
        transaction.type === VendorPayment &&
        payment &&
        ((payment.source === 'Bank transfer' &&
          payment.bankTransferType === bankTransferTypeEnum.BANK_TRANSFER_OR_DEPOSIT) ||
          (featureActive('BB-5948') && payment.source === 'Direct Debit'))
      );
    },
    showPayeeDetails: ({ transaction, payment }) => {
      return (
        transaction &&
        transaction.type === 'VendorPayment' &&
        payment &&
        payment.source.endsWith('Check') &&
        payment.payeeName
      );
    },
    isTransferTransaction: ({ transaction }) => transaction && transaction.type === Transfer,
    showReasonField: ({ transaction }) => transaction && ![BankFees, WithholdingTax, Interest].includes(transaction.type),
  },
  [regionType.US]: {
    hideInternalNote: () => false,
    showBankDetails: () => false,
    showPayeeDetails: () => false,
    isTransferTransaction: () => false,
    showReasonField: ({ transaction }) => {
      return (
        transaction.reason && (
          (featureActive('BB-10530') && transaction.type === Deposit) ||
          (transaction.type === 'VendorPayment' && transaction.bankAccountType.toUpperCase() === 'TRUST') ||
          (transaction.type === 'InvoicePayment' && transaction.bankAccountType.toUpperCase() === 'TRUST'))
      );
    },
  },
};

export default {
  ...regionStrategy[getRegion()],
};
