import { gql } from '@apollo/client';

const query = gql`
  query CreditCardPaymentModalData($id: ID!) {
    invoice(id: $id) {
      id
      invoiceNumber
      debtors {
        id
        contact {
          id
          displayName
          displayNameFull
        }
      }
      matter {
        id
      }
      totals {
        id
        unpaid
      }
    }
  }
`;

const notificationIds = ['InvoicingNotifications.InvoiceUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const CreditCardPaymentModalData = {
  query,
  notificationIds,
};
