import { gql } from '@apollo/client';

const query = gql`
  query BankAccountDetails(
    $bankAccountIds: [ID]
    $bankAccountFilter: BankAccountFilter
    $includeBeneficiaries: Boolean! # used for CMA only
  ) {
    bankAccounts(ids: $bankAccountIds, filter: $bankAccountFilter) {
      id
      accountType
      accountTypeDisplay
      accountName
      displayName
      location
      state
      statutoryDepositMatterId
      associatedMatterId
      beneficiaries @include(if: $includeBeneficiaries) {
        id
        displayName
        displayNameFull
      }
      trustToOfficeNumberingSettings {
        useManualNumbering
      }
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BankAccountDetails = {
  query,
  notificationIds,
};
