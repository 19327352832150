import composeHooks from '@sb-itops/react-hooks-compose';
import { useSubscribedQuery } from 'web/hooks';
import { BillingDashboardRecentMatters } from 'web/graphql/queries';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import React from 'react';
import { RecentlyViewedWidget } from './RecentlyViewedWidget';

interface Props {
  isEditing: boolean;
  onChange: Function;
  onRemove: Function;
  onClickLink: React.MouseEventHandler;
  settings: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hooks = (props: Props) => ({
  useGraphQLforRecentMatters: () => {
    const { data, loading, error } = useSubscribedQuery(BillingDashboardRecentMatters, {
      variables: {
        includeMatterBalance: false,
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: true,
          showUserRecentMatter: true,
        },
        sort: {
          fieldNames: ['recentlyViewed'],
          directions: ['DESC'],
        },
        limit: 5,
      },
    });

    if (error) {
      throw error;
    }
    return {
      matters: data?.matterList?.results || [],
      loading,
    };
  },
});

export const RecentlyViewedWidgetContainer = withApolloClient(composeHooks(hooks)(RecentlyViewedWidget));
