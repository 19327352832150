import { getMap as getBankAccountBalanceState }  from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getDebtorId as getMatterDebtorId, isEditable as isEditableMatter, getById as getMatterById } from '@sb-matter-management/redux/matters';
import { isClosedMatter } from '@sb-matter-management/business-logic/matters/services';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
const { getMatterBalance } = selectors;
import { getActiveProvider, isPaymentProviderEnabledForBankAccount } from '@sb-billing/redux/payment-provider-settings/selectors';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { bankAccountTypeEnum  } from '@sb-billing/business-logic/bank-account/entities/constants';
import { capitalize } from '@sb-itops/nodash';
import { hasFacet, facets } from '@sb-itops/region-facets';
import strategy from './matter-transaction-menu-strategy';
import { DEPOSIT_FUNDS_MODAL_ID, VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID, TRANSFER_FUNDS_MODAL_ID } from 'web/react-redux';
import { DEPOSIT_FUNDS_MODAL_ID as DEPOSIT_FUNDS_NEW_MODAL_ID } from 'web/components';
import { featureActive } from '@sb-itops/feature';

const PROTECT_BALANCE_ADD_MODAL_ID = 'protect-balance-add';

angular.module('sb.billing.webapp').component('sbMatterTransactionsMenu', {
  templateUrl: 'ng-routes/home/billing/view-matter/transactions/menu/matter-transactions-menu.html',
  bindings: { accountType: '<', matterId: '<', showHidden: '<', trustAccountId: '<' },
  controller: function (sbMattersMbService, sbDefaultMatterBillingConfigurationService, sbBillingConfigurationService, sbTrustRetainersService, sbBankAccountService, sbLocalisationService, sbLinkService) {
    const ctrl = this;

    ctrl.t = sbLocalisationService.t;
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.matter = sbMattersMbService.getById(ctrl.matterId);
    ctrl.bankAccountId = getBankAccountId();
    ctrl.isEditableMatter = () => isEditableMatter(ctrl.matterId);
    ctrl.isCreditCardDepositEnabled = isCreditCardDepositEnabled;
    ctrl.belowTrustRetainer = belowTrustRetainer;
    ctrl.isStatutoryDepositMatter = () => sbBankAccountService.isStatutoryDepositMatter(ctrl.matterId);
    ctrl.disableDepositFunds = disableDepositFunds;
    ctrl.onProtectBalanceAddClick = onProtectBalanceAddClick;
    ctrl.onDepositRequestButtonClick = onDepositRequestButtonClick;
    ctrl.onDepositRequestModalClose = onDepositRequestModalClose;
    ctrl.onTransferFundsClick = onTransferFundsClick;
    ctrl.onTrustDepositRequestButtonClick = onTrustDepositRequestButtonClick;
    ctrl.onTrustDepositRequestModalClose = onTrustDepositRequestModalClose;
    ctrl.showBankAccountActionButtons = showBankAccountActionButtons;
    ctrl.showViewStatementButton = strategy.showViewStatementButton();
    ctrl.showPrintButton = strategy.showPrintButton();
    ctrl.showBankFeeInterestButton = showBankFeeInterestButton();
    ctrl.onAddBankInterestClick = onAddBankInterestClick;
    ctrl.onAddBankFeeClick = onAddBankFeeClick;
    ctrl.onAddBankFeeInterestModalClose = onAddBankFeeInterestModalClose;
    ctrl.onDepositFundsClick = onDepositFundsClick;
    ctrl.onVendorPaymentEntrySplitPayorsClick = onVendorPaymentEntrySplitPayorsClick;
    ctrl.isNonBillableLead = isNonBillableLead;

    ctrl.view = {
      debtorId: undefined,
      accountTitle: getAccountTitle(ctrl.accountType),
      depositRequestModalOpened: false,
      trustDepositRequestModalOpened: false,
      addBankFeeModalOpened: false,
      addBankInterestModalOpened: false,
      bankFeeInterestButtonLabel: getBankFeeInterestButtonLabel(),
      disableTransacting: false,
      disableTransactingTooltip: undefined
    };
    
    ctrl.$onInit = () => {
      if (ctrl.matterId) {
        ctrl.view.debtorId = getMatterDebtorId(ctrl.matterId);
        const { isDisabled, tooltip } = transactingForBankAccountTypeDisabled({
          matterId: ctrl.matterId,
          bankAccountType: ctrl.accountType,
        });
        ctrl.view.disableTransacting = isDisabled;
        ctrl.view.disableTransactingTooltip = tooltip;
      }
    };
    
    ctrl.$onChanges = () => {
      ctrl.view.accountTitle = getAccountTitle(ctrl.accountType);
      ctrl.bankAccountId = getBankAccountId();
      ctrl.showBankFeeInterestButton = showBankFeeInterestButton();


      if (ctrl.matterId) {
        ctrl.view.debtorId = getMatterDebtorId(ctrl.matterId);
        const { isDisabled, tooltip } = transactingForBankAccountTypeDisabled({
          matterId: ctrl.matterId,
          bankAccountType: ctrl.accountType,
        });
        ctrl.view.disableTransacting = isDisabled;
        ctrl.view.disableTransactingTooltip = tooltip;
      }
    };

    function getBankFeeInterestButtonLabel() {
      const labels = [];
      if (hasFacet(facets.trustAccountInterest)) {
        labels.push('INTEREST');
      } 
      if (hasFacet(facets.trustAccountBankFee)) {
        labels.push('FEES');
      }
      return labels.join('/');
    }

    function showBankFeeInterestButton() {
      return (
        ctrl.accountType === bankAccountTypeEnum.TRUST &&
        (hasFacet(facets.trustAccountBankFee) || hasFacet(facets.trustAccountInterest))
      );
    }

    function showBankAccountActionButtons() {
      return strategy.showBankAccountActionButtons({ accountType: ctrl.accountType })
    }

    function getBankAccountId() {
      if (ctrl.accountType === bankAccountTypeEnum.TRUST) {
        return ctrl.trustAccountId;
      }
      if (ctrl.accountType === bankAccountTypeEnum.OPERATING) {
        return getOperatingAccount().id;
      }
    }

    function getAccountTitle (accountType) {
      if (!accountType) {
        return;
      }
      return accountType === 'OPERATING' ? `${capitalize(accountType.toLowerCase())} Retainer` : ctrl.t(accountType.toLowerCase());
    }

    function isCreditCardDepositEnabled () {
      if (!hasFacet(facets.operatingAccount) && ctrl.accountType === 'OPERATING') {
        // if we somehow get to operating account page in AU
        // we don't want to see credit card deposit buttons even if it is connected
        return false;
      }
      const providerType = getActiveProvider();
      const bankAccountId = getBankAccountId();

      return !!ctrl.accountType && providerType && isPaymentProviderEnabledForBankAccount({ bankAccountId, providerType });
    }

    function belowTrustRetainer () {
      if (ctrl.matterId) {
        const defaultConfig = sbDefaultMatterBillingConfigurationService.getConfig();
        const matterConfig = sbBillingConfigurationService.getByMatterId(ctrl.matterId);
        const trustRetainer = sbTrustRetainersService.getById(ctrl.matterId);
        return defaultConfig && matterConfig && trustRetainer && defaultConfig.minimumTrustRetainerActive && matterConfig.minimumTrustRetainerActive && !trustRetainer.replenished;
      }

      return false;
    }

    // In AU/GB, we don't want to allow trust transactions on closed matters
    function transactingForBankAccountTypeDisabled({ matterId, bankAccountType }) {
      if (!hasFacet(facets.blockTrustTransactionsOnClosedMatter) || bankAccountType !== bankAccountTypeEnum.TRUST || !matterId) {
        return { isDisabled: false, tooltip: '' };
      }

      const matterIsClosed = isClosedMatter(getMatterById(matterId));

      return {
        isDisabled: matterIsClosed,
        tooltip: matterIsClosed ? 'Cannot transact on closed matters' : '',
      };
    }

    function disableDepositFunds() {
      // this guard needs to be here as this function is called in the template, meaning there are are some timing issues
      // between the component being unmounted and digest cycles.
      // this is why you should avoid calling functions in templates as much as possible.
      if (!ctrl.accountType) {
        return false;
      }
      const bankAccountId = getBankAccountId();
      const matterBalance = ctrl.matterId ? getMatterBalance(getBankAccountBalanceState(), { bankAccountId, matterId: ctrl.matterId }) : 0;
      return ctrl.isStatutoryDepositMatter() && (matterBalance >= 0);
    }

    function onDepositFundsClick() {
      if (featureActive('BB-14323')) {
        setModalDialogVisible({
          modalId: DEPOSIT_FUNDS_NEW_MODAL_ID,
          props: {
            scope: 'MatterTransactionsMenu/deposit-funds-modal',
            matterId: ctrl.matterId,
            bankAccountId: ctrl.bankAccountId,
            contactId: ctrl.view.debtorId,
          },
        });
      } else {
        setModalDialogVisible({
          modalId: DEPOSIT_FUNDS_MODAL_ID,
          props: { matterId: ctrl.matterId, bankAccountId: ctrl.bankAccountId, contactId: ctrl.view.debtorId },
        });
      }
    }

    function onTransferFundsClick() {
      setModalDialogVisible({modalId: TRANSFER_FUNDS_MODAL_ID, props: { matterId: ctrl.matterId, bankAccountId: ctrl.bankAccountId, contactId: ctrl.view.debtorId }});
    }    
    
    function onVendorPaymentEntrySplitPayorsClick() {
      setModalDialogVisible({modalId: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID, props: { matterId: ctrl.matterId, bankAccountId: ctrl.bankAccountId, contactId: ctrl.view.debtorId }});
    }
    
    function onProtectBalanceAddClick() {
      setModalDialogVisible({modalId: PROTECT_BALANCE_ADD_MODAL_ID, props: { matterId: ctrl.matterId, bankAccountId: ctrl.bankAccountId }});
    }
    
    function onDepositRequestButtonClick() {
      ctrl.view.depositRequestModalOpened = true; // used instead if setModalDialogVisible
    }
    function onDepositRequestModalClose() {
      ctrl.view.depositRequestModalOpened = false; // used instead of setModalDialogHidden
    }
    function onTrustDepositRequestButtonClick() {
      ctrl.view.trustDepositRequestModalOpened = true; // used instead if setModalDialogVisible
    }
    function onTrustDepositRequestModalClose() {
      ctrl.view.trustDepositRequestModalOpened = false; // used instead of setModalDialogHidden
    }
    function onAddBankInterestClick() {
      ctrl.view.addBankInterestModalOpened = true; 
    }
    function onAddBankFeeClick() {
      ctrl.view.addBankFeeModalOpened = true; 
    }
    function onAddBankFeeInterestModalClose() {
      ctrl.view.addBankFeeModalOpened = false; 
      ctrl.view.addBankInterestModalOpened = false; 
    }

    function isNonBillableLead() {
      return ctrl.matter && ctrl.matter.isLead && !ctrl.matter.matterNumber;
    }
  }
});
