import { gql } from '@apollo/client';
import { matterTableFragment } from '../fragments/matterTableFragment.graphql';

// Important: List-type queries will only return the fields that have been
// queried and mapped in the list resolver. Any fields not mapped correctly
// will return `null`. Please test any changes thoroughly.

const query = gql`
  query BillingDashboardRecentMatters(
    $matterListFilter: MatterListFilter
    $offset: Int
    $limit: Int
    $includeMatterBalance: Boolean!
    $sort: ListSort
  ) {
    matterList(filter: $matterListFilter, offset: $offset, limit: $limit, sort: $sort) {
      results {
        ...matterTableFragment
        isLead
      }
    }
  }

  ${matterTableFragment}
`;

const notificationIds = ['IGNORED']; // alt: BillingMattersNotifications.RecentMattersUpdated

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BillingDashboardRecentMatters = {
  query,
  notificationIds,
};
