import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { capitalize } from '@sb-itops/nodash';
import { isMatterContactBalanceType } from '@sb-billing/redux/bank-account-settings';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { MattersDisplay } from '@sb-matter-management/react/matters-display';
import { ContactDisplay } from '@sb-customer-management/react/contact-display';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { ContextMenu } from '@sb-itops/react/context-menu';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import { InterpolatedDescription } from 'web/react-redux/components/interpolated-description';

import styles from './TrustTransactionTable.module.scss';

const {
  amountCellLocalisedRenderer,
  balanceCellLocalisedRenderer,
  yyyymmddLocalisedRenderer,
  timestampLocalisedRenderer,
} = utils;

const systemDateRenderer = ({ rowData, cellData }) => {
  if (rowData.type.toLowerCase() === transactionType.Transfer.toLowerCase()) {
    return null;
  }

  return timestampLocalisedRenderer({ cellData });
};

const TrustTransactionTable = ({
  modal,
  transactions,
  showContact,
  showMatter,
  sortBy,
  sortDirection,
  summary,
  sort,
  showSystemDate,
  showHidden,
  printCheque,
  onClickLink,
  onReceiptLink,
  onVendorProofOfPaymentLink,
  onTransferReceiptLink,
  onTrustToOfficeTransferReceiptLink,
  allowReferenceSort,
}) => {
  const { t } = useTranslation();
  const contextMenuCellRenderer = ({ rowData }) => {
    const { hasReceipt, cheque, id, paymentId, isVendorPayment, isMatterTransfer, isTrustToOfficeTransfer } = rowData;
    // only matter transfers with a paymentId can render a pdf
    const showPrintMatterTransfer = featureActive('BB-7634') && isMatterTransfer && paymentId;

    return (
      (hasReceipt ||
        (cheque && cheque.isPrinted) ||
        isVendorPayment ||
        showPrintMatterTransfer ||
        isTrustToOfficeTransfer) && (
        <ContextMenu
          // eslint-disable-next-line react/no-unstable-nested-components
          body={() => (
            <div className="context-menu-body list-group">
              {hasReceipt && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => onReceiptLink(getReceiptLinkParams(rowData))}
                >
                  Print Receipt
                </button>
              )}
              {isVendorPayment && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => onVendorProofOfPaymentLink({ transactionId: id, paymentId })}
                >
                  {t('printReceipt')}
                </button>
              )}
              {showPrintMatterTransfer && (
                <button type="button" className="list-group-item" onClick={() => onTransferReceiptLink({ paymentId })}>
                  {t('printReceipt')}
                </button>
              )}
              {isTrustToOfficeTransfer && (
                <button
                  type="button"
                  className="list-group-item"
                  onClick={() => onTrustToOfficeTransferReceiptLink({ paymentId: paymentId || id })}
                >
                  {t('printReceipt')}
                </button>
              )}
              {cheque && cheque.isPrinted && (
                <button type="button" className="list-group-item" onClick={() => printCheque(cheque.chequeId)}>
                  Reprint {capitalize(t('cheque'))}
                </button>
              )}
            </div>
          )}
        >
          <div className="context-menu-cell">...</div>
        </ContextMenu>
      )
    );
  };

  const matterCellRenderer = ({ rowData }) => {
    const { matter, matterIds, matterDisplay, isBulkDeposit } = rowData;

    if (isBulkDeposit) {
      return <span title="Show matters">{matterDisplay}</span>;
    }

    if (matterIds && matterIds.length) {
      return <MattersDisplay matterIds={matterIds} onClickLink={onClickLink} asLink />;
    }

    return <MatterDisplay matterId={matter && matter.matterId} onClickLink={onClickLink} asLink />;
  };

  const contactCellRenderer = ({ rowData }) => {
    const { type } = rowData;

    if (!isMatterContactBalanceType()) {
      switch (type) {
        case transactionType.Deposit: // Deliberate fall-through to MatterAdjustmentReversal
        case transactionType.DepositReversal: // Deliberate fall-through to MatterAdjustmentReversaln
        case transactionType.MatterAdjustment: // Deliberate fall-through to MatterAdjustmentReversal
        case transactionType.MatterAdjustmentReversal:
          break;
        case transactionType.InvoicePayment: // Deliberate fall-through to InvoicePaymentReversal
        case transactionType.InvoicePaymentReversal: {
          // If the invoice payment is from a direct source, we need to show the contact.
          // This is only relevant in the trust transaction list in the case of overpayments in AU, where a direct payment
          // of an invoice effectively creates a deposit with the overpaid amount.
          if (rowData.source) {
            break;
          }
          // Deliberate fall-through to VendorPaymentReversal
        }
        // eslint-disable-next-line no-fallthrough
        case transactionType.BankFees: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.BankFeesReversal: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.WithholdingTax: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.WithholdingTaxReversal: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.Interest: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.InterestReversal: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.Transfer: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.VendorPayment: // Deliberate fall-through to VendorPaymentReversal
        case transactionType.VendorPaymentReversal:
          return <span />;
        default:
          throw new Error(`Encountered unknown transaction type: ${JSON.stringify(type)}`);
      }
    }

    const contactIds = rowData.contactIds || [rowData.contactId];
    const tooltip =
      rowData.contactIds && // If multiple contacts process the tooltip, otherwise use default
      contactIds.map((contactId) => getContactDisplay(contactId, { showLastNameFirst: true })).join(' | ');

    return (
      <div className={styles.invoiceDebtors}>
        <span className={styles.debtorsDisplay}>
          {contactIds
            .map((contactId) => (
              <ContactDisplay
                key={contactId}
                contactId={contactId}
                onClickLink={onClickLink}
                asLink
                inline
                showLastNameFirst
                tooltip={tooltip}
              />
            ))
            .reduce((acc, elem) => {
              if (acc === null) {
                return [elem];
              }
              return [...acc, ' | ', elem];
            }, null)}
        </span>
        {contactIds.length > 1 && (
          <span className={styles.multiDebtorsIcon}>
            <i title={tooltip} className={classnames('icon', 'icon-contacts-1')} />
          </span>
        )}
      </div>
    );
  };

  const descriptionCellRenderer = ({ rowData, cellData }) => (
    <InterpolatedDescription parts={cellData} deleted={rowData.isHidden} onClickLink={onClickLink} />
  );
  return (
    <Table
      className="trust-txn-table"
      onRowClick={({ rowData }) => modal({ rowData, showHidden })}
      list={transactions}
      sort={sort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      summary={summary}
      showFooter
    >
      <Column
        className="date effective-date"
        dataKey="effectiveDate"
        label="Transaction"
        cellRenderer={yyyymmddLocalisedRenderer}
        flexGrow={2}
      />
      {showSystemDate && (
        <Column className="date" dataKey="timestamp" label="Entered" cellRenderer={systemDateRenderer} width={100} />
      )}
      <Column
        dataKey="description"
        label="Description"
        cellRenderer={descriptionCellRenderer}
        flexGrow={3}
        disableSort
      />
      <Column dataKey="reference" label="Reference" flexGrow={2} disableSort={!allowReferenceSort} />
      {showMatter && (
        <Column dataKey="matter" label="Matter" cellRenderer={matterCellRenderer} flexGrow={4} disableSort />
      )}
      {showContact && (
        <Column
          dataKey="contact"
          label={featureActive('BB-4416') ? 'Contact(s)' : 'Contact'}
          cellRenderer={contactCellRenderer}
          flexGrow={4}
          disableSort
        />
      )}
      <Column
        className="right-align debit"
        dataKey="debit"
        label="Debit"
        cellRenderer={amountCellLocalisedRenderer}
        width={150}
        footerRenderer={balanceCellLocalisedRenderer}
        disableSort
      />
      <Column
        className="right-align credit"
        dataKey="credit"
        label="Credit"
        cellRenderer={amountCellLocalisedRenderer}
        width={150}
        footerRenderer={balanceCellLocalisedRenderer}
        disableSort
      />
      <Column
        className="right-align balance"
        dataKey="balance"
        label="Balance"
        cellRenderer={balanceCellLocalisedRenderer}
        width={150}
        footerRenderer={balanceCellLocalisedRenderer}
        disableSort
      />
      <Column dataKey="" label="" cellRenderer={contextMenuCellRenderer} width={40} disableSort />
    </Table>
  );
};

function getReceiptLinkParams(rowData) {
  if (!rowData.hasReceipt) {
    return {};
  }

  if (rowData.isBulkDeposit) {
    // We use bulkDepositId for bulk deposits but we can optionally provide transactionId.
    // This is needed when we open bulk deposit receipt from a matter as we can later use
    // transactionId for context to get matter and email when emailing receipt
    const { id, transactionIds } = rowData;
    return {
      bulkDepositId: id,
      // bulk deposits have at least 2 transactions but if bulk deposit pseudo-transaction is displayed
      // on matter, transactionIds have only 1 transaction, not all of them.
      // If you display bulk deposit pseudo-transaction on global screen, transactionIds contain all transactions.
      transactionId: transactionIds.length === 1 ? transactionIds[0] : null,
    };
  }

  return { transactionId: rowData.id };
}

TrustTransactionTable.propTypes = {
  printCheque: PropTypes.func,
  onReceiptLink: PropTypes.func,
  onTransferReceiptLink: PropTypes.func.isRequired,
  onVendorProofOfPaymentLink: PropTypes.func,
  onTrustToOfficeTransferReceiptLink: PropTypes.func.isRequired,
  onClickLink: PropTypes.func,
  modal: PropTypes.func,
  transactions: PropTypes.array,
  showContact: PropTypes.bool,
  showHidden: PropTypes.bool,
  showMatter: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  summary: PropTypes.object,
  sort: PropTypes.func,
  showSystemDate: PropTypes.bool,
  allowReferenceSort: PropTypes.bool.isRequired,
};

TrustTransactionTable.defaultProps = {
  printCheque: () => {},
  onReceiptLink: () => {},
  onVendorProofOfPaymentLink: () => {},
  onClickLink: () => {},
  modal: undefined,
  transactions: [],
  showContact: undefined,
  showMatter: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  summary: undefined,
  sort: undefined,
  showSystemDate: false,
  showHidden: false,
};

export default TrustTransactionTable;
