import { useCreateEmail } from 'web/hooks';

export const useClientSdkCreateEmail = () => {
  const { createEmail } = useCreateEmail();

  const clientSdkCreateEmail = async (matterId, payload) => {
    const response = await createEmail(
      matterId,
      payload.subject,
      payload.bodyContentType,
      payload.bodyHtml,
      payload.to,
      payload.cc,
      payload.bcc,
      payload.attachments,
    );
    const createEmailUrl = response?.url;
    if (!createEmailUrl) {
      throw new Error(`The URL was missing from the response`);
    }
    window.open(createEmailUrl, '_blank');
  };

  return { clientSdkCreateEmail };
};
