import React, { useEffect } from 'react';
import { SbClientSdk } from 'web/react-redux/components/sb-client-sdk';
import { withApolloClient } from 'web/react-redux/hocs';

import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { sendMetric } from 'web/services/metrics';

interface ContainerProps {
  url: string;
  matterId: string;
  onClickLink: any;
}

const Container = (props: ContainerProps) => {
  const { url, onClickLink, matterId } = props;

  useEffect(() => {
    sendMetric('OpenSbClientSdkIframe');
  }, []);

  return url ? <SbClientSdk sdkAppUrl={url} onClickLink={onClickLink} matterId={matterId} /> : null;
};

export const SdkIframeContainer = withApolloClient(withReduxProvider(Container));
