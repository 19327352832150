import PropTypes from 'prop-types';

export const sectionKeys = Object.freeze({
  matterDetailsSection: 'matterDetailsSection',
  matterStaffSection: 'matterStaffSection',
  matterContactsSection: 'matterContactsSection',
  billingFeesAndRatesSection: 'billingFeesAndRatesSection',
  trustSettingsSection: 'trustSettingsSection',
  latePaymentSection: 'latePaymentSection',
  utbmsSection: 'utbmsSection',
  communicationSettingsSection: 'communicationSettingsSection',
  invoiceSettingsSection: 'invoiceSettingsSection',
});

export const sectionFieldsFor = {
  [sectionKeys.matterDetailsSection]: [
    'locationId',
    'matterTypeCategory',
    'matterTypeId',
    'originalMatterTypeId',
    'clientRole',
    'matterNumber',
    'status',
    'description',
  ],
  [sectionKeys.matterStaffSection]: ['attorneyResponsibleId', 'personAssistingId', 'originatingAttorneyId'],
  [sectionKeys.matterContactsSection]: [
    'clientCustomerIdsIsRequired',
    'otherSideRole',
    'otherSideCustomerIds',
    'clientCustomerIds',
    'debtorIds',
    'splitBillingDebtorRatiosMustAddUpto100',
    'splitBillingRemainderDebtorId',
  ],
  [sectionKeys.billingFeesAndRatesSection]: [
    'billingType',
    'contingencyAmount',
    'amount',
    'billingUnitOption',
    'customUnit',
    'rateType',
    'rate',
    'customRatePerStaffMatterHourlyRates',
    'rateSetId',
    'billingFrequency',
    'billingFrequencySubType',
  ],
  [sectionKeys.trustSettingsSection]: [
    'minimumTrustRetainerActive',
    'minimumTrustRetainerAmount',
    'trustRetainerReplenishAmount',
    'defaultTrustAccountId',
  ],
  [sectionKeys.latePaymentSection]: [
    'interestSettings.hasInterestRate',
    'interestSettings.ratePa',
    'interestSettings.hasGracePeriod',
    'interestSettings.interestApplyMode',
    'interestSettings.applyInterestToUnpaidInvoices',
    'interestSettings.gracePeriodDays',
  ],
  [sectionKeys.utbmsSection]: ['isUtbmsEnabled', 'ledesFirmId', 'ledesMatterId', 'ledesClientMatterId'],
  [sectionKeys.communicationSettingsSection]: [
    'ccAddresses',
    'bccAddresses',
    'documentSendingPreferences',
    'eBillingSite',
    'otherMethod',
  ],
  [sectionKeys.invoiceSettingsSection]: [
    'invoiceTitleLine1Option',
    'invoiceTitleLine1CustomText',
    'invoiceTitleLine1Overridden',
    'invoiceTitleLine2Option',
    'invoiceTitleLine2CustomText',
    'invoiceTitleLine2Overridden',
    'invoiceFooter',
    'invoiceFooterOverridden',
    'invoiceTemplateId',
    'surchargeAmount',
    'surchargePercentage',
  ],
};

export const panelItemType = {
  title: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isErrorState: PropTypes.bool.isRequired,
  handleScroll: PropTypes.func.isRequired,
};

export const panelItemsType = PropTypes.arrayOf(PropTypes.exact(panelItemType));

const sectionRefType = PropTypes.exact({ current: PropTypes.instanceOf(Element) });

export const sectionRefsType = PropTypes.exact({
  [sectionKeys.matterDetailsSection]: sectionRefType.isRequired,
  [sectionKeys.matterStaffSection]: sectionRefType.isRequired,
  [sectionKeys.matterContactsSection]: sectionRefType.isRequired,
  [sectionKeys.billingFeesAndRatesSection]: sectionRefType.isRequired,
  [sectionKeys.trustSettingsSection]: sectionRefType.isRequired,
  [sectionKeys.latePaymentSection]: sectionRefType.isRequired,
  [sectionKeys.utbmsSection]: sectionRefType.isRequired,
  [sectionKeys.communicationSettingsSection]: sectionRefType.isRequired,
  [sectionKeys.invoiceSettingsSection]: sectionRefType.isRequired,
});
