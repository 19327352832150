'use strict';

angular.module('sb.billing.webapp').controller('DataMigrationController', function ($state, $scope, sbLinkService) {
  const that = this;
  that.onClickLink = sbLinkService.onClickLink;

  that.close = function () {
    if ($scope.previousState && $scope.previousState !== '' && !$scope.previousState.match(/^settings/)) {
      if (!_.isEmpty($scope.previousParams)) {
        $state.go($scope.previousState, $scope.previousParams);
      }
      else {
        $state.go($scope.previousState);
      }
    }
    else {
      $state.go('home.billing.dashboard');
    }
  };
});
