import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplay from '@sb-itops/message-display';

angular.module('sb.billing.webapp').component('sbDepositReceiptView', {
  templateUrl: 'ng-components/deposit-receipt-view/deposit-receipt-view.html',
  controller: function ($rootScope, $stateParams, sbNotifiedOperationP, sbUnsavedChangesService) {
    'use strict';
    const ctrl = this;
    const log = getLogger('SbDepositReceiptView');
    const DEPOSIT_RECEIPT_TYPE = 'deposit-receipt';

    ctrl.$onInit = () => {
      ctrl.sbParams = _.cloneDeep($stateParams); // need to do this while we have the stone age ui-router
      ctrl.sbData = {};

      ctrl.savedChangesKey = `deposit-receipt_${ctrl.sbParams.bulkDepositId}_${ctrl.sbParams.transactionId}_${ctrl.sbParams.contactId}`;

      ctrl.getPayloadConfig = {
        endpoint: 'billing/deposit-receipt-pdf',
        additional: `${ctrl.sbParams.bulkDepositId || ctrl.sbParams.transactionId}/presigned-url`,
      };

      ctrl.tabCloseListenerDeregister = $rootScope.$on('smokeball-tab-closed', onTabClosed);
      ctrl.pdfUrl = sbUnsavedChangesService.loadMemory(ctrl.savedChangesKey);

      ctrl.view = {
        receiptLoaded: !!ctrl.pdfUrl,
      };
    };

    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.onPdfLoaded = onReceiptLoaded;
    ctrl.onNotificationsP = onNotificationsP;
    ctrl.onError = onError;

    function onTabClosed(e, { type, transactionId, bulkDepositId, contactId }) {
      // Check if the tab containing this component is being closed.
      if (type === DEPOSIT_RECEIPT_TYPE && transactionId === ctrl.sbParams.transactionId && bulkDepositId === ctrl.sbParams.bulkDepositId && contactId === ctrl.sbParams.contactId) {
        ctrl.tabCloseListenerDeregister();
        URL.revokeObjectURL(ctrl.pdfUrl);
        ctrl.pdfUrl = undefined;
        sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey);
      }
    }

    function onReceiptLoaded (pdfUrl) {
      ctrl.pdfUrl = pdfUrl;
      sbUnsavedChangesService.saveMemory(ctrl.savedChangesKey, pdfUrl);
      ctrl.view.receiptLoaded = true;
    }

    function dataChangeFunction (key, doc) {
      log.info('data change', key, doc);

      if (doc && doc.data) {
        ctrl.sbData[key] = doc.data;
      } else {
        ctrl.sbData[key] = doc;
      }
    }

    function onNotificationsP() {
      return Promise.all([
        waitForReceiptNotificationP(),
        waitForTransactionNotificationP(),
      ]);
    }

    async function waitForReceiptNotificationP () {
      await sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'AccountsNotifications',
        completionFilterFn: (message) => {
          const entityId = ctrl.sbParams.bulkDepositId || ctrl.sbParams.transactionId;
          return message.messageId === 'ReceiptUpdated' && message.entityId === entityId },
        timeoutMs: 10000,
      });
    }

    async function waitForTransactionNotificationP () {
      sbNotifiedOperationP(() => Promise.resolve(), {
        completionNotification: 'AccountsNotifications',
        completionFilterFn: (message) => {
          if (ctrl.sbParams.bulkDepositId) {
            return message.messageId === 'BulkDepositUpdated' && message.entityId === ctrl.sbParams.bulkDepositId;
          }
          // transaction
          return message.messageId === 'AccountTransactionsRecorded' && _.includes(message.entityIds, ctrl.sbParams.transactionId)},
        timeoutMs: 10000,
      });
    }

    function onError(err) {
      log.error(`Failed to download deposit receipt pdf for transactionId: '${ctrl.sbParams.transactionId}', bulkDepositId: '${ctrl.sbParams.bulkDepositId}'`, err);
      messageDisplay.error(`Failed to download deposit receipt pdf`);
    }
  }
});
